import { useState, useContext, useEffect, Fragment } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import MessagingContent from './MessagingContent'
import MessagingActions from './MessagingActions'

import MessagingSlideoverFilter from './filter/MessagingSlideoverFilter'
import MessagingTopics from './MessagingTopics'
import { blankResult, reqOk } from '../common/fetchdata';
import { GlobalStateContext } from '../state/globalState';

import {
    options as _options,
    parseUrl,
    moduleSelected,
    getNfilters,
    updateLeadsList
} from './data/messagingSelect'

import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
import { MessagingSearchHeader } from './MessagingSearchHeader';
import MessagingDashboard from './MessagingDashboard';
import MessagingNewContentBanner from './MessagingNewContentBanner';
import MessagingTabSelector from './MessagingTabSelector'
import MessagingMyLeads from './MessagingMyLeads';
moment.locale('pt');

export default function MessagingHome() {

    const [topics, setTopics] = useState(blankResult)
    const [topicsRead, setTopicRead] = useState([])
    const [topic, setTopic] = useState(blankResult)
    const [pendingRefresh, setPendingRefresh] = useState([])
    const [selectBatch, setSelectBatch] = useState([])
    const [openso_filter, setOpenso_filter] = useState(false);
    const [navigateAction, setNavigateAction] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState({})
    const _gc = useContext(GlobalStateContext);
    const globalUiUpdateBookingContact = _gc.contextFcn.globalUiUpdate.newPublicBookingContact;
    const globalUiUpdateContactMessage = _gc.contextFcn.globalUiUpdate.newPublicContactMessage;
    const globalUiUpdatePhoneCentral = _gc.contextFcn.globalUiUpdate.newPhoneCentralImport;
    const user = _gc.contextFcn.userData.data.items.user;


    let navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const { search } = location;

    // On URL change
    useEffect(() => {
        // capture page load
        const newSearchParams = new URLSearchParams(searchParams);
        const pivot = newSearchParams.get('screen');
        if (!pivot) setNavigateAction(['dashboard'])
        else setNavigateAction(['topics', 'topic'])

        parseUrl(
            navigateAction,
            searchParams,
            topics,
            setTopics,
            topic,
            setTopic)
    }, [search])


    // On page load
    useEffect(() => {
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.delete('_k');
        const uniqueKey = new Date().getTime();
        newSearchParams.set('_k', uniqueKey);
        navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }, [])


    // Loading screens
    useEffect(() => {
        //loadingScreen(topics, _gc)
    }, [topics]);

    useEffect(() => {
        //loadingScreen(topic, _gc)
        if (reqOk(topic)) _handleSelectTopic()
    }, [topic]);

    // Load inital topics read
    useEffect(() => {
        if(user && reqOk(topics)){
            const readTopics = topics.items.data.filter( t => t.is_read_by.indexOf(user.id) != -1).map(t => t.id)
            setTopicRead(readTopics)
        }
        
    }, [topics, user]);


    // auto refresh
    useEffect(() => {

        if (globalUiUpdateBookingContact) {
            setPendingRefresh(s => ([...s, 'contact-message']))
        }
        if (globalUiUpdateContactMessage) {
            setPendingRefresh(s => ([...s, 'phone-call']))
        }
        if (globalUiUpdatePhoneCentral) {
            setPendingRefresh(s => ([...s, 'dm']))
        }

        // update thread list
        // random to avoid many requests at the same time
        setTimeout(function(){
            updateLeadsList(searchParams, navigate)
        }, Math.floor(Math.random() * (2000 - 0 + 1)) + 0);

    }, [globalUiUpdateBookingContact, globalUiUpdateContactMessage, globalUiUpdatePhoneCentral])


    // when there is a change in the filtering
    // the first action is to change the URL
    function applyPagination(args) {

        if ('page' in args) {
            setNavigateAction(['topics'])
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set('page', args.page);
            newSearchParams.delete('_k');
            const uniqueKey = new Date().getTime();
            newSearchParams.set('_k', uniqueKey);
            const newURL = `${location.pathname}?${newSearchParams.toString()}`;
            navigate(newURL, { replace: true });
        }
    }

    // apply search
    function handleSearch(searchStr) {
        setNavigateAction(['topics'])
        const newSearchParams = new URLSearchParams('');
        // reset args
        newSearchParams.set('screen', 'search');
        newSearchParams.set('lookup', searchStr);
        newSearchParams.set('_k', new Date().getTime());

        const newURL = `${location.pathname}?${newSearchParams.toString()}`;
        navigate(newURL, { replace: true });
    }

    function handleResetSearch() {
        setNavigateAction(['topics'])
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.delete('_k');
        const uniqueKey = new Date().getTime();
        newSearchParams.set('_k', uniqueKey);
        newSearchParams.delete('lookup');
        navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }

    function handleSelectTopic(ev) {
        setNavigateAction(['topic'])
        const newSearchParams = new URLSearchParams(searchParams);
        const _currentView = newSearchParams.get('screen');
        if (_currentView == null) newSearchParams.set('screen', 'results');
        newSearchParams.set('threadId', ev);
        newSearchParams.delete('_k');
        newSearchParams.set('_k', new Date().getTime());
        const newURL = `${location.pathname}?${newSearchParams.toString()}`;
        navigate(newURL, { replace: true });
    }

    function _handleSelectTopic() {
        let _topic = topic.items
        //const _book_req = _topic.booking_request.map(b => ({ ...b, type: 'booking_request' }))
        // add extra delay for the comments to appear on top
        const _comments = _topic.tel_central_comments.filter(b => b.status == '').map(b => ({ ...b, type: 'comment', created_at: moment(b.created_at).add(60, 'seconds').format() }))
        const _status = _topic.tel_central_comments.filter(b => b.status != '').map(b => ({ ...b, type: 'status', created_at: moment(b.created_at).add(60, 'seconds').format() }))
        const _ctc_msg = _topic.contact_message.map(b => ({ ...b, type: 'contact_message', created_at: moment(b.created_at).format() }))
        const _call_ev = _topic.tel_central_call_event.map(b => ({ ...b, type: 'call_event' , created_at: moment(b.created_at).format() }))
        const _customer_comm = _topic.customer_comm.map(b => ({ ...b, type: 'customer_comm' , created_at: moment(b.created_at).format() }))

        let _all_contact = [..._comments, ..._status, ..._ctc_msg, ..._call_ev, ..._customer_comm]
        _all_contact = _all_contact.sort((a, b) => {
            return moment(b.created_at).diff(a.created_at);
        });

        _topic = { ..._topic, allContact: _all_contact }


        // Set topics as read
        if(topicsRead.indexOf(topic.items.id) == -1) setTopicRead([...topicsRead, topic.items.id])
        setSelectedTopic(_topic)
    }

   

    function pageRefresh() {
        setPendingRefresh([])
        setNavigateAction(['topics', 'topic'])
        const uniqueKey = new Date().getTime();
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.delete('_k');
        newSearchParams.set('_k', uniqueKey);
        navigate(`${location.pathname}?${newSearchParams.toString()}`);

    }

    function refreshCurrentTopic() {
        setNavigateAction(['topic'])
        const uniqueKey = new Date().getTime();
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.delete('_k');
        newSearchParams.set('_k', uniqueKey);
        navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }

    // updated: new screen view
    function goToView(view, hasPendContact = 0) {

        let screen = 'results'
        setSelectedTopic([])
        const newSearchParams = new URLSearchParams('');

        if (view == 'dashboard') {
            screen = 'dashboard'
            setNavigateAction([''])
        }

        if (view == 'results') {
            screen = 'results'
            setNavigateAction(['topics'])
            setTopics(blankResult)
            newSearchParams.set('hasPendContact', hasPendContact);
        }


        newSearchParams.set('screen', screen);
        newSearchParams.set('ownerId', 0);
        newSearchParams.set('status', 'OPEN,PENDING');

        newSearchParams.delete('_k');
        newSearchParams.set('_k', new Date().getTime());
        navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }

    function viewLeadFromDashboard(threadId) {

        setNavigateAction(['all'])
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.set('screen', 'results');
        newSearchParams.set('hasPendContact', '0');
        newSearchParams.set('threadId', threadId);
        newSearchParams.delete('_k');
        newSearchParams.set('_k', new Date().getTime());
        navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }

    function handleViewTopicByOwner(ownerId, hasPendContact = null, status = null) {

        setNavigateAction(['topics'])
        setSelectedTopic({})

        let newSearchParams = new URLSearchParams('');
        newSearchParams.set('_k', new Date().getTime());
        newSearchParams.set('ownerId', ownerId);
        newSearchParams.set('screen', 'results');
        if (hasPendContact != null)
            newSearchParams.set('hasPendContact',
                hasPendContact ? '1' : '0'
            );

        if (status != null) {
            newSearchParams.set('status', status.toString());
        }

        const newURL = `${location.pathname}?${newSearchParams.toString()}`;
        navigate(newURL, { replace: true });
    }

    function activeThreadFilter() {
        const newSearchParams = new URLSearchParams(searchParams);
        const _threadId = newSearchParams.get('threadId');
        if (_threadId != null && reqOk(topics)) {
            const tInfo = topics.items.data.filter(t => t.id == _threadId)
            if (tInfo.length > 0) {
                const tInfo1st = tInfo[0]
                if (tInfo1st.customers_web != null)
                    if (tInfo1st.customers_web.phone_contact != null)
                        if (tInfo1st.customers_web.phone_contact.phone_loc != null)
                            return tInfo1st.customers_web.phone_contact.phone_loc
            }
        }
        return null
    }


    return (
        <>
        {/*         {(
                (
                    pendingRefresh.indexOf('contact-message') > -1 ||
                    pendingRefresh.indexOf('phone-call') > -1 ||
                    pendingRefresh.indexOf('dm') > -1
                )
                && moduleSelected(searchParams) == 'results') && <MessagingNewContentBanner handleRefresh={pageRefresh} />}

            */}
            <MessagingSlideoverFilter
                open={openso_filter}
                setOpen={setOpenso_filter}
                searchParams={searchParams}
            />
            <div className="flex h-full flex-col">
                <div className="flex min-h-0 flex-1 overflow-hidden">
                    {/* Main area */}
                    <main className="min-w-0 flex-1 border-t border-gray-200 xl:flex">


                        {['results', 'search'].indexOf(moduleSelected(searchParams)) > -1 &&
                            <section
                                aria-labelledby="message-heading"
                                className="flex h-full min-w-0 flex-1 flex-col overflow-hidden xl:order-last"
                            >

                                {/* Top section */}
                                <MessagingActions selectedTopic={selectedTopic} handleRefresh={pageRefresh} selectBatch={selectBatch}/>
                                <div className="min-h-0 flex-1 overflow-y-auto">
                                    {/* Thread section*/}
                                    <MessagingContent
                                        selectedTopic={selectedTopic}
                                        topicData={topic}
                                        selectBatch={selectBatch}
                                        setSelectBatch={setSelectBatch}
                                    />
                                </div>
                            </section>
                        }
                        {moduleSelected(searchParams) == 'dashboard' && <MessagingDashboard viewLeadFromDashboard={viewLeadFromDashboard} viewByOwner={handleViewTopicByOwner} />}

                        {/* Message list*/}
                        <aside className="hidden xl:order-first xl:block xl:flex-shrink-0">

                            <div className="relative flex h-full w-96 flex-col border-r border-gray-200 bg-gray-100">

                                <div className="flex-shrink-0">
                                    <div className="flex h-16 flex-col justify-center bg-white">
                                        <MessagingTabSelector goToView={goToView} activeThread={activeThreadFilter} />
                                    </div>

                                    {['search'].indexOf(moduleSelected(searchParams)) > -1 &&
                                        <MessagingSearchHeader
                                            handleSearch={handleSearch}
                                            handleResetSearch={handleResetSearch}
                                        />}



                                </div>

                                {['results', 'search'].indexOf(moduleSelected(searchParams)) > -1 &&
                                    <MessagingTopics
                                        topicsData={reqOk(topics) && topics.items}
                                        selectedTopic={selectedTopic}
                                        handleSelectTopic={handleSelectTopic}
                                        applyPagination={applyPagination}
                                        handleRefresh={pageRefresh}
                                        setSelectBatch={setSelectBatch}
                                        selectBatch={selectBatch}
                                        topicsRead={topicsRead}
                                    />}

                                {['dashboard'].indexOf(moduleSelected(searchParams)) > -1 &&
                                    <MessagingMyLeads viewLeadFromDashboard={viewLeadFromDashboard} viewByOwner={handleViewTopicByOwner} />}

                            </div>
                        </aside>
                    </main>
                </div>
            </div>
        </>
    )
}
