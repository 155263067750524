
import { postRequests as post, putRequests as put } from '../common/apiEndpoints';

const rows = [
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'description',
    title: 'Nome do Exame',
    placeholder: 'Ortopantomografia',
    id: 'description',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Nome Inválido.',
  },
  {
    selector: 'input',
    required: false,
    type: 'text',
    name: 'code',
    title: 'Código',
    placeholder: 'Código do exame',
    id: 'code',
    map: true
  },
  {
    selector: 'radioselect',
    required: true,
    name: 'type',
    title: 'Tipo',
    subtitle: 'Tipo de Exame',
    id: 'type',
    map: true,
    fields: [
      {
        label: 'Exame Complementar de Diagnóstico',
        id: 'E',
      },
      {
        label: 'Análise Clínica',
        id: 'A',
      },
    ],
  },
];


export const formfield_newexam = {
  title: 'Novo Exame',
  description: 'Adicione um novo Exame',
  apiEndpoint: post.exams,
  method: 'POST',
  payloadType: 'json',
  preloadForm: false,
  submitLabel: 'Adicionar',
  rows: rows,
};

export const formfield_editexam = {
  title: 'Editar Exame',
  description: 'Edite as características do Exame',
  apiEndpoint: put.exams,
  method: 'PUT',
  payloadType: 'json',
  preloadForm: true,
  submitLabel: 'Editar',
  deleteAction: {
    label: 'Eliminar',
  },
  rows: rows,
};
