import { useState } from 'react';
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid';
import {
  postJson, blankResult,
  reqOk
} from '../../common/fetchdata';
import { postRequests as pt } from '../../common/apiEndpoints';

export function EmailNotValidatedAlert() {

  const [vrfData, setVrfData] = useState(blankResult);
  const [codeValInput, setCodeValInput] = useState(blankResult);
  const [valcode, setValcode] = useState('');

  function requestConfirmationCode() {
    postJson(pt.myuserrequestemailverification, { ...vrfData }, setVrfData);
  }
  function codeValidationInput() {
    postJson(pt.myuseremailcodevalidationinput, { ...codeValInput, payload: { code: valcode } }, setCodeValInput);
  }

  function alertSelect() {

    if (vrfData.state == 0) return 'init';
    if (!reqOk(vrfData)) return 'init';
    if (reqOk(vrfData) && codeValInput.state == 0) return 'input';
    if (reqOk(vrfData) && reqOk(codeValInput)) return 'success';
    if (reqOk(vrfData) && !reqOk(codeValInput)) return 'input';


  }

  return (
    <>
      {alertSelect() == 'init' &&
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3 w-full">
              <h3 className="text-sm font-medium text-red-800">O seu email não está validado!</h3>
              <div className="mt-2 text-sm text-red-700">
                <ul role="list" className="list-disc space-y-1 pl-5">
                  <li>Necessitará de validar o seu email para configurar os acessos ou recuperar a password. </li>
                </ul>
              </div>
            </div>

            <div className="mt-3 mr-2">
              <button onClick={requestConfirmationCode} type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                Confirmar
              </button>
            </div>
          </div>
        </div>}
      {alertSelect() == 'input' &&
        <div className="rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">Introduza o código recebido por email</p>

            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <div>
                <label htmlFor="emailVerify" className="sr-only">
                  Código de validação de email
                </label>
                <input
                  type="text"
                  name="emailVerify"
                  id="emailVerify"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="012345"
                  value={valcode}
                  onChange={(e) => setValcode(e.target.value)} />
              </div>
            </div>
            <div className="mt-1 mr-2">
              <button onClick={codeValidationInput} type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                Confirmar
              </button>
            </div>
          </div>
        </div>}

      {alertSelect() == 'success' &&
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">Email validado com sucesso</h3>

            </div>
          </div>
        </div>}

    </>
  );
}
