import React, { useState, useContext, useEffect, useRef } from 'react';
import ProviderBasicDatainfo from './ProviderBasicDatainfo';
import ProviderCalendar from './ProviderCalendar';
import ProviderInsuranceCards from './insurance/ProviderInsuranceCards';
import PaymentListing from '../payment/PaymentListing';
import PrescriptionListing from '../prescription/PrescriptionListing';
import CallListing from '../call/CallListing';
import SpeedDialMenu from '../nav/speeddial/SpeedDialMenu';
import TabNavigationControl from '../nav/tabs/TabNavigationControl';

import dialMenuProvider from '../nav/speeddial/dialMenuProvider';
import FormSlideover from '../common/slideover/FormSlideover';
import ProviderPhoto from './ProviderPhoto';

import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';
import { GlobalStateContext } from '../state/globalState';
import { useParams } from 'react-router-dom';
import { getJson, blankResult, reqOk, getFirst } from '../common/fetchdata';
import { getRequests as gt } from '../common/apiEndpoints';
import { formfield_newavailability as fin_av } from '../forms/formfield_availability';
import { formfield_newunavailability as fin_unav } from '../forms/formfield_unavailability';
import useToken from '../hooks/useToken';
import { useNavigate } from 'react-router-dom';
import ProviderSummaryPage from './ProviderSummaryPage';

const ProviderPage = () => {
  const globalcontext = useContext(GlobalStateContext);

  const { token } = useToken();
  let navigate = useNavigate();
  const [getRes, setGetRes] = useState(blankResult);

  // Availability slots
  const [openAvail, setOpenAvail] = useState(false);
  const [openUnavail, setOpenUnavail] = useState(false);

  const [formAvKey, setFormAvKey] = useState(100);
  const [formUnavKey, setFormUnavKey] = useState(0);
  const [edit_formData_av, edit_setFormData_av] = useState({});
  const [edit_formData_unav, edit_setFormData_unav] = useState({});

  const photoUploadModalRef = useRef(null);
  const { id } = useParams();
  const context = id;

  // Selected Tab
  const [sel, setSel] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const editAv_okHandler = () => {
    setFormAvKey((k) => k + 1);
    setOpenAvail(false);
    fetchData();
  };
  const editAv_errHandler = () => {
    console.error('Error processing request');
    setFormAvKey((k) => k + 1);
    setOpenAvail(false);
  };
  const editUnav_okHandler = () => {
    setFormUnavKey((k) => k + 1);
    setOpenUnavail(false);
    fetchData();
  };

  const editUnav_errHandler = () => {
    console.error('Error processing request');
    setFormUnavKey((k) => k + 1);
    setOpenUnavail(false);
  };

  const fetchData = (args = {}) => {
    getJson(
      gt.provider,
      { ...getRes, token: token, params: { providerId: context } },
      setGetRes
    );
  };

  useEffect(() => {
    if (reqOk(getRes)) {
      globalcontext.contextFcn.userDataConfig.setBookmark('provider', getFirst(getRes))
      globalcontext.contextFcn.breadcrumb.setContext(
        `${getFirst(getRes).name} ${getFirst(getRes).lastname}`
      );
    }
  }, [getRes]);

  const handleChangePhoto = () => {
    photoUploadModalRef.current.click();
  };

  const UserDetails = () => {
    return (
      <>
        <ProviderBasicDatainfo
          token={token}
          profile={getFirst(getRes)}
          refreshHandler={fetchData}
        />
        <ProviderInsuranceCards
          token={token}
          profile={getFirst(getRes)}
          refreshHandler={fetchData}
        />
      </>
    );
  };


  function gotoCalls() {
    setSel(2)
  }

  function gotoPersonDetails() {
    setSel(1)
  }

  const tabs = [
    {
      title: 'Resumo',
      idx: 0,
      content: <ProviderSummaryPage
        profile={getFirst(getRes)}
        handleRefresh={fetchData}
        gotoCalls={gotoCalls}
        gotoPersonDetails={gotoPersonDetails}
      />,
      disabled: false,
    },
    {
      title: 'Dados',
      idx: 1,
      content: <UserDetails />,
      disabled: false,
    },
    {
      title: 'Calendário',
      idx: 2,
      content: <ProviderCalendar token={token} context={context} />,
      disabled: false,
    },
    {
      title: 'Marcações',
      idx: 3,
      content: (
        <CallListing
          context={{ group: 'provider', ref: context, field: 'providerId' }}
        />
      ),
      disabled: false,
    },
    {
      title: 'Prescrições',
      idx: 4,
      content: (
        <PrescriptionListing
          token={token}
          showBreadcrumbs={false}
          showLeftFilter={false}
          defaultFilter={{ providerId: context }}
        />
      ),
      disabled: false,
    },
    {
      title: 'Financeiro',
      idx: 5,
      content: (
        <PaymentListing
          token={token}
          showBreadcrumbs={false}
          showLeftFilter={false}
          defaultFilter={{ providerId: context }}
        />
      ),
      disabled: false,
    },
  ];

  return (
    <>
      <FormSlideover
        fin={fin_av}
        key={formAvKey}
        open={openAvail}
        context={context}
        setOpen={setOpenAvail}
        formData={edit_formData_av}
        setFormData={edit_setFormData_av}
        okHandler={editAv_okHandler}
        errHandler={editAv_errHandler}
      />

      <FormSlideover
        fin={fin_unav}
        key={formUnavKey}
        open={openUnavail}
        context={context}
        setOpen={setOpenUnavail}
        formData={edit_formData_unav}
        setFormData={edit_setFormData_unav}
        okHandler={editUnav_okHandler}
        errHandler={editUnav_errHandler}
      />

      <SpeedDialMenu
        dialMenu={dialMenuProvider(
          globalcontext,
          context,
          setOpenAvail,
          setOpenUnavail
        )}
      />

      <div className="flex h-full">
        <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
          <article>
            <div className="mx-auto px-4 sm:px-6 lg:px-8 w-full">
              <section aria-labelledby="profile-overview-title w-full">
                <div className="overflow-hidden">
                  <h2 className="sr-only" id="profile-overview-title">
                    Perfil
                  </h2>

                  <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="sm:flex sm:space-x-5">
                      <div className="flex-shrink-0">
                        {reqOk(getRes) && (
                          <ProviderPhoto profile={getFirst(getRes)} />
                        )}
                      </div>
                      <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                        <p className="text-xl font-bold text-gray-900 sm:text-xl">
                          {reqOk(getRes) &&
                            `${getFirst(getRes).name} ${getFirst(getRes).lastname
                            }`}
                        </p>
                        <span className="text-sm text-gray-400">
                          {reqOk(getRes) && `Médico`}
                          <span className="ml-2 text-gray-500">{getFirst(getRes).servtypes_strlist}</span>
                        </span>
                      </div>
                    </div>

                    <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                      <button
                        type="button"
                        className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        <EnvelopeIcon
                          className="-ml-0.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        Mensagem
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        <PhoneIcon
                          className="-ml-0.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        Ligar
                      </button>
                      <button
                        onClick={() =>
                          navigate(
                            `/call/new?providerId=${getFirst(getRes).id}`
                          )
                        }
                        type="button"
                        className="inline-flex justify-center gap-x-1.5 rounded-md  px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300  bg-indigo-600  text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="-ml-0.5 h-5 w-5 text-gray-100"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M12.5 21h-6.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5"></path>
                          <path d="M16 3v4"></path>
                          <path d="M8 3v4"></path>
                          <path d="M4 11h16"></path>
                          <path d="M16 19h6"></path>
                          <path d="M19 16v6"></path>
                        </svg>
                        Marcar
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/* Tabs */}
            <div className="border-b border-gray-200">
              <nav className="mx-auto w-full px-4 sm:px-6 lg:px-8">
                <TabNavigationControl sel={sel} setSel={setSel} tabs={tabs} />
              </nav>
            </div>
          </article>
        </main>
      </div>
    </>
  );
};

export default ProviderPage;
