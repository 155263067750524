import React from 'react';
import { formfield_edittreatmenttype as fin } from '../../forms/formfield_treatmenttype';
import InputBoxFiltering from '../table/filters/InputBoxFiltering';

export const tableconfig = {
  edit: {
    showEditButton: true,
    type: 'slideover',
    template: fin,
  },
  view: {
    title: 'Tipo de Tratamento',
    description: '',
    type: 'slideover',
    template: {
      nCols: 2,
      data: [
        {
          colSpan: 1,
          key: 'id',
          label: 'ID',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'code',
          label: 'Código',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'code_interface',
          label: 'Código Externo',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'description',
          label: 'Descrição',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'price',
          label: 'Preço',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'price_counter',
          label: 'Preçõ (Balcão)',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'price_benef',
          label: 'Preço (Beneficiário)',
          valueformat: (e) => {
            return e;
          },
        },
      ],
    },
  },
  columns: [
    {
      key: 'id',
      slideoverView: true,
      label: 'ID',
    },
    {
      key: 'code',
      label: 'Código',
      valueformat: (e) => {
        return e;
      },
    },
    {
      key: 'code_interface',
      label: 'Código Externo',
      filter: {
        component: InputBoxFiltering,
        fieldSort: 'code_interface',
        fieldOrig: 'value',
        fieldOrigLabel: 'label',
        fieldDest: 'code_interface',
        fieldLabel: 'Código Externo'
      },
      valueformat: (e) => {
        return e;
      },
    },
    {
      key: 'description',
      label: 'Descrição',
      filter: {
        component: InputBoxFiltering,
        fieldSort: 'description',
        fieldOrig: 'value',
        fieldOrigLabel: 'label',
        fieldDest: 'description',
        fieldLabel: 'Descrição'
      },
      valueformat: (e) => {
        return e;
      },
    },
    {
      key: 'price',
      label: 'Preço',
      valueformat: (e) => {
        return parseInt(e) == 0 ? '-' : `${(parseInt(e)/100).toFixed(2)} €`;
      },
    },
    {
      key: 'price_counter',
      label: 'Preço (Balcão)',
      valueformat: (e) => {
        return parseInt(e) == 0 ? '-' : `${(parseInt(e)/100).toFixed(2)} €`;
      },
    },
    {
      key: 'price_benef',
      label: 'Preço (Beneficiário)',
      valueformat: (e) => {
        return parseInt(e) == 0 ? '-' : `${(parseInt(e)/100).toFixed(2)} €`;
      },
    },

  ],
  filter: {
    initFilter: {
    },
    fields: [],
  },
};
