import React from 'react';

const AvatarXs = ({ person, size = 10, fontWeight = 'medium',  fontSize = 'xs' }) => {
  return (
    <>
      {person != null ?
        (person.avatar_url_sm != null ? (
          <img
            className={`w-${size} h-${size} rounded-full`}
            src={`../static/uploads/${person.avatar_url_sm}`}
            alt="Rounded avatar"
          ></img>
        ) : (
          <div className={`relative inline-flex items-center justify-center w-${size} h-${size} overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600`}>
            <span className={`font-${fontWeight} text-gray-600 dark:text-gray-300 text-${fontSize}`}>
              {person.initials}
            </span>
          </div>
        ))
        :
        <>
        <div className={`relative inline-flex items-center justify-center w-${size} h-${size} overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600`}>
            <span className={`font-${fontWeight} text-gray-600 dark:text-gray-300 text-${fontSize}`}>
              N/A
            </span>
          </div>
        </>
      }
    </>
  );
};

export default AvatarXs;
