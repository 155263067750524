import React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { postJson, blankResult } from '../common/fetchdata';
import useToken from '../hooks/useToken';
import { postRequests as _p } from '../common/apiEndpoints';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

export default function NewCustomerSmartcardImport({ data, setData }) {
  const [show, setShow] = useState(false);
  const { token } = useToken();
  const [updateRes, setUpdateRes] = useState(blankResult);

  useEffect(() => {
    if (data != null) {
      setShow(true);
    }
  }, [data]);

  function handleClose() {
    setData(null);
    setShow(false);
  }

  function handleUpdateCustomerSmartCard(id) {
    postJson(
      _p.customersmartcardupdate,
      {
        ...updateRes,
        token: token,
        payload: {
          importId: id,
        },
      },
      setUpdateRes
    );
  }

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-50 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0 pt-0.5">
                    {data && 'data' in data && (
                      <img
                        className="h-12 w-10 rounded-md"
                        src={`../static/uploads/${data.data.avatar_url}`}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      {data &&
                        'data' in data &&
                        `${data.data.GivenName} ${data.data.Surname}`}
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Pretende importar os dados do Paciente?
                    </p>
                    <div className="mt-4 flex">
                      <button
                        onClick={() => {
                          handleUpdateCustomerSmartCard(data.data.id);
                          handleClose();
                        }}
                        type="button"
                        className="inline-flex items-center rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Confirmar
                      </button>
                      <button
                        type="button"
                        className="ml-3 inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={handleClose}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
