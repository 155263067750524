import React from 'react';
import CustomerPhoneContactSelect from '../../common/cardselect/CustomerPhoneContactSelect'
import CustomerEmailContactSelect from '../../common/cardselect/CustomerEmailContactSelect';

export default function CustomerContacts({ profile, handleRefresh }) {

  return (
    <>

      

        <CustomerEmailContactSelect
          profile={profile}
          handleRefresh={handleRefresh}
        />

        <CustomerPhoneContactSelect
          profile={profile}
          handleRefresh={handleRefresh}
        />
    
    </>
  );
}
