
import React from 'react';

export default function VideoIcon({ className }) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" className={className} width="44" height="44"
 viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M9 6h11" />
  <path d="M12 12h8" />
  <path d="M15 18h5" />
  <path d="M5 6v.01" />
  <path d="M8 12v.01" />
  <path d="M11 18v.01" />
</svg>
  );
}
