
import React, { useEffect, useState } from 'react'
import { Switch } from '@headlessui/react'
import FormSlideover from '../../../common/slideover/FormSlideover';
import { formfield_phonecentral_newinternal as fin } from "../../../forms/formfield_phonecentral_newinternal";

import PhoneCentralInternalNumsTable from './PhoneCentralInternalNumsTable'
import TextInputModal from '../../../common/modal/TextInputModal';
import { GlobalStateContext } from "../../../state/globalState";
import { apiUrl } from '../../../common/apiEndpoints';
import { putJson, reqOk, blankResult } from '../../../common/fetchdata';

import { tzbe, rgxtz } from '../../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function PhoneCentralOptionsContent_MeoGlobalConnect({ configDataOk, configData, handleDeleteInternalNum, fetchData }) {

  const [edit_formData, edit_setFormData] = useState({});
  const [putreq, setPutreq] = useState(blankResult);
  const [openAdd, setOpenAdd] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const [enabled, setEnabled] = useState(false)
  const [inpModalShow_importDelay, setinpModalShow_importDelay] = useState(false)
  const globalcontext = React.useContext(GlobalStateContext);


  const handleAddNewItem = (usr) => {
    setOpenAdd(true);
  };

  const edit_okHandler = () => {
    setFormKey((k) => k + 1)
    setOpenAdd(false)
    fetchData()
  };

  const edit_errHandler = () => {
    console.error('Error processing request')
    setFormKey((k) => k + 1)
    setOpenAdd(false)
  };

  function changeEnabledState() {
    globalcontext.contextFcn.panel.modal.open("info", "Confirma a ativação da interface?", () => {
      putJson(apiUrl.phonecentralmanager, { ...putreq, payload: { meo_gc_enabled: !enabled } }, setPutreq)
    })
  }

  useEffect(() => {
    fetchData()
  }, [putreq])

  useEffect(() => {
    if (configDataOk) {
      setEnabled(configData.meo_gc_enabled)
    }
  }, [configData])

  return (
    <>
      {configDataOk && (
        <TextInputModal
          title={"Atraso na importação"}
          open={inpModalShow_importDelay}
          setOpen={setinpModalShow_importDelay}
          initValue={[{ label: "Atraso (min)", id: "meo_gc_import_delay_sec", value: configDataOk ? configData.meo_gc_import_delay_sec : 600 }]}
          saveValue={(e) => globalcontext.contextFcn.panel.modal.open("info", "Confirma a alteração da configuração?", () => {
            putJson(apiUrl.phonecentralmanager, { ...putreq, payload: { meo_gc_import_delay_sec: e.meo_gc_import_delay_sec } }, setPutreq)
          })}
        />
      )}



      <main className="px-4 py-8 sm:px-6 lg:flex-auto lg:px-0 ">
        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">

          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Configurações</h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">Alterar as definições globais da integração.</p>

            <ul role="list" className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
              <li className="flex justify-between gap-x-6 py-6">
                <div className="font-medium text-gray-900">Ativar</div>
                <Switch
                  checked={enabled}
                  onChange={() => changeEnabledState()}
                  className={`${enabled ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span className="sr-only">Enable integration</span>
                  <span
                    className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </li>
              <li className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Atraso de importação (seg)
                  <p className="text-xs pt-2 text-gray-600 font-normal">Recomenda-se um valor em torno de 10 min (600 seg)</p>
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{configData.meo_gc_import_delay_sec}</div>
                  <button onClick={() => setinpModalShow_importDelay(true)} type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Alterar
                  </button>
                </dd>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Números Internos</h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">Números de telefone registados na central.</p>
            <div className="my-6 border-t border-gray-200 px-2 overflow-auto max-h-96 bg-white shadow sm:rounded-lg">

              <div className="flex justify-between gap-x-6 overflow-auto">
                {configDataOk && <PhoneCentralInternalNumsTable data={configData.internal_nums} handleDelete={handleDeleteInternalNum} />}

              </div>
            </div>

            <div className="flex">
              <button onClick={handleAddNewItem} type="button" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                <span aria-hidden="true">+</span> Adicionar número interno
              </button>
            </div>
          </div>


        </div>
      </main>


      <FormSlideover
        fin={fin}
        key={formKey}
        open={openAdd}
        setOpen={setOpenAdd}
        formData={edit_formData}
        setFormData={edit_setFormData}
        okHandler={edit_okHandler}
        errHandler={edit_errHandler}
      />

    </>
  )
}
