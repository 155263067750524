import React from "react";
import moment from "moment";
import 'moment/dist/locale/pt'  
moment.locale('pt')

const MessageSidebar = ({ selectedThread, handleChangeDestination, thMsg }) => {
  return (
    <div className="card" style={{ height: "32rem" }}>
      <div className="card-body card-body-scrollable card-body-scrollable-shadow">
        <div className="divide-y list-group list-group-flush">
          {thMsg.map((mt, i) => (
            <button
              key={i}
              className={`list-group-item list-group-item-action ${
                selectedThread == mt.thread_id ? "active" : ""
              }`}
              onClick={() => handleChangeDestination(mt.thread_id)}
            >
              {mt.destination.length == 1 ? (
                <div className="row">
                  <div className="col-auto">
                    <span className="avatar">{mt.destination[0].initials}</span>
                  </div>
                  <div className="col">
                    <div className="text-truncate">
                      <strong>{mt.destination[0].name}</strong>
                    </div>
                    <div className="text-muted">{moment(mt.last_update).fromNow()}</div>
                  </div>
                  {mt.n_unread > 0 && (
                    <div className="col-auto align-self-center">
                      <div className="badge bg-primary"></div>
                    </div>
                  )}
                </div>
              ) : (
                <div>Several destinations</div>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MessageSidebar;
