import React from 'react';
import { getJson, blankResult, reqOk } from '../../common/fetchdata';
import { getRequests as _g } from '../../common/apiEndpoints';
import FormSlideover from '../../common/slideover/FormSlideover';
import { formfield_newcustomermanager as fin } from '../../forms/formfield_customermanager';
import { afilliations } from '../../label/customer';

export default function OptionsRequesterPage({
  token,
  selection,
  setSelection,
}) {
  const [data, setData] = React.useState(blankResult);
  const [custman, setCustman] = React.useState(0);
  const [edit_formData, edit_setFormData] = React.useState({});
  const [openAdd, setOpenAdd] = React.useState(false);
  const [formKey, setFormKey] = React.useState(0);

  const fetchData = (args = {}) => {
    getJson(
      _g.customermanager,
      {
        ...data,
        token: token,
        params: {
          customerId: selection.steps.filter((s) => s.key == 'customer')[0]
            .value,
        },
      },
      setData
    );
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    setSelection({
      ...selection,
      bookingManager:
        custman == 0
          ? 'self'
          : data.items.data.filter((m) => m.id == custman)[0],
    });
  }, [custman]);

  const handleAddNewItem = (usr) => {
    setOpenAdd(true);
  };

  const edit_okHandler = () => {
    setFormKey((k) => k + 1);
    setOpenAdd(false);
    fetchData();
  };

  const edit_errHandler = () => {
    console.error('Error processing request');
    setFormKey((k) => k + 1);
    setOpenAdd(false);
  };

  return (
    <>
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Opções
        </h2>
        <p className="mt-1 text-xs leading-6 text-gray-500">
          Defina opções personalizadas para a consulta
        </p>

        <table className="min-w-full divide-y divide-gray-300 mt-6">
          <thead>
            <tr>
              <th
                scope="col"
                className=" py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
              >
                Relação
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
              >
                Nome
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
              >
                Contacto
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
              >
                NIF / DN
              </th>

              <th
                scope="col"
                className="relative py-3.5 pl-3 pr-4 sm:pr-6  hidden sm:block"
              >
                <span className="sr-only">Escolher</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            <tr
              className={`${custman == 0 ? 'bg-slate-300' : ''}`}
              onClick={() => setCustman(0)}
            >
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                Próprio
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500"></td>
              <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500"></td>
              <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500"></td>

              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6 hidden sm:block">
                <button className="text-indigo-600 hover:text-indigo-900">
                  Escolher
                </button>
              </td>
            </tr>

            {reqOk(data) &&
              data.items.data.map((person) => (
                <tr
                  onClick={() => setCustman(person.id)}
                  key={person.email}
                  className={`${custman == person.id ? 'bg-slate-300' : ''}`}
                >
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                    {`${
                      afilliations.filter(
                        (a) => a.value == person.affiliation.value
                      )[0].desc
                    }`}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">{`${
                    person.name != null ? person.name : 'n/a'
                  } ${person.lastname != null ? person.lastname : ''}`}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    {person.email_contact.email
                      ? person.email_contact.email
                      : 'n/a'}
                    <br />
                    {`${
                      person.phone_contact.phone_cc_dial
                        ? '(' + person.phone_contact.phone_cc_dial + ') '
                        : ''
                    }${
                      person.phone_contact.phone_loc
                        ? person.phone_contact.phone_loc
                        : ''
                    }`}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500"></td>

                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6 hidden sm:block">
                    <button className="text-indigo-600 hover:text-indigo-900">
                      Escolher
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            <button
              onClick={handleAddNewItem}
              type="button"
              className="text-xs font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              <span aria-hidden="true">+</span> Acrescentar gestor de marcação
            </button>
          </dt>
        </div>
      </div>

      <FormSlideover
        fin={fin}
        key={formKey}
        open={openAdd}
        setOpen={setOpenAdd}
        formData={edit_formData}
        setFormData={edit_setFormData}
        okHandler={edit_okHandler}
        errHandler={edit_errHandler}
      />
    </>
  );
}
