import React, { useEffect, useState, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';


export default function TinyMceModule({
  field,
  formData,
  setFormData,
  formVal,
  setFormVal
}) {

  const handleEditorChange = (content, editor) => {
    setFormData({ ...formData, [field.name]: content });
  };


  // initialize
  useEffect(() => {
    setFormVal((f) => ({
      ...f, [field.name]: {
        ...formVal[field.name],
        isTouched: false,
        isValid: isValid(formData[field.name])
      }
    }))
  }, [])


  // on update
  useEffect(() => {
    let fv = formVal[field.name]
    if (formData[field.name] != '') {
      fv = { ...fv, isTouched: true }
    }
    fv = { ...fv, isValid: isValid(formData[field.name]) }
    setFormVal((f) => ({
      ...f, [field.name]: fv
    }))
  }, [formData[field.name]])

  function isValid(dt) {
    if (!field.required) return true
    else return field.validation(dt)
  }

  return (
    <>
       <Editor
        apiKey="5rsqbk5sts3a72pzrrlyy00bz17pxnjzo2yj2xyd8sxxrnhz"
        value={formData[field.name]} // use `value` instead of `initialValue`
        onEditorChange={handleEditorChange}
        init={{
          //selector: 'textarea#premiumskinsandicons-borderless',
          //skin: 'borderless',
          height: '300',
          menubar: false,
          toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat ' + '',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
      {
        (formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) &&
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {'validationerr' in field ? field.validationerr : 'Valor inválido'}
        </p>

      }
    </>
  );
}
