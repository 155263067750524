import React, { useState, useEffect } from 'react';
import { ArrowRightIcon } from '@heroicons/react/20/solid'

function MfaInputCode({ handleNext }) {
    const [mfaCode, setMfaCode] = useState('');

    function handleSubmit(e) {
        e.preventDefault();
        handleNext({otpCode: mfaCode})
    }

    return (
        <form onSubmit={handleSubmit} className="Enrollment" >
            <h2 className="mb-6 text-black">Introduza o código que aparece na aplicação Authenticator.</h2>

            <div className="mb-6 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                    Código
                </label>
                <input
                    onChange={(e) => setMfaCode(e.target.value)}
                    value={mfaCode}
                    type="text"
                    name="auth-code"
                    id="auth-code"
                    className="block h-12 w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-xl sm:leading-6"
                    placeholder=""
                />
            </div>


            <div className="flex m-auto space-x-3">

                <button
                    disabled={mfaCode.length != 6}
                    type="submit"
                    onClick={handleSubmit}
                    className={`inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold ` +
                        ` text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ` +
                        ` ${mfaCode.length == 6 ? 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600' : 'bg-indigo-200'}`}
                >
                    Confirmar
                    <ArrowRightIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                </button>
            </div>
        </form>
    );
}

export default MfaInputCode;