import React, { Fragment, useState } from 'react';
import FormSlideover from '../slideover/FormSlideover';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, StarIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, PhoneIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import {
  phoneDesc
} from '../../label/phoneDesc';

import {
  formfield_newcontactdata as fin,
} from '../../forms/formfield_customer';
import { putRequests as pt } from '../apiEndpoints';
import { putJson, blankResult, reqOk } from '../fetchdata';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}


export default function CustomerPhoneContactSelect({ profile, handleRefresh }) {

  const [edit_formData, edit_setFormData] = useState({});
  const [openAdd, setOpenAdd] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const [formTempl, setFormTempl] = useState(null);
  const [putreq, setPutreq] = useState(blankResult);



  React.useEffect(() => {
    if (reqOk(putreq)) handleRefresh();
  }, [putreq]);


  const edit_okHandler = () => {
    setFormKey((k) => k + 1);
    setOpenAdd(false);
    handleRefresh();
  };

  const edit_errHandler = () => {
    console.error('Error processing request');
    setFormKey((k) => k + 1);
    setOpenAdd(false);
  };



  const setDefaultPhone = (phoneContactId) => {
    putJson(
      pt.customeredit,
      {
        ...putreq,
        id: profile.id,
        params: { type: 'customer_setdefaultcontactphone' },
        payload: { phoneContactId: phoneContactId },
      },
      setPutreq
    );
  };

  const handleDeletePhone = (phoneContactId) => {
    putJson(
      pt.customeredit,
      {
        ...putreq,
        id: profile.id,
        params: { type: 'customer_deletecontactphone' },
        payload: { phoneContactId: phoneContactId },
      },
      setPutreq
    );
  };

  const handleAddNewPhone = () => {
    setFormTempl(fin);
    setTimeout(() => {
      setOpenAdd(true);
    }, '200');
  };


  return (
    <>
      {formTempl != null && (
        <FormSlideover
          context={profile.id}
          fin={formTempl}
          key={formKey}
          open={openAdd}
          setOpen={setOpenAdd}
          formData={edit_formData}
          setFormData={edit_setFormData}
          okHandler={edit_okHandler}
          errHandler={edit_errHandler}
        />
      )}

      <div className="mt-3 flex flex-col space-y-2">
        {profile.phone_contact
          .sort(function (x, y) {
            return x.is_default === y.is_default ? 0 : x ? -1 : 1;
          })
          .map((ins, k) => (


            <div key={k} className="flex rounded-lg border border-gray-300 bg-white shadow-sm">
              <div className="w-full flex">
                <div className="inline-flex items-center pl-3 text-gray-500">
                  <PhoneIcon className="w-5 h-5 m-1" />
                </div>

                <div className="inline-flex items-center px-3 text-gray-500  ">
                  <span className="truncate text-xs text-gray-500 mr-1">
                    {ins.phone_cc_dial}
                  </span>
                  <span className="truncate text-md text-gray-500">
                    {ins.phone_loc}
                  </span>
                </div>

                <div className="inline-flex items-center px-3 text-gray-500  ">
                  <span className="truncate text-xs text-gray-500 mr-1">
                    {ins.description != null ?
                      phoneDesc.filter((p) => p.labelApi == ins.description)[0]
                        .label
                      : 'Telemóvel'
                    }
                  </span>
                </div>

              </div>
              <div className="w-auto pr-2 flex">
                <div className="flex flex-shrink-0 pt-2 gap-x-1">
                  {ins.is_default && (
                    <StarIcon className="h-6 w-6 text-amber-400" />
                  )}
                  {ins.confirmed ? (
                    <CheckCircleIcon className="h-6 w-6 mr-1 text-green-700" />
                  ) : (
                    <QuestionMarkCircleIcon className="h-6 w-6 mr-1 text-red-400" />
                  )}
                </div>


                <Menu as="div" className="relative ">
                  <Menu.Button className="inline-flex items-center rounded-md  px-1 py-2 text-xs font-semibold text-gray-900">
                    <ChevronDownIcon
                      className="-mr-1 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {!ins.is_default && (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              onClick={() => setDefaultPhone(ins.id)}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Definir Principal
                            </a>
                          )}
                        </Menu.Item>
                      )}

                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            onClick={() => handleDeletePhone(ins.id)}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-red-700'
                            )}
                          >
                            Eliminar
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>

              </div>


            </div>


          ))}
        <div
          onClick={handleAddNewPhone}
          className="w-12 relative flex items-center space-x-3 rounded-lg border border-gray-300 border-dashed  bg-white px-3 py-2 shadow-sm hover:border-gray-400 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
            <path d="M15 6h6m-3 -3v6"></path>
          </svg>
        </div>
      </div>
    </>
  );
}
