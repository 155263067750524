import React from 'react';
import AvatarBubble from './AvatarBubble'

const AvatarXsItem = ({ person }) => {
  return (

    <div className="tooltip tooltip-primary" data-tip={person.fullname}>
      <AvatarBubble buttonContent={
        person.avatar_url_sm != null ? (
          <img
            className="w-10 h-10 rounded-full"
            src={`../static/uploads/${person.avatar_url_sm}`}
            alt="Rounded avatar"
          ></img>
        ) : (
          <div className="border-2 border-white relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
            <span className="font-medium text-gray-600 dark:text-gray-300">
              {person.initials}
            </span>
          </div>
        )} />
    </div>

  );
};

const AvatarXSStack = ({ persons, persMax = 3 }) => {
  return (
    <>

      <div className="flex -space-x-4 text-xs">
        {(persons.length > persMax ? persons.slice(0, persMax) : persons).map((person, k) => (
          <AvatarXsItem person={person} key={k} />
        ))}

        {persons.length > persMax && (
          <div className="border-2 border-white relative inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-full text-white bg-gray-700">
            <span className="font-medium text-xs">+{persons.length - persMax}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default AvatarXSStack;
