


import { useSearchParams } from 'react-router-dom';
import { useState } from "react";

import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { isLookupSet } from './data/messagingSelect'

export function MessagingSearchHeader({ handleSearch, handleResetSearch }) {


    const [searchStr, setSearchStr] = useState('')
    const [searchParams] = useSearchParams();

    return (

        <div className="border-b border-t border-gray-200 bg-gray-50 text-gray-500">
            <div>
                <div className="flex">
                    <div className="relative flex flex-grow items-stretch focus-within:z-10 rounded-md border-0" >

                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="text-xs block w-full border-0 py-0.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                            placeholder="911002003"
                            value={searchStr}
                            onChange={(e) => setSearchStr(e.target.value)}
                        />
                        {isLookupSet(searchParams) && <div className="absolute inset-y-0 right-0 flex items-center p-1">
                            <button
                                onClick={() => {
                                    handleResetSearch()
                                    setSearchStr('')
                                }}
                                type="button"
                                className="border-0 relative inline-flex items-center px-2 py-2 font-semibold text-gray-900 hover:bg-gray-50 hover:rounded-full"
                            >
                                <XMarkIcon className="-ml-0.5 h-3 w-3 text-gray-400" aria-hidden="true" />
                            </button>

                        </div>}
                    </div>
                    <button
                        onClick={() => handleSearch(searchStr)}
                        type="button"
                        className="border-0 relative -ml-px inline-flex items-center gap-x-1.5 px-3 py-2 font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        <MagnifyingGlassIcon className="-ml-0.5 h-4 w-4 text-gray-400" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    )
}