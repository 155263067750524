import React, { Fragment } from 'react'
import { useState, useContext, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { useSearchParams, useNavigate } from 'react-router-dom';
import parsePhoneNumber from 'libphonenumber-js'

import { loadingScreen } from '../common/fetchdata';
import { ArrowsRightLeftIcon, EnvelopeIcon, EyeIcon, PencilIcon, PhoneIcon } from '@heroicons/react/24/outline'
import TextInputModal from '../common/modal/TextInputModal'
import { GlobalStateContext } from '../state/globalState';
import {
    blankResult,
    reqOk,
    postJson,
    getJson
} from '../common/fetchdata';
import { apiUrl } from '../common/apiEndpoints';
import MessagingViewersBubble from './viewers/MessagingViewersBubble';
import RelatedContactList from './relatedcontacts/RelatedContactList';
import PopoutDialog from '../common/popover/PopoutDialog';
import { updateLeadsList } from './data/messagingSelect'

const moods = [
    { name: 'Aberto', value: 'OPEN', bgColor: 'bg-red-500' },
    { name: 'Fechado', value: 'CLOSED', bgColor: 'bg-green-400' },
    { name: 'Pendente', value: 'PENDING', bgColor: 'bg-yellow-400' },
]

export default function MessagingContentHeader({ selectedTopic }) {

    const globalcontext = useContext(GlobalStateContext);
    const [usrdata, setUsrdata] = useState(blankResult)
    const [reldata, setRelData] = useState(blankResult)
    const [inpModalShow_name, setinpModalShow_name] = useState(false)
    const [searchParams] = useSearchParams();
    const [relate, setRelate] = useState(blankResult)
    let navigate = useNavigate();

 
    useEffect(() => {
        loadingScreen(usrdata, globalcontext)
    }, [usrdata]);



    useEffect(() => {
        if (selectedTopic.id != null && selectedTopic.id > 0) {
            getJson(apiUrl.relatedcontact, {
                ...reldata, params: {
                    custWebId: selectedTopic.customers_web.id
                }
            }, setRelData);
        }
    }, [selectedTopic]);

    useEffect(() => {
        if (reqOk(relate)) {
            updateLeadsList(searchParams, navigate)
            setRelate(blankResult)
        }
    }, [relate.state])

    function handleAssignment(_type, id) {
        globalcontext.contextFcn.panel.modal.open("info", "Confirma a ação de relacionar este contato?", () =>
            postJson(apiUrl.relatedcontact, {
                ...relate, payload: {
                    type: _type,
                    id: id,
                    custWebId: selectedTopic.customers_web.id
                }
            }, setRelate)
        );
    }

    function saveChangesName(data) {
        postJson(
            apiUrl.leadsmanage,
            {
                ...usrdata, payload: {
                    mode: 'name',
                    threadId: selectedTopic.id,
                    firstName: data.firstname,
                    lastName: data.lastname
                }
            }, setUsrdata
        );
    }

    return (
        <>
            <TextInputModal
                title={"Nome completo"}
                open={inpModalShow_name}
                setOpen={setinpModalShow_name}
                initValue={[
                    { label: "Primeiro nome", id: "firstname", value: selectedTopic.customers_web.name },
                    { label: "Último nome", id: "lastname", value: selectedTopic.customers_web.lastname },
                ]}
                saveValue={(e) => saveChangesName(e)}
            />
            <div className="bg-white pb-2 pt-5 shadow">
                <div className="px-4 flex">
                    <div className="sm:w-0 sm:flex-1 flex">

                        <div className='flex pt-2 pl-2'>
                            <h1 id="message-heading" className="text-lg font-medium text-gray-900">
                                {`${selectedTopic.customers_web.name != null ?
                                    selectedTopic.customers_web.name :
                                    'Novo contato'} ${selectedTopic.customers_web.lastname != null ?
                                        selectedTopic.customers_web.lastname :
                                        ''}`}
                            </h1>
                            <div
                                className="ml-3 cursor-pointer rounded-full h-8 w-8 bg-white p-2 text-xs font-semibold text-gray-900 hover:bg-gray-100"
                            >
                                <a href="#" onClick={() => setinpModalShow_name(true)}><PencilIcon className="w-4 h-4" /></a>
                            </div>
                        </div>
                    </div>
                    {/*   <div>
                        outro1
                    </div>
                    <div>
                        outro2
                    </div> */}

                    <div className="mt-4 flex items-center justify-between sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start">

                        <div className="isolate inline-flex">
                            {
                                selectedTopic.customers_web.phone_contact != null &&
                                <div className="mr-3">
                                    <div
                                        className="text-xs inline-flex items-center font-bold leading-sm  bg-gray-100 rounded-full"
                                    >
                                        <div className="bg-gray-50 rounded-full">
                                            <PhoneIcon className="w-4 h-4 m-3" />
                                        </div>

                                        <div className="p-3">
                                            +{parsePhoneNumber(selectedTopic.customers_web.phone_contact.phonenum_e164, null).countryCallingCode}
                                            {` `}
                                            {parsePhoneNumber(selectedTopic.customers_web.phone_contact.phonenum_e164, null).nationalNumber}

                                            <span className="ml-2">{selectedTopic.customers_web.phone_contact != null
                                                ? (typeof parsePhoneNumber(selectedTopic.customers_web.phone_contact.phonenum_e164, null) != 'undefined'
                                                    && parsePhoneNumber(selectedTopic.customers_web.phone_contact.phonenum_e164, null).country)
                                                : 'N/D'
                                            }</span>

                                        </div>
                                    </div>
                                </div>}
                        </div>

                        <div className="isolate inline-flex">
                            {
                                selectedTopic.customers_web.email_contact != null &&
                                <div className="mr-3">
                                    <div
                                        className="text-xs inline-flex items-center font-bold leading-sm  bg-gray-100 rounded-full"
                                    >
                                        <div className="bg-gray-50 rounded-full">
                                            <EnvelopeIcon className="w-4 h-4 m-3" />
                                        </div>

                                        <div className="p-3">{selectedTopic.customers_web.email_contact != null ? selectedTopic.customers_web.email_contact.email : ''}</div>
                                    </div>
                                </div>}
                        </div>

                        <div className="mr-3">
                            <PopoutDialog
                                popoutContent={<RelatedContactList data={reldata} selectedTopic={selectedTopic} handleAssignment={handleAssignment}/>}
                                width={96}
                                buttonContent={
                                    <div className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100 p-1 hover:bg-gray-200">
                                        <ArrowsRightLeftIcon className="w-6 h-6" />
                                    </div>
                                } />
                        </div>

                        <div className="mr-3 ">
                            <PopoutDialog
                                popoutContent={<MessagingViewersBubble readReceipts={selectedTopic.contact_message_thread_read_receipt} />}
                                buttonContent={
                                    <div className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100 p-1 hover:bg-gray-200">
                                        <EyeIcon className="w-6 h-6" />
                                    </div>
                                }
                            />
                        </div>

                        <span className={`inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium ${selectedTopic.current_status == null ? 'bg-cyan-100 text-gray-800' : `${moods.filter(s => s.value == selectedTopic.current_status)[0].bgColor} text-white`} `}>
                            {selectedTopic.current_status == null ? 'Sem estado' : moods.filter(s => s.value == selectedTopic.current_status)[0].name}
                        </span>
                        {/* <Menu as="div" className="relative ml-3 inline-block text-left">
                            <div>
                                <Menu.Button className="-my-2 flex items-center rounded-full bg-white p-2 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-600">
                                    <span className="sr-only">Open options</span>
                                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    type="button"
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'flex w-full justify-between px-4 py-2 text-sm'
                                                    )}
                                                >
                                                    <span>Copy email address</span>
                                                </button>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'flex justify-between px-4 py-2 text-sm'
                                                    )}
                                                >
                                                    <span>Previous conversations</span>
                                                </a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'flex justify-between px-4 py-2 text-sm'
                                                    )}
                                                >
                                                    <span>View original</span>
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu> */}
                    </div>


                </div>

            </div>


        </>
    )
}
