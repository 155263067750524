

export default function MessagingPagination({ results, applyPagination }) {
    return (
        <nav
            className="text-xs flex"
        >
            {results && <div className="hidden sm:flex">
                <span className="text-xs text-gray-700 mt-2">
                    <span className="font-medium">{results.initIdx}</span>{' '}a{' '}<span className="font-medium">{results.lastIdx}</span>{' '}de{' '}
                    <span className="font-medium">{results.totalResults}</span>{' '}
                </span>
            </div>}
            {results && <div className="hidden sm:flex ml-8">
                <span className="text-xs text-gray-700 mt-2">
                    <span className="font-medium">Página</span><span className="font-medium ml-1">{results.page}</span>{' '}
                </span>
            </div>}
            <div className="flex flex-1 justify-between sm:justify-end">
                {results.prevPage > 0 && <a
                    href="#"
                    onClick={() => applyPagination({ page: results.prevPage })}
                    className="relative inline-flex items-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                    Anterior
                </a>}
                {results.nextPage <= results.maxPage && <a
                    href="#"
                    onClick={() => applyPagination({ page: results.nextPage })}
                    className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2  font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                    Seguinte
                </a>}
            </div>
        </nav>
    )
}
