import React from 'react';
import { useState } from 'react';
import { getRequests as gt } from '../../common/apiEndpoints';
import { getJson, blankResult, reqOk } from '../../common/fetchdata';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import OptionCommModel from './OptionCommModel';
import { comm_key } from '../../label/comms';

export default function OptionsComms({ token }) {
  const [selected, setSelected] = useState(null);
  const [cfg, setCfg] = React.useState(blankResult);
  const [basehtml, set_basehtml] = React.useState(blankResult);

  React.useEffect(() => {
    fetchConfig();
    getJson(
      gt.globalconfig,
      { ...basehtml, token: token, params: { config: 'baseEmail' } },
      set_basehtml
    );
  }, []);

  const fetchConfig = (providerId) => {
    getJson(gt.commconfig, { ...cfg, token: token }, setCfg);
  };

  React.useEffect(() => {
    if (reqOk(cfg)) {
      setSelected(null);
    }
  }, [cfg]);

  return (
    <>
      <div>
        {' '}
        <h2 className="text-base font-semibold text-gray-900">
          Modelos de Comunicação
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Comunicações quando uma marcação é feita.
        </p>
        {reqOk(cfg) && (
          <div className="mt-3 w-72">
            <Listbox value={selected} onChange={setSelected}>
              <div className="relative mt-1">
                <Listbox.Button className="inline-flex w-full  gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  <span className="block truncate">
                    {selected != null ?  comm_key.filter(c => c.value == selected.key)[0].desc : 'Modelo'}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {cfg.items.data
                      .sort(function (a, b) {
                        return (a.id - b.id);
                      })
                      .map((ccfg, ccfgIdx) => (
                        <Listbox.Option
                          key={ccfgIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? 'bg-amber-100 text-amber-900'
                                : 'text-gray-900'
                            }`
                          }
                          value={ccfg}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}
                              >
                                {comm_key.filter(c => c.value == ccfg.key).length > 0 &&  comm_key.filter(c => c.value == ccfg.key)[0].desc}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        )}
      </div>

      {selected != null && (
        <OptionCommModel
          token={token}
          cfg={selected}
          basehtml={reqOk(basehtml) && basehtml.items.data}
          handleRefresh={fetchConfig}
        />
      )}
    </>
  );
}
