import React, { Fragment, useState, useContext, useEffect, useRef } from 'react'
import AvatarXs from '../../common/card/AvatarXs';
import { GlobalStateContext } from '../../state/globalState';
import moment from 'moment-timezone'
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import 'moment/dist/locale/pt';
moment.locale('pt');

export default function MessagingCustomerInternalComment({ item }) {
    const globalcontext = useContext(GlobalStateContext);
    const user = globalcontext.contextFcn.userData.data.items.user;
    return (
        <div className="flex items-center gap-x-6 rounded-xl  px-6 py-2.5 sm:px-3.5 text-gray-700 border-gray-300 bg-gray-200 mx-4">
            <div className="flex flex-1 justify-start">
                <span className="text-sm ">Comentário interno de</span>
                <span className="text-sm font-medium pl-3">{item.users.fullname}</span>
            </div>
            <p className="text-md leading-6 ">
                    {item.comment}
            </p>
            <div className="flex flex-1 justify-end">
                <time
                    dateTime={moment(item.datetm).format()}
                    className="flex-none py-0.5 text-xs leading-5 "
                >
                    {moment(item.datetm).fromNow()}
                </time>
            </div>
        </div>

    )
}
