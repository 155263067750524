import React from "react";
import { postJson, blankResult } from "../common/fetchdata";
import { postRequests as pt } from "../common/apiEndpoints";
import moment from "moment";

const NewCallConfirmation = ({ token, setSelection, selection }) => {
  const [pres, setPres] = React.useState(blankResult);

  const postCallRequest = () => {
    postJson(pt.callbooking, { ...pres, token: token, payload: selection }, setPres);
  };

  React.useEffect(() => {
    postCallRequest();
  }, []);


  return (
    <div className="container">
      
      {pres.state == 2 && pres.error && (
        <div className="card-body">
          <div className="modal-body text-center py-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon mb-2 text-danger icon-lg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 9v2m0 4v.01" />
              <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
            </svg>
            <h3>Error</h3>
            <div className="text-muted">There was an error while sending the data to the server.</div>
          </div>
        </div>
      )}

      {pres.state == 2 && !pres.error && (
        <div className="row row-cards">
          <div className="col-md-6 col-xl-6">
            <div className="card">
              <div className="card-body text-center">
                <div className="mb-3">
                <span
                    className="avatar avatar-xl avatar-rounded"
                    style={{
                      backgroundImage: (selection.provider.avatar_url_sm != null) ? `url(../static/uploads/${selection.provider.avatar_url_sm})` : '',
                    }}
                  >{`${!selection.provider.avatar_url ? selection.provider.initials : ""}`}</span>
                </div>
                <div className="card-title mb-1">{selection.provider.name}</div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-6">
            <div className="card">
              <div className="card-body">
                <div className="card-title">
                  <span className="status status-green">Marcação Confirmada</span>
                </div>
                <div className="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon me-2 text-muted"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <rect x="4" y="5" width="16" height="16" rx="2"></rect>
                    <line x1="16" y1="3" x2="16" y2="7"></line>
                    <line x1="8" y1="3" x2="8" y2="7"></line>
                    <line x1="4" y1="11" x2="20" y2="11"></line>
                    <rect x="8" y="15" width="2" height="2"></rect>
                  </svg>
                  Data: <strong>{moment(selection.steps.filter((s) => s.key == "slot")[0].slot).format('HH:mm DD/MM/YYYY')}</strong>
                </div>
                <div className="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon me-2 text-muted"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                  </svg>
                  Paciente: <strong>{selection.steps.filter((s) => s.key == "customer")[0].label}</strong>
                </div>
                <div className="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon me-2 text-muted"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M6 4h-1a2 2 0 0 0 -2 2v3.5h0a5.5 5.5 0 0 0 11 0v-3.5a2 2 0 0 0 -2 -2h-1"></path>
                    <path d="M8 15a6 6 0 1 0 12 0v-3"></path>
                    <path d="M11 3v2"></path>
                    <path d="M6 3v2"></path>
                    <circle cx="20" cy="10" r="2"></circle>
                  </svg>
                  Médico: <strong>{selection.provider.name}</strong>
                </div>
                <div className="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon me-2 text-muted"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <rect x="3" y="7" width="18" height="13" rx="2"></rect>
                    <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="12" y1="12" x2="12" y2="12.01"></line>
                    <path d="M3 13a20 20 0 0 0 18 0"></path>
                  </svg>
                  Type: <strong>{selection.opt.name}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewCallConfirmation;
