import React, { useState, createRef } from 'react';
import CallCalendar from '../call/CallCalendar';
import CalendarWrapper from '../call/calendar/CalendarWrapper';

const ProviderCalendar = ({ token, context }) => {
  const calendarRef = createRef();
  const [currentViewRange, setCurrentViewRange] = useState({
    start: null,
    end: null,
  });

  return (
    <>
      <CalendarWrapper
        calendarRef={calendarRef}
        currentViewRange={currentViewRange}
        calendar={
          <CallCalendar
          initialView={'timeGridWeek'}
            calendarRef={calendarRef}
            context={context}
            currentViewRange={currentViewRange}
            setCurrentViewRange={setCurrentViewRange}
          />
        }
      />
      {/*    <CallCalendar context={context} options={{addSlot: true, deleteSlot:  true}}/> */}
    </>
  );
};

export default ProviderCalendar;
