import React, { Fragment } from 'react';
import { GlobalStateContext } from '../../state/globalState';
import FormSlideover from '../../common/slideover/FormSlideover';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { afilliations } from '../../label/customer';
import {
  formfield_editafiliationdata as fin_afil_edit,
  formfield_newafiliationdata as fin_afil_new,
} from '../../forms/formfield_customer';

//import { formfield_editcustomerinsurance as fin_edit } from '../../forms/formfield_customer';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function CustomerManagers({ profile, handleRefresh }) {
  const globalcontext = React.useContext(GlobalStateContext);
  const [edit_formData, edit_setFormData] = React.useState({});
  const [openAdd, setOpenAdd] = React.useState(false);
  const [formKey, setFormKey] = React.useState(0);
  const [editId, setEditId] = React.useState(null);
  const [formTempl, setFormTempl] = React.useState(null);

  const handleAddNewItem = () => {
    setEditId(profile.id);
    setFormTempl(fin_afil_new);
    setTimeout(() => {
      setOpenAdd(true);
    }, '200');
  };

  const handleEditItem = (id, afil) => {
    setEditId(id);
    setFormTempl(fin_afil_edit);
    setTimeout(() => {
      setOpenAdd(true);
    }, '200');
  };

  const edit_okHandler = () => {
    setFormKey((k) => k + 1);
    setOpenAdd(false);
    handleRefresh();
  };

  const edit_errHandler = () => {
    console.error('Error processing request');
    setFormKey((k) => k + 1);
    setOpenAdd(false);
  };

  return (
    <>
      {formTempl != null && (
        <FormSlideover
          context={editId}
          fin={formTempl}
          key={formKey}
          open={openAdd}
          setOpen={setOpenAdd}
          formData={edit_formData}
          setFormData={edit_setFormData}
          okHandler={edit_okHandler}
          errHandler={edit_errHandler}
        />
      )}

      <div className="mt-3 flex flex-col space-y-2">
        {profile.customer_managers
          .sort(function (x, y) {
            return x.idx === y.idx ? 0 : x ? -1 : 1;
          })
          .map((ins, k) => (


            <div key={k} className="flex rounded-lg border border-gray-300 bg-white shadow-sm">
              <div className="w-full flex">
                <div className="w-16 inline-flex items-center pl-3 text-gray-500">
                  <p className="truncate text-xs text-gray-500">
                    {
                      afilliations.filter(
                        (a) => a.value == ins.affiliation
                      )[0].desc
                    }
                  </p>
                </div>

                <div className="inline-flex items-center px-3 text-gray-500">

                  <span className="truncate text-md text-gray-500">
                    <p className="truncate text-sm text-gray-500">{`${ins.name == null || ins.lastname == null
                      ? 'Não definido'
                      : `${ins.name} ${ins.lastname}`
                      }`}</p>
                  </span>
                </div>

                <div className="inline-flex items-center px-3 text-gray-500  ">
                  <p className="truncate text-xs text-gray-500 mt-1">
                    {ins.email_contact != null && ins.email_contact.email}
                  </p>
                  <p className="truncate text-xs text-gray-500 mt-1">
                    {`${ins.phone_contact != null && ins.phone_contact.phone_cc_dial} ${ins.phone_contact != null && ins.phone_contact.phone_loc}`}
                  </p>
                </div>

              </div>
              <div className="w-auto pr-2 flex">

                <Menu as="div" className="relative ">
                  <Menu.Button className="inline-flex items-center rounded-md  px-1 py-2 text-xs font-semibold text-gray-900">
                    <ChevronDownIcon
                      className="-mr-1 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            onClick={() =>
                              handleEditItem(ins.id, ins.affiliation.value)
                            }
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Editar
                          </a>
                        )}
                      </Menu.Item>

                      {ins.affiliation.value != 'MOTHER' &&
                        ins.affiliation.value != 'FATHER' && (
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                onClick={() =>
                                  globalcontext.contextFcn.customer.manager.del(
                                    ins.id
                                  )
                                }
                                href="#"
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-red-700'
                                )}
                              >
                                Remover
                              </a>
                            )}
                          </Menu.Item>
                        )}
                    </Menu.Items>
                  </Transition>
                </Menu>

              </div>
            </div>
          ))}
        <div
          onClick={handleAddNewItem}
          className="w-12 relative flex items-center space-x-3 rounded-lg border border-gray-300 border-dashed  bg-white px-3 py-2 shadow-sm hover:border-gray-400 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
            <path d="M16 19h6"></path>
            <path d="M19 16v6"></path>
            <path d="M6 21v-2a4 4 0 0 1 4 -4h4"></path>
          </svg>
        </div>
      </div>

    </>
  );
}
