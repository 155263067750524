import React, { useState } from "react"
import { validatePassword } from './authFunctions'

export default function PasswordResetBox({ handleNext, isLoading, errorToast }) {

    const [showPassA, setShowPassA] = React.useState(false);
    const [showPassB, setShowPassB] = React.useState(false);
    const [password_A, setPassword_A] = useState('');
    const [password_B, setPassword_B] = useState('');

    function handleSubmit(e) {
        e.preventDefault();


        if (password_A != password_B) {
            errorToast('As palavras-passe não coincidem')
            return
        }
        if (!validatePassword(password_A)) {
            errorToast(
                <span className="space-y-3">
                    As palavras-passe não cumprem os requisitos de segurança
                    <ul className="list-disc pl-6">
                        <li><span className="text-gray-600">Entre 8 e 24 caracteres</span></li>
                        <li><span className="text-gray-600">Pelo menos uma letra maiúscula</span></li>
                        <li><span className="text-gray-600">Pelo menos uma letra minúscula</span></li>
                        <li><span className="text-gray-600">Pelo menos um número</span></li>
                        <li><span className="text-gray-600">Pelo menos um carácter especial</span></li>
                    </ul>
                </span>
            )
            return
        }
        console.log(password_A)
        handleNext({  password: password_A })

    }


    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Palavra-passe
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                        id="password_A"
                        name="password_A"
                        autoComplete="current-password"
                        required
                        placeholder="Introduza a nova palavra-passe..."
                        key="password_A"
                        type={`${showPassA ? "text" : "password"}`}
                        value={password_A}
                        onChange={(e) => setPassword_A(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div className=" absolute inset-y-0 right-0 flex items-center pr-3">
                        <div
                            onClick={() => setShowPassA(!showPassA)}
                            className="link-secondary"
                            data-bs-toggle="tooltip"
                            aria-label="Show password"
                            data-bs-original-title="Show password"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-gray-500"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <circle cx="12" cy="12" r="2"></circle>
                                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                            </svg>
                        </div>
                    </div>

                </div>
            </div>

            <div className='mt-6'>

                <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                        id="password_B"
                        name="password_B"
                        autoComplete="password_B"
                        required
                        placeholder="Repita a nova palavra-passe..."
                        key="password_B"
                        type={`${showPassB ? "text" : "password"}`}
                        value={password_B}
                        onChange={(e) => setPassword_B(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div className=" absolute inset-y-0 right-0 flex items-center pr-3">
                        <div
                            onClick={() => setShowPassB(!showPassB)}
                            className="link-secondary"
                            data-bs-toggle="tooltip"
                            aria-label="Show password"
                            data-bs-original-title="Show password"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-gray-500"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <circle cx="12" cy="12" r="2"></circle>
                                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                            </svg>
                        </div>
                    </div>

                </div>
            </div>



            <div className='mt-6'>
                <button
                    onClick={handleSubmit}
                    type="submit"
                    className={`${isLoading ? "bg-green-600" : "bg-indigo-600"} group relative flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                >
                    Seguinte
                </button>
            </div>
        </form>
    )
}