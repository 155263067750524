import React, { useState, useEffect } from 'react'
import { countryCodes } from '../label/countrycodes'
import Datepicker from 'react-tailwindcss-datepicker';
import PhoneInputModule from '../common/slideover/PhoneInputModule'
import { localText } from './bookRequestLocaliz'
import moment from 'moment-timezone'
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import 'moment/dist/locale/pt';
import GuestLangPicker from './GuestLangPicker';
moment.locale('pt');


export default function GuestBookRequestForm({ formData, setFormData, setAlert, handleSubmit, formInfo, getProvider, formAttr, lang, setLang }) {



    const [selectedService, setSelectedService] = useState(0);
    const [selectedSpecialty, setSelectedSpecialty] = useState(0);
    const [selectedProvider, setSelectedProvider] = useState(0);
    const [availableProviders, setAvailableProviders] = useState([]);

    useEffect(() => {
        const fetchProviders = async () => {
            if (selectedSpecialty && selectedSpecialty !== 0) {
                const providers = await getProvider(selectedSpecialty);
                setAvailableProviders(providers);
            }
        };

        fetchProviders();
    }, [selectedSpecialty]);


    useEffect(() => {
        setFormData((f) => ({ ...f, servId: selectedService, specId: selectedSpecialty, provId: selectedProvider }))
    }, [selectedService, selectedSpecialty, selectedProvider]);

    function handleValidate(e) {

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        e.preventDefault();
        let errors = []

        if (!formData.termAndConditions)
            errors.push('termAndConditions')
        if (formData.firstName == null || formData.firstName.length == 0)
            errors.push('firstName')
        if (formData.firstName == null || formData.firstName.length == 0)
            errors.push('lastName')
        if (formData.taxId == null || formData.taxId == '' || (formData.hasNif && formData.taxId.length != 9))
            errors.push('taxId')
        if (formData.date_1.startDate == null || formData.date_1.startDate == '')
            errors.push('date_1')
        if (selectedService == 0)
            errors.push('service')
        if (selectedSpecialty == 0)
            errors.push('specialty')

        if (formData.mobile_phone.loc == null
            || formData.mobile_phone.loc == ''
            || (formData.mobile_phone.cc.value == 'PRT' && formData.mobile_phone.loc.length != 9)
        )
            errors.push('mobile_phone')

        if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            String(formData.email).toLowerCase())
        ))
            errors.push('email')

        setFormData((f) => ({ ...f, errorFields: errors, isValid: errors.length > 0 }))

        if (errors.length > 0) {
            setAlert({ type: 'error', message: localText(lang, 'FORM_FIX_FIELDS') });
        }
        else {
            handleSubmit(formData)
        }

    }

    return (
        <form onSubmit={handleValidate}>
            <div className="space-y-12">

                <div className="border-b border-gray-900/10 pb-12">
                    <div className='flex'>
                        <div className='w-full'>                    
                            <h2 className="text-base font-semibold leading-7 text-gray-900">{localText(lang, 'FORM_TITLE')}</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">{localText(lang, 'FORM_SUBTITLE')}</p></div>
                        <div className='m-auto'><GuestLangPicker setLang={setLang}/></div>
                    </div>


                    <div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                        <div className="sm:col-span-2">
                            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                {localText(lang, 'FORM_FNAME')}
                            </label>
                            <div className="mt-2">

                                <input
                                    disabled={formAttr.disabled}
                                    type="text"
                                    name="first-name"
                                    id="first-name"
                                    value={formData.firstName}
                                    onChange={(e) => setFormData((f) => ({ ...f, firstName: e.target.value }))}
                                    className={`block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ` +
                                        ` ${formData.errorFields.includes("firstName") ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'}  `}

                                />
                                {
                                    (formData.errorFields.includes("firstName")) &&
                                    <p className="mt-2 text-sm text-red-600" id="firstName-error">
                                        {localText(lang, 'FORM_VALUE_NOT_VALID')}
                                    </p>

                                }
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                {localText(lang, 'FORM_LNAME')}
                            </label>
                            <div className="mt-2">
                                <input
                                    disabled={formAttr.disabled}
                                    value={formData.lastName}
                                    onChange={(e) => setFormData((f) => ({ ...f, lastName: e.target.value }))}
                                    type="text"
                                    name="last-name"
                                    id="last-name"
                                    autoComplete="family-name"
                                    className={`block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ` +
                                        ` ${formData.errorFields.includes("lastName") ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'}  `}
                                />
                                {
                                    (formData.errorFields.includes("lastName")) &&
                                    <p className="mt-2 text-sm text-red-600" id="lastName-error">
                                        {localText(lang, 'FORM_VALUE_NOT_VALID')}
                                    </p>

                                }
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="tax-id" className="block text-sm font-medium leading-6 text-gray-900">
                                {formData.hasNif ? localText(lang, 'FORM_NIF') : localText(lang, 'FORM_ID_DOC')}
                            </label>
                            <div className="mt-2">
                                <input
                                    disabled={formAttr.disabled}
                                    value={formData.taxId}
                                    onChange={(e) => setFormData((f) => ({ ...f, taxId: e.target.value }))}
                                    id="tax-id"
                                    name="tax-id"
                                    type="text"
                                    className={`block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ` +
                                        ` ${formData.errorFields.includes("taxId") ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'}  `}
                                />
                                <p className="mt-2 text-xs text-gray-600" id="firstName-error">
                                    <a href='#' onClick={() => setFormData((f) => ({ ...f, hasNif: !f.hasNif }))}>
                                        {formData.hasNif ? localText(lang, 'FORM_NO_NIF') : localText(lang, 'FORM_HAVE_NIF')}</a>
                                </p>
                                {
                                    (formData.errorFields.includes("taxId")) &&
                                    <p className="mt-2 text-sm text-red-600" id="email-error">
                                        {localText(lang, 'FORM_VALUE_NOT_VALID')}
                                    </p>

                                }
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                {localText(lang, 'FORM_EMAIL')}
                            </label>
                            <div className="mt-2">
                                <input
                                    disabled={formAttr.disabled}
                                    value={formData.email}
                                    onChange={(e) => setFormData((f) => ({ ...f, email: e.target.value }))}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    className={`block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ` +
                                        ` ${formData.errorFields.includes("email") ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'}  `}
                                />
                                {
                                    (formData.errorFields.includes("email")) &&
                                    <p className="mt-2 text-sm text-red-600" id="email-error">
                                        {localText(lang, 'FORM_VALUE_NOT_VALID')}
                                    </p>

                                }
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="mobile_phone" className="block text-sm font-medium leading-6 text-gray-900">
                                {localText(lang, 'FORM_MOB_PHONE')}
                            </label>
                            <div className="mt-2">
                                <PhoneInputModule
                                    formData={formData}
                                    setFormData={setFormData}
                                    field={{
                                        selector: 'phoneinput',
                                        required: true,
                                        countryList: countryCodes,
                                        defaultCountry: 'PRT',
                                        placeholder: 'Escolha...',
                                        placeholderNum: '910000000',
                                        type: 'text',
                                        name: 'mobile_phone',
                                        title: 'Telemóvel',
                                        id: 'mobile_phone',
                                        map: true,
                                    }} />

                                {
                                    (formData.errorFields.includes("mobile_phone")) &&
                                    <p className="mt-2 text-sm text-red-600" id="firstName-error">
                                        {localText(lang, 'FORM_BAD_MOB_PHONE')}
                                    </p>

                                }
                            </div>
                        </div>

                        <div className="sm:col-span-2 border-r-2 pr-6">
                            <label htmlFor="serviceselect" className="block text-sm font-medium leading-6 text-gray-900">
                                {localText(lang, 'FORM_SERV_TYPE')}
                            </label>
                            <div className="mt-2">

                                <div className="mt-2">
                                    <select
                                        disabled={formAttr.disabled}
                                        value={selectedService}
                                        onChange={(e) => setSelectedService(e.target.value)}
                                        id="serviceselect"
                                        name="serviceselect"
                                        className={`block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ` +
                                            ` ${formData.errorFields.includes("service") ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'}  `}

                                    >
                                        <option value="0">{localText(lang, 'FORM_PICK')}</option>
                                        {formInfo.service && formInfo.service.map(s =>
                                            <option key={s.id} value={s.id}>{s.name}</option>
                                        )}
                                    </select>
                                    {
                                        (formData.errorFields.includes("service")) &&
                                        <p className="mt-2 text-sm text-red-600" id="firstName-error">
                                            {localText(lang, 'FORM_BAD_PICK')}
                                        </p>

                                    }
                                </div>
                            </div>
                        </div>

                        <div className="sm:col-span-2 border-r-2 pr-6">
                            {selectedService != 0 && <>
                                <label htmlFor="specselect" className="block text-sm font-medium leading-6 text-gray-900">
                                    {localText(lang, 'FORM_SERV_SPECIALTY')}
                                </label>
                                <div className="mt-2">
                                    <div className="mt-2">
                                        <select
                                            disabled={formAttr.disabled}
                                            value={selectedSpecialty}
                                            onChange={(e) => setSelectedSpecialty(e.target.value)}
                                            id="specselect"
                                            name="specselect"
                                            className={`block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ` +
                                                ` ${formData.errorFields.includes("specialty") ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'}  `}
                                        >
                                            <option value="0">{localText(lang, 'FORM_PICK')}</option>
                                            {formInfo.service.filter(s => s.id == selectedService)[0].booking_request_servtype_b.map(s =>
                                                <option key={s.id} value={s.id}>{s.name}</option>
                                            )}

                                        </select>
                                        {
                                            (formData.errorFields.includes("specialty")) &&
                                            <p className="mt-2 text-sm text-red-600" id="firstName-error">
                                                {localText(lang, 'FORM_BAD_PICK')}
                                            </p>

                                        }
                                    </div>
                                </div>
                            </>}

                        </div>

                        <div className="sm:col-span-2 border-r-2 pr-6">
                            {(selectedService != 0 && selectedSpecialty != 0) && <>
                                <label htmlFor="providerselect" className="block text-sm font-medium leading-6 text-gray-900">
                                    {localText(lang, 'FORM_SERV_PROVIDER')}
                                </label>

                                <div className="mt-2">
                                    <div className="mt-2">
                                        <select
                                            disabled={formAttr.disabled}
                                            value={selectedProvider}
                                            onChange={(e) => setSelectedProvider(e.target.value)}
                                            id="providerselect"
                                            name="providerselect"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                        >
                                            <option value="0">Todos</option>
                                            {availableProviders
                                                .map(s =>
                                                    <option key={s.id} value={s.id}>{s.med_name}</option>
                                                )}

                                        </select>
                                    </div>
                                </div>
                            </>}

                        </div>


                        <div className="sm:col-span-2">
                            <label htmlFor="insurance" className="block text-sm font-medium leading-6 text-gray-900">
                                {localText(lang, 'FORM_INSURANCE')}
                            </label>
                            <div className="mt-2">
                                <select
                                    disabled={formAttr.disabled}
                                    value={formData.insTypeId}
                                    onChange={(e) => setFormData((f) => ({ ...f, insTypeId: e.target.value }))}
                                    id="insurance"
                                    name="insurance"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >

                                    <option value={-1}>{localText(lang, 'FORM_PRIV_INSURANCE')}</option>
                                    <option value={0}>{localText(lang, 'FORM_OTHER')}</option>
                                    {formInfo.insurance && formInfo.insurance.map(f =>
                                        <option key={f.id} value={f.id}>{f.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="sm:col-span-2">

                            {formData.insTypeId == 0 &&
                                <React.Fragment>
                                    <label htmlFor="otherinsurance" className="block text-sm font-medium leading-6 text-gray-900">
                                        {localText(lang, 'FORM_WHICH')}
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            disabled={formAttr.disabled}
                                            value={formData.insTypeOther}
                                            onChange={(e) => setFormData((f) => ({ ...f, insTypeOther: e.target.value }))}
                                            id="otherinsurance"
                                            name="otherinsurance"
                                            type="text"
                                            className={`block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ` +
                                                ` ${formData.errorFields.includes("insTypeOther") ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'}  `}
                                        />
                                        {
                                            (formData.errorFields.includes("insTypeOther")) &&
                                            <p className="mt-2 text-sm text-red-600" id="email-error">
                                                {localText(lang, 'FORM_VALUE_NOT_VALID')}
                                            </p>

                                        }
                                    </div>
                                </React.Fragment>}

                        </div>

                        <div className="sm:col-span-2">
                        </div>

                        <div className="sm:col-span-2 border-r-2 pr-6">
                            <label htmlFor="date_1" className="block text-sm font-medium leading-6 text-gray-900">
                                {`${localText(lang, 'FORM_OPTION')} 1`}
                            </label>
                            <div className="mt-2">
                                <Datepicker
                                    disabled={formAttr.disabled}
                                    minDate={new Date()}
                                    asSingle={true}
                                    value={formData.date_1}
                                    displayFormat={"DD/MM/YYYY"}
                                    onChange={(e) => setFormData((f) => ({ ...f, date_1: e }))}
                                    inputClassName={` w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ` +
                                        ` ${formData.errorFields.includes("date_1") ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'}  `}

                                />
                                <div className="mt-2">
                                    <select
                                        disabled={formAttr.disabled}
                                        value={formData.period_1}
                                        onChange={(e) => setFormData((f) => ({ ...f, period_1: e.target.value }))}
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    >
                                        <option value="0">{localText(lang, 'FORM_ANY')}</option>
                                        <option value='morning'>{localText(lang, 'FORM_MORNING')}</option>
                                        <option value='afternoon'>{localText(lang, 'FORM_AFTERNOON')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="sm:col-span-2 border-r-2 pr-6">
                            <label htmlFor="date_2" className="block text-sm font-medium leading-6 text-gray-900">
                                {`${localText(lang, 'FORM_OPTION')} 2`}
                            </label>
                            <div className="mt-2">
                                <Datepicker
                                    disabled={formAttr.disabled}
                                    minDate={new Date()}
                                    asSingle={true}
                                    value={formData.date_2}
                                    displayFormat={"DD/MM/YYYY"}
                                    onChange={(e) => setFormData((f) => ({ ...f, date_2: e }))}
                                    inputClassName={` w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ` +
                                        ` ${formData.errorFields.includes("date_2") ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'}  `}

                                />
                                <div className="mt-2">
                                    <select
                                        disabled={formAttr.disabled}
                                        value={formData.period_2}
                                        onChange={(e) => setFormData((f) => ({ ...f, period_2: e.target.value }))}
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    >
                                        <option value="0">{localText(lang, 'FORM_ANY')}</option>
                                        <option value='morning'>{localText(lang, 'FORM_MORNING')}</option>
                                        <option value='afternoon'>{localText(lang, 'FORM_AFTERNOON')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="sm:col-span-2 pr-6">
                            <label htmlFor="date_3" className="block text-sm font-medium leading-6 text-gray-900">
                                {`${localText(lang, 'FORM_OPTION')} 3`}
                            </label>
                            <div className="mt-2">
                                <Datepicker
                                    disabled={formAttr.disabled}
                                    minDate={new Date()}
                                    asSingle={true}
                                    value={formData.date_3}
                                    displayFormat={"DD/MM/YYYY"}
                                    onChange={(e) => setFormData((f) => ({ ...f, date_3: e }))}
                                    inputClassName={` w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ` +
                                        ` ${formData.errorFields.includes("date_3") ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'}  `}

                                />
                                <div className="mt-2">
                                    <select
                                        disabled={formAttr.disabled}
                                        value={formData.period_3}
                                        onChange={(e) => setFormData((f) => ({ ...f, period_3: e.target.value }))}
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    >
                                        <option value="0">{localText(lang, 'FORM_ANY')}</option>
                                        <option value='morning'>{localText(lang, 'FORM_MORNING')}</option>
                                        <option value='afternoon'>{localText(lang, 'FORM_AFTERNOON')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>





                        <div className="col-span-full">
                            <label htmlFor="notes" className="block text-sm font-medium leading-6 text-gray-900">
                                {localText(lang, 'FORM_COMMENTS')}
                            </label>
                            <div className="mt-2">
                                <textarea
                                    disabled={formAttr.disabled}
                                    value={formData.notes}
                                    onChange={(e) => setFormData((f) => ({ ...f, notes: e.target.value }))}
                                    id="notes"
                                    name="notes"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-6 sm:col-start-1">
                            <fieldset>
                                <div className="mt-6 space-y-6">
                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <input
                                                disabled={formAttr.disabled}
                                                checked={formData.termAndConditions}
                                                onChange={() => setFormData({ ...formData, termAndConditions: !formData.termAndConditions })}
                                                id="termAndConditions"
                                                name="termAndConditions"
                                                type="checkbox"
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="termAndConditions" className="font-normal text-gray-500">
                                                {`${localText(lang, 'FORM_READAGREE')} `}<span className="underline"><a href="https://www.consulped.pt/pol%C3%ADtica-privacidade" target='_blank'>{localText(lang, 'FORM_PRIV_POLICY')}</a></span>
                                            </label>
                                            {formData.errorFields.includes("termAndConditions") &&
                                                <p className="mt-2 text-sm text-red-600" id="email-error">
                                                    {localText(lang, 'FORM_PRIV_POLICY_ERROR')}
                                                </p>}
                                        </div>
                                    </div>


                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm font-semibold leading-6 text-gray-900" disabled={formAttr.disabled}>
                    {localText(lang, 'FORM_CANCEL')}
                </button>
                <button
                    disabled={formAttr.disabled}
                    onClick={handleValidate}
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    {localText(lang, 'FORM_SUBMIT')}
                </button>
            </div>
        </form>
    )
}
