import React, { useEffect } from 'react';
import { useState } from 'react';
import AutocompleteOnBe from '../combobox/AutocompleteOnBe';

export default function AutocompleteOnBeModule({
  field,
  formData,
  setFormData,
  formVal,
  setFormVal,
  removeOptions = []
}) {

  const [remId, setRemId] = useState([])
  const [query, setQuery] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [field.name]: e });
  };

 // initialize
  useEffect(() => {
    setFormVal((f) => ({
      ...f, [field.name]: {
        ...formVal[field.name],
        isTouched: false,
        isValid: isValid(formData[field.name])
      }
    }))
  }, [])


  // on update
  useEffect(() => {
    if (formData[field.name] !== '')
      setFormVal((f) => ({
        ...f, [field.name]: {
          ...formVal[field.name],
          isTouched: true,
          isValid: isValid(formData[field.name])
        }
      }))
  }, [formData[field.name]])

  function isValid(dt) {
    if (!field.required) return true
    else return dt != ""
  }
  return (
    <>

      <AutocompleteOnBe
        sel={formData[field.name] != null ? formData[field.name] : ''}
        setSel={handleChange}
        showTitle={false}
        removeOptions={remId}
        query={query}
        setQuery={setQuery}
        backendNameAccessor={field.backendNameAccessor}
      />
      {
        (formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) &&
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {'validationerr' in field ? field.validationerr : 'Escolha uma opção'}
        </p>

      }
       {
        query.length < 3 &&
        <p className="mt-2 text-xs text-emerald-600" id="email-error">
         introduza pelo menos 3 caracteres para pesquisar
        </p>

      }
    </>
  );
}
