export const formfield_newcallstate = {
  title: 'Adicionar',
  description: 'Adicione um novo estado de consulta',
  apiEndpoint: '/api/call/states',
  method: 'POST',
  preloadForm: false,
  name: '',
  id: '',
  submitLabel: 'Adicionar',
  rows: [
    {
      selector: 'input',
      required: true,
      class: '',
      type: 'text',
      name: 'name',
      title: 'Nome',
      placeholder: 'Nome do estado...',
      id: 'newcallstate__name',
      map: true,
      validation: (f) => f.length > 0,
      validationerr: 'Nome Inválido.',
    },
    {
      selector: 'emojipicker',
      required: true,
      default: '✅',
      title: 'Ícone',
      name: 'icon',
      map: true,
    },
  ],
};

export const formfield_editcallstate = {
  title: 'Editar',
  description: 'Edite opções do estado',
  apiEndpoint: '/api/call/states',
  method: 'PUT',
  preloadForm: true,
  name: '',
  id: '',
  submitLabel: 'Editar',
  deleteAction: {
    label: 'Eliminar',
  },
  rows: [
    {
      selector: 'input',
      required: true,
      class: '',
      type: 'text',
      name: 'name',
      title: 'Nome',
      placeholder: 'Nome do estado...',
      id: 'newcallstate__name',
      map: true,
      validation: (f) => f.length > 0,
      validationerr: 'Nome Inválido.',
    },
    {
      selector: 'emojipicker',
      required: true,
      default: '✅',
      title: 'Ícone',
      name: 'icon',
      map: true,
    },
  ],
};
