import React from 'react';
import { Link } from 'react-router-dom';
import { formfield_editspacefeatures as fin } from '../../forms/formfield_spacefeatures';

export const tableconfig = {
  edit: {
    showEditButton: true,
    type: 'slideover',
    template: fin,
  },
  view: {
    title: 'Detalhes do Equipamento',
    description: '',
    type: 'slideover',
    template: {
      nCols: 2,
      data: [
        {
          colSpan: 1,
          key: 'id',
          label: 'ID',
          valueformat: (e) => {
            return <Link to={`/call/${e}`}>{`${e}`}</Link>;
          },
        },
        {
          colSpan: 1,
          key: 'name',
          label: 'Nome',
          valueformat: (e) => {
            return <Link to={`/customer/${e.id}`}>{`${e}`}</Link>;
          },
        },
      ],
    },
  },
  columns: [
    {
      key: 'id',
      slideoverView: true,
      label: 'ID',
    },
    {
      key: 'name',
      slideoverView: true,
      label: 'Nome',
    },
  ],
  button: {
    label: 'Ver',
    href: (r) => {
      return '/call/' + r.id;
    },
  },
  filter: {
    initFilter: {
      entries: 10,
      page: 1
    },
    fields: [
     
    ],
  },
};
