
import React, { useEffect, useState, useContext } from "react";
import { putRequests as pt } from '../../common/apiEndpoints';
import { GlobalStateContext } from '../../state/globalState';
import { putJson, blankResult, reqOk } from '../../common/fetchdata';
import CallTagsAddTag from './CallTagsAddTag';

export default function CallTagsList({ call, tags }) {

    const globalcontext = useContext(GlobalStateContext);
    const [putreq, setPutreq] = useState(blankResult);
    const [currentTags, setCurrentTags] = useState([]);

    useEffect(() => {
        if (tags != null && tags.length > 0)
            setCurrentTags(tags)
    }, [call]);

    useEffect(() => {
        if (reqOk(putreq)) {
            if (putreq.items.data.get_call_tags == null)
                setCurrentTags([])
            else
                setCurrentTags(putreq.items.data.get_call_tags)
        }
    }, [putreq]);

    const editData = (args = {}) => {
        putJson(
            pt.call,
            { ...putreq, payload: { ...args, callId: call.id } },
            setPutreq
        );
    };

    const removeTag = (tag) => {
        console.log(tag)
        // type, title, message, okTrigg
        globalcontext.contextFcn.panel.modal.open(
            'info',
            `Remover etiqueta "${tag.label}"?`,
            () => editData({ callTagRemove: tag.call_tag_type.key })
        );
    };

    const addTag = (tag) => {
        // type, title, message, okTrigg
        globalcontext.contextFcn.panel.modal.open(
            'info',
            `Adicionar etiqueta "${tag.label}"?`,
            () => editData({ callTagAdd: tag.key })
        );
    };


    return (
        <>
            <div className="flex  flex-wrap ">

                {currentTags.map((t, tIdx) =>
                    <span key={tIdx} className="mx-2 inline-flex items-center gap-x-0.5 rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">
                        {t.call_tag_type.icon}
                        <span className="pl-2">{t.call_tag_type.label}</span>
                        {!t.call_tag_type.is_system &&
                            <button type="button" onClick={() => removeTag(t)}
                                className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-purple-600/20"
                            >
                                <span className="sr-only">Remove</span>
                                <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-violet-700/50 group-hover:stroke-violet-700/75">
                                    <path d="M4 4l6 6m0-6l-6 6" />
                                </svg>
                                <span className="absolute -inset-1" />
                            </button>
                        }
                    </span>
                )}

            </div>
            <CallTagsAddTag call={call} addTag={addTag} />
        </>
    )
}