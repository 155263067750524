import React from 'react';

import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone'
import 'moment/dist/locale/pt';
moment.locale('pt');

export default function MessagingViewersBubble({ readReceipts }) {

    return (
        <>
            <p>Visto por</p>
            <ul role="list" className="divide-y divide-gray-100">
                {readReceipts.map((item) => (
                    <li key={item.id} className="py-4">
                        <div className="flex items-center gap-x-3">
                            <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                                <span className="text-sm font-medium leading-none text-white">{item.users.initials}</span>
                            </span>
                            <div className="flex flex-col">
                                <h3 className="flex-auto truncate text-sm font-semibold leading-6 text-gray-900">{item.users.fullname}</h3>
                                <time dateTime={item.created_at} className="flex-none text-xs text-gray-500">
                                    {moment(item.created_at).fromNow()}
                                </time>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    );
}
