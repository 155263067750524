import React, { useEffect } from 'react';

export default function InputModule({
  field,
  formData,
  setFormData,
  formVal,
  setFormVal
}) {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  // initialize
  useEffect(() => {
    setFormVal((f) => ({
      ...f, [field.name]: {
        ...formVal[field.name],
        isTouched: false,
        isValid: isValid(formData[field.name])
      }
    }))
  }, [])


  // on update
  useEffect(() => {
    let fv = formVal[field.name]
    if (formData[field.name] != '') {
      fv = { ...fv, isTouched: true }
    }
    fv = { ...fv, isValid: isValid(formData[field.name]) }
    setFormVal((f) => ({
      ...f, [field.name]: fv
    }))
  }, [formData[field.name]])

  function isValid(dt) {
    if (!field.required) return true
    else return field.validation(dt)
  }

  return (
    <>
      <input
        type={field.type}
        name={field.name}
        placeholder={field.placeholder}
        id={field.id}
        onChange={handleChange}
        className={`block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ` +
          ` ${(formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'}  `}
        value={formData[field.name] != null ? formData[field.name] : ''}
      />
      {
        (formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) &&
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {'validationerr' in field ? field.validationerr : 'Valor inválido'}
        </p>

      }
    </>
  );
}
