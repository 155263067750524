import React from 'react';
import RoundAvatar from '../common/card/RoundAvatar';
import AppointmentActionMenu from './appointments/AppointmentActionMenu';
import { reqOk } from '../common/fetchdata';

import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt'; // without this line it didn't work
moment.locale('pt');

const NextAppointments = ({ context }) => {

  const [events, setEvents] = React.useState([]);
  
  React.useEffect(() => {
    if (reqOk(context)) {
      const recs = context.items.data.sort((a, b) => a.datetm - b.datetm);
      const recsdays = [
        ...new Set(recs.map((r) => moment(r.datetm).format('YYYY-MM-DD'))),
      ];
      let ev = [];
      recsdays.map((a) => {
        ev = [
          ...ev,
          {
            day: a,
            relstr:
              a < moment().endOf('day')
                ? 'Hoje'
                : a < moment().add(1, 'day').endOf('day')
                ? 'Amanhã'
                : moment(a).format('DD-MM-YYYY'),
            events: recs.filter(
              (r) => moment(r.datetm).format('YYYY-MM-DD') == a
            ),
          },
        ];
      });
      setEvents(ev);
    }
  }, [context]);

  return (
    <>
   

      {reqOk(context) && context.items.data.length == 0 && (
        <div>Sem consultas </div>
      )}
      {events.length > 0 &&
        events.map((a, k) => (
          <div key={k} className="mb-1 mt-2 ml-2">
            <div className="ml-2 text-gray-600 uppercase text-xs font-semibold tracking-wider">
              {a.relstr}
            </div>
            {a.events.map((ev, kk) => (
              <AppointmentActionMenu
                key={kk}
                btn={
                  <>
                    <div className="bg-white p-2 rounded-lg shadow-sm mb-1">
                      <div className="flex items-baseline">
                        <span className="bg-teal-200 text-teal-800 text-xs px-2 inline-block rounded-full  uppercase font-semibold tracking-wide">
                          {moment(ev.datetm).format('HH:mm')}
                        </span>
                        <div className="ml-2">
                          <RoundAvatar
                            size={1.5}
                            firstName={ev.customers.name}
                            lastName={''}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            ))}
          </div>
        ))}
    </>
  );
};

export default NextAppointments;

/* 
    <div className="bg-white p-6 rounded-lg shadow-lg">
        <div className="flex items-baseline">
          <span className="bg-teal-200 text-teal-800 text-xs px-2 inline-block rounded-full  uppercase font-semibold tracking-wide">
            New
          </span>
          <div className="ml-2 text-gray-600 uppercase text-xs font-semibold tracking-wider">
            2 baths • 3 rooms
          </div>
        </div>
        <h4 className="mt-1 text-xl font-semibold uppercase leading-tight truncate">
          A random Title
        </h4>
        <div className="mt-1">
          $1800
          <span className="text-gray-600 text-sm"> /wk</span>
        </div>
        <div className="mt-4">
          <span className="text-teal-600 text-md font-semibold">
            4/5 ratings{' '}
          </span>
          <span className="text-sm text-gray-600">(based on 234 ratings)</span>
        </div>
    </div>
         */
