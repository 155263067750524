import React from 'react';
import { sortFields } from '../label/locals';
import { personal_data, address_data } from '../label/provider';

const ProviderBasicDatainfo = ({ profile, token, refreshHandler }) => {
  return (
    <>
       <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 border-b border-t border-gray-200 py-4">
        <h2 className="mb-3 text-md font-medium text-gray-500">
          Dados pessoais
          <span
            //onClick={handleEditPersonalData}
            className="ml-2 cursor-pointer text-xs font-medium text-gray-300"
          >
            Editar
          </span>
        </h2>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
          
          {sortFields(
            Object.keys(profile),
            personal_data,
            'pt'
          ).map((field, k) => (
            <div key={k} className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">
                {field.label}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {`${
                  profile[field.key] == null ||
                  profile[field.key] == ''
                    ? '-'
                    : 'format' in field
                    ? field.format(profile[field.key])
                    : profile[field.key]
                } `}
              </dd>
            </div>
          ))}
        </dl>
      </div>

    </>
  );
};

export default ProviderBasicDatainfo;
