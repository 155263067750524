import React, { Fragment, useState, useEffect } from 'react';
import HomeDayView from './HomeDayView';
import HomeLeftSidebar from './sidebar/HomeLeftSidebar'

import { getJson, blankResult, reqOk } from '../common/fetchdata';
import { getRequests as gt } from '../common/apiEndpoints';
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
import HomeRightSidebar from './sidebar/HomeRightSidebar';
import HomeStats from './stats/HomeStats';
import CalendarPage from '../call/calendar/CalendarPage';
moment.locale('pt');



export default function HomeDashboard() {

  const [reqStat, setReqStat] = useState(blankResult);

  function setDateFcn(dateObj) {
    getJson(gt.landingstats, { ...reqStat, params: dateObj }, setReqStat);
  }


  return (
    <>
      <div className="flex h-full w-full">
        <div className="flex flex-1 flex-col overflow-hidden">
          <div className="flex flex-1 items-stretch overflow-hidden">
            <aside className="hidden w-48 overflow-y-auto border-r border-gray-200 bg-white lg:block">
              <HomeLeftSidebar />
            </aside>
            <main className="flex-1">
              <section
                aria-labelledby="primary-heading"
                className="flex h-full min-w-0 flex-1 flex-col lg:order-last"
              >
                {/*      <HomeDayView />  */}

                <div className="hidden lg:block"><HomeStats callStats={reqOk(reqStat) && reqStat.items.data} /></div>

                <div className="w-full h-full p-2">
                <CalendarPage
                  callEvents={reqOk(reqStat) && reqStat.items.data.next_calls}
                  setDateFcn={setDateFcn} />
                </div>
              </section>
            </main>

            <aside className="hidden w-96 overflow-y-auto border-l border-gray-200 bg-white lg:block">

              <HomeRightSidebar nextCalls={reqOk(reqStat) && reqStat.items.data.next_calls} />
              {/* <NextAppointments /> */}

            </aside>
          </div>
        </div>
      </div>
    </>
  );
}
