import React, { useEffect } from 'react';
import Select from 'react-select';
const SelectMultipleFilterCS = ({ sel, setSel, inputData }) => {


  useEffect(() => {
    if (sel != null && inputData != null && inputData.length > 0) {
      let newSel = [];
      let updateObject = false
      sel.map((s) => {
        if (s.label == '') {
          updateObject = true
          newSel = [...newSel, { value: s.value, label: inputData.filter(i => i.value == s.value)[0].label }];
        }
      });
      if (updateObject) setSel(newSel)
    }
  }, [sel, inputData]);


  const handleChangeSelectDoc = (selectedOption) => {
    setSel(selectedOption);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px',
      padding: 5,
    }),
    menu: (provided, state) => ({
      zIndex: 10,
      maxHeigth: '224px',
      borderRadius: '0.2rem',
      position: 'fixed',
      backgroundColor: 'white',
      width: '75%',
      color: ' rgb(0 0 0)',
      fontSize: '0.75rem',
      lineHeight: '1rem',
      boxShadow:
        '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      fontWeight: '400',
    }),
  };


  return (
    <>
      <Select
        isMulti
        placeholder={'Escolha...'}
        className={` w-full rounded-md border-0 p-0.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 font-thin sm:leading-6 bg-white`}
        styles={customStyles}
        //defaultMenuIsOpen={true}
        name="select_provider"
        value={sel}
        onChange={handleChangeSelectDoc}
        options={inputData}
        classNamePrefix="select"
        noOptionsMessage={() => 'Sem opções disponíveis'}
      //menuPosition={"fixed"}
      /></>
  );
};

export default SelectMultipleFilterCS;
