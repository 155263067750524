import React, { useEffect } from 'react'
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import {
    TagIcon,
    HandRaisedIcon
} from '@heroicons/react/20/solid'

import { loadingScreen } from '../../common/fetchdata';
import { blankResult, reqOk } from "../../common/fetchdata";
import SelectProvider from '../../common/combobox/SelectProvider'
import { GlobalStateContext } from '../../state/globalState';
import { doAssignmentChange } from './leadTags'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function LeadAssignmentMenu({ selectedTopic, handleRefresh, actionBtn }) {

    const globalcontext = React.useContext(GlobalStateContext);
    const [pres, setPres] = useState(blankResult);
    const [assigneeSelected, setAssigneeSelected] = useState({})

    useEffect(() => {
        loadingScreen(pres, globalcontext)
    }, [pres]);

    useEffect(() => {
        if (reqOk(pres)) {
            handleRefresh()
        }
    }, [pres])


    return (
        <Listbox className="static">
            {({ open }) => (
                <>
                    <Listbox.Label className="sr-only">Atribuir</Listbox.Label>
                    <div className="absolute">
                        {/*  <Listbox.Button className="relative -ml-px hidden items-center gap-x-1.5 rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 sm:inline-flex"> */}
                        <Listbox.Button className="relative">
                            {actionBtn}
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                                <Listbox.Option
                                    onClick={() => doAssignmentChange(
                                        globalcontext, 
                                        globalcontext.userData.items.user.id,
                                        globalcontext.userData.items.user.fullname,
                                        selectedTopic, pres, setPres
                                    )}
                                    className={({ active }) =>
                                        classNames(
                                            active ? 'bg-gray-200 text-gray-900' : 'text-gray-900',
                                            'group flex w-full items-center rounded-md p-3 text-sm cursor-pointer'
                                        )
                                    }
                                //value={mood}
                                >
                                    <div className="flex items-center">
                                        <div
                                            className={classNames(
                                                'bg-sky-600 flex h-6 w-6 items-center justify-center rounded-full'
                                            )}
                                        >
                                            <HandRaisedIcon
                                                className={'text-white  h-4 w-4 flex-shrink-0'}
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <span className="ml-3 block truncate font-normal">Atribuir a mim</span>
                                    </div>
                                </Listbox.Option>


                                <div className={'text-gray-90 group flex flex-col w-full rounded-md p-3 text-sm '}>
                                    <div className="pb-3 text-xs text-gray-700 font-medium">Atribuir a:</div>
                                    <div className="flex items-center ">

                                        <SelectProvider sel={assigneeSelected} setSel={setAssigneeSelected} showTitle={false} filterByRole={['crm:selfassign']} />
                                    </div>

                                    <div className=" text-xs text-gray-700 font-medium flex flex-row mt-3">
                                        <div className="w-full"></div>
                                        <div className="m-auto">
                                            <button
                                                onClick={() => doAssignmentChange(globalcontext, assigneeSelected.id, assigneeSelected.fullname,
                                                    selectedTopic, pres, setPres)}
                                                type="button"
                                                className="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                Guardar
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
} 