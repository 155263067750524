

function doInitializeCall(props, globalContext) {

    console.log(props)
    console.log(globalContext)

    globalContext.contextFcn.navigate(`/call/newactive?customerId=${props.id}`)

}

export function initializeCall(event, props, globalContext) {


    globalContext.contextFcn.panel.modal.open(
        'info',
        `Deseja iniciar uma sessão agora a ${props.fullname}?`,
        () => doInitializeCall(props, globalContext));

}

