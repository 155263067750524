import React, { useEffect, useState, Fragment } from 'react';
import { VideoCameraIcon, BuildingOfficeIcon } from '@heroicons/react/20/solid';
import { reqOk, blankResult, getJson, putJson } from '../../common/fetchdata';
import { getRequests as gt } from '../../common/apiEndpoints';
import { putRequests as put } from '../../common/apiEndpoints';
import SelectProvider from '../../common/combobox/SelectProvider';

export default function AppointmentManagement({ token }) {
  const [gserv, setGserv] = React.useState(blankResult);
  const [gservcfg, setGservcfg] = React.useState(blankResult);
  const [provCfg, setProvCfg] = React.useState([]);
  const [puserv, setPuserv] = React.useState(blankResult);
  const [defaultVal, setDefaultVal] = useState([]);
  const [selectedOpt, setSelected] = useState(null);

  useEffect(() => {
    if (reqOk(puserv)) {
      fetchConfig();
    }
  }, [puserv]);

  const fetchConfig = () => {
    if (selectedOpt != null && selectedOpt.id > 0) {
      fetchServiceTypes(selectedOpt.id);
      fetchCustomServiceConfig(selectedOpt.id);
    }
  };

  React.useEffect(() => {
    fetchConfig();
  }, [selectedOpt]);

  React.useEffect(() => {
    if (reqOk(gserv) && reqOk(gservcfg)) {
      let provCustCfg = [];
      gserv.items.data.map((s) => {
        let provIds = [];
        s.providers.map((p) => (provIds = [...provIds, p.id]));
        if (provIds.includes(parseInt(selectedOpt.id))) {
          s.servsubtype.map((ss) => {
            let cstCfg = gservcfg.items.data.filter(
              (c) => c.servsubtype_id == ss.id
            );
            let thisCstCfg = ss;
            if (cstCfg.length > 0) {
              thisCstCfg = cstCfg[0];
              delete thisCstCfg.servsubtype_id;
            }
            provCustCfg = [...provCustCfg, { ...thisCstCfg, sstype_id: ss.id }];
          });
        }
      });
      provCustCfg.sort(function (a, b) {
        return a.id - b.id;
      });
      setProvCfg(provCustCfg);
      setDefaultVal(provCustCfg);
    }
  }, [gserv]);

  const fetchServiceTypes = () => {
    getJson(gt.servicetype, { ...gserv, token: token }, setGserv);
  };

  const fetchCustomServiceConfig = () => {
    getJson(
      gt.provservcfg,
      { ...gservcfg, token: token, params: { providerId: selectedOpt.id } },
      setGservcfg
    );
  };

  const configProviderServiceTypes = () => {
    putJson(
      put.provservcfg,
      { ...puserv, token: token, payload: provCfg, id: selectedOpt.id },
      setPuserv
    );
  };

  const getValue = (id, field) => {
    if (provCfg.length > 0) {
      return provCfg.filter((p) => p.sstype_id == id)[0][field];
    }
  };

  const handleChange = (e, id, field) => {
    const val = e;
    let provCustCfg = provCfg;
    provCustCfg = [
      ...provCustCfg.filter((s) => s.sstype_id != id),
      { ...provCustCfg.filter((s) => s.sstype_id == id)[0], [field]: val },
    ];
    provCustCfg.sort(function (a, b) {
      return a.id - b.id;
    });
    setProvCfg(provCustCfg);
  };

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Profile
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            This information will be displayed publicly so be careful what you
            share.
          </p>
          <div className="mt-3 w-72">
            <SelectProvider
              sel={selectedOpt}
              setSel={setSelected}
            />
          </div>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 flex space-x-3">
          <button
            type="button"
            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Novo Perfil
          </button>
          {JSON.stringify(provCfg) != JSON.stringify(defaultVal) && (
            <button
              type="button"
              onClick={configProviderServiceTypes}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Guardar alterações
            </button>
          )}
        </div>
      </div>
      <div className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6" />

      <div>
        {selectedOpt != null && (
          <>
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Bank accounts</h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">Connect bank accounts to your account.</p>

              <ul role="list" className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <li className="flex justify-between gap-x-6 py-6">
                  <div className="font-medium text-gray-900">TD Canada Trust</div>
                  <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Update
                  </button>
                </li>
                <li className="flex justify-between gap-x-6 py-6">
                  <div className="font-medium text-gray-900">Royal Bank of Canada</div>
                  <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Update
                  </button>
                </li>
              </ul>

              <div className="flex border-t border-gray-100 pt-6">
                <button type="button" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                  <span aria-hidden="true">+</span> Add another bank
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      <div>
        {selectedOpt != null && (
          <>
            <h2 className="text-base font-semibold text-gray-900">
              Configuração de consulta
            </h2>
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 px-6  text-left text-sm font-semibold text-gray-900 sm:pl-0 w-12"
                        >
                          Habilitar
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3.5 text-left text-sm font-semibold text-gray-900 w-12"
                        >
                          Tipo
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-6  text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Consulta
                        </th>
                        <th
                          scope="col"
                          className="px-6  py-3.5 text-left text-sm font-semibold text-gray-900 w-48"
                        >
                          Duração
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3.5 text-center text-sm font-semibold text-gray-900 w-12"
                        >
                          Marcação Externa
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {provCfg.length > 0 &&
                        selectedOpt != null &&
                        reqOk(gserv) &&
                        gserv.items.data.map((s) =>
                          s.servsubtype.map((ss, k) => (
                            <React.Fragment key={k}>
                              {s.providers.filter(
                                (p) => p.id == parseInt(selectedOpt.id)
                              ).length > 0 && (
                                <tr>
                                  <td className="whitespace-nowrap px-6 py-2 text-sm text-gray-500 text-left">
                                    <label className="form-check form-switch">
                                      <input
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        type="checkbox"
                                        checked={getValue(ss.id, 'is_enabled')}
                                        onChange={(e) =>
                                          handleChange(
                                            e.target.checked,
                                            ss.id,
                                            'is_enabled'
                                          )
                                        }
                                      />
                                    </label>
                                  </td>
                                  <td className="whitespace-nowrap px-6 py-2 text-sm text-gray-500">
                                    {ss.is_remote ? (
                                      <VideoCameraIcon className="w-4 h-4" />
                                    ) : (
                                      <BuildingOfficeIcon className="w-4 h-4" />
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap py-2 px-6 text-sm  sm:pl-0">
                                    <div className="text-gray-900">
                                      {`${ss.name}`}
                                    </div>
                                    <div className="text-gray-500">
                                      {`${s.name}`}
                                    </div>
                                  </td>

                                  <td className="whitespace-nowrap px-6 py-2 text-sm text-gray-500">
                                    <div className="relative mt-2 flex rounded-md shadow-sm">
                                      <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                        {ss.duration_min}
                                      </span>
                                      <input
                                        className="block w-full min-w-0 flex-1 rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        type="number"
                                        name="duration_min"
                                        placeholder="Duração (min.)"
                                        value={
                                          getValue(ss.id, 'duration_min') ==
                                          null
                                            ? ''
                                            : getValue(ss.id, 'duration_min')
                                        }
                                        onChange={(e) =>
                                          handleChange(
                                            e.target.value,
                                            ss.id,
                                            'duration_min'
                                          )
                                        }
                                      />
                                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                        <span
                                          className="text-gray-500 sm:text-sm"
                                          id="price-currency"
                                        >
                                          min.
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-6 py-2 text-sm text-gray-500 text-center">
                                    <label className="form-check">
                                      <input
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        type="checkbox"
                                        checked={getValue(
                                          ss.id,
                                          'allow_client_booking'
                                        )}
                                        onChange={(e) =>
                                          handleChange(
                                            e.target.checked,
                                            ss.id,
                                            'allow_client_booking'
                                          )
                                        }
                                      />
                                    </label>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
