import React from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import {  Combobox } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ListEditModule({
  field,
  childDep,
  formData,
  setFormData,
}) {
  const [query, setQuery] = React.useState('');

  //listedit
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [listItems, setListItems] = React.useState([]);
  const [items, setItems] = React.useState([]);

    //DEPRECATED!!!
  // CHild data listedit
  React.useEffect(() => {
    if (fin.listedit && fin.listedit.childData.getEndpoint != null && open) {
      getJson(
        fin.listedit.childData.getEndpoint,
        { ...childData, token: token },
        setChildData
      );
    }
  }, [fin, open]);

  React.useEffect(() => {
    if (childData.state == 2 && !childData.error) {
      setItems(childData.items.data);
    }
  }, [childData]);

  function ListCombobox() {
    const ledit = fin.listedit;

    const filteredItems =
      query === ''
        ? items
        : items.filter((items) => {
            return items[ledit.title]
              .toLowerCase()
              .includes(query.toLowerCase());
          });

    return (
      <Combobox as="div" value={selectedItem} onChange={setSelectedItem}>
        <div className="relative mt-2">
          <Combobox.Input
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(items) => items?.name}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {filteredItems.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredItems.map((items, k) => (
                <Combobox.Option
                  key={k}
                  value={items}
                  onClick={() => {
                    const uniq = [...new Set([...listItems, items])];
                    setListItems(uniq);
                    setFormData({
                      ...formData,
                      [ledit.name]: uniq.map((a) => a.id),
                    });
                  }}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <div className="flex">
                        <span
                          className={classNames(
                            'truncate',
                            selected && 'font-semibold'
                          )}
                        >
                          {items[ledit.title]}
                        </span>
                        <span
                          className={classNames(
                            'ml-2 truncate text-gray-500',
                            active ? 'text-indigo-200' : 'text-gray-500'
                          )}
                        >
                          {items[ledit.subtitle]}
                        </span>
                      </div>
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    );
  }
  return (
    <>
      <ListCombobox />
      <ul
        role="list"
        className="divide-y divide-gray-100 rounded-md border border-gray-200 mt-3"
      >
        {listItems.map((l, k) => (
          <li
            key={k}
            className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
          >
            <div className="flex w-0 flex-1 items-center">
              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                <span className="truncate font-medium">
                  {l[fin.listedit.title]}
                </span>
                <span className="flex-shrink-0 text-gray-400">
                  {l[fin.listedit.subtitle]}
                </span>
              </div>
            </div>
            <div className="ml-4 flex-shrink-0">
              <div
                className="cursor-pointer"
                onClick={() => {
                  const _it = [...listItems.filter((n) => n.id != l.id)];
                  setListItems(_it);
                  setFormData({
                    ...formData,
                    [fin.listedit.name]: _it.map((i) => i.id),
                  });
                }}
              >
                <TrashIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400 hover:text-red-500"
                  aria-hidden="true"
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
