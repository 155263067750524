import React, { useEffect, useState, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { reqOk, blankResult, getJson, putJson, postJson, deleteJson } from '../../common/fetchdata';
import { apiUrl } from '../../common/apiEndpoints';
import TextInputModal from "../../common/modal/TextInputModal";
import AvatarXSStack from '../../common/card/AvatarXSStack';
import { TrashIcon } from '@heroicons/react/24/outline';

const landingPages = [
  { url: '/', name: 'A minha Agenda' },
  { url: '/bookrequest', name: 'Pedidos de Marcação' },
  { url: '/phonecentral', name: 'Registo Telefónico' },
  { url: '/analytics', name: 'Dashboards e Analytics' },
  { url: '/filemanager', name: 'Gestor de Ficheiros' },
  { url: '/preferences', name: 'Configurações' },
  { url: '/call', name: 'Consultas' },
  { url: '/provider', name: 'Médicos' },
  { url: '/customer', name: 'Pacientes' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export default function RoleManagement({ }) {

  const [newRoleNameInput, setNewRoleNameInput] = useState(false);

  const [rget, setRget] = useState(blankResult);
  const [rput, setRput] = useState(blankResult);

  const [saveCfg, setSavecfg] = useState([]);
  const [defaultVal, setDefaultVal] = useState([]);

  const [selectedOpt, setSelected] = useState(null);


  useEffect(() => {
    fetchConfig();
  }, []);


  function handleReset() {
    let reqval = rget.items.data;
    reqval = reqval.sort(function (a, b) {
      return a.id - b.id;
    })

    setSavecfg(reqval);

    let defValue = JSON.parse(JSON.stringify(reqval));
    defValue = defValue.sort(function (a, b) {
      return a.id - b.id;
    })
    setDefaultVal(defValue);
  }


  useEffect(() => {
    if (reqOk(rget)) {
      handleReset()
    }
  }, [rget]);

  useEffect(() => {
    if (reqOk(rput)) {
      fetchConfig();
    }
  }, [rput]);

  const fetchConfig = () => {
    getJson(apiUrl.configrole, { ...rget }, setRget);
    setSelected(null)
  };

  const handleSave = () => {
    putJson(
      apiUrl.configrole,
      { ...rput, payload: saveCfg },
      setRput
    );
  };

  const handleChange = (e, field, target) => {
    let roleItems = [...saveCfg];
    let roleItem = roleItems.filter((i) => i.id == field.user_roles_id)[0];
    const roleItemPos = roleItems.indexOf(roleItem);
    let items = [...roleItem.role_resource_config];
    let item = items.filter((i) => i.id == field.id)[0];
    const itemPos = items.indexOf(item);
    item[target] = e;
    items[itemPos] = item;
    roleItem.role_resource_config = items;
    roleItems[roleItemPos] = roleItem;
    setSavecfg(roleItems);
  };

  const handleSetLanding = (e) => {

    let _sConfig = [
      ...saveCfg.filter(s => s.id != selectedOpt.id),
      { ...saveCfg.filter(s => s.id == selectedOpt.id)[0], landing_page: e.url }
    ]

    setSavecfg(_sConfig)
  }


  const landingPage = () => {
    return saveCfg.filter((s) => s.id == selectedOpt.id)[0].landing_page
  }

  const landingPageLabel = () => {
    return landingPages.filter(l => l.url == saveCfg.filter((s) => s.id == selectedOpt.id)[0].landing_page)[0].name
  }

  function saveNewRoleChanges(e) {
    postJson(
      apiUrl.configrole,
      { ...rput, payload: { ...e, templateId: selectedOpt.id } },
      setRput
    );
  }

  function handleDeleteRole() {
    deleteJson(
      apiUrl.configrole,
      { ...rput, params: { roleId: selectedOpt.id } },
      setRput
    );
  }

  return (
    <>

      <TextInputModal
        title={"Criar um novo perfil a partir de atual"}
        open={newRoleNameInput}
        setOpen={setNewRoleNameInput}
        initValue={[{ label: "Nome", id: "roleName", value: '' }]}
        saveValue={(e) => saveNewRoleChanges(e)}
      />

      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Perfis de acesso
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Configurações de acesso por perfil
          </p>

          {reqOk(rget) && (
            <div className="mt-3 w-72">
              <Listbox value={selectedOpt} onChange={setSelected}>
                <div className="relative mt-1">
                  <Listbox.Button className="inline-flex w-full  gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    <span className="block truncate">
                      {selectedOpt == null ? 'Escolha' : selectedOpt.name}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {saveCfg.length > 0 &&
                        saveCfg
                          .sort(function (a, b) {
                            return a.id - b.id;
                          })
                          .map((ccfg, ccfgIdx) => (
                            <Listbox.Option
                              key={ccfgIdx}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                  ? 'bg-amber-100 text-amber-900'
                                  : 'text-gray-900'
                                }`
                              }
                              value={ccfg}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                      }`}
                                  >
                                    {ccfg.name}
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          )}
        </div>


        <div className="mt-4 sm:ml-16 sm:mt-0 flex space-x-3">
          {(selectedOpt != null && saveCfg.length > 0) &&

            <Fragment>
              {!(saveCfg.filter((s) => s.id == selectedOpt.id)[0].is_system) &&
                <button
                  onClick={handleDeleteRole}
                  type="button"
                  className="block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>}

              {JSON.stringify(saveCfg) != JSON.stringify(defaultVal) ? (
                <Fragment>
                  <button
                    type="button"
                    onClick={handleReset}
                    className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={handleSave}
                    className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Guardar alterações
                  </button>
                </Fragment>
              ) :
                <button
                  onClick={() => setNewRoleNameInput(true)}
                  type="button"
                  className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Clonar
                </button>
              }
            </Fragment>}

        </div>
      </div>
      <div className="mt-3 space-y-3 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6" />

      {selectedOpt != null && (
        <div className="px-4 sm:px-6 lg:px-8">

          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-3">

            <div className="mx-4 py-3">
              <div className="block text-sm font-medium leading-6 text-gray-900 mb-3">Utilizadores</div>

              {saveCfg.filter((s) => s.id == selectedOpt.id)[0].users.length == 0 ?
                <>Não existem utilizadores com o perfil seleccionado</> :
                <AvatarXSStack
                  persons={saveCfg.filter((s) => s.id == selectedOpt.id)[0].users}
                  persMax={saveCfg.filter((s) => s.id == selectedOpt.id)[0].users.length} />}
            </div>

            <div className="mx-4 py-3">

              <Listbox value={landingPage()} onChange={handleSetLanding}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Página de Início</Listbox.Label>
                    <div className="relative mt-2">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <span className="block truncate">{landingPageLabel()}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {landingPages.map((epoint) => (
                            <Listbox.Option
                              key={epoint.url}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={epoint}
                            >
                              {({ selected_HP, active }) => (
                                <>
                                  <span className={classNames(selected_HP ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                    {epoint.name}
                                  </span>

                                  {selected_HP ? (
                                    <span
                                      className={classNames(
                                        active ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>

            </div>
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Categoria
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Descrição
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Leitura
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Escrita
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {saveCfg.length > 0 &&
                    selectedOpt != null &&
                    selectedOpt.id > 0 &&
                    saveCfg
                      .filter((s) => s.id == selectedOpt.id)
                      .map((role, k) => (
                        <Fragment key={k}>
                          {role.role_resource_config.map((rcfg, j) => (
                            <tr key={j}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{`${rcfg.role_resource.category}`}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{`${rcfg.role_resource.description}`}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <input
                                  onChange={(e) =>
                                    handleChange(e.target.checked, rcfg, 'read')
                                  }
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  type="checkbox"
                                  checked={rcfg.read}
                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <input
                                  onChange={(e) =>
                                    handleChange(
                                      e.target.checked,
                                      rcfg,
                                      'write'
                                    )
                                  }
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  type="checkbox"
                                  checked={rcfg.write}
                                />
                              </td>
                            </tr>
                          ))}
                        </Fragment>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
