import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PhoneCentralInternalNumsTable({ handleDelete, data }) {
    return (
        <table className="min-w-full border-separate border-spacing-0">
            <thead>
                <tr>
                    <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                    >
                        Número
                    </th>
                    <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                    >
                        Descrição
                    </th>
                    <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    >
                        Privado
                    </th>
                    <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    >
                        Ocultar
                    </th>
                    <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                        Extensão
                    </th>
                    <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                    >
                        <span className="sr-only">Edit</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {data.map((phone, phoneIdx) => (
                    <tr key={phone.id}>
                        <td
                            className={classNames(
                                phoneIdx !== data.length - 1 ? 'border-b border-gray-200' : '',
                                'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                            )}
                        >
                            <span className="text-xs mr-2">{phone.phone_cc}</span>
                            {phone.phone_loc}
                        </td>
                        <td
                            className={classNames(
                                phoneIdx !== data.length - 1 ? 'border-b border-gray-200' : '',
                                'whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell'
                            )}
                        >
                            {phone.description}
                        </td>
                        <td
                            className={classNames(
                                phoneIdx !== data.length - 1 ? 'border-b border-gray-200' : '',
                                'whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell'
                            )}
                        >
                            {phone.is_private ? (
                                <CheckCircleIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                    aria-hidden="true"
                                />
                            ) : (
                                ''
                            )}
                        </td>
                        <td
                            className={classNames(
                                phoneIdx !== data.length - 1 ? 'border-b border-gray-200' : '',
                                'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                            )}
                        >
                            {phone.hide_outgoing ? (
                                <CheckCircleIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                    aria-hidden="true"
                                />
                            ) : (
                                ''
                            )}
                        </td>
                        <td
                            className={classNames(
                                phoneIdx !== data.length - 1 ? 'border-b border-gray-200' : '',
                                'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                            )}
                        >
                            {phone.is_extension ? (
                                <CheckCircleIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                    aria-hidden="true"
                                />
                            ) : (
                                ''
                            )}
                        </td>
                        <td
                            className={classNames(
                                phoneIdx !== data.length - 1 ? 'border-b border-gray-200' : '',
                                'relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-8 lg:pr-8'
                            )}
                        >
                            <button onClick={() => handleDelete(phone.id)} className="text-indigo-600 hover:text-indigo-900">
                                Remover<span className="sr-only">, {phone.phone_loc}</span>
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
