import React, { Fragment, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import { getRequests as gt } from '../common/apiEndpoints';
import { getJson, blankResult, reqOk } from '../common/fetchdata';
import { Listbox, Transition } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const PickProvider = ({ token, selection, setSelection }) => {
  const [query, setQuery] = useState('');
  const [selectedPerson, setSelectedPerson] = useState(null);

  const [submitResult, setSubmitResult] = React.useState(blankResult);
  const [cascadingLev2, setCascadingLev2] = React.useState([]);
  const [cascadingLev3, setCascadingLev3] = React.useState([]);

  React.useEffect(() => {
    if (
      selection.steps.filter((s) => s.key == 'provider')[0].completed &&
      submitResult.state === 2
    ) {
      setCascadingLev2(
        submitResult.items.data.filter((f) => f.id == selection.providerId)[0]
          .active_servtype
      );
      setCascadingLev3(
        submitResult.items.data
          .filter((f) => f.id == selection.providerId)[0]
          .active_servtype.filter((f) => f.id == selection.optId)[0].servsubtype
      );
    }
  }, [submitResult, selection]);

  const getData = () => {
    getJson(gt.provider, { ...submitResult, token: token }, setSubmitResult);
  };

  const cascadingDropdown = (prov) => {
    setSelection({
      ...selection,
      providerId: prov.id,
      provider: prov,
      optId: 0,
      opt: [],
      subOptId: 0,
      subOpt: [],
      durationMin: 0,
      steps: [
        ...selection.steps.filter((s) => s.key != 'provider'),
        {
          ...selection.steps.filter((s) => s.key == 'provider')[0],
          completed: false,
          value: prov.id,
          label: prov.name,
        },
      ],
    });
    setCascadingLev2(prov.active_servtype);
    setCascadingLev3([]);
  };

  const cascadingDropDownL2 = (e) => {
    const opt = e;
    setSelection({
      ...selection,
      optId: opt.id,
      opt: opt,
      subOptId: 0,
      subOpt: [],
      durationMin: 0,
      steps: [
        ...selection.steps.filter((s) => s.key != 'provider'),
        {
          ...selection.steps.filter((s) => s.key == 'provider')[0],
          completed: false,
        },
      ],
    });
    setCascadingLev3(opt.servsubtype);
  };

  React.useEffect(() => {
    getData();
  }, []);

  // To capture when the providerId goes in the url argument context
  React.useEffect(() => {
    if (
      reqOk(submitResult) &&
      selection.steps.filter((s) => s.key == 'provider')[0].value != ''
    ) {
      cascadingDropdown(
        selection.steps.filter((s) => s.key == 'provider')[0].value
      );
    }
  }, [submitResult]);

  // Provider Picker
  const filteredPeople = !reqOk(submitResult)
    ? []
    : query === ''
    ? submitResult.items.data
    : submitResult.items.data.filter((person) => {
        return person.name.toLowerCase().includes(query.toLowerCase());
      });

  const handleSelectPerson = (e) => {
    cascadingDropdown(e);
    setSelectedPerson(e);
  };

  React.useEffect(() => {
    if (
      reqOk(submitResult) &&
      selection.steps.filter((s) => s.key == 'provider')[0].value != ''
    ) {
      const selecPers = submitResult.items.data.filter(
        (person) =>
          person.id ==
          selection.steps.filter((s) => s.key == 'provider')[0].value
      )[0];
      handleSelectPerson(selecPers);
    }
  }, [submitResult]);

  const optSelected = selection.optId
    ? selection.optId != 0
      ? true
      : false
    : false;

    const persSelected = selection.steps.filter((s) => s.key == 'provider')[0].value != ''
  


  return (
    <>
      <div className="p-4 w-full grid  place-items-center">
        <ul
          role="list"
          className="divide-y divide-gray-200 sm:w-5/6 md:w-4/6 lg:w-1/2"
        >
          <li className="px-4 py-4 sm:px-0 w-full">
            <Combobox
              className="w-full place-items-center"
              as="div"
              value={selectedPerson}
              onChange={handleSelectPerson}
            >
              <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                Médico
              </Combobox.Label>
              <div className="relative mt-2 w-full">
                <Combobox.Input
                  className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(event) => setQuery(event.target.value)}
                  displayValue={(person) => person?.name}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>

                {filteredPeople.length > 0 && (
                  <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredPeople.map((person) => (
                      <Combobox.Option
                        key={person.id}
                        value={person}
                        className={({ active }) =>
                          classNames(
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            active
                              ? 'bg-indigo-600 text-white'
                              : 'text-gray-900'
                          )
                        }
                      >
                        {({ active, selected }) => (
                          <>
                            <div className="flex items-center">
                              {person.avatar_url == null ? (
                                <span className="inline-flex h-6 w-6  items-center justify-center rounded-full bg-gray-500">
                                  <span className="text-sm font-medium leading-none text-white">
                                    {person.name[0]}
                                  </span>
                                </span>
                              ) : (
                                <img
                                  className="h-6 w-6 rounded-full"
                                  src={`../static/uploads/${person.avatar_url}`}
                                  alt=""
                                />
                              )}
                              <span
                                className={classNames(
                                  'ml-3 truncate',
                                  selected && 'font-semibold'
                                )}
                              >
                                {person.name}
                              </span>
                            </div>

                            {selected && (
                              <span
                                className={classNames(
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                  active ? 'text-white' : 'text-indigo-600'
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
            </Combobox>
          </li>
          <li className="px-4 py-4 sm:px-0 w-full ">
            <Listbox
              disabled={!persSelected}
              id="servtype"
              className=" w-full"
              value={
                selection.optId &&
                cascadingLev2.filter((c) => c.id == selection.optId).length > 0
                  ? cascadingLev2.filter((c) => c.id == selection.optId)[0]
                  : { name: '', id: '' }
              }
              onChange={cascadingDropDownL2}
            >
              {({ open }) => (
                <>
                  <Listbox.Label className={`block text-sm font-medium leading-6 ${persSelected ? 'text-gray-900' : 'text-gray-500' }`}>
                    Especialidade
                  </Listbox.Label>
                  <div className="relative mt-2 w-full">
                    <Listbox.Button className={`relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 ${persSelected ? 'text-gray-900 ring-1 ring-inset ring-gray-300' : 'text-gray-500 ring-1 ring-inset ring-gray-100'}`}>
                      <span className="block truncate">
                        {selection.optId &&
                        cascadingLev2.filter((c) => c.id == selection.optId)
                          .length > 0
                          ? cascadingLev2.filter(
                              (c) => c.id == selection.optId
                            )[0].name
                          : 'Escolha...'}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {cascadingLev2.map((stype) => (
                          <Listbox.Option
                            key={stype.id}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? 'bg-indigo-600 text-white'
                                  : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-8 pr-4'
                              )
                            }
                            value={stype}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'block truncate'
                                  )}
                                >
                                  {stype.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-indigo-600',
                                      'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </li>
          <li className="px-4 py-4 sm:px-0 w-full ">
            <Listbox
              disabled={!optSelected}
              id="servsubtype"
              className=" w-full"
              onChange={(e) => {
                setSelection({
                  ...selection,
                  steps: [
                    ...selection.steps.filter((s) => s.key != 'provider'),
                    {
                      ...selection.steps.filter((s) => s.key == 'provider')[0],
                      completed: true,
                    },
                  ],
                  subOptId: e.id,
                  subOpt: e,
                  durationMin: e.duration_min,
                });
              }}
              value={selection.subOptId ? selection.subOpt : {}}
            >
              {({ open }) => (
                <>
                 <Listbox.Label className={`block text-sm font-medium leading-6 ${optSelected ? 'text-gray-900' : 'text-gray-500' }`}>
                    Consulta
                  </Listbox.Label>
                  <div className="relative mt-2 w-full">
                    <Listbox.Button className={`relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 ${optSelected ? 'text-gray-900 ring-1 ring-inset ring-gray-300' : 'text-gray-500 ring-1 ring-inset ring-gray-100'}`}>
                      <span className="block truncate">
                        {selection.optId &&
                        cascadingLev3.filter((c) => c.id == selection.subOptId)
                          .length > 0
                          ? cascadingLev3.filter(
                              (c) => c.id == selection.subOptId
                            )[0].name
                          : 'Escolha...'}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {cascadingLev3.map((stype) => (
                          <Listbox.Option
                            key={stype.id}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? 'bg-indigo-600 text-white'
                                  : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-8 pr-4'
                              )
                            }
                            value={stype}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'block truncate'
                                  )}
                                >
                                  {stype.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-indigo-600',
                                      'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </li>
        </ul>
      </div>
      {/* 
    
      {/* 
                <option disabled value={0}>
                
                  <option disabled={selection.providerId == 0} key={k} value={d.id}>
                  
      )} */}
    </>
  );
};

export default PickProvider;
