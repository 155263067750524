import { useState, useEffect } from "react";
import { getJson, blankResult, reqOk, putJson } from "../common/fetchdata";
import { getRequests as gt, putRequests as pu } from "../common/apiEndpoints";
import TextInputModal from "../common/modal/TextInputModal";

export default function MyProfileSecurityData() {
  const [usrdata, setUsrdata] = useState(blankResult);
  const [savedata, setSaveData] = useState(blankResult);
  const [inpModalShow_password, setinpModalShow_password] = useState(false);

  function fetchUsrData() {
    getJson(gt.myuserconfig, { ...usrdata }, setUsrdata);
  }
  function saveChanges(saveData) {
    putJson(pu.myuserconfig, { ...savedata, payload: { ...saveData } }, setSaveData);
  }

  useEffect(() => {
    fetchUsrData();
  }, []);

  useEffect(() => {
    if (reqOk(savedata)) fetchUsrData();
  }, [savedata]);

  return (
    <>
      {reqOk(usrdata) && (
        <TextInputModal
          inputType={"password"}
          title={"Palavra-passe"}
          open={inpModalShow_password}
          setOpen={setinpModalShow_password}
          initValue={[
            { label: "Password", id: "newpassword", value: "" },
            { label: "Repita a password", id: "repeatpassword", value: "" },
          ]}
          saveValue={(e) => saveChanges(e)}
        />
      )}

      <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">O meu perfil</h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">Segurança</p>

            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Palavra-passe</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900"></div>
                  <button
                    type="button"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                    onClick={() => setinpModalShow_password(true)}
                  >
                    Alterar
                  </button>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </main>
    </>
  );
}
