
import { postRequests as post, putRequests as put } from '../common/apiEndpoints';

const rows = [
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'title',
    title: 'Título',
    placeholder: 'Recomendações',
    id: 'title',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Nome Inválido.',
  },
  {
    selector: 'servicetype',
    required: true,
    name: 'servtype',
    title: 'Especialidade',
    id: 'servtype',
    map: true
  },

  {
    selector: 'tinymce',
    fullwidth: true,
    required: true,
    type: 'text',
    name: 'content',
    title: 'Descrição',
    placeholder: 'Introduza as recomemndações para o paciente',
    id: 'content',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Descrição Inválida.',
  },

  
 
];


export const formfield_newcalladvice = {
  title: 'Novo',
  description: 'Adicione recomendações para partilhar com o paciente antes da consulta.',
  apiEndpoint: post.calladvice,
  method: 'POST',
  payloadType: 'json',
  preloadForm: false,
  submitLabel: 'Adicionar',
  rows: rows,
};

export const formfield_editcalladvice = {
  title: 'Editar',
  description: 'Edite recomendações para partilhar com o paciente antes da consulta.',
  apiEndpoint: put.calladvice,
  method: 'PUT',
  payloadType: 'json',
  preloadForm: true,
  submitLabel: 'Editar',
  deleteAction: {
    label: 'Eliminar',
  },
  rows: rows,
};
