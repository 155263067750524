
import React, { useContext, useState, useEffect } from 'react';
import { GlobalStateContext } from '../state/globalState';
import { getJson, blankResult, reqOk } from '../common/fetchdata';
import { apiUrl } from '../common/apiEndpoints';
import { CheckIcon, EnvelopeIcon } from '@heroicons/react/20/solid';
import moment from 'moment-timezone'
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import 'moment/dist/locale/pt';
import MessagingLoadingTopics from './MessagingLoadingTopics';
moment.locale('pt');
import parsePhoneNumber from 'libphonenumber-js'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { NoSymbolIcon } from '@heroicons/react/24/outline';



const statusMap = [
    { name: 'Aberto', value: 'OPEN' },
    { name: 'Resolvido', value: 'CLOSED' },
    { name: 'Pendente', value: 'PENDING' },
]

function greetUser() {
    const now = moment(); // Get the current time using moment
    const currentHour = now.hour(); // Extract the hour from the current time

    // Determine the greeting based on the current hour
    if (currentHour < 12) {
        return "☀️ Bom dia";
    } else if (currentHour < 19) {
        return "🌅 Boa tarde";
    } else {
        return "🌙 Boa noite";
    }
}


export default function MessagingMyLeads({ viewLeadFromDashboard, viewByOwner }) {

    const [data, setData] = useState(blankResult)

    function handleRefresh() {
        getJson(apiUrl.myactivethreads, { ...data }, setData)
    }

    useEffect(() => {
        handleRefresh()
    }, [])

    const openLeads = [
        {
            name: 'Todas',
            unreadItems: reqOk(data) && data.items.openleads.all,
            onClick: () => viewByOwner(-1),
            hasData: reqOk(data) && data.items.openleads.all > 0 ? true : false,
        },
        {
            name: 'Com pedidos',
            unreadItems: reqOk(data) && data.items.openleads.unanswered_comm,
            onClick: () => viewByOwner(-1, true),
            hasData: reqOk(data) && data.items.openleads.unanswered_comm > 0 ? true : false,
        }
    ]

    const globalcontext = useContext(GlobalStateContext);
    /* 
        ${globalcontext.userData.items.user.name} */
    return (
        <div className="p-6">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">{`${greetUser()} ${reqOk(globalcontext.userData) ? globalcontext.userData.items.user.name : ''}`}</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Tem as seguintes leads do seu lado pendentes de resposta:
                    </p>
                </div>
            </div>
            <div className="mt-3 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">As minhas leads abertas</h1>
                        <div className="mt-3 shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg overflow-auto max-h-48">
                            {
                                !reqOk(data) ? <MessagingLoadingTopics /> :
                                    (data.items.myleads.length > 0 ?
                                        <table className="min-w-full divide-y divide-gray-300 ">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        Contacto
                                                    </th>

                                                    <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">
                                                        Estado
                                                    </th><th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {data.items.myleads.map((lead, i) => (
                                                    <tr key={i}>
                                                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            <a href="#"
                                                                className="flex flex-row"
                                                                onClick={() =>
                                                                    viewLeadFromDashboard(lead.id)
                                                                } >
                                                                <span
                                                                    className={
                                                                        ' rounded-md p-0.5 text-xs ring-1 ring-inset font-thin text-green-700 bg-green-50 ring-green-600/20'
                                                                    }
                                                                >
                                                                    {typeof parsePhoneNumber(lead.phone_number, null) != 'undefined'
                                                                        ? parsePhoneNumber(lead.phone_number, null).country
                                                                        : ''}
                                                                </span>
                                                                <span className="ml-2 text-xs">
                                                                    {typeof parsePhoneNumber(lead.phone_number, null) != 'undefined' ? parsePhoneNumber(lead.phone_number, null).nationalNumber : lead.phone_number}
                                                                </span>
                                                            </a>
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-2 text-xs text-gray-500">
                                                            {statusMap.filter(s => s.value == lead.current_status)[0].name}

                                                        </td>
                                                        <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                                                            {lead.unanswered_comm ? <span className="flex h-9 items-center">
                                                                <span className="relative flex h-4 w-4 items-center justify-center rounded-full bg-emerald-600">
                                                                    <EnvelopeIcon
                                                                        className="h-2 w-2 text-white"
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            </span> : <></>}
                                                        </td>
                                                    </tr>
                                                ))}


                                            </tbody>

                                            <tfoot className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6">
                                                        <a href="#" onClick={() => viewByOwner(globalcontext.userData.items.user.id)}>Ver todas</a>
                                                    </th>
                                                    <th />
                                                    <th />

                                                </tr>
                                            </tfoot>

                                        </table>
                                        :
                                        <NoLeadsAssigned />)
                            }
                        </div>
                    </div>
                </div>
                <div className="mt-3 -mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Leads não atribuídas</h1>

                        <ul
                            /* unreadItems="list" */
                            className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl mt-3"
                        >
                            {openLeads.map((item, i) => (
                                <li key={i} className={
                                    `relative flex justify-between gap-x-6 px-4 py-3 sm:px-6 ` +
                                    (item.hasData
                                        ? 'hover:bg-gray-50 text-gray-900'
                                        : 'text-gray-300')
                                }>
                                    <div className="flex min-w-0 gap-x-4">
                                        <div className="min-w-0 flex-auto">
                                            <p className="text-sm font-semibold leading-6">
                                                {
                                                    item.hasData
                                                        ? <a href="#" onClick={item.onClick}>
                                                            <span className="absolute inset-x-0 -top-px bottom-0" />
                                                            {item.name}
                                                        </a>
                                                        : <span>
                                                            <span className="absolute inset-x-0 -top-px bottom-0" />
                                                            {item.name}
                                                        </span>
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex shrink-0 items-center gap-x-4">
                                        <div className="hidden sm:flex sm:flex-col sm:items-end">
                                            <p className="text-sm leading-6">{item.unreadItems}</p>
                                        </div>
                                        {
                                            item.hasData
                                                ? <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                                :<></>
                                        }
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}


function NoLeadsAssigned() {
    return (
        <div className="text-center p-6">

            <h3 className="text-sm font-semibold text-gray-900">Sem Leads</h3>
            <p className="mt-1 text-sm text-gray-500">Não tens nada assignado.</p>
        </div>
    )
}
