import React from 'react';
import { postRequests as post } from '../common/apiEndpoints';
import { putRequests as put } from '../common/apiEndpoints';
import { getRequests as get } from '../common/apiEndpoints';

export const formfield_newprovider = {
  title: 'Novo Médico',
  apiEndpoint: post.provider,
  method: 'POST',
  preloadForm: false,
  name: '',
  id: '',
  reqBody: { type: 'provider_setup' },
  childFetch: [
    { key: 'center', endpoint: get.center },
    { key: 'servicetype', endpoint: get.servicetype },
  ],
  submitLabel: 'Adicionar',
  rows: [
    {
      selector: 'input',
      required: true,
      type: 'text',
      name: 'name',
      title: 'Primeiro Nome',
      placeholder: 'Primeiro Nome',
      id: 'newProvider__name',
      map: true,
      validation: (f) => f.length > 0,
      validationerr: 'Primeiro Nome inválido.',
    },
    {
      selector: 'input',
      required: true,
      type: 'text',
      name: 'lastname',
      title: 'Último Nome',
      placeholder: 'Último Nome',
      id: 'newProvider__lname',
      map: true,
      validation: (f) => f.length > 0,
      validationerr: 'Último Nome inválido.',
    },
    {
      selector: 'stackedit',
      required: true,
      name: 'center',
      title: 'Centros',
      id: 'name',
      map: true,
      stack: {
        title: 'name',
        subtitle: 'name',
        listItemView: (e) => {
          return (
            <>
              <span className="truncate font-medium">{e.center.name}</span>
              <span className="flex-shrink-0 text-gray-400"></span>
            </>
          );
        },
        fields: [
          {
            selector: 'selectcenter',
            required: true,
            name: 'center',
            title: 'Nome do Centro',
            id: 'center__sel',
            map: true,
          },
        ],
      },
    },
  
  ],
};

export const formfield_editprovider = {
  title: 'Novo Médico',
  apiEndpoint: put.provider,
  method: 'POST',
  preloadForm: false,
  name: '',
  id: '',
  submitLabel: 'Adicionar',
  rows: [
    {
      selector: 'input',
      type: 'text',
      name: 'name',
      title: 'Name',
      placeholder: 'Enter the name',
      id: 'newProvider__name',
    },
    {
      selector: 'input',
      type: 'email',
      name: 'email',
      title: 'Email',
      placeholder: 'Enter the email',
      id: 'newProvider__email',
    },
  ],
};

// Insurance Edit


export const formfield_newinsurance = {
  title: 'Novo Seguro',
  description: 'Associar novos seguros ou subsistemas ao médico',
  apiEndpoint: put.provideredit,
  method: 'PUT',
  reqArg: { type: 'provider_newinsurance' },
  preloadForm: false,
  childFetch: [{ key: 'insurance', endpoint: get.insurance }],
  name: '',
  id: '',
  submitLabel: 'Adicionar',
  rows: [
    {
      selector: 'selectinsurance',
      required: true,
      name: 'insurance',
      title: 'Seguros',
      id: 'insurance',
      map: true,
    }
  ],
};
