import { useState } from "react";
import { BellIcon, FingerPrintIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import MyProfileEditData from "./MyProfileEditData";
import MyProfileSecurityData from "./MyProfileSecurityData";
import MyProfileNotifications from "./MyProfileNotifications"

const secondaryNavigation = [
  { key:"myprofiledata", name: "Geral", href: "#", icon: UserCircleIcon },
  { key:"security", name: "Segurança", href: "#", icon: FingerPrintIcon },
  { key:"notification", name: "Notificações", href: "#", icon: BellIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProfileEdit() {
  const [page, setPage] = useState("myprofiledata");
  return (
    <>
      <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <a
                    href="#"
                    onClick={() => setPage(item.key)}
                    className={classNames(
                      page == item.key
                        ? "bg-gray-50 text-indigo-600"
                        : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                      "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current ? "text-indigo-600" : "text-gray-400 group-hover:text-indigo-600",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        {page == "myprofiledata" && <MyProfileEditData />}
        {page == "security" && <MyProfileSecurityData />}
        {page == "notification" && <MyProfileNotifications />}

        
      </div>
    </>
  );
}
