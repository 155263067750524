import React from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';


export default function CallTagsAddTag({ call, addTag }) {

    return (
        <div>
            <Menu as="div" className="relative inline-block text-left ">
                <div>
                    <Menu.Button
                        className="w-6 h-6 rounded-full bg-gray-400 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <PlusIcon className="h-4 w-4" aria-hidden="true" />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="z-10 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">

                            {call.get_call_available_tags.map((t, tk) =>
                                <Menu.Item key={tk}>
                                    {({ active }) => (
                                        <button
                                            onClick={() => addTag(t)}
                                            className={`${active
                                                ? 'bg-gray-200 text-gray-900'
                                                : 'text-gray-900'
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        >
                                            <div className="mr-2" >{t.icon}</div>
                                            {t.label}
                                        </button>
                                    )}
                                </Menu.Item>
                            )}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}
