import React from 'react';
import { useState, useRef } from 'react';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { PuzzlePieceIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CommEditor = ({ text, setText, options, handleSave, submitLabel }) => {
  const [selfield, setSelField] = useState(null);
  const textareaRef = useRef();

  const handleInsertField = (field) => {
    const position = textareaRef.current.selectionEnd;
    var output = [text.slice(0, position), field, text.slice(position)].join(
      ''
    );
    setText(output);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <div className="relative">
      <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
        <div className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0" />
        <label htmlFor="description" className="sr-only">
          Conteúdo
        </label>
        <textarea
          ref={textareaRef}
          value={text}
          onChange={handleChange}
          rows={4}
          name="description"
          id="description"
          className="block w-full resize-none border-0 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder="Escreva..."
        />

        {/* Spacer element to match the height of the toolbar */}
        <div aria-hidden="true">
          <div className="py-2">
            <div className="h-9" />
          </div>
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-9" />
            </div>
          </div>
        </div>
      </div>

      <div className="absolute inset-x-px bottom-0">
        <div className="flex flex-nowrap justify-end space-x-2 px-2 py-2 sm:px-3">
          {options.length > 0 && <Listbox
            as="div"
            value={selfield}
            onChange={setSelField}
            className="flex-shrink-0"
          >
            {({ open }) => (
              <>

                <div className="relative">
                  <Listbox.Button className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                    <PuzzlePieceIcon
                      className={classNames(
                        selfield === null ? 'text-gray-600' : 'text-gray-600',
                        'h-5 w-5 flex-shrink-0 sm:-ml-1'
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        selfield === null ? '' : 'text-gray-800',
                        'hidden truncate sm:ml-2 sm:block'
                      )}
                    >
                      Inserir campo
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute right-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {options.map((opt, k) => (
                        <Listbox.Option
                          onClick={() => handleInsertField(opt.field)}
                          key={k}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'bg-amber-100 text-amber-900'
                                : 'bg-white',
                              'relative cursor-default select-none px-3 py-2'
                            )
                          }
                          value={opt}
                        >
                          <div className="flex items-center">
                            <span className="block truncate">{opt.desc}</span>
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>}
        </div>
        <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <div className="flex"></div>
          <div className="flex-shrink-0">
            <button
              onClick={handleSave}
              type="submit"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {submitLabel ? submitLabel : 'Guardar'}
              
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommEditor;
