export function txtLoc(loc, key) {}

export function sortFields(fields, template, local) {

  let sortFields = [];
  template.map((t) => {
    if (fields.includes(t.key) && t.local == local) {
      sortFields = [...sortFields, t];
    }
  });

  return sortFields;
}
