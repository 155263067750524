import React from 'react';

export const comm_key = [
  { value: 'booking_remote_new', desc: 'Nova marcação' },
  { value: 'booking_live_new', desc: 'Nova marcação (videoconsulta)' },
  { value: 'booking_remote_confirm', desc: 'Pedido de confirmação' },
  { value: 'booking_live_confirm', desc: 'Pedido de confirmação (videoconsulta)' },
  { value: 'user_invitation', desc: 'Convite' },
  { value: 'cust_invitation', desc: 'Convite' },
  { value: 'new_client_user_account', desc: 'new_client_user_account' },
];

export const comm_email_field = [
  {
    key: 'title',
    sample: 'Nova conta criada',
    desc: 'Título',
    field: '%FIELD_TITLE%',
  },
  {
    key: 'description',
    sample: 'Lorem ipsum',
    desc: 'Descrição',
    field: '%FIELD_DESCR%',
  },
  {
    key: 'primBut',
    sample: 'Confirmar',
    desc: 'Confirmar',
    field: '%PRIMARY_BUTTON_TEXT%',
  },
  {
    key: 'secBut',
    sample: 'Alterar',
    desc: 'Alterar',
    field: '%SECONDARY_BUTTON_TEXT%',
  },
];

export const comm_model_vars = [
  {
    sample: 'Carlos Sousa',
    color: 'blue',
    desc: 'Nome Paciente',
    field: '%CUST_NAME%',
  },
  {
    sample: 'Carlos',
    color: 'blue',
    desc: 'Primeiro nome Paciente',
    field: '%CUST_FNAME%',
  },
  {
    sample: 'Sousa',
    color: 'blue',
    desc: 'Último nome Paciente',
    field: '%CUST_EMAIL%',
  },
  {
    sample: '236100000',
    color: 'blue',
    desc: 'NIF Paciente',
    field: '%CUST_NIF%',
  },
  {
    sample: 'Mário',
    color: 'indigo',
    desc: 'Primeiro nome Médico',
    field: '%PROV_FNAME%',
  },
  {
    sample: 'Ribeiro',
    color: 'indigo',
    desc: 'Último nome Médico',
    field: '%PROV_LNAME%',
  },
  {
    sample: '21/5/23',
    color: 'purple',
    desc: 'Data (D/M/Y)',
    field: '%CALL_<d/_m/_Y>%',
  },
  {
    sample: '21/5',
    color: 'purple',
    desc: 'Data (D/M)',
    field: '%CALL_<d/_m>%',
  },
  {
    sample: '18:30',
    color: 'purple',
    desc: 'Hora (H:M)',
    field: '%CALL_<H:_M>%',
  },
  { sample: 'sden213n', color: 'red', desc: 'Referência', field: '%CALL_REF%' },
  { sample: '30', color: 'purple', desc: 'Duração', field: '%CALL_DUR_M%' },
  {
    sample: 'Consulta de rotina',
    color: 'yellow',
    desc: 'Tipo consulta',
    field: '%CALL_SERV_STYP%',
  },
  {
    sample: 'Pediatria',
    color: 'yellow',
    desc: 'Especialidade',
    field: '%CALL_SERV_TYP%',
  },
  {
    sample: 'app.medzi.io/c/sden213n',
    color: 'red',
    desc: 'Link',
    field: '%URL_CUST_APP_URL%',
  },
];

export const previewEmail = function previewEmail(baseModel, field) {
  function replaceEmailFields() {
    let replCont = baseModel;
    comm_email_field.forEach((f) => {
      if (f.key in field) {
        replCont = replCont.split(f.field).join(field[f.key]);
      }
    });
    return replCont;
  }

  return (
    <>
    {replaceByTags(replaceEmailFields())}
    </>
  );
};

export const replaceByTags = function replaceByTags(txt, sample = false) {
  let newtxt = txt;
  comm_model_vars.map((f) => {

    const repField = 
    "<span class='inline-flex items-center rounded-md bg-" +
      f.color +
      '-100 px-1 py-1 text-xs font-medium text-' +
      f.color +
      "-600'>" +
      (sample ? f.sample : f.desc) +
      '</span>'
    newtxt = newtxt.split(f.field).join(repField);
  });
  return (
    <>
      {' '}
      <span
        className="leading-7"
        dangerouslySetInnerHTML={{
          __html: newtxt,
        }}
      />
    </>
  );
};
