import React, { useState, useEffect } from 'react';
import { ArrowRightIcon } from '@heroicons/react/20/solid'

function LoginSuccess({ handleNext }) {


    return (
        <div >
            <h2 className="mb-6 text-black">Autenticado com sucesso.</h2>

        </div>
    );
}

export default LoginSuccess;