import React, { Fragment, useState, useContext, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import AvatarXs from '../../common/card/AvatarXs';
import { GlobalStateContext } from '../../state/globalState';
import { loadingScreen } from '../../common/fetchdata';
import { postJson, blankResult, reqOk } from "../../common/fetchdata";
import { postRequests as pt } from "../../common/apiEndpoints";
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');


export default function MessagingNewNoteModal({ open, setOpen, selectedTopic, handleRefresh }) {

    const [text, setText] = useState('')
    const [pres, setPres] = useState(blankResult);
    const cancelButtonRef = useRef(null)
    const globalcontext = useContext(GlobalStateContext);
    const user = globalcontext.contextFcn.userData.data.items.user;

    useEffect(() => {
        loadingScreen(pres, globalcontext)
    }, [pres]);


    function handleSubmit(e) {
        e.preventDefault();
        postJson(pt.messagingdetailnote, {
            ...pres, payload: {
                threadId: selectedTopic.id,
                text: text
            }
        }, setPres);
    }


    useEffect(() => {
        if (reqOk(pres)) {
            setText('')
            setOpen(false)
            handleRefresh()
        }
    }, [pres])

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <form onSubmit={handleSubmit}>
                                    <div>
                                        <div className="">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                Adicionar uma nota
                                            </Dialog.Title>
                                            <div className="mt-2">


                                                <div>

                                                    <div className="mt-2">
                                                        <div className="mt-6 flex gap-x-3">
                                                            <AvatarXs person={user} />
                                                            <div className="relative flex-auto">
                                                                <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                                                                    <label htmlFor="comment" className="sr-only">
                                                                        Deixe uma nota
                                                                    </label>
                                                                    <textarea
                                                                        rows={2}
                                                                        name="comment"
                                                                        id="comment"
                                                                        className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                                        placeholder=" Deixe uma nota..."
                                                                        value={text}
                                                                        onChange={(e) => setText(e.target.value)}
                                                                    />
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                            onClick={handleSubmit}
                                            type="submit"
                                        >
                                            Guardar
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
