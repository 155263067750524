import React from 'react';
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

export function GuestWelcome({ callData, confirmCall }) {
  const info = [
    { id: 1, name: 'Consulta', value: callData.servsubtype.servtype.name },
    { id: 2, name: 'Médico', value: callData.users.fullname },
    { id: 3, name: 'Dia', value: moment(callData.datetm).format('D/M/Y') },
    { id: 4, name: 'Hora', value: moment(callData.datetm).format('HH:mm') },
  ];

  let enableConfirmation =
    callData.customer_confirm_by != null &&
    moment(callData.customer_confirm_by).isBefore(moment()) &&
    callData.customer_confirmed == null;
  return (
    <>
      <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
        A sua consulta está marcada!
      </h1>
      <span className="text-lg leading-8 text-gray-600">
        <dl className="mx-auto mt-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-gray-500 sm:mt-12 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {info.map((i, k) => (
            <div
              key={i.id}
              className={`flex flex-col gap-y-3 pl-6 ${
                k > 0 ? 'border-l border-gray-500/10 ' : ''
              }`}
            >
              <dt className="text-sm leading-6">{i.name}</dt>
              <dd className="order-first text-2xl font-semibold tracking-tight">
                {i.value}
              </dd>
            </div>
          ))}
        </dl>
        <div className="mt-6">
          {enableConfirmation
            ? ' '
            : 'A sua consulta está agendada. Deverá confirmar a marcação 24h antes seguindo as instruções recebidas por Email e SMS.'}
        </div>
      </span>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        {enableConfirmation && (
          <a
            href="#"
            onClick={confirmCall}
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Confirmar
          </a>
        )}
        <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
          Desmarcar
        </a>
        <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
          Alterar
        </a>
      </div>
    </>
  );
}

export function ErrorFetching() {
  return (
    <>
      <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
        Ocorreu um erro
      </h1>
      <span className="text-lg leading-8 text-gray-600">
        <dl className="mx-auto mt-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-gray-500 sm:mt-12 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4"></dl>
        <div className="mt-6">
          Não foi possivel obter os detalhes da consulta.
        </div>
      </span>
    </>
  );
}
