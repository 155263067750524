import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { GlobalStateContext } from "../state/globalState";
import useToken from "../hooks/useToken";
import { putJson, postJson, blankResult, reqOk } from "../common/fetchdata";
import { putRequests as put } from "../common/apiEndpoints";
import { postRequests as post } from "../common/apiEndpoints";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


export default function UserActionMenu({ data, refreshHandler }) {
  const globalcontext = React.useContext(GlobalStateContext);
  const { token } = useToken();
  const [putreq, setPutreq] = React.useState(blankResult);
  const [postreq, setPpstreq] = React.useState(blankResult);

  const editData = (args = {}) => {
    putJson(put.call, { ...putreq, token: token, payload: { ...args, callId: data.id } }, setPutreq);
  };

  React.useEffect(() => {
    if (reqOk(putreq)) refreshHandler();
  }, [putreq]);

  const sendEmailInvite = () => {
    // type, title, message, okTrigg
    globalcontext.contextFcn.panel.modal.open("info", "Quero confirmar o envio do email de convite?", () =>
      postJson(post.emailinvite, { ...postreq, payload: { userId: data.id } }, setPpstreq)
    );
  };
  const checkinCall = () => {
    // type, title, message, okTrigg
    globalcontext.contextFcn.panel.modal.open("info", "Quero confirmar o check-in do paciente", () =>
      editData({ pacientStatus: "CHECKIN" })
    );
  };

  return (
    <>
      <Menu as="div" className="relative ">
        <Menu.Button className="inline-flex items-center rounded-md bg-white px-2 py-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
          <ChevronDownIcon className=" h-4 w-4 text-gray-400" aria-hidden="true" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={sendEmailInvite}
                  className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700")}
                >
                  Enviar email de convite
                </a>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700")}
                >
                  Repôr palavra-passe
                </a>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
