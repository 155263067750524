
import { CalendarDaysIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { Transition } from '@headlessui/react'

/* 
Add additional features as comment box, attachments
https://tailwindui.com/components/application-ui/page-examples/detail-screens?include=archived 
*/

import { useEffect, useState } from 'react'
import { Tab } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function ExamsAnalysisContent() {

    const [tabIndex, setTabIndex] = useState(0);
    let [categories, setCategories] = useState([
        {
            title: 'Marcadas',
            key: 0,
            icon: CalendarDaysIcon,
            records: 1
        },
        {
            title: 'Realizadas',
            key: 1,
            icon: CheckCircleIcon,
            records: 2
        },
        {
            title: 'Canceladas',
            key: 2,
            icon: XCircleIcon,
            records: 3
        },
    ])

    return (

        <section className="w-full h-full">

            {/* Activity Feed */}
            <div className="flow-root mt-3">
                <div className="w-full max-w-md px-2 sm:px-0">
                    <Tab.Group selectedIndex={tabIndex} onChange={setTabIndex}>
                        <Tab.List className="flex space-x-1 rounded-xl bg-slate-100 p-1">
                            {categories.map((category) => (
                                <Tab
                                    key={category.key}
                                    className={({ selected }) =>
                                        classNames(
                                            'relative w-full rounded-lg p-2 text-sm font-medium leading-5 flex',
                                            'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                            selected
                                                ? 'bg-white text-blue-700 shadow'
                                                : 'text-slate-900 hover:bg-white/[0.12] hover:text-slate-400'
                                        )
                                    }
                                >  <category.icon className={`h-5 w-5 mr-3 ${tabIndex == category.key ? 'text-sky-500' : 'text-slate-500'}`} aria-hidden="true" />


                                    <span className="text-xs mt-0.5">{category.title}</span>


                                </Tab>
                            ))}
                        </Tab.List>


                        <Tab.Panels className="mt-2">
                            {categories.map((items, idx) => (
                                <Tab.Panel
                                    key={idx}
                                    className={classNames(
                                        'rounded-xl bg-white p-3',
                                        'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                                    )}
                                >

                                    <Transition appear show={tabIndex == idx}
                                        enter="transition-opacity duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="transition-opacity duration-500"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0">
                                        <ul>
                                            <div className="text-center">
                                                <svg
                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        vectorEffect="non-scaling-stroke"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                                    />
                                                </svg>
                                                <p className="mt-1 text-sm text-gray-500">Não existem registos para exibir.</p>

                                            </div>
                                        </ul>
                                    </Transition>

                                </Tab.Panel>
                            ))}
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>

        </section>



    )
}
