



import { countryCodes } from '../label/countrycodes';
import { postRequests as pt } from '../common/apiEndpoints';


const usr_fields = [
  {
    selector: 'input',
    required: true,
    class: '',
    type: 'text',
    name: 'name',
    title: 'Nomes',
    placeholder: 'João',
    id: 'name',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Nome Inválido.',
  },
  {
    selector: 'input',
    required: true,
    class: '',
    type: 'text',
    name: 'lastname',
    title: 'Apelidos',
    placeholder: 'Martins',
    id: 'lastname',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Apelidos Inválidos.',
  },
  {
    selector: 'daterangepicker',
    required: false,
    selectType: 'single',
    allowPast: true,
    name: 'dateofbirth',
    title: 'Data de Nascimento',
    placeholder: 'Escolha',
    id: 'dateofbirth',
    map: true,
  },
  {
    selector: 'select',
    required: false,
    options: [
      { value: 'F', desc: 'Feminino' },
      { value: 'M', desc: 'Masculino' },
      { value: 'U', desc: 'Indefinido' },
    ],
    name: 'gender',
    title: 'Género',
    placeholder: 'Escolha',
    id: 'gender',
    fieldTitle: 'desc',
    noselectTxt: 'Escolha...',
    map: true,
  },
  {
    selector: 'input',
    required: true,
    class: 'col-12 col-sm-6',
    type: 'email',
    name: 'email',
    title: 'Email',
    placeholder: 'Email',
    id: 'email',
    map: true,
    validation: (f) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(f).toLowerCase()
      ),
    validationerr: 'Email inválido.',
  },
  {
    selector: 'phoneinput',
    required: true,
    countryList: countryCodes,
    defaultCountry: 'PRT',
    placeholder: 'Escolha...',
    placeholderNum: '910000000',
    type: 'text',
    name: 'mobile_phone',
    title: 'Telemóvel',
    id: 'mobile_phone',
    map: true,
  },
  {
    selector: 'toggle',
    name: 'is_provider',
    title: 'Médico',
    subtitle: '',
    id: 'is_provider',
    map: true
  },
  {
    selector: 'toggle',
    name: 'is_active',
    title: 'Ativo',
    subtitle: '',
    id: 'is_active',
    map: true
  },
  {
    selector: 'input',
    required: false,
    class: '',
    type: 'text',
    name: 'med_name',
    title: 'Nome Médico',
    placeholder: 'Martins',
    id: 'med_name',
    map: true,
    //validation: (f) => f.length > 0,
    //validationerr: 'Nome Médico Inválidos.',
  },
  {
    selector: 'input',
    required: false,
    class: '',
    type: 'text',
    name: 'med_license',
    title: 'Certidão Médica',
    placeholder: 'C0001',
    id: 'med_license',
    map: true,
    // validation: (f) => f.length != null ? f.length > 0 : false,
    //validationerr: 'Certidão Inválida.',
  },
  {
    selector: 'selectuserrole',
    required: true,
    name: 'user_roles',
    title: 'Perfil utilizador',
    noselectTxt: ' Escolha...',
    id: 'user_roles',
    map: true,
  },
  {
    selector: 'input',
    required: false,
    class: '',
    type: 'text',
    name: 'tax_id_ref',
    title: 'NIF',
    placeholder: '123000',
    id: 'tax_id_ref',
    map: true,
    //validation: (f) => f.length > 0,
    //validationerr: 'NIF Inválido.',
  },
  {
    selector: 'input',
    required: false,
    class: '',
    type: 'text',
    name: 'nid_id_ref',
    title: 'Cartão do Cidadão',
    placeholder: '100000',
    id: 'nid_id_ref',
    map: true,
    //validation: (f) => f.length > 0,
    //validationerr: 'CC Inválido.',
  }
]


export const formfield_edituser = {
  title: "Editar Utilizador",
  apiEndpoint: pt.userconfig,
  successMessage: "Alterações guardadas",
  method: "PUT",
  preloadForm: true,
  name: "",
  id: "",
  submitLabel: "Guardar",
  rows: [
    ...usr_fields
   ],
};

export const formfield_newuser = {
  title: "Novo Utilizador",
  apiEndpoint: pt.userconfig,
  successMessage: "Alterações guardadas",
  method: "POST",
  preloadForm: false,
  name: "",
  id: "",
  submitLabel: "Guardar",
  rows: [
   ...usr_fields
  ],
};


export const formfield_changepassword = {
  title: "",
  apiEndpoint: "/api/config/user/password",
  successMessage: "Alterações guardadas",
  method: "POST",
  preloadForm: false,
  name: "",
  id: "",
  submitLabel: "Guardar",
  rows: [
    {
      selector: "input",
      class: "col-6 col-sm-6 col-md-4 col-xl-4",
      type: "password",
      name: "password_actual",
      title: "Password atual",
      placeholder: "",
      id: "newUser__passactual",
    },
    {
      selector: "input",
      class: "col-6 col-sm-6 col-md-4 col-xl-4",
      type: "password",
      name: "password_repeat",
      title: "Repita a Password",
      placeholder: "",
      id: "newUser__passrepeat",
    },
    {
      selector: "newline",
      class: "col-12 mt-0",
      title: "",
    },
    {
      selector: "input",
      class: "col-6 col-sm-6 col-md-4 col-xl-4",
      type: "password",
      name: "password_new",
      title: "Nova Password",
      placeholder: "",
      id: "newUser__passnew",
    },
  ],
};

