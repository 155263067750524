import React, { Fragment, useState } from 'react';
import FormSlideover from '../slideover/FormSlideover';
import {
  formfield_editemaildata as fin_email_edit,
} from '../../forms/formfield_customer';

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, EnvelopeIcon, EnvelopeOpenIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}


export default function CustomerEmailContactSelect({ profile, handleRefresh }) {

  const [edit_formData, edit_setFormData] = useState({});
  const [openAdd, setOpenAdd] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const [formTempl, setFormTempl] = useState(null);

  const handleEditEmail = () => {
    setFormTempl(fin_email_edit);
    setTimeout(() => {
      setOpenAdd(true);
    }, '200');
  };

  const edit_okHandler = () => {
    setFormKey((k) => k + 1);
    setOpenAdd(false);
    handleRefresh();
  };

  const edit_errHandler = () => {
    console.error('Error processing request');
    setFormKey((k) => k + 1);
    setOpenAdd(false);
  };


  return (
    <>
      {formTempl != null && (
        <FormSlideover
          context={profile.id}
          fin={formTempl}
          key={formKey}
          open={openAdd}
          setOpen={setOpenAdd}
          formData={edit_formData}
          setFormData={edit_setFormData}
          okHandler={edit_okHandler}
          errHandler={edit_errHandler}
        />
      )}


      <div className="mt-3 flex flex-col">
        {profile.email_contact != null &&
          profile.email_contact.email != null ? (

          <div className="flex rounded-lg border border-gray-300 bg-white shadow-sm">
            <div className="w-full flex">
              <div className="inline-flex items-center px-3 text-gray-500  ">
                <EnvelopeOpenIcon className="w-5 h-5 m-1" />
              </div>

              <div className="relative flex items-center ">

                <div className="min-w-0 flex-1 ml-1">
                  <div className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />

                    <p className="text-sm font-medium text-gray-900 truncate">
                      {profile.email_contact.email}
                    </p>
                  </div>
                </div>
              </div>

            </div>
            <div className="w-auto pr-2 flex">
              <div className="flex-shrink-0 pt-2">
                {profile.email_contact.confirmed ? (
                  <CheckCircleIcon className="h-6 w-6 mr-1 text-green-700" />
                ) : (
                  <QuestionMarkCircleIcon className="h-6 w-6 mr-1 text-red-400" />
                )}
              </div>
              <Menu as="div" className="relative ">
                <Menu.Button className="inline-flex items-center rounded-md  px-1 py-2 text-xs font-semibold text-gray-900">
                  <ChevronDownIcon
                    className="-mr-1 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          onClick={handleEditEmail}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700'
                          )}
                        >
                          Editar
                        </a>
                      )}
                    </Menu.Item>
                    {!profile.email_contact.confirmed && (
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Confirmar
                          </a>
                        )}
                      </Menu.Item>
                    )}
                  </Menu.Items>
                </Transition>
              </Menu>

            </div>


          </div>
        ) : (
          <div
            onClick={handleEditEmail}
            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 border-dashed  bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400 cursor-pointer"
          >
            <EnvelopeIcon className="h-8 w-8 pt-1" />
            <span className="mt-2 block text-sm font-semibold text-gray-900">
              Adicionar email
            </span>
          </div>
        )}
      </div>
    </>
  );
}
