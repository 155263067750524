import React, { Fragment, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import {
  ArrowUpIcon,
  ArrowDownIcon,
  ArrowRightIcon,
  BarsArrowUpIcon,
  BarsArrowDownIcon,
  ChevronDownIcon
} from '@heroicons/react/20/solid';
import { FunnelIcon } from '@heroicons/react/24/outline';

export default function HeaderSortFilter({
  buttonContent,
  tfilter,
  setFilter,
  colCfg,
  filtLabel,
  setFiltLabel,
}) {
  const [sel, setSel] = React.useState([]);

  useEffect(() => {
    const fDef = tfilter[colCfg.filter.fieldDest];
    if (typeof fDef != 'undefined') {
      let fState = [];
      fDef.map(
        (f) =>
        (fState = [
          ...fState,
          {
            [colCfg.filter.fieldOrig]: f,
            [colCfg.filter.fieldOrigLabel]: '',
          },
        ])
      );

      setSel(fState);
    }
  }, [tfilter]);

  function handleApplyFilter() {
    const apiId = colCfg.filter.fieldDest;
    const origValKey = colCfg.filter.fieldOrig;
    const tagLabel = colCfg.filter.fieldLabel;
    const fieldOrigLabel = colCfg.filter.fieldOrigLabel;
    let valLook = [];
    let labLook = '';

    if (sel == null || (sel != null && sel.length == 0)) {
      // removes the previous filter for the same object
      setFilter((f) => {
        const newData = { ...f };
        delete newData[apiId];
        return newData;
      });
      // removes the previous filter for the same object (label state oobject)
      setFiltLabel((f) => {
        const newData = { ...f };
        delete newData[apiId];
        return newData;
      });
    } else {
      sel.map((s) => (valLook = [...valLook, s[origValKey]]));
      sel.map((s) => (labLook = labLook + s[fieldOrigLabel] + ', '));
      labLook = labLook.substring(0, labLook.length - 2);


      setFilter((f) => ({
        ...f,
        [apiId]: valLook,
      }));
      setFiltLabel((f) => ({
        ...f,
        [apiId]: {
          label: tagLabel,
          value: labLook,
        },
      }));
    }
  }

  function sortAsc() {
    setFilter((f) => ({
      ...f,
      sortBy: colCfg.filter.fieldSort,
      sortOrder: 'asc',
    }));
  }

  function sortDesc() {
    setFilter((f) => ({
      ...f,
      sortBy: colCfg.filter.fieldSort,
      sortOrder: 'desc',
    }));
  }

  const callsToAction = [
    { name: 'Asc.', click: () => sortAsc(), icon: ArrowUpIcon },
    { name: 'Desc.', click: () => sortDesc(), icon: ArrowDownIcon },
  ];

  return (
    <>
      <Popover className="relative">
        {({ open }) => (
          <>

            <Popover.Button>
              <div className="flex">
                {tfilter.sortBy == colCfg.filter.fieldSort &&
                  (tfilter.sortOrder == 'asc' ? (
                    <BarsArrowUpIcon className="h-4 w-4 mr-1" />
                  ) : (
                    <BarsArrowDownIcon className="h-4 w-4 mr-1" />
                  ))}

                {'filter' in colCfg && colCfg.filter.fieldDest in tfilter && <FunnelIcon className="h-4 w-4 mr-1" />}

                {buttonContent}{' '}
                <span
                  className={`ml-2 flex-none rounded text-gray-900 group-hover:bg-gray-200 ${open ? 'bg-gray-300 rotate-180' : 'bg-gray-100'
                    }`}
                >
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </div>
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex  max-w-max -translate-x-1/2 px-4 w-80">
                <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                  {'component' in colCfg.filter &&
                    <div className=" w-full">
                      <div className="group relative flex gap-x-6 rounded-lg p-3 hover:bg-gray-50 w-full">
                        <div className="w-full">
                          <div className="flex">
                            <div className='w-full'>
                              {colCfg.filter.component({ expanded: open, sel: sel, setSel: setSel, filterDef: colCfg.filter, setFilter: setFilter, setFiltLabel: setFiltLabel })}
                            </div>
                            {(('showSubmit' in colCfg.filter && colCfg.filter.showSubmit) || !('showSubmit' in colCfg.filter)) &&

                              <div className="ml-2">
                                <button
                                  onClick={handleApplyFilter}
                                  type="button"
                                  className="my-2 w-7 h-7 rounded-full bg-indigo-600 p-1.5 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  <ArrowRightIcon
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>}
                  <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                    {callsToAction.map((item) => (
                      <a
                        key={item.name}
                        href="#"
                        onClick={item.click}
                        className="flex items-center justify-center gap-x-2.5 p-3 font-normal text-xs text-gray-900 hover:bg-gray-100"
                      >
                        <item.icon
                          className="h-5 w-5 flex-none text-gray-400"
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
}
