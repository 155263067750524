import React, { useContext } from 'react';
import {
    PaperClipIcon
} from '@heroicons/react/20/solid'
import {
    CheckCircleIcon
} from '@heroicons/react/24/solid'

import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

import { callActivityStateLabel } from '../getCallStatus'
import { GlobalStateContext } from '../../state/globalState';
import Avatar from '../../common/card/Avatar';
import CallReceptionNotes from '../callnotes/CallReceptionNotes';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}



export default function CallTimelineCard({
    call
}) {
    const globalcontext = useContext(GlobalStateContext);


    return (
        <>
            <ul role="list" className="space-y-6">

                {call.get_call_state_events.map((activityItem, activityItemIdx) => (
                    <li key={activityItem.id} className="relative flex gap-x-4">
                        <div
                            className={classNames(
                                activityItemIdx === call.get_call_state_events.length - 1 ? 'h-6' : '-bottom-6',
                                'absolute left-0 top-0 flex w-6 justify-center'
                            )}
                        >
                            <div className="w-px bg-gray-200" />
                        </div>
                        {activityItem.event_key === 'commented' ? (
                            <>
                                <img
                                    src={activityItem.user}
                                    alt=""
                                    className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                                />
                                <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                                    <div className="flex justify-between gap-x-4">
                                        <div className="py-0.5 text-xs leading-5 text-gray-500">
                                            <span className="font-medium text-gray-900">{activityItem.user}</span> commented
                                        </div>
                                        <time
                                            dateTime={activityItem.created_at}
                                            className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                                        >
                                            {activityItem.created_at}
                                        </time>
                                    </div>
                                    <p className="text-sm leading-6 text-gray-500">{activityItem.details}</p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                    {activityItem.event_key === 'paid' ? (
                                        <CheckCircleIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                                    ) : (
                                        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                    )}
                                </div>
                                <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                    <span className="font-medium text-gray-900">{activityItem.users != null ?
                                        activityItem.users.fullname
                                        : ''}</span>{' '}
                                    {callActivityStateLabel.filter(c => c.key == activityItem.event_key)[0].labelExt}
                                </p>
                                <time
                                    dateTime={activityItem.created_at}
                                    className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                                >
                                    {moment(activityItem.created_at).format('ddd, D/MM/YYYY, HH:mm')}
                                </time>
                            </>
                        )}
                    </li>
                ))}

            </ul>
            <div className="mt-4 pt-4 ">

                <CallReceptionNotes callId={call.id} />
            </div>



        </>
    );
};

