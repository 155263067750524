import React, { useEffect, useState } from 'react';
import { GuestWelcome, ErrorFetching } from './GuestWelcome';
import axios from 'axios';
import {
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';

import { useSearchParams } from 'react-router-dom';

function AlertError({ message, closeAlert }) {
  return (
    <div className="w-full mb-6">
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{message}</h3>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                onClick={closeAlert}
                type="button"
                className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-green-50"
              >
                <span className="sr-only">Fechar</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function AlertSuccess({ message, closeAlert }) {
  return (
    <div className="w-full mb-6">
      <div className="rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-green-800">{message}</p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                onClick={closeAlert}
                type="button"
                className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
              >
                <span className="sr-only">Fechar</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function GuestHome() {
  let [searchParams] = useSearchParams();
  const [callData, setCallData] = useState(null);
  const [alert, setAlert] = useState({ type: null, message: null });

  function closeAlert() {
    setAlert({ type: null, message: null });
  }
  async function getCallInfo() {
    if (searchParams.get('id') != null) {
      const req = await axios.get('../api/external/call', {
        params: { ref: searchParams.get('id') },
      });
      setCallData(req.data.data);
    }
  }

  async function confirmCall() {
    if (searchParams.get('id') != null) {
      try {
        const req = await axios.post('../api/external/call', {
          params: { ref: searchParams.get('id'), action: 'CONFIRM' },
        });
        if (req.request.status == 200) {
          getCallInfo();
        } else
          setAlert({ type: 'error', message: 'Erro ao confirmar a marcação' });
      } catch {
        setAlert({ type: 'error', message: 'Erro ao confirmar a marcação' });
      }
    }
  }
  useEffect(() => {
    getCallInfo();
  }, []);
  return (
    <div className="bg-white min-h-screen">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </a>
          </div>

          <div className="hidden lg:flex lg:gap-x-12"></div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end"></div>
        </nav>
      </header>

      <main className="isolate">
        <div className="relative pt-14">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>

          <div className="py-6 sm:py-12">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                {alert.type == 'error' && (
                  <AlertError message={alert.message} closeAlert={closeAlert} />
                )}
                {alert.type == 'success' && (
                  <AlertSuccess
                    message={alert.message}
                    closeAlert={closeAlert}
                  />
                )}
                {callData != null &&
                  (Object.keys(callData).length == 0 ? (
                    <ErrorFetching />
                  ) : (
                    <>
                      {callData.customer_confirmed != null && (
                        <AlertSuccess
                          message={'Marcação confirmada'}
                          closeAlert={closeAlert}
                        />
                      )}
                      <GuestWelcome
                        callData={callData}
                        confirmCall={confirmCall}
                      />
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
