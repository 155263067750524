/* eslint-disable no-unused-vars */
import React from 'react';
import { reqOk } from '../fetchdata';
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');
import { GlobalStateContext } from '../../state/globalState';

export default function TimerClock() {
  const [data, setData] = React.useState(false);
  const [duration, setDuration] = React.useState('');
  const [percComplete, setPercComplete] = React.useState(0);
  const globalcontext = React.useContext(GlobalStateContext);

  React.useEffect(() => {
    if (reqOk(globalcontext.activeCall)) {
      setData(globalcontext.activeCall.items.current);
    }
  }, [globalcontext.activeCall]);

  const getEventTime = () => {
    const startdate = data.start_date;
    let diftime = moment().diff(moment(startdate));
    diftime = moment.utc(diftime).format('HH:mm:ss');
    return diftime;
  };

  const getEventPerc = () => {
    const startdate = moment(data.start_date);
    let diftime = moment().diff(startdate, 'minutes');
    const percComplete =
      diftime > parseInt(data.servsubtype.duration_min)
        ? 100
        : Math.round(diftime / parseInt(data.servsubtype.duration_min));
    return percComplete;
  };

  React.useEffect(() => {
    if (data != false) {
      setInterval(() => {
        setDuration(getEventTime);
        setPercComplete(getEventPerc);
      }, 1000);
    }
  }, []);

  return (
    <>
      <div className="flex flex-col">
        <div>
          <div className="w-full bg-gray-200 rounded-full">
            <div
              className="h-2.5 bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
              style={{ width: `${percComplete}%` }}
            >
              {`${duration}`}
            </div>
          </div>
        </div>

        <div className="relative grid lg:grid-cols-2">
          <div className="flex flex-row mt-3 text-gray-700">
            <div className="w-16 text-sm font-medium text-gray-900">
              Marcação
            </div>
            <div className="ml-4 block text-sm text-gray-500">
              {moment(data && data.datetm).format('HH:mm:ss (DD/MM/YY)')}
            </div>
          </div>

          <div className="flex flex-row mt-3 text-gray-700">
            <div className="w-16 text-sm font-medium text-gray-900">
              Início
            </div>
            <div className="ml-4 block text-sm text-gray-500">
              {moment(data && data.start_date).format('HH:mm:ss (DD/MM/YY)')}
            </div>
          </div>
        </div>

        <div></div>
        <div></div>
      </div>
    </>
  );
}
