import React from 'react';
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

function NoMessages() {
  return (
    <>
      <img
        src="../static/img/illustrations/begin_chat.svg"
        alt=""
        className=" m-auto w-44 h-44"
      />
      <p className="text-xs mt-4 text-center">Ainda sem mensagens</p>
    </>
  );
}

export default function MessageFlow({
  chatSessionContext,
  globalcontext,
  messages,
}) {
  const [message, setMessage] = React.useState('');
  const scrollable = React.useRef(null);
  const submitMessage = (e) => {
    const _tCont = chatSessionContext;

    console.log(_tCont);
    e.preventDefault();
    globalcontext.socket.emit('OUTGOING_MESSAGE', {
      content: message,
      chatRef: _tCont.ref,
      ts: moment().format(),
    });
    setMessage('');
  };

  React.useEffect(() => {
    if (messages.length > 0) {
      //const scrollLength = messageBox.current.clientHeight;
      scrollable.current.scrollBy({ top: 1e6 });
    }
  }, [messages]);

  /*   const scrollItDown = (toRight) => {
    const scrollLength = messageBox.current.clientHeight;
    scrollable.current.scrollBy({ top: scrollLength });
  }; */

  return (
    <>
      <div
        className="flex flex-col flex-grow p-4 overflow-auto h-full"
        ref={scrollable}
      >
        {messages.length > 0 &&
          messages.map((m, k) => (
            <React.Fragment key={k}>
              {m.from_self && (
                <div className="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-end">
                  <div>
                    <div
                      className={`bg-sky-500 text-white p-3 rounded-l-lg rounded-br-lg`}
                    >
                      <p className="text-sm">{m.content}</p>
                    </div>
                    <span className="text-xs text-gray-500 leading-none">
                      {moment(m.ts).fromNow()}
                    </span>
                  </div>

                  <div className="flex h-10 w-10 rounded-full bg-gray-300">
                    <div className="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600">
                      <span className="text-sm font-medium text-gray-600 dark:text-gray-300 uppercase">
                        {chatSessionContext.sender.initials}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {!m.from_self && (
                <div className="flex w-full mt-2 space-x-3 max-w-xs">
                  <div className="flex h-10 w-10 rounded-full bg-gray-300">
                    <div className="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600">
                      <span className="text-sm font-medium text-gray-600 dark:text-gray-300 uppercase">
                        {chatSessionContext.recipient.initials}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg">
                      <p className="text-sm"> {m.content}</p>
                    </div>
                    <span className="text-xs text-gray-500 leading-none">
                      {moment(m.ts).fromNow()}
                    </span>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        {messages.length == 0 && <NoMessages />}
      </div>
      <div className="bg-gray-300 p-4 absolute bottom-4 left-4 rounded-b-lg right-4">
        <form onSubmit={submitMessage}>
          <input
            className="border border-gray-100 flex items-center h-10 w-full rounded px-3 text-sm"
            type="text"
            placeholder="Escreva a mensagem…"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button className="hidden" type="submit">
            Submit
          </button>
        </form>
      </div>
    </>
  );
}
