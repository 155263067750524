import React from 'react';

import TableEngineTw from '../common/table/TableEngineTw';
import SpeedDialAdd from '../nav/speeddial/SpeedDialAdd';
import { useNavigate } from 'react-router-dom';
import { reqOk, blankResult, getJson, getExcelFile } from '../common/fetchdata';
import { getRequests as gt } from '../common/apiEndpoints';
import { tableconfig as tbcfg } from '../common/configs/tableconfig_call';
import useToken from '../hooks/useToken';

const CallListing = ({ context }) => {
  const { token } = useToken();
  let navigate = useNavigate();

  const [getRes, setGetRes] = React.useState(blankResult);
  const fetchData = (args = {}, mode = 'fetch') => {
    if (mode == 'download') getExcelFile(gt.call, getRes, 'RegistoConsultas')
    getJson(gt.calllist, { ...getRes, token: token, params: args }, setGetRes);
  };

  const handleAddNewItem = (usr) => navigate('/call/new');

  return (
    <>
      <SpeedDialAdd onClickAction={handleAddNewItem} />


      <TableEngineTw
        cfg={tbcfg}
        items={reqOk(getRes) && getRes.items}
        fetchData={fetchData}
        context={context}
      />

    </>
  );
};

export default CallListing;
