import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
    EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid'
import {
    CreditCardIcon,
    DocumentIcon,
} from '@heroicons/react/24/outline'
import CallTreatmentsList from '../treatments/CallTreatmentsList'
import ExamsAnalysisList from '../examsanalysis/ExamsAnalysisList'
import CallPaymentsSummaryCard from '../billing/cards/CallPaymentsSummaryCard'

import BandageIcon from '../../common/icon/BandageIcon';
import MicroscopeIcon from '../../common/icon/MicroscopeIcon'
import CallTimelineCard from '../timeline/CallTimelineCard'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function CallHomeContentInfo({ call, editTtm }) {

    const homeCards = [
        { 
            title: 'Tratamentos', 
            icon: BandageIcon, 
            editHandler: () => editTtm(),
            content: <CallTreatmentsList newHandler={editTtm} groupByDay={false} callTreatments={call.get_call_treatments} /> 
        },
        { 
            title: 'Exames/Análises', 
            icon: MicroscopeIcon, 
            editHandler: () => editTtm(),
            content: <ExamsAnalysisList newHandler={editTtm} groupByDay={false} callExamsans={call.get_call_exams_analysis} /> 
        },
        { 
            title: 'Ficheiros', 
            icon: DocumentIcon, 
            editHandler: () => editTtm(),
            content: <ExamsAnalysisList newHandler={editTtm} groupByDay={false} callExamsans={call.get_call_exams_analysis} /> 
        },
        { 
            title: 'Faturação', 
            icon: CreditCardIcon, 
            editHandler: () => editTtm(),
            content: <CallPaymentsSummaryCard newHandler={editTtm} groupByDay={false} billing={call.get_billing_info} /> 
        },
    ]

    return (
        <><div className="">
            <main className="lg:pr-96">
                <div className="py-8">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">

                            <ul role="list" className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                                {homeCards.map((hc, idx) =>
                                    <li key={idx} className="overflow-hidden rounded-xl border border-gray-200">
                                        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                            <div className="h-12 w-12">
                                                <hc.icon className={'text-gray-400 shrink-0'} />
                                            </div>
                                            <div className="text-sm font-medium leading-6 text-gray-900">{hc.title}</div>
                                            <Menu as="div" className="relative ml-auto">
                                                <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                                                    <span className="sr-only">Open options</span>
                                                    <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                                                </Menu.Button>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href="#"
                                                                    onClick={hc.editHandler}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-50' : '',
                                                                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                                    )}
                                                                >
                                                                    Editar
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                        <dl className="m-2 divide-y divide-gray-100 px-6 py-4 text-sm leading-6 max-h-64 overflow-y-auto overflow-x-hidden">
                                            {hc.content}
                                        </dl>
                                    </li>
                                )}

                            </ul>
                        </div>
                    </div>
                </div>
            </main>

            {/* Activity feed */}
            <aside className="pt-16 lg:fixed lg:bottom-0 lg:right-0 lg:top-16 lg:w-96 lg:overflow-y-auto lg:border-l lg:border-gray-200 p-4 ">
                <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:pt-6 lg:px-8">
                    <h2 className="text-base font-semibold leading-7">Linha de Tempo</h2>
                </header>
                <div className="p-4">

                    <CallTimelineCard call={call} />
                </div>
            </aside>
        </div>
        </>
    )
}
