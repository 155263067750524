import { PlusIcon } from '@heroicons/react/20/solid'
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline'

export default function MessagingLookupIdle() {
    return (
        <div className="p-8">
            <div className="text-center">
                <MagnifyingGlassCircleIcon className='mx-auto h-12 w-12 text-gray-400' />

                <h3 className="mt-2 text-sm font-semibold text-gray-900">Sem resultados</h3>
                <p className="mt-1 text-sm text-gray-500">Introduza o número a pesquisar e clique no botão</p>

            </div>
        </div>
    )
}
