import { countryCodes } from './countrycodes';

const gender = [
  { value: 'F', desc: 'Feminino' },
  { value: 'M', desc: 'Masculino' },
  { value: 'U', desc: 'Indefinido' },
];


export const personal_data = [
  { key: 'name', local: 'pt', order: 1, label: 'Nome' },
  { key: 'lastname', local: 'pt', order: 2, label: 'Último Nome' },
  {
    key: 'gender',
    local: 'pt',
    order: 3,
    label: 'Género',
    format: (e) => {
      return gender.filter((c) => c.value == e)[0].desc;
    },
  },
  { key: 'dateofbirth', local: 'pt', order: 4, label: 'Data de Nascimento' },
  { key: 'age', local: 'pt', order: 5, label: 'Idade' }
];


export const address_data = [
  { key: 'address_line1', local: 'pt', order: 1, label: 'Morada' },
  { key: 'address_line2', local: 'pt', order: 2, label: 'Morada (detalhes)' },
  { key: 'address_postcode', local: 'pt', order: 3, label: 'Código Postal' },
  { key: 'address_city', local: 'pt', order: 4, label: 'Cidade' },
  { key: 'address_region', local: 'pt', order: 5, label: 'Região' },
  {
    key: 'address_country',
    local: 'pt',
    order: 6,
    label: 'País',
    format: (e) => {
      let cLabel = countryCodes.filter((c) => c.value == e)
      cLabel = cLabel.length == 0 ? e : cLabel[0].desc
      return cLabel
    },
  },
];
