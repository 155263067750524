import React, { useState, useContext } from 'react';
import { getRequests as gt } from '../../../common/apiEndpoints';
import { deleteRequests as dt } from "../../../common/apiEndpoints";
import { getJson, blankResult, reqOk, deleteJson } from '../../../common/fetchdata';
import { GlobalStateContext } from '../../../state/globalState';
import PhoneCentralOptionsContent_MeoGlobalConnect from './PhoneCentralOptionsContent_MeoGlobalConnect';

export default function PhoneCentralOptions_MeoGlobalConnect() {

  const globalcontext = useContext(GlobalStateContext);
  const [getData, setGetData] = useState(blankResult);
  const [delData, setDelData] = useState(blankResult);


  React.useEffect(() => {
    fetchConfig();
  }, []);

  React.useEffect(() => {
    if (reqOk(delData))
      fetchConfig();
  }, [delData]);


  const fetchConfig = () => {
    getJson(gt.phonecentralmanager, getData, setGetData);
  };


  function handleDeleteInternalNum(numId) {
    globalcontext.contextFcn.panel.modal.open(
      'danger',
      `Remover o número interno?`,
      () => deleteJson(dt.phonecentralmanagerinternal, { ...delData, params: { id: numId } }, setDelData)
    );
  }


  return (
      <div className="px-6">
        <h2 className="text-base font-semibold text-gray-900">
          Interface MEO Global Connect
        </h2>
        
          <PhoneCentralOptionsContent_MeoGlobalConnect
            fetchData={fetchConfig}
            handleDeleteInternalNum={handleDeleteInternalNum}
            configDataOk={reqOk(getData)}
            configData={reqOk(getData) ? getData.items : {}} />
     
      </div>
  );
}
