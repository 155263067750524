import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
import useToken from '../hooks/useToken';
import { getRequests as gt } from '../common/apiEndpoints';
import { reqOk, blankResult, getJson, getExcelFile } from '../common/fetchdata';
import axios from 'axios';
moment.locale('pt');

import {
  prevMonth,
  nextMonth,
  getMonthCalendarDays,
} from '../common/calendar/datefunctions';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MonthCalendarSummary({
  selDate,
  selDay,
  setSelDate,
  setSelDay,
  allowPast = true,
  context
}) {
  const [monthDays, setMonthDays] = useState([]);
  const { token } = useToken();
  const [getResSlot, setGetResSlot] = React.useState(blankResult);

  async function getAvailability(sdate) {
    if ((context.type == 'provider' || context.type == 'servtype') && context.id != null) {
      let dayRng = getMonthCalendarDays(sdate);
      const queryDates = {
        start: moment(dayRng[0].date, 'YYYY-MM-DD').format(),
        end: moment(dayRng.at(-1).date, 'YYYY-MM-DD').format(),
      };
      const srcEntity = (context.type == 'provider') ? { providerId: context.id } : { serviceTypeId: context.id }
      getJson(gt.calendarevents, { ...getResSlot, params: { ...srcEntity, ...queryDates } }, setGetResSlot);
    }
  }


  useEffect(() => {
    if (reqOk(getResSlot)) {
      const slots = getResSlot.items.slots;
      const events = getResSlot.items.events;
      let dayRng = getMonthCalendarDays(selDate);

      dayRng.forEach((s) => {
        const filtSlot = slots.filter((i) =>
          moment(i.start, 'YYYY-MM-DD').isSame(moment(s.date), 'day')
        );
        const filtEvent = events.filter((i) =>
          moment(i.start, 'YYYY-MM-DD').isSame(moment(s.date), 'day')
        );
        
        s.availability = null
        s.nAppointments = filtEvent.length 
        if (filtSlot.length > 0) {
          s.availability = filtSlot[0].availability;
          if (filtSlot.length > 1) {
            throw {
              name: 'NotImplementedError',
              message: 'More than one slot in the same day',
            };
          }
        }
      });
      setMonthDays(dayRng);
    }
  }, [getResSlot])



  function availabilityColor(avValue) {
    if (avValue > 0.5) return 'emerald-100';
    if (avValue > 0) return 'amber-100';
    return 'red-100';
  }
  function availabilityHoverColor(avValue) {
    if (avValue > 0.5) return 'emerald-200';
    if (avValue > 0) return 'amber-200';
    return 'red-200';
  }

  useEffect(() => {
    setMonthDays(getMonthCalendarDays(selDate))
    getAvailability(selDate);
  }, [context]);

  function gotoNextMonth() {
    const nMonth = nextMonth(selDate);
    console.log(nMonth)
    setMonthDays(getMonthCalendarDays(nMonth));
    setSelDate(nMonth);
    getAvailability(nMonth);
  }

  function gotoPrevMonth() {
    const nMonth = prevMonth(selDate);
    setMonthDays(getMonthCalendarDays(nMonth));
    setSelDate(nMonth);
    getAvailability(nMonth);
  }

  function handleSelectDay(day) {
    const idx = monthDays.findIndex((d) => d.date == day.format('YYYY-MM-DD'));
    monthDays.forEach((d) => {
      d.isSelected = false;
    });
    monthDays[idx].isSelected = true;
    setSelDay(day);
  }

  const getButtonClass = (day, dayIdx) => {
    const isTodayOrFuture = moment(day.date).startOf('day') >= moment().startOf('day');
    const isAllowed = !allowPast || isTodayOrFuture;
  
    let bgClasses = 'bg-gray-50 hover:bg-gray-100';
    if (isTodayOrFuture && day.availability != null) {
      bgClasses = `bg-${availabilityColor(day.availability)} hover:bg-${availabilityHoverColor(day.availability)}`;
    } else if (day.isCurrentMonth) {
      bgClasses = 'bg-white hover:bg-gray-100';
    }
  
    const textClasses = day.isSelected ? 'text-white' : day.isCurrentMonth && !day.isToday ? 'text-gray-900' : !day.isCurrentMonth && !day.isToday ? 'text-gray-400' : day.isToday && !day.isSelected ? 'text-indigo-600' : '';
  
    const roundedClasses =
      (dayIdx === 0 ? 'rounded-tl-lg' : '') +
      (dayIdx === 6 ? 'rounded-tr-lg' : '') +
      (dayIdx === monthDays.length - 7 ? 'rounded-bl-lg' : '') +
      (dayIdx === monthDays.length - 1 ? 'rounded-br-lg' : '');
  
    return `py-1.5 focus:z-10 ${bgClasses} ${day.isSelected || day.isToday ? 'font-semibold' : ''} ${textClasses} ${roundedClasses} ${isAllowed ? 'cursor-pointer' : 'cursor-not-allowed'}`;
  };
  

  return (
    <>
      <div className="flex items-center text-center text-gray-900 mt-12">
        <button
          onClick={gotoPrevMonth}
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold">
          {selDate.format('MMMM YYYY')}
        </div>
        <button
          onClick={gotoNextMonth}
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
        <div>S</div>
        <div>T</div>
        <div>Q</div>
        <div>Q</div>
        <div>S</div>
        <div>S</div>
        <div>D</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {monthDays.map((day, dayIdx) => (
          <button
            onClick={() => {
              if (
                (!allowPast &&
                  moment(day.date).startOf('day') >= moment().startOf('day')) ||
                allowPast
              ) {
                handleSelectDay(moment(day.date, 'YYYY-MM-DD'));
              }
            }}
            key={day.date}
            type="button"
             className={getButtonClass(day, dayIdx)}
          >
            <time
              dateTime={day.date}
              className={classNames(
                'relative mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                day.isSelected && day.isToday && 'bg-indigo-600',
                day.isSelected && !day.isToday && 'bg-gray-900',
                ('nAppointments' in day && day.nAppointments > 0) && 'bg-indigo-200' 
                
              )}
            >
              {day.date.split('-').pop().replace(/^0/, '')}
            </time>
          </button>
        ))}
      </div>
    </>
  );
}
