export const custCallStatus = [
  {
    key: 'AWAY',
    showInTable: true,
    showLightInd: false,
    label: 'Ausente',
    class: '',
    desc: 'O paciente ainda não está no centro médico.',
  },
  {
    key: 'CONFIRMED',
    showInTable: true,
    showLightInd: true,
    label: 'Confirmado',
    class: 'text-green-400 bg-green-400/10',
    desc: 'Em espera',
  },
  {
    key: 'CHECKIN',
    showInTable: true,
    showLightInd: true,
    label: 'Sala de espera',
    class: 'text-blue-400 bg-blue-400/10',
    desc: 'Aguarda vaga',
  },
  {
    key: 'PENDING',
    showInTable: false,
    showLightInd: true,
    label: '',
    class: 'text-rose-400 bg-rose-400/10',
    desc: 'Em fila de espera',
  },
  {
    key: 'ACTIVE',
    showInTable: false,
    showLightInd: true,
    label: '',
    class: 'text-rose-400 bg-rose-400/10',
    desc: 'Em consulta',
  },
  {
    key: 'PENDPAY',
    showInTable: false,
    showLightInd: true,
    label: '',
    class: 'text-rose-400 bg-rose-400/10',
    desc: 'Pendente pagamento',
  },
  {
    key: 'MISS',
    showInTable: false,
    showLightInd: true,
    label: '',
    class: 'text-rose-400 bg-rose-400/10',
    desc: 'Faltou',
  },
  {
    key: 'COMPLETED',
    showInTable: false,
    showLightInd: true,
    label: '',
    class: 'text-rose-400 bg-rose-400/10',
    desc: 'Finalizado',
  },
];
