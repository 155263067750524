import React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default function ViewSlideover({ open, setOpen, data, tmpl }) {
  function getDataVal(_c) {
    let val = data[_c.key];
    if ('valueformat' in _c) {
      val = _c['valueformat'](val, data);
    }
    return val;
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-2xl">
                  {open && (
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll pt-12">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                {tmpl.title}
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                {tmpl.description}
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Divider container */}
                        <div className={`space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0 px-4 ${tmpl.fullHeight != null ? 'h-full' : ''}`}>
                          <dl
                            className={`grid grid-cols-1 sm:grid-cols-${tmpl.template.nCols}  ${tmpl.fullHeight != null ? 'h-full' : ''}`}
                          >
                            {tmpl.template.data.map((u, k) => (
                              <div
                                key={k}
                                className={`border-b border-gray-100 px-4 pb-3 sm:col-span-${u.colSpan} sm:px-0 ${tmpl.fullHeight != null ? 'h-full' : ''}`}
                              >
                                {u.label != '' && (
                                  <dt className="text-sm font-medium leading-6 text-gray-900">
                                    {u.label}
                                  </dt>
                                )}
                                <dd className={`mt-1 text-sm leading-6 text-gray-700 sm:mt-2 ${tmpl.fullHeight != null ? 'h-full' : ''}`}>
                                  {getDataVal(u)}
                                </dd>
                              </div>
                            ))}
                          </dl>
                        </div>
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
