import { useState, useEffect } from 'react'
import { UserGroupIcon } from '@heroicons/react/24/outline'
import MessagingContentSlideover from './slideover/MessagingContentSlideover'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'


const projects = [
  { icon: ExclamationCircleIcon, key: 'openleads', name: 'Leads Abertas', bgColor: 'bg-pink-300' },
  { icon: CheckCircleIcon, key: 'closedleads', name: 'Leads Fechadas', bgColor: 'bg-emerald-300' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function MessagingDashboardQuickLinks({
  viewLeadFromDashboard,
  viewByOwner }) {
  const [openSo, setOpenSo] = useState(false)
  const [content, setContent] = useState(false)

  function showData(data) {

    setOpenSo(true)
    setContent(data)
  }

  useEffect(() => {
    if (!openSo) setContent(false)
  }, [openSo])


  return (
    <>
      <MessagingContentSlideover open={openSo} setOpen={setOpenSo} content={content} viewLeadFromDashboard={viewLeadFromDashboard} viewByOwner={viewByOwner} />
      <div className="pr-3">
        <ul role="list" className="flex flex-col gap-y-2">
          {projects.map((project) => (
            <li key={project.name} className="col-span-1 flex rounded-md shadow-sm">
              <div
                className={classNames(
                  project.bgColor,
                  'flex w-12 flex-shrink-0 items-center justify-center rounded-l-md text-xs font-medium text-white'
                )}
              >
                <project.icon className={'w-5 h-5'} />
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-1 text-xs">
                  <a href="#" onClick={() => showData(project.key)} className="font-medium text-gray-900 hover:text-gray-600">
                    {project.name}
                  </a>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>

  )
}
