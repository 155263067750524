import React from 'react';
import { putRequests as put } from '../../common/apiEndpoints';
import { getRequests as get } from '../../common/apiEndpoints';
import {
  getJson,
  putJson,
  blankResult,
  reqOk,
  getFirst,
} from '../../common/fetchdata';
import { useState, useEffect } from 'react';
import { countryCodes } from '../../label/countrycodes';
import FormSlideover from '../../common/slideover/FormSlideover';
//import PhoneContactSelect from '../../common/cardselect/PhoneContactSelect';

import {
  formfield_org_name as fin_name,
  formfield_org_comercialname as fin_comercialName,
  formfield_org_taxidref as fin_taxIdRef,
  formfield_org_email as fin_email,
  formfield_org_logo as fin_logo,
  formfield_org_newphone as fin_phone,
  formfield_org_address as fin_address
} from '../../forms/formfield_organization';

const OrgConfig = ({ token }) => {
  const [getRes, setGetRes] = useState(blankResult);
  const [putreq, setPutreq] = useState(blankResult);
  const [content, setContent] = useState({
    address_city: '',
    address_line1: '',
    address_postcode: '',
    email: '',
    name: '',
  });
  const [formTempl, setFormTempl] = React.useState(null);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [formKey, setFormKey] = React.useState(0);
  const [edit_formData, edit_setFormData] = React.useState({});

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (reqOk(getRes)) {
      setContent(getFirst(getRes));
    }
  }, [getRes]);

  useEffect(() => {
    if (reqOk(putreq)) {
      fetchData();
    }
  }, [putreq]);

  const fetchData = (args = {}) => {
    getJson(get.orgdetails, { ...getRes, token: token }, setGetRes);
  };

  const editTemplate = (temp) => {
    setFormTempl(temp);
    setTimeout(() => {
      setOpenAdd(true);
    }, '200');
  };
  const edit_okHandler = () => {
    setFormKey((k) => k + 1);
    setOpenAdd(false);
    fetchData();
  };

  const edit_errHandler = () => {
    console.error('Error processing request');
    setFormKey((k) => k + 1);
    setOpenAdd(false);
  };

  const setDefaultPhone = (phoneContactId) => {
    putJson(
      put.orgdetails,
      {
        ...putreq,
        token: token,
        params: { type: 'org_setdefaultcontactphone' },
        payload: { phoneContactId: phoneContactId },
      },
      setPutreq
    );
  };

  const handleDeletePhone = (phoneContactId) => {
    putJson(
      put.orgdetails,
      {
        ...putreq,
        token: token,
        params: { type: 'org_deletecontactphone' },
        payload: { phoneContactId: phoneContactId },
      },
      setPutreq
    );
  };

  return (
    <>
      {formTempl != null && (
        <FormSlideover
          fin={formTempl}
          key={formKey}
          open={openAdd}
          setOpen={setOpenAdd}
          formData={edit_formData}
          setFormData={edit_setFormData}
          okHandler={edit_okHandler}
          errHandler={edit_errHandler}
        />
      )}

      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none mb-12">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Dados
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Informação genérica sobre a empresa
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Nome
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{content.name}</div>
                <button
                  onClick={() => editTemplate(fin_name)}
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Alterar
                </button>
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Nome comercial
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{content.comercial_name}</div>
                <button
                  onClick={() => editTemplate(fin_comercialName)}
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Alterar
                </button>
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Número Fiscal
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{content.tax_id_ref}</div>
                <button
                  onClick={() => editTemplate(fin_taxIdRef)}
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Alterar
                </button>
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Email
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{content.email}</div>
                <button
                  onClick={() => editTemplate(fin_email)}
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Alterar
                </button>
              </dd>
            </div>
          </dl>
        </div>

        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Media
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">Logotipo</p>

          <ul
            role="list"
            className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6"
          >
            <li className="flex justify-between gap-x-6 py-6">
              <div className="font-medium text-gray-900">
                <div className="col-span-full flex items-center gap-x-8">
                  {content.logo_url == null ? (
                    <div className="grid content-center text-center h-24 w-24 flex-none rounded-lg bg-gray-800 align-middle text-slate-300 text-xs">
                      Logotipo não disponivel
                    </div>
                  ) : (
                    <img
                      className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                      src={`../static/uploads/${content.logo_url}`}
                      alt=""
                    />
                  )}

                  <div>
                    <button
                      onClick={() => editTemplate(fin_logo)}
                      type="button"
                      className="font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                      Alterar
                    </button>
                    <p className="mt-2 text-xs leading-5 text-gray-400">
                      JPG, GIF ou PNG.
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Telefones
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Indique todos os números de telefone do centro médico
          </p>

          <ul
            role="list"
            className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6"
          >
            <li className="flex justify-between gap-x-6 py-6">
              <div className="mt-1 grid grid-cols-2 gap-4 lg:grid-cols-12 sm:grid-cols-9">
                {content.phone_contact != null && (

                  <>

                    Not Available. Refactor PhoneContactSelect</>

                )}
              </div>
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Morada da Sede
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Defina a morada da sede do centro clínico.
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Sede
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {content.address_contact == null ? (
                    'Morada não definida'
                  ) : (
                    <>
                      <p>{content.address_contact.address_line1}</p>
                      <p>{content.address_contact.address_line2}</p>
                      <p>{`${content.address_contact.address_postcode} ${content.address_contact.address_city}`}</p>
                      <p>{`${content.address_contact.address_region}, ${countryCodes.filter(c => c.value == content.address_contact.address_country)[0].desc}`}</p>
                    </>
                  )}
                </div>
                <button
                  onClick={() => editTemplate(fin_address)}
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Alterar
                </button>
              </dd>
            </div>

          </dl>
        </div>
      </div>
    </>
  );
};

export default OrgConfig;
