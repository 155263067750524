import React from 'react'
import { formfield_editservicetype as fin } from '../../forms/formfield_servicetype';
import AvatarXSStack from '../card/AvatarXSStack';
export const tableconfig = {
  edit: {
    showEditButton: true,
    type: 'slideover',
    template: fin,
  },
  view: {
    title: 'Especialidade',
    description: '',
    type: 'slideover',
    template: {
      nCols: 2,
      data: [
        {
          colSpan: 1,
          key: 'id',
          label: 'ID',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'name',
          label: 'Nome',
          valueformat: (e) => {
            return e;
          },
        },
      ],
    },
  },
  columns: [
    {
      key: 'id',
      slideoverView: true,
      label: 'ID',
    },
    {
      key: 'name',
      slideoverView: true,
      label: 'Nome',
    },
/*     {
      key: 'servsubtype',
      slideoverView: true,
      label: 'Tipos de Consulta',
      valueformat: (e, row) => {
        let sstr = '';
        e.map((s, j) => (sstr = sstr + s.name + ', '));
        sstr = sstr.substring(0, sstr.length - 2);
        return `${sstr}`;
      },
    }, */
    {
      key: 'providers',
      slideoverView: true,
      label: 'Médicos',
      valueformat: (e, row) => {
        let sstr = '';
        e.map((s, j) => (sstr = sstr + s.name + ', '));
        sstr = sstr.substring(0, sstr.length - 2);
        return (
          <>
            <AvatarXSStack persons={row.providers} />
          </>
        );
      },
    },
  ],
  filter: {
    initFilter: {
      entries: 10,
      page: 1,
    },
    fields: [],
  },
};
