import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

export default function TablePagination({ items, filterCfg, setFilterCfg }) {
  const numAdjacentPages = 2; // Number of adjacent pages to display on each side of the current page

  const pageButtons = () => {
    let buttons = [];

    // Always include the first page
    buttons.push(
      <a
        key={0}
        href="#"
        onClick={() =>
          setFilterCfg({
            ...filterCfg,
            page: 1,
          })
        }
        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${1 === items.page ? "bg-indigo-600 text-white" : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"} focus:z-20 focus:outline-offset-0`}
      >
        1
      </a>
    );

    // Include ellipsis if there are more than numAdjacentPages pages before the current page and current page is not 2
    if (items.page - numAdjacentPages > 2) {
      buttons.push(<span key="start-ellipsis" className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>);
    }

    // Page numbers around the current page (excluding first and last)
    for (let i = Math.max(2, items.page - numAdjacentPages); i <= Math.min(items.maxPage - 1, items.page + numAdjacentPages); i++) {
      buttons.push(
        <a
          key={i}
          href="#"
          onClick={() =>
            setFilterCfg({
              ...filterCfg,
              page: i,
            })
          }
          className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${i === items.page ? "bg-indigo-600 text-white" : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"} focus:z-20 focus:outline-offset-0`}
        >
          {i}
        </a>
      );
    }

    // Include ellipsis if there are more than numAdjacentPages pages after the current page and current page is not maxPage - 1
    if (items.page + numAdjacentPages < items.maxPage - 1) {
      buttons.push(<span key="end-ellipsis" className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>);
    }

    // Always include the last page
    buttons.push(
      <a
        key={items.maxPage}
        href="#"
        onClick={() =>
          setFilterCfg({
            ...filterCfg,
            page: items.maxPage,
          })
        }
        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${items.maxPage === items.page ? "bg-indigo-600 text-white" : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"} focus:z-20 focus:outline-offset-0`}
      >
        {items.maxPage}
      </a>
    );

    return buttons;
  };


  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">


      {items.totalResults > items.lastIdx &&
        <>

          <div className=" justify-between sm:hidden">
            <a
              href="#"
              onClick={() =>
                setFilterCfg({
                  ...filterCfg,
                  page: items.prevPage,
                })
              }
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Anterior
            </a>
            <a
              href="#"
              onClick={() =>
                setFilterCfg({
                  ...filterCfg,
                  page: items.nextPage,
                })
              }
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Seguinte
            </a>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                A mostrar <span className="font-medium"> {`${items.initIdx}`}</span> a <span className="font-medium"> {`${items.lastIdx}`}</span> de{' '}
                <span className="font-medium"> {`${items.totalResults}`}</span> resultados
              </p>
            </div>
            <div>
              <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                <a
                  href="#"
                  onClick={() =>
                    setFilterCfg({
                      ...filterCfg,
                      page: items.prevPage,
                    })
                  }
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Anterior</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </a>
                {pageButtons()}
                <a
                  href="#"
                  onClick={() =>
                    setFilterCfg({
                      ...filterCfg,
                      page: items.nextPage,
                    })
                  }
                  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Seguinte</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </a>
              </nav>
            </div>
          </div>
        </>

      }

    </div>
  )
}
