import React, { Fragment, useState, useContext } from 'react'
import AvatarXs from '../../common/card/AvatarXs';
import { GlobalStateContext } from '../../state/globalState';
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
import MessagingSmsTemplate from './MessagingSmsTemplate';
moment.locale('pt');



export default function MessagingSmsInput({ onSubmit, smsText, setSmsText }) {

    const globalcontext = useContext(GlobalStateContext);
    const user = globalcontext.contextFcn.userData.data.items.user;

    function isGSMAlphabet(text) {
        var regexp = new RegExp("^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$");
        return regexp.test(text);
    }

    function updateText(val) {
        if (val.length <= 160 && isGSMAlphabet(val))
            setSmsText(val)
    }

    return (

        <div>
            <div className="mt-2">
                <div className="flex gap-x-3 flex-col">
                    <div className="relative flex-auto">
                        <form className="relative">
                            <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                                <label htmlFor="comment" className="sr-only">
                                    Add your comment
                                </label>
                                <textarea
                                    rows={3}
                                    name="comment"
                                    id="comment"
                                    className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    placeholder="Escreva a SMS a enviar..."
                                    value={smsText}
                                    onChange={(e) => updateText(e.target.value)}
                                />
                            </div>
                        </form>
                    </div>
                    <div>
                        {/*      <MessagingSmsTemplate /> */}

                        <div className="grid grid-cols-3 gap-x-6">
                            <div></div>
                            <div></div>
                            <div className="relative mt-2 rounded-md shadow-sm">
                                <input
                                    value={smsText.length}
                                    onChange={() => void(0)}
                                    type="text"
                                    disabled
                                    id="smscount"
                                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <span className="text-gray-500 sm:text-sm" id="smscount">
                                        caracteres
                                    </span>
                                </div>

                            </div>


                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
