import React from 'react';
import { useState } from 'react';
import {
  UserCircleIcon
} from '@heroicons/react/24/outline';
import useToken from '../../hooks/useToken';
import AppointmentManagement from '../appointment/AppointmentManagement'
import ServiceTypePage from '../../services/ServiceTypePage';
import ServiceSubTypePage from '../../services/ServiceSubTypePage';
import CallStatePage from '../../call/callstate/CallStatePage';
import CallAdvicePage from '../../call/advice/CallAdvicePage';
import InsurancePage from '../../insurance/InsurancePage';
import ExamsPage from '../../exams/ExamsPage';
import MicroscopeIcon from '../../common/icon/MicroscopeIcon'


const DoctorIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
   <path d="M6 21v-2a4 4 0 0 1 4 -4h.5"></path>
   <path d="M18 22l3.35 -3.284a2.143 2.143 0 0 0 .005 -3.071a2.242 2.242 0 0 0 -3.129 -.006l-.224 .22l-.223 -.22a2.242 2.242 0 0 0 -3.128 -.006a2.143 2.143 0 0 0 -.006 3.071l3.355 3.296z"></path>
 </svg>
  );
};


const StethoscopeIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
       <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M6 4h-1a2 2 0 0 0 -2 2v3.5h0a5.5 5.5 0 0 0 11 0v-3.5a2 2 0 0 0 -2 -2h-1"></path>
   <path d="M8 15a6 6 0 1 0 12 0v-3"></path>
   <path d="M11 3v2"></path>
   <path d="M6 3v2"></path>
   <path d="M20 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path> </svg>
  );
};


const ReportMedicalIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
   <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path>
   <path d="M10 14l4 0"></path>
   <path d="M12 12l0 4"></path>
 </svg>
  );
};




const StatusChangeIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M6 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
   <path d="M18 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
   <path d="M6 12v-2a6 6 0 1 1 12 0v2"></path>
   <path d="M15 9l3 3l3 -3"></path>
 </svg>
  );
};



const ShieldIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M12.462 20.87c-.153 .047 -.307 .09 -.462 .13a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3a12 12 0 0 1 .11 6.37"></path>
   <path d="M16 19h6"></path>
   <path d="M19 16v6"></path>
 </svg>
  );
};





const InfoIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
     <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
   <path d="M12 9h.01"></path>
   <path d="M11 12h1v4h1"></path>
 </svg>
  );
};



export default function ConfigServicesHome() {
  const [page, setPage] = useState('general');
  const { token } = useToken();

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const secondaryNavigation = [
    {
      name: 'Geral',
      icon: UserCircleIcon,
      current: true,
      key: 'general',
    },
    {
      name: 'Definições Médico',
      icon: DoctorIcon,
      current: false,
      key: 'appointment',
    },
    {
      name: 'Seguros',
      icon: ShieldIcon,
      current: false,
      key: 'insurance',
    },
    {
      name: 'Especialidades',
      icon: StethoscopeIcon,
      current: false,
      key: 'servicetype',
    },
    {
      name: 'Tipos Consulta',
      icon: ReportMedicalIcon,
      current: false,
      key: 'servicesubtype',
    }, 
    {
      name: 'Estados Consulta',
      icon: StatusChangeIcon,
      current: false,
      key: 'callstate',
    }, 
    {
      name: 'Exames e Análises',
      icon: MicroscopeIcon,
      current: false,
      key: 'exams',
    }, 
    {
      name: 'Pré-Consulta',
      icon: InfoIcon,
      current: false,
      key: 'preappadvice',
    }, 
  ];

  return (
    <>
      <div className="mx-auto max-w-7xl lg:flex lg:gap-x-4 lg:px-8">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-48 lg:flex-none lg:border-0 lg:py-4">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul
              role="list"
              className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
            >
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <button
                    onClick={() => setPage(item.key)}
                    className={classNames(
                      page == item.key
                        ? 'bg-gray-50 text-indigo-600'
                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        page == item.key
                          ? 'text-indigo-600'
                          : 'text-gray-400 group-hover:text-indigo-600',
                        'h-6 w-6 shrink-0'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-4">
          <div className="mx-auto max-w-2xl space-y-6 sm:space-y-10 lg:mx-0 lg:max-w-none">
      
            {page == 'appointment' && <AppointmentManagement token={token} />}
            {page == 'servicetype' && <ServiceTypePage token={token} />}
            {page == 'servicesubtype' && <ServiceSubTypePage token={token} />}
            {page == 'callstate' && <CallStatePage token={token} />}
            {page == 'insurance' && <InsurancePage token={token} />}
            {page == 'exams' && <ExamsPage token={token} />}
            {page == 'preappadvice' && <CallAdvicePage token={token} />}

                 
          </div>
        </main>
      </div>
    </>
  );
}
