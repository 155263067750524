import React from "react";
import moment from "moment";
import 'moment/locale/pt'  // without this line it didn't work
moment.locale('pt')

const MessageContent = ({ destination }) => {
  const scrollable = React.useRef(null);
  const messageBox = React.useRef(null);

  React.useEffect(() => {
    if (destination.length > 0) {
      scrollItDown()
    }
  }, [destination]);

  const scrollItDown = (toRight) => {
    const scrollLength = messageBox.current.clientHeight
    scrollable.current.scrollBy({top: scrollLength});
  }

  return (
    <div className="card" style={{ height: "24rem" }}>
      <div className="card-body card-body-scrollable card-body-scrollable-shadow" ref={scrollable}>
        <div className="imessage" id="loadMessagingFlow" data-callid="234" ref={messageBox}>
          {destination.map((m, k) => (
            <div style={{ display: "flex", flexDirection: "column" }} key={k}>
              <p className={`${m.from_self ? `from-me` : `from-them`}`}>{m.content}</p>
              <p className={`imessage-timestamp ${m.from_self ? `from-me` : `from-them`}-date`}>
                {moment(m.created_at).format('DD MMM YYYY H:mm')}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MessageContent;
