import React, {useState} from 'react';
import FormSlideover from '../common/slideover/FormSlideover';
import TableEngineTw from '../common/table/TableEngineTw';
import SpeedDialAdd from '../nav/speeddial/SpeedDialAdd';
import { 
  getJson,
  blankResult,
  reqOk } from '../common/fetchdata';
import { formfield_newprovider as fin } from '../forms/formfield_provider';
import { tableconfig as tbcfg } from '../common/configs/tableconfig_provider';
import { apiUrl } from '../common/apiEndpoints';

const ProviderListing = () => {
  const [provList, setProvList] = useState(blankResult);
  const [edit_formData, edit_setFormData] = React.useState({});
  const [openAdd, setOpenAdd] = React.useState(false);
  const [formKey, setFormKey] = React.useState(0);


  function fetchData(args = {}) {
    getJson(
      apiUrl.providers,
      { ...provList, params: args },
      setProvList
    );
  }

  const handleAddNewItem = (usr) => {
    setOpenAdd(true);
  };

  const edit_okHandler = () => {
    setFormKey((k) => k + 1);
    setOpenAdd(false);
    fetchData();
  };

  const edit_errHandler = () => {
    console.error('Error processing request');
    setFormKey((k) => k + 1);
    setOpenAdd(false);
  };
  return (
    <>
      <FormSlideover
        fin={fin}
        key={formKey}
        open={openAdd}
        setOpen={setOpenAdd}
        formData={edit_formData}
        setFormData={edit_setFormData}
        okHandler={edit_okHandler}
        errHandler={edit_errHandler}
      />
      <SpeedDialAdd onClickAction={handleAddNewItem} />

      <TableEngineTw
        cfg={tbcfg}
        items={
          reqOk(provList) &&
          provList.items
        }
        fetchData={fetchData}
      />
    </>
  );
};

export default ProviderListing;
