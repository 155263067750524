import React, { useEffect, useState } from 'react';

const InputBoxFiltering = ({ expanded, sel, setSel, filterDef}) => {

  const [inputVal, setInputVal] = useState('')

  useEffect(() => {
    if (inputVal != '') {
      setSel([{
        [filterDef.fieldOrig]: inputVal,
        [filterDef.fieldOrigLabel]: inputVal
      }])
    }

  }, [inputVal])


  return (
    <div className="">
      <input
        type="text"
        name={filterDef.fieldDest}
        id={filterDef.fieldDest}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        placeholder="Filtrar..."
        value={inputVal}
        onChange={(e) => setInputVal(e.target.value)}
      />
    </div>
  );
};

export default InputBoxFiltering;
