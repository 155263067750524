import React, { Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
    CogIcon,
    HomeIcon,
    PhotoIcon,
    RectangleStackIcon,
    Squares2X2Icon,
    UserGroupIcon,
} from '@heroicons/react/24/outline';

import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');


const activityItems = [
    {
        user: {
            name: 'Michael Foster',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        projectName: 'ios-app',
        commit: '2d89f0c8',
        branch: 'main',
        date: '1h',
        dateTime: '2023-01-23T11:00',
    },
    // More items...
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}


export default function HomeRightSidebar({ nextCalls }) {
    return (
        <>

            <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
                <h2 className="text-base font-semibold leading-7 ">
                    Consultas
                </h2>
                <Menu as="div" className="relative ml-3 ">
                    <Menu.Button className="inline-flex items-center rounded-md  px-3 py-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                        Mais
                        <ChevronDownIcon
                            className="-mr-1 ml-1.5 h-4 w-4 text-gray-400"
                            aria-hidden="true"
                        />
                    </Menu.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="#"
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                    >
                                        Edit
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="#"
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                    >
                                        View
                                    </a>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </header>
            <ul role="list" className="divide-y">
                {nextCalls && nextCalls.map((item) => (
                    <li key={item.id} className="px-4 py-2 border-b">
                        <div className="flex items-center gap-x-3">

                            <h3 className="flex-auto truncate text-sm font-normal leading-6">
                                {item.customers.fullname}
                            </h3>
                            <time
                                dateTime={item.datetm}
                                className="flex-none text-xs text-gray-600"
                            >
                                {`${moment(item.datetm).format('HH:mm')} (${moment(item.datetm).fromNow()})`}
                            </time>
                        </div>
                        <div className="flex items-center gap-x-3">
                            <p className="flex-auto truncate text-sm font-normal leading-6 mt-3 text-gray-500">
                                {`${item.users != null ? item.users.fullname : 'N/D'}`}
                                 <span className="ml-2 text-gray-400 text-xs">{item.users != null ? item.users.servtypes_strlist: 'N/D'}</span>
                            </p>
                            <span className="flex-none text-xs text-gray-600">{`${item.duration_min} min.`}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    )
}