import Avatar from "./Avatar";

export default function AvatarWithDesc({ person, desc }) {
  return (
    <a href="#" className="group block flex-shrink-0">
      <div className="flex items-center">
        <div>
          <Avatar person={person} sz={8} />

        </div>
        <div className="ml-3">
          <p className="text-sm font-normal text-gray-700 group-hover:text-gray-900">{person.fullname}</p>
          <p className="text-xs font-normal text-gray-500 group-hover:text-gray-700">{desc}</p>
        </div>
      </div>
    </a>
  )
}