

const navigation = [
    { key: 'phoneCall', name: 'Chamadas' },
    { key: 'requests', name: 'Pedidos' },
    /*   { key: 'leads', name: 'Leads',current: false } */
]

const secondaryNavigation = [
    { key: 'today', name: 'Últimos 30 dias' },
    { key: 'thisWeek', name: 'Esta Semana' },
    { key: 'thisMonth', name: 'Hoje' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function MessagingChartMenu({ chartSelected, setChartSelected }) {



    return (
        <nav className="flex flex-1 flex-col divide-y space-y-6 w-28" aria-label="Sidebar">
            <ul role="list" className="-mx-2 space-y-1">
                {navigation.map((item) => (
                    <li key={item.name}>
                        <button
                            onClick={() => setChartSelected(item.key)}
                            className={classNames(
                                item.key == chartSelected ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            )}
                        >
                            {item.name}
                        </button>
                    </li>
                ))}
            </ul>
          {/*   <ul role="list" className="-mx-2 space-y-1 pt-6">
                {secondaryNavigation.map((item) => (
                    <li key={item.name}>
                        <button
                            onClick={() => setChartSelected(item.key)}
                            className={classNames(
                                item.key == chartSelected ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            )}
                        >
                            {item.name}
                        </button>
                    </li>
                ))}
            </ul> */}
        </nav>
    )
}
