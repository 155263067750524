import React, { useState, useEffect } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { getJson, blankResult, reqOk } from '../fetchdata';
import { Combobox } from '@headlessui/react';
import useToken from '../../hooks/useToken';
import { apiUrl } from '../apiEndpoints';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AutocompleteOnBe({ sel, setSel, showTitle = true, removeOptions = [], query, setQuery, backendNameAccessor = 'name' }) {
  const { token } = useToken();
  const [submitResult, setSubmitResult] = React.useState(blankResult);


  const handleSelectPerson = (e) => {
    setSel(e);
  };

  const applyRemoveUsedOpts = (reqOk(submitResult) && removeOptions.length > 0) ? submitResult.items.data.filter(p => !removeOptions.includes(p.id)) : submitResult.items.data

  const filteredPeople = !reqOk(submitResult)
    ? []
    : query === ''
      ? applyRemoveUsedOpts
      : applyRemoveUsedOpts.filter((item) => {
        return item[backendNameAccessor].toLowerCase().includes(query.toLowerCase());
      });

  useEffect(() => {
    if (query.length > 2) {
      getJson(apiUrl.examsandanalysis,
        { ...submitResult, params: { [backendNameAccessor]: query, entries: 20 } }, setSubmitResult);
    }
  }, [query])

  return (
    <Combobox
      className="w-full place-items-center"
      as="div"
      value={sel}
      onChange={handleSelectPerson}
    >
      {showTitle && (
        <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
          Médico
        </Combobox.Label>
      )}
      <div className="relative w-full">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item) => item[backendNameAccessor] == null ? '' : item[backendNameAccessor]}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredPeople.length > 0 ? (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.sort(function (a, b) {
              return a[backendNameAccessor].localeCompare(b[backendNameAccessor]);
            }).map((item) => (
              <Combobox.Option
                key={item.id}
                value={item}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      {item.avatar_url == null ? (
                        <span className="inline-flex h-6 w-6  items-center justify-center rounded-full bg-gray-500">
                          <span className="text-sm font-medium leading-none text-white">
                            {item[backendNameAccessor][0]}
                          </span>
                        </span>
                      ) : (
                        <img
                          className="h-6 w-6 rounded-full"
                          src={`../static/uploads/${item.avatar_url}`}
                          alt=""
                        />
                      )}
                      <span
                        className={classNames(
                          'ml-3 truncate',
                          selected && 'font-semibold'
                        )}
                      >
                        {item[backendNameAccessor]}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )

          :
          <></>
        }
      </div>
    </Combobox>
  );
}
