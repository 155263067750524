
import { postJson } from '../../common/fetchdata';
import { apiUrl } from '../../common/apiEndpoints';

const allowedExtensions = [
    'pdf',
    'jpg',
    'png',
]

export function actionAiDocumentInterpret( file, action, setAction ) {
    
    postJson(
        apiUrl.documentinterpret,
        {
            ...action, payload: {
                filename: file.filename
            }
        },
        setAction
    );

}

export const canInterpretDocument = (fileName) => {

    console.log(fileName)

    var ext = /(?:\.([^.]+))?$/.exec(fileName)[1];
    if (allowedExtensions.indexOf(ext) > -1) 
        return true
    
    return false
}