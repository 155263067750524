import React from 'react';
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import 'moment/dist/locale/pt';
import IconCheck from '../icon/IconCheck';
import InputBoxFiltering from '../table/filters/InputBoxFiltering';
import CustomerWebSummaryPopup from '../../client/CustomerWebSummaryPopup';
import SelectMultipleCustomer from '../../common/combobox/SelectMultipleCustomer';
import { CalendarIcon, ChatBubbleBottomCenterTextIcon, PlayIcon } from '@heroicons/react/24/outline';

import { initializeCall } from '../../call/actionfunctions/initializeCall'
moment.locale('pt');

export const tableconfig = {
  title: 'Fichas de Paciente',
  pickable: false,
  pickSubmitTxt: 'Select',
  searchable: false,
  columns: [
    {
      key: 'avatar_url_sm',
      class: 'pr-0 w-4',
      label: '',
      valueformat: (e, row) => {
        const initials = e == null ? row.initials : '';
        return (
          <div className="relative inline-flex items-center">
            {e == null ? (
              <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <span className="font-medium text-gray-600 dark:text-gray-300">
                  {initials}
                </span>
              </div>
            ) : (
              <img
                className="w-8 h8 rounded-full"
                src={`../static/uploads/${e}`}
                alt="Rounded avatar"
              />
            )}
            {row.customers_web && (
              <div className={`absolute inline-flex items-center justify-center w-6 h-6 text-xs text-white border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900 ${row.customers_web.is_online ? 'bg-green-500' : 'bg-red-500'}`}>
                <CustomerWebSummaryPopup
                data={row}
                  btn={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-1"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                      <path d="M3.6 9h16.8"></path>
                      <path d="M3.6 15h16.8"></path>
                      <path d="M11.5 3a17 17 0 0 0 0 18"></path>
                      <path d="M12.5 3a17 17 0 0 1 0 18"></path>
                    </svg>
                  }
                />
              </div>
            )}
          </div>
        );
      },
    },
    {
      key: 'gid',
      class: '',
      label: 'ID',
    },
    {
      key: 'fullname',
      class: '',
      label: 'Nome',
      filter: {
        component: InputBoxFiltering,
        fieldSort: 'fullname',
        fieldOrig: 'value',
        fieldOrigLabel: 'label',
        fieldDest: 'fullname',
        fieldLabel: 'Nome'
      },
      valueformat: (e, row) => {
        return (
          <Link
            className=""
            to={`/customer/${row.id}`}
          >
            {e}
          </Link>
        );
      },
    },
    {
      key: 'gender',
      class: '',
      label: 'Género',
      valueformat: (e) => {
        return (
          <>
            {e == 'M' ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-mars"
                width={16}
                height={16}
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M10 14m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0"></path>
                <path d="M19 5l-5.4 5.4"></path>
                <path d="M19 5l-5 0"></path>
                <path d="M19 5l0 5"></path>
              </svg>
            ) : e == 'F' ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-gender-female"
                width={16}
                height={16}
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M12 9m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0"></path>
                <path d="M12 14v7"></path>
                <path d="M9 18h6"></path>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-question-mark"
                width={16}
                height={16}
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4"></path>
                <path d="M12 19l0 .01"></path>
              </svg>
            )}
          </>
        );
      },
    },
    {
      key: 'age',
      class: '',
      label: 'Idade',
    },
    {
      key: 'last_call_date',
      class: '',
      label: 'Última Consulta',
    },
    {
      key: 'next_call_date',
      class: '',
      label: 'Próxima Consulta',
    },
    {
      key: 'n_calls',
      class: '',
      label: 'Marcações',
    },
    {
      key: 'tax_id_ref',
      class: '',
      label: 'NIF',
    },
    {
      key: 'nid_id_ref',
      class: '',
      label: 'CC',
    },
    {
      key: 'searchable_phone',
      label: 'Telefone',
      sortable: true
    },
  ],
  button: {
    label: 'Ver',
    href: (r) => {
      return '/customer/' + r.id;
    },
  },
  expandField: 'customers_web',
  expandTab: (_r) => {
    return (
      <>
        <div className="row">
          <div className="col">
            <div className="datagrid-item">
              <div className="datagrid-title">Email</div>
              <div className="datagrid-content">
                <IconCheck status={_r.email_confirmed} />
                {_r.email}
              </div>
            </div>
          </div>
          <div className="col">
            <div className="datagrid-item">
              <div className="datagrid-title">Telefone</div>
              <div className="datagrid-content">
                <IconCheck status={_r.phone_confirmed} />
                <span className="text-xs">{`(+${_r.phone_cc})`}</span>
                <span>{` ${_r.phone_loc}`}</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="datagrid-item">
              <div className="datagrid-title">Último login</div>
              <div className="datagrid-content">
                <span>{` ${moment(_r.customers_web_last_login).format(
                  'DD-MM-YYYY HH:mm:ss'
                )}`}</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
  actionButton: {
    actionsLabel: 'Actions',
    items: [
      {
        label: 'Enviar mensagem',
        onclick: (e,k) => console.log(e,k),
        icon: ChatBubbleBottomCenterTextIcon
      },
      {
        label: 'Marcar consulta',
        onclick: (e,k) => console.log(e,k),
        icon: CalendarIcon
      },
      {
        label: 'Iniciar consulta',
        onclick: (e, props, globalContext) => initializeCall(e, props, globalContext ),
        icon: PlayIcon
      },
    ],
  },
  filter: {
    initFilter: {
      entries: 10,
      page: 1,
      customerName: '',
      customerEmail: '',
      customerPhone: '',
    },
    fields: [
      {
        autocomplete: 'off',
        placeholder: '',
        element: 'input',
        type: 'text',
        inputgrouptext: 'Nome',
        formname: 'customerName',
      },
      {
        autocomplete: 'off',
        placeholder: '',
        element: 'input',
        type: 'text',
        inputgrouptext: 'Email',
        formname: 'customerEmail',
      },
      {
        autocomplete: 'off',
        placeholder: '',
        element: 'input',
        type: 'text',
        inputgrouptext: 'Telemóvel',
        formname: 'customerPhone',
      },
    ],
  },
};
