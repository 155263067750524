import React from "react";
import moment from "moment";
import { postJson, blankResult, reqOk } from "../../common/fetchdata";
import { postRequests as pt } from "../../common/apiEndpoints";

const MessageSendbox = ({ token, destination, destinationUids, refreshHandler }) => {
  const [msgContent, setMsgContent] = React.useState("");
  const [postRes, setPostRes] = React.useState(blankResult);

  const onSendMessage = (e) => {
    e.preventDefault();
    let threadId = 0;
    if (destination.length > 0) {
      threadId = destination[0].thread_id;
    }
    postJson(
      pt.messaging,
      {
        ...postRes,
        token: token,
        payload: {
          destinationUids: [destinationUids],
          threadId: threadId,
          conversationType: "dm",
          content: msgContent,
        },
      },
      setPostRes
    );
    setMsgContent("");
  };

  React.useEffect(() => {
    if (reqOk(postRes)) {
      refreshHandler();
    }
  }, [postRes]);

  return (
    <div className="card">
      <form onSubmit={onSendMessage}>
        <div className="card-body">
          <textarea
            className="form-control"
            id="sendMessageTxtArea"
            value={msgContent}
            placeholder="Send a Message"
            rows="4"
            onChange={(e) => setMsgContent(e.target.value)}
          ></textarea>
        </div>
        <div className="card-footer">
          <div className="row align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <button className="btn btn-primary">
                Send
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MessageSendbox;
