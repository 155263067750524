import * as React from "react";
import { Routes, Route, Link, useNavigate, useLocation, Navigate, Outlet } from "react-router-dom";
import { authProvider } from "../auth/authrequest";
import useToken from './useToken'


let AuthContext = React.createContext(null);

function AuthProvider({ children }) {

  let [user, setUser] = React.useState(null);
  const { token, removeToken, setToken } = useToken();


  let signin = (recaptchaToken, usrname, password, otpCode, fingerprint, passwordResetToken, callback) => {
    return authProvider.signin(
      (res) => {
        setUser(usrname);
        callback(res);
      },
      recaptchaToken,
      usrname,
      password,
      otpCode,
      fingerprint,
      passwordResetToken,
      setToken
    );
  };


  let signout = (callback) => {
    return authProvider.signout(() => {
      setUser(null);
      callback();
    }, removeToken);
  };

  let value = { user, signin, signout,  token };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();


  if (!auth.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

export { useAuth, AuthProvider, RequireAuth };
