import React from 'react';
import { formfield_editcenter as fin } from '../../forms/formfield_center';

function openHoursTable(e, row){

  return (
    <>
      <table className="divide-y divide-gray-300 max-w-sm">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-1 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-0"
            >
              Dia
            </th>
            <th
              scope="col"
              className="px-3 py-1 text-left text-xs font-semibold text-gray-900"
            >
              Abertura
            </th>
            <th
              scope="col"
              className="px-3 py-1 text-left text-xs font-semibold text-gray-900"
            >
              Encerramento
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {e.map((day) => (
            <tr key={day.weekday}>
              <td className="whitespace-nowrap py-1 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-0">
                {day.weekday_desc}
              </td>
              <td className="whitespace-nowrap px-3 py-1 text-xs text-gray-500">
                {`${day.oper_open == null ? 'Encerrado' : day.oper_open}`}
              </td>
              <td className="whitespace-nowrap px-3 py-1 text-xs text-gray-500">
                {`${day.oper_close == null ? '' : day.oper_close}`}
              </td>
            </tr>
          ))} 
        </tbody>
      </table>
    </>
  );
}


export const tableconfig = {
  edit: {
    showEditButton: true,
    type: 'slideover',
    template: fin,
  },
  view: {
    title: 'Detalhes do Centro',
    description: '',
    type: 'slideover',
    template: {
      nCols: 2,
      data: [
        {
          colSpan: 1,
          key: 'id',
          label: 'ID',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'name',
          label: 'Nome',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 2,
          key: 'oper_hours',
          label: 'Horário de Funcionamento',
          valueformat: (e, row) => openHoursTable(e, row),
        },
      ],
    },
  },
  columns: [
    {
      key: 'id',
      slideoverView: true,
      label: 'ID',
    },
    {
      key: 'name',
      slideoverView: true,
      label: 'Nome',
    },
    {
      key: 'oper_hours',
      slideoverView: true,
      label: 'Horário de Funcionamento',
      valueformat: (e, row) => openHoursTable(e),
    },
  ],
  filter: {
    initFilter: {
      entries: 10,
      page: 1,
    },
    fields: [],
  },
};
