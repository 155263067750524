import React, { useContext } from 'react'
import { useSearchParams } from 'react-router-dom';
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import parsePhoneNumber from 'libphonenumber-js'
import 'moment/dist/locale/pt';
import MessagingPagination from "./control/MessagingPagination";
import MessagingLookupIdle from "./MessagingLookupIdle"
import MessagingLoadingTopics from './MessagingLoadingTopics'
import { GlobalStateContext } from '../state/globalState';
moment.locale('pt');


import {
  moduleSelected
} from './data/messagingSelect'
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { UserPlusIcon, StarIcon } from "@heroicons/react/24/solid";
import LeadAssignmentMenu from "./actions/LeadAssignmentMenu";



export default function MessagingTopics({ topicsData, selectedTopic, handleSelectTopic, applyPagination, handleRefresh, selectBatch, setSelectBatch, topicsRead }) {

  const [searchParams] = useSearchParams();
  const globalcontext = useContext(GlobalStateContext);
  const user = globalcontext.contextFcn.userData.data.items.user;

  const handleChange = (checked, id) => {
    if (checked)
      setSelectBatch([...selectBatch, id])
    else
      setSelectBatch(selectBatch.filter(s => s != id))
  };



  return (
    <>
      <nav aria-label="Message list" className="min-h-0 flex-1 overflow-y-auto">
        <ul role="list" className="divide-y divide-gray-200 border-b border-gray-200">
          {topicsData ? topicsData.data.map((thread) => (
            <li
              key={thread.id}
              className={`flex relative focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 ` +
                `${selectedTopic.id == thread.id
                  ? 'bg-slate-300 hover:bg-gray-300' :
                  topicsRead.indexOf(thread.id) == -1
                    ? 'bg-slate-200 hover:bg-gray-300'
                    : 'bg-slate-100 hover:bg-gray-300'

                }`}
            >
              <div className="flex  focus:outline-none">
                <div className={`w-1  
              ${thread.current_status == 'CLOSED' ? 'bg-emerald-500' : (
                    thread.current_status == 'PENDING' ? 'bg-yellow-500' : (
                      thread.current_status == 'OPEN' ? 'bg-red-500' : ''
                    )
                  )}`}></div>

                <div className="flex w-full pl-2 h-12">
                  <div className="w-8">
                    <div className="py-2 pl-2">
                      <input
                        checked={selectBatch.indexOf(thread.id) != -1}
                        onChange={(e) => handleChange(e.target.checked, thread.id)}
                        id="comments"
                        aria-describedby="comments-description"
                        name="comments"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      /></div>
                  </div>

                  <div className="w-48 px-2 py-1 flex truncate">
                    <a href="#" onClick={() => handleSelectTopic(thread.id)} >
                      <div className=" inline-flex flex-col rounded-md px-1 py-0.5 text-xs font-medium text-gray-600">

                        <div>{thread.customer_fullname}</div>
                        <div className="pt-1 font-normal flex">
                          {('phone_contact' in thread.customers_web && thread.customers_web.phone_contact != null)
                            ? (
                              typeof parsePhoneNumber(thread.customers_web.phone_contact.phonenum_e164, null) != 'undefined'
                                ? parsePhoneNumber(thread.customers_web.phone_contact.phonenum_e164, null).nationalNumber
                                : thread.customers_web.phone_contact.phonenum_e164)
                            : 'N/D'
                          }<span className="pl-2 pt-0.5">
                            {thread.customers_web.is_a_new_phone &&
                              <StarIcon className="text-yellow-500 h-3 w-3" />}
                          </span>
                        </div>

                      </div>
                    </a>
                  </div>
                  <div className="w-8">
                    <div className="py-3 px-1">
                      {thread.booking_request_count > 0 && <div className={`rounded-full h-6 w-6 p-1 ${thread.has_unanswered_comm ? 'bg-sky-100' : 'bg-gray-200'}`} 
                      ><EnvelopeIcon className={`w-4 h-4 ${thread.has_unanswered_comm ? 'text-sky-600' : ''}`} />
                      </div>}
                    </div>
                  </div>
                  <div className="w-12 px-2 py-1 flex">
                    <div className="flex pt-1.5 truncate w-full">

                      {thread.assigned_user != null ?

                        <div className="tooltip tooltip-left absolute" data-tip={thread.assigned_user.fullname}>
                          <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-500">
                            <span className="text-xs font-medium leading-none text-white">{thread.assigned_user.initials}</span>
                          </span>
                        </div>

                        :
                        <div className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100 p-1">

                          <LeadAssignmentMenu
                            actionBtn={
                              <>
                                <UserPlusIcon className="w-6 h-6" />
                              </>}
                            selectedTopic={thread}
                            handleRefresh={handleRefresh} />
                        </div>
                      }

                    </div>
                  </div>
                  <div className="w-12 px-2 py-1 flex text-xs">
                    <div className="w-full"></div>
                    <div className="m-auto flex flex-col">
                      <time dateTime={thread.last_update}>
                        {moment(thread.last_update).format('HH:mm')}
                      </time>
                      <time dateTime={thread.last_update}>
                        {moment(thread.last_update).isBefore(moment().startOf('day')) && (
                          <span>{moment(thread.last_update).format('DD/MM')}</span>
                        )}
                      </time>

                    </div>
                  </div>
                </div>

                {/*   <div className="flex justify-between space-x-3 px-2 py-2 w-full">
                <div className="min-w-0 flex-1">
                  <a href="#" onClick={() => handleSelectTopic(thread.id)} className="flex  focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <div className="ml-6 flex">
                      <div className="font-medium text-sm w-28 truncate text-gray-900 flex gap-2">
                        <div
                          className={
                            'rounded-md p-0.5 text-xs ring-1 ring-inset font-thin text-green-700 bg-green-50 ring-green-600/20'
                          }
                        >{('phone_contact' in thread.customers_web && thread.customers_web.phone_contact != null)
                          ? (
                            typeof parsePhoneNumber(thread.customers_web.phone_contact.phonenum_e164, null) != 'undefined'
                              ? parsePhoneNumber(thread.customers_web.phone_contact.phonenum_e164, null).country
                              : '')
                          : 'N/D'
                          }

                        </div>
                        {('phone_contact' in thread.customers_web && thread.customers_web.phone_contact != null)
                          ? (
                            typeof parsePhoneNumber(thread.customers_web.phone_contact.phonenum_e164, null) != 'undefined'
                              ? parsePhoneNumber(thread.customers_web.phone_contact.phonenum_e164, null).nationalNumber
                              : thread.customers_web.phone_contact.phonenum_e164)
                          : 'N/D'
                        }

                      </div>

                      <div className="pl-3 flex w-8">
                        {thread.phone_contact_status == 'inbound:connected' ? <PhoneArrowDownLeftIcon className="w-3 h-3 text-lime-800" /> :
                          thread.phone_contact_status == 'outbound:connected' ? <PhoneArrowUpRightIcon className="w-3 h-3 text-lime-800" /> :
                            thread.phone_contact_status == 'inbound:not-connected' ? <PhoneXMarkIcon className="w-3 h-3 text-red-800" /> :
                              thread.phone_contact_status == 'outbound:not-connected' ? <PhoneArrowUpRightIcon className="w-3 h-3 text-red-800" /> : <div className="px-2"></div>}

                      </div>
                      <div className="pl-3 flex  w-8">
                        {thread.booking_request_count > 0 && <EnvelopeIcon className="w-4 h-4" />}
                      </div>
                    </div>

                  </a>
                </div>

                <div className=" flex-shrink-0 whitespace-nowrap text-xs text-gray-500 w-32">
                  <div className="flex">
                    <div className="w-full"></div>
                    <div className="m-auto">
                      <time dateTime={thread.last_update}>
                        {moment(thread.last_update).fromNow()}
                      </time>
                    </div>
                  </div>
                  <div className="flex pt-1.5 truncate w-full">
                    <div className="w-full"></div>
                    <div className="m-auto">
                      {thread.assigned_user != null &&
                        <p
                          className="text-xs inline-flex items-center font-normal leading-sm  bg-blue-100 text-blue-700 rounded-md p-1 truncate  text-right"
                        >{thread.assigned_user.fullname}</p>
                      }
                    </div>

                  </div>
                </div>
              </div> */}
              </div>

            </li>
          )) :
            <>
              {['lookup'].indexOf(moduleSelected(searchParams)) > -1 ?
                <MessagingLookupIdle /> :
                <MessagingLoadingTopics />}
            </>
          }
        </ul>
      </nav >
      <div className="flex-shrink-0">

        <div className="h-12 border-b border-t border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500">
          <MessagingPagination results={topicsData} applyPagination={applyPagination} />
        </div>
      </div>

    </>
  );
}
