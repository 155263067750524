import React, { useState } from 'react';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/20/solid'
import QRCode from 'qrcode.react';

function MfaEnrollment({ loginData, handlePrev, handleNext }) {
    const [message, setMessage] = useState('');


    return (
        <div className="Enrollment">
            <h2 className="mb-6 text-black">Abra a aplicação Authenticator, opção adicionar ou + escolha a opção ler código QR e leia o código abaixo.</h2>

            <div className="my-6 w-full">
                {loginData.provisioningURL && (
                    <QRCode value={loginData.provisioningURL} />
                )}
            </div>


            <div className="flex m-auto space-x-3">
                <button
                    onClick={handlePrev}
                    type="button"
                    className="inline-flex items-center gap-x-2 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray"
                >
                    <ArrowLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Anterior
                </button>
                <button
                    onClick={handleNext}
                    type="button"
                    className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Já adicionei
                    <ArrowRightIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                </button>
            </div>
        </div>
    );
}

export default MfaEnrollment;