import React, { useState, useEffect } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { getJson, blankResult, reqOk } from '../fetchdata';
import { Combobox } from '@headlessui/react';
import { getRequests as gt } from '../apiEndpoints';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SelectCenterModule({
  field,
  formData,
  setFormData,
  formVal,
  setFormVal,
  removeOptions = [],
}) {

  const [remId, setRemId] = useState([])
  const [query, setQuery] = useState('');
  const [submitResult, setSubmitResult] = React.useState(blankResult);



  // initialize
  useEffect(() => {
    setFormVal((f) => ({
      ...f, [field.name]: {
        ...formVal[field.name],
        isTouched: false,
        isValid: isValid(formData[field.name])
      }
    }))
  }, [])


  // on update
  useEffect(() => {
    if(removeOptions != null & removeOptions.length > 0){
      let rId = []
      removeOptions.map(i => {
        if ('id' in i) {
          rId = [...rId, i.id]
        }
        if ('center' in i) {
          rId = [...rId, i.center.id]
        }
      })
      setRemId(rId)
    }
  }, [removeOptions])

  // on update
  useEffect(() => {
    if (formData[field.name] !== '')
      setFormVal((f) => ({
        ...f, [field.name]: {
          ...formVal[field.name],
          isTouched: true,
          isValid: isValid(formData[field.name])
        }
      }))
  }, [formData[field.name]])

  function isValid(dt) {
    if (!field.required) return true
    else return dt != ""
  }


  React.useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getJson(gt.center, { ...submitResult }, setSubmitResult);
  };


  const handleSelectPerson = (e) => {
    handleChange(e);
  };

  const applyRemoveUsedOpts = (reqOk(submitResult) && remId.length > 0) ? submitResult.items.data.filter(p => !remId.includes(p.id)) : submitResult.items.data

  const filteredPeople = !reqOk(submitResult)
    ? []
    : query === ''
      ? applyRemoveUsedOpts
      : applyRemoveUsedOpts.filter((person) => {
        return person.name.toLowerCase().includes(query.toLowerCase());
      });



  const handleChange = (e) => {
    setFormData({ ...formData, [field.name]: e });
  };

  return (
    <>
      <Combobox
        className="w-full place-items-center"
        as="div"
        value={formData[field.name] != null ? formData[field.name] : ''}
        onChange={handleSelectPerson}
      >

        <div className="relative mt-2 w-full">
          <Combobox.Input
            className={`block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ` +
            ` ${(formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'}  `}
       
            
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(person) => person?.name}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {filteredPeople.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredPeople.map((person) => (
                <Combobox.Option
                  key={person.id}
                  value={person}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <div className="flex items-center">
                        {person.avatar_url == null ? (
                          <span className="inline-flex h-6 w-6  items-center justify-center rounded-full bg-gray-500">
                            <span className="text-sm font-medium leading-none text-white">
                              {person.name[0]}
                            </span>
                          </span>
                        ) : (
                          <img
                            className="h-6 w-6 rounded-full"
                            src={`../static/uploads/${person.avatar_url}`}
                            alt=""
                          />
                        )}
                        <span
                          className={classNames(
                            'ml-3 truncate',
                            selected && 'font-semibold'
                          )}
                        >
                          {person.name}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
      {
        (formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) &&
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {'validationerr' in field ? field.validationerr : 'Valor inválido'}
        </p>

      }
    </>
  );
}
