import React from 'react';
import { getRequests as gt } from '../common/apiEndpoints';


const rows = [
  {
    selector: 'input',
    required: true,
    class: '',
    type: 'text',
    name: 'name',
    title: 'Nome',
    placeholder: 'Nome do espaço...',
    id: 'newcenter__name',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Nome Inválido.',
  },
 
];

export const formfield_newtreatmenttype = {
  title: 'Adicionar',
  description: 'Novo Tipo de Tratamento',
  apiEndpoint: gt.treatmenttype,
  method: 'POST',
  preloadForm: false,
  name: '',
  id: '',
  submitLabel: 'Adicionar',
  rows: rows,
};

export const formfield_edittreatmenttype = {
  title: 'Editar',
  description: 'Editar Tipo de Tratamento',
  apiEndpoint: gt.treatmenttype,
  method: 'PUT',
  preloadForm: true,
  name: '',
  id: '',
  submitLabel: 'Editar',
  deleteAction: {
    label: 'Eliminar',
  },
  rows: rows,
};
