import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import SelectCustomerManager from '../combobox/SelectCustomerManager';

export default function SelectCustomerWasWithModule({
  context,
  field,
  formData,
  setFormData,
  removeOptions = [],
}) {
  const handleChange = (e) => {
    setFormData({ ...formData, [field.name]: e });
  };

  const [afTypeCustom, setAfTypeCustom] = useState('');
  //const [afName, setAfName] = useState('');

  /*  useEffect(() => {
    if (
      formData[field.name] != null &&
      formData[field.name].name != null &&
      formData[field.name].name != ''
    ) {
      setAfName(formData[field.name].name);
    }
  }, [formData]); */

  return (
    <>
      <SelectCustomerManager
        customerId={context.customers != null ? context.customers.id : 0}
        sel={formData[field.name] != null ? formData[field.name] : ''}
        setSel={handleChange}
        showTitle={false}
        removeOptions={removeOptions}
      />
      {formData[field.name] != null &&
        formData[field.name].affiliation != null &&
        formData[field.name].affiliation.value != null &&
        formData[field.name].affiliation.value == 'OTHER' && (
          <input
            type="text"
            name="name"
            placeholder="Indique o parentesco..."
            id="aftype__custom"
            onChange={(e) => {
              const currData = formData[field.name];
              setFormData({
                ...formData,
                [field.name]: {
                  ...currData,
                  customAffiliation: e.target.value,
                },
              });
            }}
            className={`mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-white`}
            value={
              formData[field.name] != null &&
              formData[field.name].customAffiliation != null
                ? formData[field.name].customAffiliation
                : ''
            }
          />
        )}
      <input
        type="text"
        name="name"
        placeholder="Indique o nome..."
        id="af__name"
        onChange={(e) => {
          const currData = formData[field.name];
          setFormData({
            ...formData,
            [field.name]: { ...currData, name: e.target.value },
          });
        }}
        className={`mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-white`}
        value={
          formData[field.name] != null && formData[field.name].name != null
            ? formData[field.name].name +
              (formData[field.name].lastname != null
                ? ' ' + formData[field.name].lastname
                : '')
            : ''
        }
      />
    </>
  );
}
