import React, { useState } from "react"

export default function LoginBox({ handleNext, isLoading }) {

    const [showPass, setShowPass] = React.useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    function handleSubmit(e) {

        e.preventDefault();
        handleNext({ username: username, password: password })

    }


    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                    Utilizador
                </label>
                <div className="mt-2">

                    <input
                        id="username"
                        name="username"
                        type="text"
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Nome de utilizador"
                        key="username"
                        defaultValue={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />

                </div>
            </div>

            <div className='mt-6'>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Palavra-passe
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                        id="password"
                        name="password"
                        autoComplete="current-password"
                        required
                        placeholder="Password"
                        key="password"
                        type={`${showPass ? "text" : "password"}`}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div className=" absolute inset-y-0 right-0 flex items-center pr-3">
                        <div
                            onClick={() => setShowPass(!showPass)}
                            className="link-secondary"
                            data-bs-toggle="tooltip"
                            aria-label="Show password"
                            data-bs-original-title="Show password"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-gray-500"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <circle cx="12" cy="12" r="2"></circle>
                                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                            </svg>
                        </div>
                    </div>

                </div>
            </div>
            <div className="text-xs leading-6 mt-1">
                <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Esqueci-me da palavra-passe
                </a>
            </div>
            <div className="flex items-center justify-between mt-6">
                <div className="flex items-center">
                    <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                        Lembrar-me{' '}<p className='text-xs text-gray-500'>Não usar em computadores públicos</p>
                    </label>
                </div>


            </div>

            <div className='mt-6'>
                <button
                    onClick={handleSubmit}
                    type="submit"
                    className={`${isLoading ? "bg-green-600" : "bg-indigo-600"} group relative flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                >
                    Sign in
                </button>
            </div>
        </form>
    )
}