
const rows = [
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'name',
    title: 'Nome',
    placeholder: 'Nome do seguro...',
    id: 'insurance__name',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Nome Inválido.',
  },
  {
    selector: 'avatar',
    required: true,
    name: 'avatar_url',
    title: 'Ícone',
    id: 'avatar_url',
    map: true,
  }
];


export const formfield_newinsurance = {
  title: 'Novo Seguro',
  description: 'Adicione um novo Seguro',
  apiEndpoint: '/api/insurance',
  method: 'POST',
  payloadType: 'formdata',
  preloadForm: false,
  submitLabel: 'Adicionar',
  rows: rows,
};

export const formfield_editinsurance = {
  title: 'Editar Seguro',
  description: 'Edite as características do Seguro',
  apiEndpoint: '/api/insurance',
  method: 'PUT',
  payloadType: 'formdata',
  preloadForm: true,
  submitLabel: 'Editar',
  deleteAction: {
    label: 'Eliminar',
  },
  rows: rows,
};
