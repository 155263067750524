/* eslint-disable react/display-name */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import FormSlideover from '../../common/slideover/FormSlideover';
import {
  formfield_customerattendance as fAtt
} from '../../forms/formfield_customer_actions';
import CustomerActionPaySlideover from './CustomerActionPaySlideover';

const CustomerActions = forwardRef((props, ref) => {
  const [edit_formData, edit_setFormData] = useState({});
  const [openAdd, setOpenAdd] = useState(false);
  const [openActionPay, setOpenActionPay] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const [context, setContext] = useState(0);
  const [finContext, setFinContext] = useState({});

  useImperativeHandle(ref, () => ({
    async attendanceReport(call) {
      setFinContext(fAtt)
      setContext(call);
      setOpenAdd(true);
    },
    async callPaymentAction(call) {
      setContext(call);
      setOpenActionPay(true);
    },
  }));

  const edit_okHandler = () => {
    setFormKey((k) => k + 1);
    setOpenAdd(false);
  };

  const edit_errHandler = () => {
    setFormKey((k) => k + 1);
    setOpenAdd(false);
  };


/*  */



  return (
    <>
      <FormSlideover
        context={context}
        fin={finContext}
        key={formKey}
        open={openAdd}
        setOpen={setOpenAdd}
        formData={edit_formData}
        setFormData={edit_setFormData}
        okHandler={edit_okHandler}
        errHandler={edit_errHandler}
      />

      <CustomerActionPaySlideover
        key={formKey + 1}
        open={openActionPay}
        context={context}
        setOpen={setOpenActionPay}
        okHandler={edit_okHandler}
        errHandler={edit_errHandler}
      />
    </>
  );
});
export default CustomerActions;
