import * as React from 'react';
import { useAuth } from '../hooks/useAuth';
import { getJson, blankResult } from '../common/fetchdata';
import { getRequests as gt } from '../common/apiEndpoints';
import { GlobalStateContext } from '../state/globalState';

import LoadingIndicator from "../loading/LoadingIndicator";
import NavMenu from '../nav/NavMenu';
import UserNav from '../nav/UserNav';
import HomeLayout from '../nav/HomeLayout';


import { useErrorBoundary } from "react-error-boundary";
import { ErrorBoundary } from "react-error-boundary";
import ModalContainer from '../modal/ModalContainer';

const logError = (error, info) => {

  console.error(info)
  console.error(error)
};


export default function LoadAuthContext(props) {
  const globalcontext = React.useContext(GlobalStateContext);
  let auth = useAuth();
  const [user, setUser] = React.useState(blankResult);

  React.useEffect(() => {
    if (auth.token)
      getJson(gt.usercontext, { ...user, token: auth.token }, setUser);
  }, [auth]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>

      <LoadingIndicator />

      {(auth.token || auth.token != '') && user.items.clearance && (
        /*  <div className={`${globalcontext.contextFcn.view.dark ? 'dark' : ''}`}> */
        <div>
          <div className="page">
            <div
              className="page-wrapper"
              style={{ padding: 0, marginLeft: 0, marginRight: 0 }}
            >
              {/*     <NavMenu showbreadCrumbs={props.showbreadCrumbs} content={props.content} /> */}
              <HomeLayout noPadding={props.noPadding} showbreadCrumbs={props.showbreadCrumbs} content={props.content} />
            </div>
            <ModalContainer />
            <UserNav token={auth.token} />
          </div>
        </div>
      )}
    </ErrorBoundary>
  );
}


function ErrorFallback({ error }) {
  const { resetBoundary } = useErrorBoundary();

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <button onClick={resetBoundary}>Try again</button>
    </div>
  );
}
