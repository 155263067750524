import moment from 'moment';

export function nextMonth(_date) {
  var fm = _date.add(1, 'M');
  var fmEnd = moment(fm).endOf('month');
  return _date.date() != fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD'))
    ? fm.add(1, 'd')
    : fm;
}

export function prevMonth(_date) {
  var fm = _date.add(-1, 'M');
  var fmEnd = moment(fm).endOf('month');
  return _date.date() != fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD'))
    ? fm.add(-1, 'd')
    : fm;
}

export function getMonthCalendarDays(date) {
  let daysArray = [];
  const startOfMonth = moment(date).startOf('month');
  const endOfMonth = moment(date).endOf('month');
  let calMoDayOne = startOfMonth.clone();
  const daysToAdd = startOfMonth.day() - 1;
  const today = moment().format('YYYY-MM-DD')

  if (-daysToAdd < 0) {
    calMoDayOne.add(-(daysToAdd + 1), 'days');
    while (calMoDayOne.add(1, 'days').diff(startOfMonth) < 0) {
      daysArray = [
        ...daysArray,
        {
          date: moment(calMoDayOne).format('YYYY-MM-DD'),
        },
      ];
    }
  }

  while (
    calMoDayOne.diff(startOfMonth) >= 0 &&
    calMoDayOne.diff(endOfMonth) <= 0
  ) {
    daysArray = [
      ...daysArray,
      {
        date: moment(calMoDayOne).format('YYYY-MM-DD'),
        isCurrentMonth: true,
      },
    ];
    calMoDayOne.add(1, 'days');
  }
  let remCalDays = 7 - (daysArray.length % 7);
  if (remCalDays < 7) {
    while (remCalDays > 0) {
      daysArray = [
        ...daysArray,
        {
          date: moment(calMoDayOne).format('YYYY-MM-DD'),
        },
      ];
      remCalDays -= 1;
      calMoDayOne.add(1, 'days');
    }
  }

  daysArray.forEach(d => {
    if(d.date == today){
        d.isToday = true
    }
});

  return daysArray;
}


