import { Robot } from '@phosphor-icons/react'

export const addonList = [
    {
        name: 'Marta Padilha',
        icon: Robot,
        role: 'AI',
        title: 'Processo médico definido pela Drª Marta Padilha',
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
    },
    // More people...
]