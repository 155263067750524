import React from 'react';
import CustomerInsurances from './CustomerInsurances';
import CustomerContacts from './CustomerContacts';
import CustomerManagers from './CustomerManagers';



import { sortFields } from '../../label/locals';
import { personal_data, address_data } from '../../label/customer';
import FormSlideover from '../../common/slideover/FormSlideover';
import {
  formfield_editpersonaldata as fin_data_edit,
  formfield_editaddressdata as fin_addr_edit,
} from '../../forms/formfield_customer';
import { PencilIcon } from '@heroicons/react/24/outline';

export default function CustomerPersonalData({ profile, handleRefresh }) {
  const [edit_formData, edit_setFormData] = React.useState({});
  const [openAdd, setOpenAdd] = React.useState(false);
  const [formKey, setFormKey] = React.useState(0);
  const [formTempl, setFormTempl] = React.useState(null);

  const handleEditPersonalData = () => {
    setFormTempl(fin_data_edit);
    setTimeout(() => {
      setOpenAdd(true);
    }, '200');
  };



  const handleEditAddressData = () => {
    setFormTempl(fin_addr_edit);
    setTimeout(() => {
      setOpenAdd(true);
    }, '200');
  };


  const edit_okHandler = () => {
    setFormKey((k) => k + 1);
    setOpenAdd(false);
    handleRefresh();
  };

  const edit_errHandler = () => {
    console.error('Error processing request');
    setFormKey((k) => k + 1);
    setOpenAdd(false);
  };

  return (
    <>
      {formTempl != null && (
        <FormSlideover
          context={profile.id}
          fin={formTempl}
          key={formKey}
          open={openAdd}
          setOpen={setOpenAdd}
          formData={edit_formData}
          setFormData={edit_setFormData}
          okHandler={edit_okHandler}
          errHandler={edit_errHandler}
        />
      )}
      <div className="min-h-full ">
        <main className="pb-10">
          <div className="mx-auto grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-span-2 lg:col-start-1">
              <section aria-labelledby="customer-personaldata">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="flex px-4 py-2 sm:px-6">
                    <div className="w-full">
                      <h2 id="customer-personaldata" className="pt-1.5 text-md font-medium leading-6 text-gray-900">
                        Dados Pessoais
                      </h2>
                    </div>
                    <div
                      onClick={handleEditPersonalData}
                      className="w-auto cursor-pointer text-sm font-medium text-gray-300"
                    >
                      <button
                        type="button"
                        className="rounded-full bg-white px-2.5 py-2.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50"
                      >
                        <PencilIcon className="w-4 h-4" />
                      </button>

                    </div>

                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

                    <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">

                      {sortFields(
                        Object.keys(profile.get_personal_data),
                        personal_data,
                        'pt'
                      ).map((field, k) => (
                        <div key={k} className="sm:col-span-1">
                          <dt className="text-xs font-medium text-gray-500">
                            {field.label}
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {`${profile.get_personal_data[field.key] == null ||
                              profile.get_personal_data[field.key] == ''
                              ? '-'
                              : 'format' in field
                                ? field.format(profile.get_personal_data[field.key])
                                : profile.get_personal_data[field.key]
                              } `}
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                </div>
              </section>


              <section aria-labelledby="customer-address">
                <div className="bg-white shadow sm:rounded-lg">

                  <div className="flex px-4 py-2 sm:px-6">
                    <div className="w-full">
                      <h2 id="customer-personaldata" className="pt-1.5 text-md font-medium leading-6 text-gray-900">
                        Residência
                      </h2>
                    </div>
                    <div
                      onClick={handleEditAddressData}
                      className="w-auto cursor-pointer text-sm font-medium text-gray-300"
                    >
                      <button
                        type="button"
                        className="rounded-full bg-white px-2.5 py-2.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50"
                      >
                        <PencilIcon className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

                    <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-5">
                      {sortFields(
                        Object.keys(profile.address_contact),
                        address_data,
                        'pt'
                      ).map((field, k) => (
                        <div key={k} className="sm:col-span-1">
                          <dt className="text-xs font-medium text-gray-500">
                            {field.label}
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {`${profile.address_contact[field.key] == null ||
                              profile.address_contact[field.key] == ''
                              ? '-'
                              : 'format' in field
                                ? field.format(profile.address_contact[field.key])
                                : profile.address_contact[field.key]
                              } `}
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                </div>
              </section>


            </div>

            <div className="space-y-6 col-span-1 ">

              <section aria-labelledby="customer-contactdata">
                <div className="bg-white shadow sm:rounded-lg">

                  <div className="flex px-4 py-2 sm:px-6">
                    <div className="w-full">
                      <h2 id="customer-personaldata" className="pt-1.5 text-md font-medium leading-6 text-gray-900">
                        Contatos
                      </h2>
                    </div>
                  </div>

                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

                    <CustomerContacts
                      profile={profile}
                      handleRefresh={handleRefresh}
                    />

                  </div>
                </div>
              </section>
              <section aria-labelledby="customer-affiliations">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="flex px-4 py-2 sm:px-6">
                    <div className="w-full">
                      <h2 id="customer-personaldata" className="pt-1.5 text-md font-medium leading-6 text-gray-900">
                        Afiliações
                      </h2>
                    </div>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

                    <CustomerManagers
                      profile={profile}
                      handleRefresh={handleRefresh}
                    />

                  </div>
                </div>
              </section>

              <section aria-labelledby="customer-insurance">
                <div className="bg-white shadow sm:rounded-lg">
                <div className="flex px-4 py-2 sm:px-6">
                    <div className="w-full">
                      <h2 id="customer-personaldata" className="pt-1.5 text-md font-medium leading-6 text-gray-900">
                        Seguros
                      </h2>
                    </div>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

                    <CustomerInsurances profile={profile} handleRefresh={handleRefresh} />

                  </div>
                </div>
              </section>

            </div>
          </div>
        </main>
      </div>


    </>
  );
}
