import React, { useState } from 'react';
import { Fragment } from 'react';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt'; // without this line it didn't work
import { CalendarIcon } from '@heroicons/react/24/outline';
import Datepicker from 'react-tailwindcss-datepicker';
moment.locale('pt');

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function CalendarWrapper({
  calendar,
  calendarRef,
  currentViewRange,
  showDateRangeLabel = true,
  showViewSelectionControl = true,
  showPrevNextControl = true,
  showDayPickControl = true
}) {
  function gotoToday() {
    calendarRef.current.getApi().today();
  }
  function gotoPrev() {
    calendarRef.current.getApi().prev();
  }
  function gotoNext() {
    calendarRef.current.getApi().next();
  }
  function gotoDate(selDate) {
    const _date = moment(selDate, 'YYYY-MM-DD').format()
    calendarRef.current.getApi().gotoDate(_date);
  }

  function showDayView() {
    setCurrentViewMode('Dia')
    calendarRef.current.getApi().changeView('timeGridDay');
  }

  function showWeekView() {
    setCurrentViewMode('Semana')
    calendarRef.current.getApi().changeView('timeGridWeek');
  }

  function showMonthView() {
    setCurrentViewMode('Mês')
    calendarRef.current.getApi().changeView('dayGridMonth');
  }

  const [currentViewMode, setCurrentViewMode] = useState('Dia')

  return (
    <div className="flex h-full flex-col">
      <header className="flex flex-none items-center justify-between border-b border-gray-200 py-4">
        {showDateRangeLabel && <h1 className="text-base font-semibold leading-6 text-gray-900">
          <time dateTime="2022-01">
            {moment(currentViewRange.start).format('MM') ==
              moment(currentViewRange.end).format('MM')
              ? `${moment(currentViewRange.start).format('D')} a ${moment(
                currentViewRange.end
              ).format('DD/MMMM/YYYY')}`
              : `${moment(currentViewRange.start).format('D/MMM')} a ${moment(
                currentViewRange.end
              ).format('DD/MMMM/YYYY')}`}
          </time>
        </h1>}
        <div className="flex items-center">
          {showDayPickControl && <div className="pr-3">
            <div
              className="rounded-md bg-white p-1.5 w-9 h-9 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <Datepicker
                i18n={'pt'}
                asSingle={true}
                name={'filterByDate'}
                id={'filterByDate'}
                placeholder={'Intervalo de datas...'}
                //containerClassName="fixed"
                inputClassName={`p-0 w-0 h-0 border-none`}
                toggleClassName="absolute right-0.5 h-full px-0 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
                /* value={{
                  startDate: moment(currentViewRange.start).format('YYYY-MM-DD'),
                  endDate: moment(currentViewRange.start).format('YYYY-MM-DD')
                }} */
                onChange={(e) => {
                  gotoDate(e.startDate)
                }}
                displayFormat={'DD/MM/YY'}
              />
            </div>
          </div>}

          {showPrevNextControl && <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
            <div
              className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300"
              aria-hidden="true"
            />
            <button
              onClick={() => gotoPrev()}
              type="button"
              className="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500  md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Previous week</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />

            </button>
            <button
              onClick={() => gotoToday()}
              type="button"
              className="hidden px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 md:block"
            >
              Hoje
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              onClick={() => gotoNext()}
              type="button"
              className="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next week</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>}
          {showViewSelectionControl && <div className="hidden md:ml-4 md:flex md:items-center">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="w-24 flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                {currentViewMode}
                <ChevronDownIcon
                  className="-mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={showDayView}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left'
                          )}
                        >
                          Dia
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={showWeekView}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left'
                          )}
                        >
                          Semana
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={showMonthView}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left'
                          )}
                        >
                          Mês
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>}
        </div>
      </header>

      <div className="isolate flex flex-auto flex-col overflow-auto bg-white">
        {calendar}
      </div>
    </div>
  );
}
