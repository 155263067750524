/* eslint-disable no-unused-vars */
import React from 'react';
import TinyMceEditor from '../../common/TinyMceEditor';

export default function CallNotes({ token }) {

  const [txtContent, setTxtContent] = React.useState('txtContent');
  const editorRef = React.useRef(null);
  

  return (
    <>
      <TinyMceEditor content={txtContent} editorRef={editorRef} setContent={setTxtContent} />
    </>
  );
}
