import React from 'react';

export default function CustomerCard({ person }) {
  return (
    <div className="grid place-items-center">
      <div className="relative flex justify-between py-5">
        <div className="flex">
          {person.avatar_url == null ? (
            <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-500">
              <span className="text-lg font-medium leading-none text-white">
                {person.initials}
              </span>
            </span>
          ) : (
            <img
              className="flex-none h-12 w-12 rounded-full bg-gray-50"
              src={`../static/uploads/${person.avatar_url}`}
              alt=""
            />
          )}
        </div>
      </div>
    </div>
  );
}
