import { Fragment, useState, useRef, useEffect } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import {
    ChartBarSquareIcon,
    EnvelopeIcon,
    HomeIcon,
    MagnifyingGlassIcon,
    PhoneIcon
} from '@heroicons/react/24/outline'
import {
    getActiveFilters,
    getLeadAssigneeId,
    moduleSelected,
    onlyShowPendContacts,
    setActiveStatus,
    setShowPendContacts,
    showLeadsByAsigneeId,
    searchLeads,
    isLookupSet,
    removeLookup,
    isFilterSetVanilla,
    resetFiltersToVanilla
} from './data/messagingSelect'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { doStatusChange, statusLabel } from './actions/leadTags'
import SelectProvider from '../common/combobox/SelectProvider'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MessagingTabSelector({ goToView, activeThread }) {

    const menu = [
        { key: 1, hasPendContact: null, screen: 'dashboard', name: 'Início', href: '#', icon: HomeIcon },
        { key: 2, hasPendContact: '0', screen: 'results', name: 'Todos', href: '#', icon: PhoneIcon },
        { key: 3, hasPendContact: '1', screen: 'results', name: 'Pedidos', href: '#', icon: EnvelopeIcon },
        /*   { key: 4, hasPendContact: null, screen: 'search', name: 'Pesquisar', href: '#', icon: MagnifyingGlassIcon }, */
    ]

    const popoverPan = useRef(null);
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();
    const [assigneeSelected, setAssigneeSelected] = useState({})
    const [searchStr, setSearchStr] = useState('')

    function changeStatusVisible(checked, e) {

        let status = ''
        let _urlStatus = searchParams.get('status');
        if (_urlStatus == null) status = 'PENDING,OPEN'
        else status = _urlStatus

        status = status.split(',');
        if (checked) {
            status = [...status, e]
        }
        else {
            status = status.filter(s => s != e)
        }
        status = status.toString()
        setActiveStatus(searchParams, navigate, status)

    }

    function getLeadAssignee() {
        return getLeadAssigneeId(searchParams)
    }

    function selectAssigneeMode(ownerId) {

        if (ownerId <= 0) {
            setAssigneeSelected({})
        }
        showLeadsByAsigneeId(searchParams, navigate, ownerId)
    }

    function handleSubmit(e) {
        e.preventDefault()
        searchLeads(searchParams, navigate, searchStr)

    }

    useEffect(() => {
        if (assigneeSelected.id != null) {
            showLeadsByAsigneeId(searchParams, navigate, assigneeSelected.id)
        }
    }, [assigneeSelected])

    return (

        <Popover className="relative isolate z-10 shadow">
            <div className="bg-white py-5 flex">
                <div className="px-6 w-full flex">
                    {moduleSelected(searchParams) == 'results' &&
                        <Popover.Button ref={popoverPan} className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                            Filtrar  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                        </Popover.Button>}

                    {moduleSelected(searchParams) == 'dashboard' &&
                        <button
                            type="button"
                            onClick={() => goToView('results')}
                            className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                        >
                            Ver todas as leads
                        </button>
                    }
                    {(moduleSelected(searchParams) == 'results' &&

                        ((isLookupSet(searchParams))
                            ?
                            <div className="ml-6">
                                <span className="inline-flex items-center gap-x-0.5 rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">
                                    {searchStr}
                                    <button
                                        onClick={() => removeLookup(searchParams, navigate)}
                                        type="button" className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-purple-600/20">
                                        <span className="sr-only">Remove</span>
                                        <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-violet-700/50 group-hover:stroke-violet-700/75">
                                            <path d="M4 4l6 6m0-6l-6 6" />
                                        </svg>
                                        <span className="absolute -inset-1" />
                                    </button>
                                </span>

                            </div>
                            :
                            (isFilterSetVanilla(searchParams, navigate)
                                ? <></>
                                : <>
                                    <button
                                        onClick={() => resetFiltersToVanilla(searchParams, navigate)}
                                        type="button"
                                        className="ml-6 rounded-full bg-white px-2.5 py-1 text-xs font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                        Reiniciar filtro
                                    </button>
                                </>)))
                    }
                </div>
                <div className="m-auto px-4">
                    {moduleSelected(searchParams) == 'results' && <button
                        type="button"
                        className="rounded-full bg-indigo-600 p-1.5 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <ChartBarSquareIcon className="h-4 w-4" aria-hidden="true" onClick={() => goToView('dashboard')} />
                    </button>
                    }
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 -translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-1"
            >
                <Popover.Panel className="absolute inset-x-0 top-0 -z-10 bg-white pt-16 shadow-lg ring-1 ring-gray-900/5">
                    <div className="mx-auto max-w-7xl gap-x-8 gap-y-10 px-6 pb-10 ">
                        <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
                            <fieldset>
                                <legend className="block font-medium text-sm">Estado</legend>
                                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                    {statusLabel.map((option, optionIdx) => (
                                        <div key={option.value} className="flex items-center text-base sm:text-sm">
                                            <input
                                                checked={getActiveFilters(searchParams).indexOf(option.value) != -1}
                                                id={`status-${optionIdx}`}
                                                name="status[]"
                                                //defaultValue={option.value}
                                                type="checkbox"
                                                /* className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" */
                                                className={classNames(
                                                    'group-hover:border-indigo-600 group-hover:text-indigo-600',
                                                    'flex h-4 w-4 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium',
                                                    `bg-${option.color}`
                                                )}
                                                onChange={() => changeStatusVisible(
                                                    !(getActiveFilters(searchParams).indexOf(option.value) != -1),
                                                    option.value)}
                                            //defaultChecked={option.checked}
                                            />
                                            <label htmlFor={`status-${optionIdx}`} className="ml-3 min-w-0 flex-1 text-gray-600">
                                                {option.name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend className="block font-medium text-sm">Filtros</legend>
                                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                    <fieldset>
                                        <legend className="sr-only">Notifications</legend>
                                        <div className="space-y-5">
                                            <div className="relative flex items-start">
                                                <div className="flex h-6 items-center">
                                                    <input
                                                        checked={onlyShowPendContacts(searchParams)}
                                                        onChange={(e) => setShowPendContacts(searchParams, navigate, e.target.checked)}
                                                        id="comments"
                                                        aria-describedby="comments-description"
                                                        name="comments"
                                                        type="checkbox"
                                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm leading-6">
                                                    <label htmlFor="comments" className="font-medium text-gray-900">
                                                        Por responder
                                                    </label>
                                                    <p id="comments-description" className="text-gray-500 text-xs">
                                                        Mostrar só com mensagens por responder
                                                    </p>
                                                </div>
                                            </div>


                                        </div>
                                    </fieldset>
                                </div>
                            </fieldset>
                            <fieldset className="col-span-2">
                                <legend className="block font-medium text-sm">Assignação</legend>
                                <div className="space-y-6">
                                    <div>
                                        <fieldset className="mt-2">
                                            <div className="divide-y divide-gray-200">
                                                <div className="relative flex items-start pb-4 pt-3.5">
                                                    <div className="min-w-0 flex-1 text-sm leading-6">
                                                        <label htmlFor={`notassigned`} className=" text-gray-900 text-xs">
                                                            Tudo
                                                        </label>
                                                    </div>
                                                    <div className="ml-3 flex h-6 items-center">
                                                        <input
                                                            checked={getLeadAssignee() == 0}
                                                            id={`allleads`}
                                                            aria-describedby={`allleads-description`}
                                                            name="allleads"
                                                            type="radio"
                                                            onChange={() => selectAssigneeMode(0)}
                                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="relative flex items-start pb-4 pt-3.5">
                                                    <div className="min-w-0 flex-1 text-sm leading-6">
                                                        <label htmlFor={`notassigned`} className=" text-gray-900 text-xs">
                                                            Não assignado
                                                        </label>
                                                    </div>
                                                    <div className="ml-3 flex h-6 items-center">
                                                        <input
                                                            checked={getLeadAssignee() == -1}
                                                            id={`notassigned`}
                                                            aria-describedby={`notassigned-description`}
                                                            name="notassigned"
                                                            type="radio"
                                                            onChange={() => selectAssigneeMode(-1)}
                                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="relative flex items-start pb-4 pt-3.5">
                                                    <div className="min-w-0 flex-1 text-sm leading-6">
                                                        <div className="flex">
                                                            <div>
                                                                <p className=" text-gray-900 mt-2 text-xs">
                                                                    Assignado a
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex ml-3 w-44">
                                                        <SelectProvider
                                                            sel={assigneeSelected}
                                                            setSel={setAssigneeSelected}
                                                            showTitle={false}
                                                            filterByRole={['crm:selfassign']}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="col-span-2">
                                <legend className="block font-medium text-sm">Pesquisar</legend>
                                <div className="space-y-6">
                                    <div>
                                        <div className="mt-2">
                                            <form className="flex gap-x-2" onSubmit={handleSubmit}>
                                                <div className="w-full">
                                                    <input
                                                        type="text"
                                                        name="searchterms"
                                                        id="searchterms"
                                                        value={searchStr}
                                                        onChange={(e) => setSearchStr(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="Termos de pesquisa"
                                                    />

                                                </div>
                                                <div className="w-auto">
                                                    <button
                                                        type="submit"
                                                        className="rounded-full w-8 bg-gray-400 p-1.5 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                                    >
                                                        <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>


    )
}
