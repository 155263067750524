

import { formfield_editcalladvice as fin } from '../../forms/formfield_calladvice';

export const tableconfig = {
  edit: {
    showEditButton: true,
    type: 'slideover',
    template: fin,
  },
  view: {
    title: 'Recomendação Pré-Consulta',
    description: '',
    type: 'slideover',
    template: {
      nCols: 2,
      data: [
        {
          colSpan: 1,
          key: 'id',
          label: 'ID',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'title',
          label: 'Título',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'content',
          label: 'Descrição',
          valueformat: (e) => {
            return <div dangerouslySetInnerHTML={{__html:e}}/>
          },
        },
      ],
    },
  },
  columns: [
    {
      key: 'ID',
      slideoverView: true,
      label: 'ID',
    },
    {
      key: 'title',
      slideoverView: true,
      label: 'Título',
    },
    {
      key: 'content',
      slideoverView: true,
      label: 'Descrição',
      valueformat: (e) => {
        var divContainer= document.createElement("div");
        divContainer.innerHTML = e;
        return divContainer.textContent || divContainer.innerText || "";
      },
    },
  ],

  filter: {
    initFilter: {
      entries: 10,
      page: 1
    },
    fields: [
      
    ],
  },
};
