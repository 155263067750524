

import MessagingDashboardStats from './MessagingDashboardStats'
import MessagingCharts from './MessagingCharts'


export default function MessagingDashboard({
    viewLeadFromDashboard,
    viewByOwner
}) {

    return (
        <>
            <main>
                <MessagingDashboardStats viewLeadFromDashboard={viewLeadFromDashboard} viewByOwner={viewByOwner} />
                <div className="space-y-2 py-2">
                    <MessagingCharts />
                </div>
            </main>
        </>
    )
}
