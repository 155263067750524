import { useContext } from 'react';
import { EnvelopeOpenIcon, FolderMinusIcon, FunnelIcon, PhoneIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom';

import { getJson, blankResult, reqOk } from '../common/fetchdata';
import { apiUrl } from '../common/apiEndpoints';
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
import { useState, useEffect } from 'react';
moment.locale('pt');
import { GlobalStateContext } from '../state/globalState';
import MessagingDashboardQuickLinks from './MessagingDashboardQuickLinks';




export default function MessagingDashboardStats({
  viewLeadFromDashboard,
  viewByOwner }) {

  let navigate = useNavigate();
  const _gc = useContext(GlobalStateContext);
  const globalUiUpdateBookingContact = _gc.contextFcn.globalUiUpdate.newPublicBookingContact;
  const globalUiUpdateContactMessage = _gc.contextFcn.globalUiUpdate.newPublicContactMessage;
  const globalUiUpdatePhoneCentral = _gc.contextFcn.globalUiUpdate.newPhoneCentralImport;

  const [statsData, setStatsData] = useState(blankResult)
  const [filters, setFilters] = useState({
    startDate: moment().startOf('day').format(),
    endDate: moment().endOf('day').format(),
    dataset: 'stats'
  })


  function isStartOf(time) {
    return filters.startDate == moment().startOf(time).format() && filters.endDate == moment().endOf(time).format()
  }

  function setRange(time) {
    const _newFilter = {
      ...filters,
      startDate: moment().startOf(time).format(),
      endDate: moment().endOf(time).format()
    }
    setFilters(_newFilter)
    getJson(apiUrl.messagingstats, { ...statsData, params: _newFilter }, setStatsData)
  }



  const stats = [
    {
      id: 1, screen: 'results', hasPendContact: 0, name: 'Chamadas', icon: PhoneIcon, stat: [
        { name: 'Não atendidas', value: reqOk(statsData) ? statsData.items.call_stats.inbound_not_connected : '' },
        { name: 'Recebidas', value: reqOk(statsData) ? statsData.items.call_stats.inbound_connected : '' },
      ]
    },
    {
      id: 2, screen: 'results', hasPendContact: 1, name: 'Pedidos', icon: EnvelopeOpenIcon, stat: [
        { name: 'Marcação', value: reqOk(statsData) ? statsData.items.requests.bookingrequest : '' },
        { name: 'Informações', value: reqOk(statsData) ? statsData.items.requests.inforequest : '' },
      ]
    },
  ]

  const secondaryNavigation = [
    {
      name: 'Hoje',
      click: () => setRange('day'),
      current: isStartOf('day')
    },
    {
      name: 'Esta Semana',
      click: () => setRange('isoWeek'),
      current: isStartOf('isoWeek')
    },
    {
      name: 'Este mês',
      click: () => setRange('month'),
      current: isStartOf('month')
    }
  ]


  function gotoUrl(screen, hasPendContact) {
    const newSearchParams = new URLSearchParams('');
    newSearchParams.set('screen', screen);
    newSearchParams.set('hasPendContact', hasPendContact);
    const newURL = `${location.pathname}?${newSearchParams.toString()}`;
    return newURL
  }

  function handleRefresh() {

    getJson(apiUrl.messagingstats, { ...statsData, params: filters }, setStatsData)
  }

  useEffect(() => {
    handleRefresh()
  }, [])



  return (


    <div className="relative isolate overflow-hidden ">
      {/* Secondary navigation */}
      <header className="pb-4 pt-6 sm:pb-6">
        <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
          <h1 className="text-base font-semibold leading-7 text-gray-900">Período</h1>
          <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
            {secondaryNavigation.map((item) => (
              <button onClick={item.click} key={item.name} className={item.current ? 'text-indigo-600' : 'text-gray-700'}>
                {item.name}
              </button>
            ))}
          </div>
          <div className="min-w-fit">
            <div className="text-xs font-semibold">
              <span className="text-gray-800 font-normal mr-1">Última atualização de Chamadas</span>{statsData.items.tel_central_last_update != null ? `${moment(statsData.items.tel_central_last_update).fromNow()}` : ``}
            </div>
          </div>
        </div>
      </header>
      <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
        <dl className="ml-3 mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0 gap-x-3 p-4 ">
          {stats.map((item) => (
            <div
              key={item.id}
              className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                <div className="absolute rounded-md bg-indigo-200 p-2">
                  <item.icon className="h-4 w-4 text-white" aria-hidden="true" />
                </div>
                <p className="ml-12 truncate text-sm font-medium text-gray-500">
                  <button
                    onClick={() =>
                      navigate(gotoUrl(item.screen, item.hasPendContact))
                    }
                    className="font-medium text-indigo-600 hover:text-indigo-500">
                    {item.name}
                  </button>

                </p>
              </dt>
              <dd className="ml-12 flex flex-col items-baseline pb-3 sm:pb-4 w-full">

                <table className="min-w-full mt-3">
                  <tbody className="">
                    {
                      item.stat.map((s, si) =>
                        <tr className="grid grid-cols-2" key={si}>
                          <td className="mt-1 mr-2 flex items-baseline text-xs font-semibold" >
                            {s.name}
                          </td>
                          <td className="text-md font-semibold text-gray-900">{s.value}</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>


              </dd>
            </div>
          ))}

          <div
            className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-indigo-200 p-2">
                <FunnelIcon className="h-4 w-4 text-white" aria-hidden="true" />
              </div>
              <p className="ml-12 truncate text-sm font-medium text-gray-500">Leads</p>
            </dt>
            <dd className="ml-12 flex flex-col items-baseline sm:pb-4 w-full">

              <table className="min-w-full mt-3">
                <tbody className="">

                  <tr className="grid grid-cols-2">
                    <td className="mt-1 mr-2 flex items-baseline text-xs font-semibold text-red-400" >
                      Abertas
                    </td>
                    <td className="text-md font-semibold text-gray-900">{reqOk(statsData) ? statsData.items.request_leads.open : ''}</td>
                  </tr>
                  <tr className="grid grid-cols-2">
                    <td className="mt-1 mr-2 flex items-baseline text-xs font-semibold text-yellow-400" >
                      Pendentes
                    </td>
                    <td className="text-md font-semibold text-gray-900">{reqOk(statsData) ? statsData.items.request_leads.pending : ''}</td>
                  </tr>
                  <tr className="grid grid-cols-2">
                    <td className="mt-1 mr-2 flex items-baseline text-xs font-semibold text-emerald-800" >
                      Fechadas
                    </td>
                    <td className="text-md font-semibold text-gray-900">{reqOk(statsData) ? statsData.items.request_leads.closed : ''}</td>
                  </tr>

                </tbody>
              </table>


            </dd>
          </div>

          <MessagingDashboardQuickLinks viewLeadFromDashboard={viewLeadFromDashboard} viewByOwner={viewByOwner} />


        </dl>
      </div>
    </div>




  )
}
