import React from 'react';
//import '@fullcalendar/react/dist/vdom'

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import { getJson, blankResult } from '../common/fetchdata';
import { getRequests as gt } from '../common/apiEndpoints';

const CalendarAddSlot = ({
  token,
  context,
  selSlot,
  setSelSlot,
  durationNewEventMin,
  calendarSlots,
  setCalendarSlots,
  calendarRef,
}) => {
  const [getRes_slot, setGetRes_slot] = React.useState(blankResult);




  React.useEffect(() => {
    fetchSlots(context);
  }, []);

  React.useEffect(() => {
    if (
      getRes_slot.state == 2 &&
      !getRes_slot.error &&
      getRes_slot.items.data.length > 0
    ) {
      if (selSlot == 0) setCalendarSlots(getRes_slot.items.data);
    }
  }, [getRes_slot]);

  const fetchSlots = (providerId, type = 3) => {
    let geturl = '';
    if (type == 1) geturl = gt.appointment;
    if (type == 2) geturl = gt.calendarslots;
    if (type == 3) geturl = gt.schedule;

    getJson(
      geturl,
      {
        ...getRes_slot,
        token: token,
        params: { providerId: providerId, entries: 10000 },
      },
      setGetRes_slot
    );
  };

  const handleCalendarSelection = (slot) => {
    let endSlot = moment(slot.start).add(durationNewEventMin, 'minutes');
    let newSlot = { ...slot, end: endSlot.format(), endStr: endSlot.format() };
    setSelSlot(newSlot);
  };

  return (
    <FullCalendar
      locale={'pt'}
      allDaySlot={false}
      weekends={true}
      hiddenDays={[]}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridWeek',
      }}
      selectable={true}
      initialView="timeGridWeek"
      events={calendarSlots}
      //eventContent={renderEventContent} // custom render function
      buttonText={{
        today: 'hoje',
        month: 'mês',
        week: 'semana',
        day: 'dia',
        list: 'lista',
      }}
      select={handleCalendarSelection}
      ref={calendarRef}
    />
  );
};

export default CalendarAddSlot;
