import React from 'react';
import { GlobalStateContext } from '../state/globalState';

/* import SocketManager from '../socket/SocketManager'; */

import NavUserActionMenu from './NavUserActionMenu';

import { BellIcon } from '@heroicons/react/24/outline';
import { InboxIcon } from '@heroicons/react/24/outline';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import NextAppointments from './NextAppointments';
import InboxNav from './InboxNav';
import NavAccordion from './sidebar/NavAccordion';
import CallToolbar from './calltoolbar/CallToolbar';
import VideoCallContainer from './calltoolbar/VideoCallContainer';
import CallTools from './CallTools';
import ChatBox from '../common/chat/ChatBox';

const UserNav = ({ token }) => {
  const globalcontext = React.useContext(GlobalStateContext);
  const [viewPanel, setViewPanel] = React.useState(-1);

  return (
    <>
      {globalcontext.activeCall.items.active == 1 && (
        <>
          <VideoCallContainer
            token={token}
            isCallActive={globalcontext.activeCall.items.active == 1}
          />
          <CallTools
            token={token}
            navExpanded={
              globalcontext.contextFcn.panel.sidebar.expand.isExpanded
            }
            viewPanel={viewPanel}
          />
        </>
      )}

      <ChatBox />

   {/*   <aside
        className={
          `h-screen fixed inset-y-0 z-10 p-0 right-0 flex flex-col justify-between transition-all duration-500 shadow-md ${
            globalcontext.contextFcn.panel.sidebar.expand.isExpanded
              ? 'w-64'
              : 'w-14'
          } ` +
          `${
            globalcontext.activeCall.items.active == 1
              ? 'bg-sky-800'
              : 'bg-gray-200'
          }`
        }
        onMouseEnter={() =>
          globalcontext.contextFcn.panel.sidebar.expand.setExpanded(true)
        }
        onMouseLeave={() =>
          globalcontext.contextFcn.panel.sidebar.expand.setExpanded(false)
        }
      >
        <div className="static h-full w-full flex divide-y divide-gray-300">
          <div className="absolute top-0 left-0 flex flex-col">
            <div className="p-3 flex flex-row whitespace-nowrap">
              Hello Tiago !
            </div>
          </div>
          {globalcontext.activeCall.items.active == 1 ? (
            <CallToolbar
              token={token}
              viewPanel={viewPanel}
              setViewPanel={setViewPanel}
              data={globalcontext.activeCall.items.current}
            />
          ) : (
            <div className="absolute top-16 w-full bottom-24 left-0">
              <NavAccordion
                items={[
                  {
                    title: (
                      <div className="p-3 flex flex-row">
                        <MagnifyingGlassIcon className="w-6 h-6" />
                        <div onClick={() => globalcontext.contextFcn.panel.search.show()} className="ml-4">Procurar</div>
                      </div>
                    ),
                    content: <></>,
                  },
                  {
                    title: (
                      <div className="p-3 flex flex-row">
                        <CalendarDaysIcon className=" w-6 h-6 " />
                        <div className="ml-4">Marcações</div>
                      </div>
                    ),
                    content: (
                      <>
                        <NextAppointments context={globalcontext.activeCall} />
                      </>
                    ),
                  },
                  {
                    title: (
                      <div className="p-3 flex flex-row">
                        <InboxIcon className=" w-6 h-6 " />
                        <div className="ml-4">Mensagens</div>
                      </div>
                    ),
                    content: (
                      <>
                        <InboxNav token={token} />
                      </>
                    ),
                  },
                  {
                    title: (
                      <div className="p-3 flex flex-row">
                        <BellIcon className=" w-6 h-6 " />
                        <div className="ml-4">Notificações</div>
                      </div>
                    ),
                    content: 'B',
                  },
                ]}
              />
            </div>
          )}

          <div className="absolute bottom-0 left-0 p-4">
         
          </div>
        </div>
      </aside> */}
    </>
  );
};

export default UserNav;
