import FileListingWithContext from '../../filemanager/FileListingWithContext'

export default function CallFileManager() {


    return (
        <>
            <FileListingWithContext
                config={{
                    uploadFTypesAllowed: [
                        'pdf',
                        'jpg'
                    ]
                }}
            />
        </>
    )
}