import React, { useEffect } from 'react';
import { Transition } from '@headlessui/react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import ReCAPTCHA from 'react-google-recaptcha';
import { ClientJS } from 'clientjs';
import { postJson, blankResult, reqOk } from '../common/fetchdata';
import { postRequests as pt } from '../common/apiEndpoints';
import { errorToast, successToast } from '../common/toasts';
import { MedziLogoWithText } from '../common/icon/MedziLogo'
import LoginBox from './LoginBox';
import PasswordResetBox from './PasswordResetBox';
import MfaEnrollment from './MfaEnrollment';
import MfaInstall from './MfaInstall';
import MfaInputCode from './MfaInputCode';
import LoginSuccess from './LoginSuccess';


const SigninPage = () => {

  const [loginData, setLoginData] = React.useState({
    username: '',
    password: '',
    smscode: '',
    otpCode: '',
    provisioningURL: '',
    passwordResetToken: ''
  });

  const [passwordResetToken, setPasswordResetToken] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [viewModule, setViewModule] = React.useState('login');

  const client = new ClientJS();
  const fingerprint = client.getFingerprint();

  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  const [searchParams] = useSearchParams();
  const recaptchaRef = React.useRef();

  let from = location.state?.from?.pathname || '/home';

  React.useEffect(() => {
    if (auth.token) {
      console.log('here')
      navigate(from, { replace: true });
    }
  }, [])

  React.useEffect(() => {
    if (location.pathname.includes('welcome')) {
      const _token = searchParams.get('token');
      if (_token != null) {
        setPasswordResetToken(_token)
        setViewModule('password-reset')
      }
    }
  }, [])




  function submitLogin({ username = null, password = null, otpCode = null }) {


    // normal login
    let _loginData = loginData
    if (username != null) setLoginData((l) => ({ ...l, username: username }))
    if (password != null) setLoginData((l) => ({ ...l, password: password }))

    if (username == null) username = loginData.username
    if (password == null) password = loginData.password


    setIsLoading(!isLoading);
    const token = '';
    //const token = await recaptchaRef.current.executeAsync();
    //recaptchaRef.current.reset();

    auth.signin(token, username, password, otpCode, fingerprint, passwordResetToken, (res) => {

      if (!res.error) {
        successToast(res.message)
        if (res.proceed_to_mfa) {
          setViewModule('loading')
          setIsLoading(false);

          let _viewMod = ''
          if (res.is_mfa_configured) {
            _viewMod = 'mfa-input'
          }
          else {
            setLoginData((d) => ({ ...d, provisioningURL: res.provisioning_url }))
            _viewMod = 'mfa-install'
          }

          setTimeout(function () {
            setViewModule(_viewMod)
          }, 1500);

        }


        if (res.is_authenticated) {

          setViewModule('welcome')

          let _goTo = '/home'
          const locationFrom = location.state?.from?.pathname
          if (locationFrom && locationFrom == '/home') _goTo = res.landing
          if (locationFrom && locationFrom != '/home') _goTo = locationFrom
          if (!locationFrom) _goTo = res.landing

          setTimeout(function () {
            navigate(_goTo, { replace: true });
          }, 1500);
        }

      } else {
        setIsLoading(false);
        errorToast(res.message)
      }

      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
    });

    // apply to form data
  }

  const AuthLoader = () => {
    return (
      <div>
        A Autenticar...
      </div>
    )
  }


  function handleNext(args) {

    switch (viewModule) {

      case 'login':
        submitLogin(args)
        break;

      case 'mfa-install':
        setViewModule('mfa-enroll')
        break;

      case 'mfa-enroll':
        setViewModule('mfa-input')
        break;

      case 'mfa-input':
        submitLogin(args)
        break;

      case 'password-reset':
        submitLogin(args)
        break;

      default:
        break;
    }
  }


  function handlePrev() {

    switch (viewModule) {
      case 'mfa-enroll':
        setViewModule('mfa-install')
        break;

      default:
        break;
    }
  }

  const TransitionModule = ({ content, vModule }) => {

    return (
      <Transition
        show={vModule == viewModule}
        enter="transition-opacity duration-1000"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {content}
      </Transition>
    )
  }

  return (
    <>
      {/*       <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
      /> */}
      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none">
          <div className="mx-auto w-full max-w-sm lg:w-48">
            <div>
              <MedziLogoWithText height="38" />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                {viewModule == 'login' ? 'Introduza os seus dados de acesso' :
                  (viewModule == 'mfa-install' ? 'Introduza os seus dados de acesso' : (
                    viewModule == 'mfa-enroll' ? 'Introduza os seus dados de acesso' : (
                      viewModule == 'mfa-input' ? 'Introduza os seus dados de acesso' : (
                        viewModule == 'password-reset' ? 'Introduza uma nova palavra-passe' : (
                          ''
                        )))))
                }
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Precisa de ajuda?{' '}
                <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Entre em contacto
                </a>
              </p>
            </div>

            <div className="mt-10">
              <div>

                {viewModule == 'loading' && <AuthLoader />}

                <TransitionModule
                  content={<LoginBox
                    handleNext={handleNext}
                    isLoading={isLoading}
                  />}

                  vModule={'login'} />
                <TransitionModule
                  content={<PasswordResetBox
                    handleNext={handleNext}
                    isLoading={isLoading}
                    errorToast={errorToast}
                  />}
                  vModule={'password-reset'} />


                <TransitionModule
                  content={<MfaInstall
                    handleNext={handleNext}
                  />}
                  vModule={'mfa-install'} />

                <TransitionModule
                  content={<MfaEnrollment
                    loginData={loginData}
                    handlePrev={handlePrev}
                    handleNext={handleNext}
                  />}
                  vModule={'mfa-enroll'} />

                <TransitionModule
                  content={<MfaInputCode
                    loginData={loginData}
                    setLoginData={setLoginData}
                    handleNext={handleNext}
                  />}
                  vModule={'mfa-input'} />


                <TransitionModule
                  content={<LoginSuccess
                  />}
                  vModule={'welcome'} />
              </div>

              <div className="mt-10">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-900"></span>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="../static/bglogin.jpg"
            alt=""
          />
        </div>
      </div>


    </>
  );
};

export default SigninPage;
