import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt'; // without this line it didn't work
moment.locale('pt');

export default function StartEndTimeInputModule({
  field,
  formData,
  setFormData,
  formVal,
  setFormVal
}) {
  const [tspan, setTSpan] = useState({
    start: '',
    end: '',
  });
  const [duration, setDuration] = useState(null)

  useEffect(() => {
    if (tspan.start != '' && tspan.end != '') {
      var duration = moment.duration(moment(tspan.end, 'HH:mm').diff(moment(tspan.start, 'HH:mm'))).asHours()
      const hours = Math.floor(duration)
      const mins = Math.floor((duration % 1) * 60)
      setDuration(`${hours > 0 ? hours + 'h ' : ''}${mins > 0 ? mins + 'm' : ''}`)
      setFormData({ ...formData, [field.name]: tspan })
    }
  }, [tspan]);


  useEffect(() => {
    if (formData[field.name].start != null && formData[field.name].end != null) {
      setTSpan(formData[field.name])
    }
  }, [formData]);


  // initialize
  useEffect(() => {
    setFormVal((f) => ({
      ...f, [field.name]: {
        ...formVal[field.name],
        isTouched: false,
        isValid: isValid()
      }
    }))
  }, [])


  // on update
  useEffect(() => {
    if (formData[field.name] !== '')
      setFormVal((f) => ({
        ...f, [field.name]: {
          ...formVal[field.name],
          isTouched: true,
          isValid: isValid()
        }
      }))
  }, [formData[field.name]])

  function isValid() {
    if (!field.required) return true
    else return  tspan.start != '' && tspan.end != ''
  }


  return (
    <>
      <div className="grid grid-cols-3 w-full space-x-2">
        <div className="col-span-1">
          <input
            type="time"
            name={field.name}
            id={field.id}
            onChange={(e) => {
              setTSpan({
                ...tspan,
                start: e.target.value,
              });
            }}
            className={`block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ` +
              ` ${(formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'}  `}

            value={tspan.start}
          />
        </div>
        <div className="col-span-1">
          <input
            type="time"
            name={field.name}
            id={field.id}
            onChange={(e) => {
              setTSpan({
                ...tspan,
                end: e.target.value,
              });
            }}
            className={`block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ` +
              ` ${(formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'}  `}

            value={tspan.end}
          />
        </div>
        <div className="text-center text-xs">
          <p>Total:</p>
          <p>{duration != null && `${duration}`}</p>
        </div>
      </div>
      {
        (formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) &&
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {'validationerr' in field ? field.validationerr : 'Intervalo inválido'}
        </p>

      }
    </>
  );
}
