import { useRef, useEffect, useState } from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";

import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function TextInputModal({ open, setOpen, initValue, saveValue, title, inputType = "text" }) {
  /// Create an array of refs
  const cancelButtonRef = useRef(null);
  const [inpVal, setInpVal] = useState({});
  const [passwValid, setPasswValid] = useState(false);

  useEffect(() => {
    initValue.map((i) => setInpVal((e) => ({ ...e, [i.id]: i.value })));
  }, [initValue]);

  function handleConfirm() {
    saveValue(inpVal);
  }

  useEffect(() => {
    if (inputType == "password" && "newpassword" in inpVal && "repeatpassword" in inpVal) {
      var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,24}$/;
      if (inpVal.newpassword.match(decimal)) setPasswValid(true);
      else setPasswValid(false);
    }
  }, [inpVal]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {`${title}`}
                      </Dialog.Title>
                      <div className="mt-2 w-full">
                        {(inputType == "password" && !passwValid) && (
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3 w-full">
                              <h3 className="text-sm font-medium text-red-800">
                                A Palavra-passe não cumpre os requisitos de segurança
                              </h3>
                              <div className="mt-2 text-sm text-red-700">
                                <ul role="list" className="list-disc space-y-1 pl-5">
                                  <li>Entre 8 a 24 caracteres</li>
                                  <li>Pelo menos uma letra maiúscula, uma minúscula, um número e um símbolo.</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}

                        {(inputType == "password" && inpVal.newpassword !== inpVal.repeatpassword) && (
                          <div className="flex">
                            <div className="flex-shrink-0">
                            </div>
                            <div className="ml-3 w-full">
                              <div className="mt-2 text-sm text-red-700">
                              <h3 className="text-sm font-medium text-red-800">
                                As Palavras-passe não são iguais
                              </h3>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="flex space-x-3 w-full">
                          {initValue.map((v, k) => (
                            <div key={k} className="mt-2 w-full mx-3">
                              <label
                                htmlFor={`inputField-${k}`}
                                className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                              >
                                {v.label}
                              </label>
                              <input
                                autoComplete="new-password"
                                type={inputType}
                                name={`inputField-${k}`}
                                id={`inputField-${k}`}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={inpVal[v.id]}
                                onChange={(e) => setInpVal((h) => ({ ...h, [v.id]: e.target.value }))}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                    onClick={() => {
                      setOpen(false);
                      handleConfirm();
                    }}
                  >
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancelar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
