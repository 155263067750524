import moment from 'moment';
import 'moment/dist/locale/pt';
moment.locale('pt');

export function centersMaxOpenHours(centers) {
  let openHours = [
    { weekday: 1 },
    { weekday: 2 },
    { weekday: 3 },
    { weekday: 4 },
    { weekday: 5 },
    { weekday: 6 },
    { weekday: 7 },
  ];
  let open = [null, null, null, null, null, null, null];
  let close = [null, null, null, null, null, null, null];
  if (centers.length > 0) {
    centers.map((c) => {
      c.oper_hours.map((ch) => {
        const idx = ch.weekday - 1;
        if (ch.oper_open != null) {
          const _time = moment(ch.oper_open.substring(0, 5), ['h:m a', 'H:m']);
          open[idx] == null
            ? (open[idx] = _time.format('HH:mm'))
            : open[idx] < _time
              ? open[idx]
              : _time.format('HH:mm');
        }
        if (ch.oper_close != null) {
          const _time = moment(ch.oper_close.substring(0, 5), ['h:m a', 'H:m']);
          close[idx] == null
            ? (close[idx] = _time.format('HH:mm'))
            : close[idx] < _time
              ? close[idx]
              : _time.format('HH:mm');
        }
      });
    });
  }

  openHours.forEach(function (part, index, theOw) {
    theOw[index] = { ...theOw[index], open: open[index], close: close[index] };
  });

  return openHours;
}

export function fcSlotMinTime(viewMode, centerHours) {
  let setHour = '06:00:00';
  if (viewMode == 'timeGridWeek') {
    let _hrs = centerHours
      .map((c) => (c.open != null ? moment(c.open, 'HH:mm') : null))
      .filter((c) => c != null);
    if (_hrs.length > 0) setHour = moment.min(_hrs).format('HH:mm:ss');
  }
  return setHour;
}

export function fcSlotMaxTime(viewMode, centerHours) {
  let setHour = '23:00:00';
  if (viewMode == 'timeGridWeek') {
    let _hrs = centerHours
      .map((c) => (c.close != null ? moment(c.close, 'HH:mm') : null))
      .filter((c) => c != null);
    if (_hrs.length > 0) setHour = moment.max(_hrs).format('HH:mm:ss');
  }

  return setHour;
}

export function callsToEvent(calls) {
  let evArray = []
  calls.map(c => {
    evArray = [...evArray, {
      sourceObj: c,
      id: c.id,
      status: c.state,
      start: moment(c.datetm).format(),
      end: moment(c.datetm).add(c.duration_min,'minutes').format(),
      user_id: c.users != null ? c.users.id : 0,
      duration_min: c.duration_min,
      allDay: false,
      backgroundColor: '#e9f0f9',
      textColor: '#206bc4',
      borderColor:'#e9f0f9',
      slotType: 'call_slot',
      serviceType: c.users != null ? c.users.servtypes_strlist : '',
      customerName: c.customers != null ? c.customers.fullname : '',
    }]
  })
  return evArray
}