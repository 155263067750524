import React from 'react';
import { getJson, blankResult, reqOk } from '../fetchdata';
import { getRequests as gt } from '../apiEndpoints';
import useToken from '../../hooks/useToken';
import SelectMultipleFilterCS from './SelectMultipleFilterCS';

const SelectMultipleProvider = ({ expanded, sel, setSel }) => {
  const { token } = useToken();
  const [inputData, setInputData] = React.useState(null);
  const [getRes_prov, setGetRes_prov] = React.useState(blankResult);

  React.useEffect(() => {
    if (expanded)
    fetchProviders();
  }, [expanded]);


  const fetchProviders = () => {
    getJson(
      gt.provider,
      { ...getRes_prov, token: token, params: { detailInfo: 'min', entries: 1000 } },
      setGetRes_prov
    );
  };

  React.useEffect(() => {
    if (reqOk(getRes_prov)) {
      let selvalue = [];
      getRes_prov.items.data.map(
        (p) => (selvalue = [...selvalue, { value: p.id, label: p.fullname }])
      );
      setInputData(selvalue);
    }
  }, [getRes_prov]);

  return (
    <>
      <SelectMultipleFilterCS sel={sel} setSel={setSel} inputData={inputData}/>
    </>
  );
};

export default SelectMultipleProvider;
