
import React  from "react";
import { callStatusLabel } from '../getCallStatus';

export default function CallStateLabel({ call  }) {

    let cState = callStatusLabel.filter(s => s.key == 'UNKNOWN')[0]
    if (call.state != null && callStatusLabel.filter(s => s.key == call.state).length > 0) {
      cState = callStatusLabel.filter(s => s.key == call.state)[0]
    }

    return (
      <div className="flex flex-row">
          <div className="flex items-center text-xs">
            <span
              className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${cState.class}`}
            >
              {cState.label}
            </span>
          </div>

      </div>
    );
}