import React, { useState, useRef, useEffect } from 'react';
import PickProviderOrService from './PickProviderOrService';
import HomeDayTimeline from './HomeDayTimeline';
import EventDetail from './EventDetail';
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

import MonthCalendarSummary from '../calendar/MonthCalendarSummary'
import { ChevronLeftIcon } from '@heroicons/react/20/solid';

export default function HomeDayView() {
  const container = useRef(null);

  const [selEvent, setSelEvent] = useState(null);
  const [selDay, setSelDay] = useState(moment());
  const [selDate, setSelDate] = useState(moment());
  const [viewMode, setViewMode] = useState('calendar');
  const [context, setContext] = useState({ type: null, id: null, item: null });

  
  useEffect(() => {
    if (selEvent != null) {
      console.log(selEvent);
      setViewMode('event');
    }
  }, [selEvent]);

  return (
    <div className="flex h-full flex-col">
      <header className="flex flex-none items-center justify-between border-b border-gray-200 px-6 py-4">
        <div>
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            <time dateTime="2022-01-22" className="sm:hidden">
              {selDay.format('DD MMM YYYY')}
            </time>
            <time dateTime="2022-01-22" className="hidden sm:inline">
              {selDay.format('DD MMMM YYYY')}
            </time>
          </h1>
          <p className="mt-1 text-sm text-gray-500">{selDay.format('dddd')}</p>
        </div>
        <div className="flex items-center">
          <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
            <div
              className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300"
              aria-hidden="true"
            />
          </div>
          <div className="hidden md:ml-4 md:flex md:items-center">
            {/*             <button
              type="button"
              className="rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Hoje
            </button>
            <div className="mx-3 h-6 w-px bg-gray-300" />
 */}
            <div className="">
              <PickProviderOrService
                context={context}
                setContext={setContext}
              />
            </div>
          </div>
        </div>
      </header>
      <div className="isolate flex flex-auto bg-white">
        <div
          ref={container}
          className="flex flex-auto flex-col"
         /*  overflow-y-auto h-[calc(100vh-51rem)] */
        >
          <div className="flex w-full flex-auto h-20">
            <HomeDayTimeline
              selDay={selDay}
              context={context}
              setSelEvent={setSelEvent}
            />
          </div>
        </div>
        <div className="hidden w-1/2 max-w-md flex-none border-l border-gray-100 px-4 py-4 md:block">
          {viewMode != 'calendar' && (
            <div>
              <button
                onClick={() => setViewMode('calendar')}
                type="button"
                className="inline-flex items-center gap-x-1.5  rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              >
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                Voltar
              </button>
            <EventDetail selEvent={selEvent}/>
            </div>
          )}
          {viewMode == 'calendar' && (
            <>
            <MonthCalendarSummary
             selDate={selDate}
             selDay={selDay}
             setSelDate={setSelDate}
             setSelDay={setSelDay}
             context={context}
            />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
