import React from 'react';

import {
  ArrowLeftIcon,
  BuildingOffice2Icon,
  LockClosedIcon,
  CurrencyEuroIcon,
  BriefcaseIcon,
  PhoneIcon,
  CircleStackIcon,
} from '@heroicons/react/24/outline';

import ConfigServicesHome from './services/ConfigServicesHome';
import ConfigSpacesHome from './spaces/ConfigSpacesHome';
import ConfigDataHome from './data/ConfigDataHome'
import ConfigBillingHome from './billing/ConfigBillingHome'
import ConfigCommHome from './comm/ConfigCommHome'
import ConfigUserRoleHome from './usersroles/ConfigUserRoleHome'



const actions = [
  {
    title: 'Centros e Espaços',
    link: 'centerspace',
    icon: BuildingOffice2Icon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
    text: 'Configurações gerais a nível da organização, espaços e equipamentos disponíveis.',
  },
  {
    title: 'Especialidades, Consultas e Seguros',
    link: 'appointment',
    icon: BriefcaseIcon,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
    text: 'Associar médicos a especialidades, gerir seguros e subsistemas e consultas disponíveis.',
  },
  {
    title: 'Financeiro',
    link: 'billing',
    icon: CurrencyEuroIcon,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
    text: 'Configurar preçários, retenções e percentagens de médicos.',
  },
  {
    title: 'Comunicações',
    link: 'comm',
    icon: PhoneIcon,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
    text: 'Gestão das comunicações externas (SMS/Emails) e respetivos conteúdos.',
  },
  {
    title: 'Utilizadores e Permissões',
    link: 'userrole',
    icon: LockClosedIcon,
    iconForeground: 'text-rose-700',
    iconBackground: 'bg-rose-50',
    text: 'Atribuir perfis de acesso personalizados, adicionar, editar e remover utizadores.',
  },
  {
    title: 'Definições Avançadas',
    link: 'configmanager',
    icon: CircleStackIcon,
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
    text: 'Importação de dados externos, configurações de parâmetros,...',
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SettingsMain() {
  const [menu, setMenu] = React.useState('home');

  const ConfigWrapper = ({ content }) => {
    return (
      <>
        <div className="pl-6">
          {' '}
          <button
            onClick={() => setMenu('home')}
            type="button"
            className="flex rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
          >
            <ArrowLeftIcon className="h-4 w-4 mt-0.5" />
            <span className="ml-2">Voltar</span>
          </button>
        </div>

        {content}
      </>
    );
  };

  return (
    <>
      {menu == 'home' && (
        <div className="p-8 divide-y divide-gray-200 overflow-hidden rounded-lg shadow sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0 h-max">
          {actions.map((action, actionIdx) => (
            <div
              key={action.title}
              className={classNames(
                actionIdx === 0
                  ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                  : '',
                actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                actionIdx === actions.length - 1
                  ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                  : '',
                'group relative bg-white hover:bg-slate-50 p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
              )}
            >
              <div>
                <span
                  className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    'inline-flex rounded-lg p-3 ring-4 ring-white'
                  )}
                >
                  <action.icon className="h-6 w-6" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-8">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  <a
                    href="#"
                    onClick={() => setMenu(action.link)}
                    className="focus:outline-none"
                  >
                    {/* Extend touch target to entire panel */}
                    <span className="absolute inset-0" aria-hidden="true" />
                    {action.title}
                  </a>
                </h3>
                <p className="mt-2 text-sm text-gray-500">{action.text}</p>
              </div>
              <span
                className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
        </div>
      )}

      {menu == 'centerspace' && (
        <ConfigWrapper content={<ConfigSpacesHome />} />
      )}
      {menu == 'appointment' && (
        <ConfigWrapper content={<ConfigServicesHome />} />
      )}
      {menu == 'billing' && <ConfigWrapper content={<ConfigBillingHome />} />}
      {menu == 'comm' && <ConfigWrapper content={<ConfigCommHome />} />}
      {menu == 'userrole' && <ConfigWrapper content={<ConfigUserRoleHome />} />}
      {menu == 'configmanager' && (
        <ConfigWrapper content={<ConfigDataHome />} />
      )}
    </>
  );
}
