import React from 'react';

import { getJson, blankResult, reqOk } from '../common/fetchdata';
import { getRequests as gt } from '../common/apiEndpoints';
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt'; // without this line it didn't work
moment.locale('pt');

const InboxNav = ({ token }) => {
  const [context, setContext] = React.useState(blankResult);
  React.useEffect(() => {
    getJson(gt.nextcalls, { ...context, token: token }, setContext);
  }, []);

  return (
    <>
      {reqOk(context) && context.items.data.length == 0 && (
        <div>Sem mensagens </div>
      )}
    </>
  );
};

export default InboxNav;
