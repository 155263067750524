import React from 'react';
import { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { Switch } from '@headlessui/react';
import {
  comm_model_vars,
  replaceByTags,
  previewEmail,
} from '../../label/comms';
import { postRequests as pt } from '../../common/apiEndpoints';
import { postJson, blankResult, reqOk } from '../../common/fetchdata';
import ContentSlideover from '../../common/slideover/ContentSlideover';
import CommEditor from './CommEditor';
import CommEditorEmailFields from './CommEditorEmailFields';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function OptionCommModel({
  token,
  cfg,
  basehtml,
  handleRefresh,
}) {
  const [smsEnabled, setSmsEnabled] = useState(cfg.sms_enabled);
  const [emailEnabled, setEmailEnabled] = useState(cfg.email_enabled);
  const [openso_sms, setOpenso_sms] = useState(false);
  const [openso_email, setOpenso_email] = useState(false);
  const [enToggle, setEnToggle] = React.useState(blankResult);

  useEffect(() => {
    if (reqOk(enToggle)) {
      setSmsEnabled(enToggle.items.data.sms_enabled);
      setEmailEnabled(enToggle.items.data.email_enabled);
    }
  }, [enToggle]);

  const handleSetSms = (toggle) => {
    postJson(
      pt.commconfig,
      {
        ...enToggle,
        token: token,
        payload: { key: cfg.key, sms_enabled: toggle },
      },
      setEnToggle
    );
  };

  const handleSetEmail = (toggle) => {
    postJson(
      pt.commconfig,
      {
        ...enToggle,
        token: token,
        payload: { key: cfg.key, email_enabled: toggle },
      },
      setEnToggle
    );
  };

  const SmsEditContent = ({ txtDefault }) => {
    const [text, setText] = useState(txtDefault);
    const [thiscfg, setThisCfg] = React.useState(blankResult);

    useEffect(() => {
      if (reqOk(thiscfg)) {
        handleRefresh();
        setOpenso_sms(false);
      }
    }, [thiscfg]);

    const handleSave = () => {
      postJson(
        pt.commconfig,
        {
          ...thiscfg,
          token: token,
          payload: { key: cfg.key, sms_content: text },
        },
        setThisCfg
      );
    };

    return (
      <>
        <div className="">
          <div className="space-y-2 px-4 p-4  sm:space-y-0 sm:px-6 sm:py-5">
            <CommEditor
              text={text}
              setText={setText}
              options={comm_model_vars}
              handleSave={handleSave}
            />
          </div>
          <div className="space-y-2 px-4 p-4  sm:space-y-0 sm:px-6 sm:py-5">
            <h2 className="text-base font-medium text-gray-900 mb-3">
              Pré-visualização
            </h2>
            <div className="text-gray-900 border shadow-sm rounded-md min-h-16 w-full p-4 text-sm leading-3">
              {replaceByTags(text, true)}
            </div>
          </div>
        </div>
      </>
    );
  };

  const EmailEditContent = ({ baseModel, txtDefault }) => {
    const [thiscfg, setThisCfg] = React.useState(blankResult);
    const [emailSubject, set_emailSubject] = useState(txtDefault.email_subject);
    const [fieldTitle, set_fieldTitle] = useState(txtDefault.email_title);
    const [fieldDesc, set_fieldDesc] = useState(txtDefault.email_description);
    const [fieldButPrim, set_fieldButPrim] = useState(
      txtDefault.email_button_primary_text
    );
    const [fieldButSec, set_fieldButSec] = useState(
      txtDefault.email_button_secondary_text
    );

    useEffect(() => {
      if (reqOk(thiscfg)) {
        handleRefresh();
        setOpenso_email(false);
      }
    }, [thiscfg]);

    const handleSave = (content) => {
      postJson(
        pt.commconfig,
        {
          ...thiscfg,
          token: token,
          payload: {
            key: cfg.key,
            email_title: fieldTitle,
            email_description: fieldDesc,
            email_button_primary_text: fieldButPrim,
            email_button_secondary_text: fieldButSec,
            email_subject: emailSubject,
          },
        },
        setThisCfg
      );
    };

    return (
      <>
        <div className="">
          <div className="space-y-2 px-4 p-4  sm:space-y-0 sm:px-6 sm:py-5">
            <CommEditorEmailFields
              title={'Assunto'}
              text={emailSubject}
              setText={set_emailSubject}
              options={comm_model_vars}
            />
            <CommEditorEmailFields
              title={'Título'}
              text={fieldTitle}
              setText={set_fieldTitle}
              options={comm_model_vars}
            />
            <CommEditorEmailFields
              title={'Descrição'}
              rows={3}
              text={fieldDesc}
              setText={set_fieldDesc}
              options={comm_model_vars}
            />
            {txtDefault.email_button_primary_text != null &&
              txtDefault.email_button_primary_text != '' && (
                <CommEditorEmailFields
                  title={`Botão primário (${txtDefault.email_button_primary_text})`}
                  text={fieldButPrim}
                  setText={set_fieldButPrim}
                  options={[]}
                />
              )}

            {txtDefault.email_button_secondary_text != null &&
              txtDefault.email_button_secondary_text != '' && (
                <CommEditorEmailFields
                  title={`Botão secundário (${txtDefault.email_button_secondary_text})`}
                  text={fieldButSec}
                  setText={set_fieldButSec}
                  options={[]}
                />
              )}
          </div>

          <div className="flex items-center justify-between space-x-3 border-b border-gray-200 sm:px-3">
            <div className="flex"></div>
            <div className="flex-shrink-0 p-3">
              <button
                onClick={handleSave}
                type="submit"
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Guardar
              </button>
            </div>
          </div>

          <div className="px-4 p-4 sm:px-6 sm:py-5">
            <h2 className="text-base font-medium text-gray-900 mb-3">
              Pré-visualização
            </h2>
            <div className="border shadow-sm rounded-md min-h-12 w-full p-4 leading-3 mb-3">
              <span className="font-normal text-sm text-gray-800 ">
                Assunto
              </span>
              <span className="font-normal text-sm text-gray-500 ml-3">
                {replaceByTags(emailSubject, true)}
              </span>
            </div>
            <div className="text-gray-900 border shadow-sm rounded-md min-h-16 w-full p-4 text-sm leading-3">
              {previewEmail(baseModel, {
                title: fieldTitle,
                description: fieldDesc,
                primBut: fieldButPrim,
                secBut: fieldButSec,
              })}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <ContentSlideover
        open={openso_sms}
        setOpen={setOpenso_sms}
        data={{
          content: (
            <SmsEditContent
              txtDefault={cfg.sms_content == null ? '' : cfg.sms_content}
            />
          ),
          title: 'Editar',
          description: '',
        }}
      />

      <ContentSlideover
        open={openso_email}
        setOpen={setOpenso_email}
        data={{
          content: (
            <EmailEditContent
              baseModel={basehtml}
              txtDefault={cfg == null ? {} : cfg}
            />
          ),
          title: 'Editar',
          description: '',
        }}
      />

      <div>
        <h2 className="text-base font-semibold text-gray-900">SMS</h2>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <Switch.Group as="div" className="flex pt-6">
            <Switch.Label
              as="dt"
              className="w-64 flex-none pr-6 font-medium text-gray-900"
              passive
            >
              Ativar
            </Switch.Label>
            <dd className="flex flex-auto items-center justify-end">
              <Switch
                checked={smsEnabled}
                onChange={handleSetSms}
                className={classNames(
                  smsEnabled ? 'bg-indigo-600' : 'bg-gray-200',
                  'flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    smsEnabled ? 'translate-x-3.5' : 'translate-x-0',
                    'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </dd>
          </Switch.Group>

          <div className="pt-6 sm:flex sm:flex-col">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:mb-1">
              Conteúdo
              <button
                onClick={() => setOpenso_sms(true)}
                type="button"
                className="text-xs ml-2 font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Alterar
              </button>
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 border shadow-sm rounded-md min-h-16 min-w-full p-2 text-xs leading-3">
                {replaceByTags(cfg.sms_content == null ? '' : cfg.sms_content)}
              </div>
            </dd>
          </div>
        </dl>
      </div>

      <div>
        <h2 className="text-base font-semibold text-gray-900">Email</h2>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <Switch.Group as="div" className="flex pt-6">
            <Switch.Label
              as="dt"
              className="w-64 flex-none pr-6 font-medium text-gray-900"
              passive
            >
              Ativar
            </Switch.Label>
            <dd className="flex flex-auto items-center justify-end">
              <Switch
                checked={emailEnabled}
                onChange={handleSetEmail}
                className={classNames(
                  emailEnabled ? 'bg-indigo-600' : 'bg-gray-200',
                  'flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    emailEnabled ? 'translate-x-3.5' : 'translate-x-0',
                    'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </dd>
          </Switch.Group>

          <div className="pt-6 sm:flex sm:flex-col">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:mb-1">
              Conteúdo
              <button
                onClick={() => setOpenso_email(true)}
                type="button"
                className="text-xs ml-2 font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Alterar
              </button>
            </dt>

            <dd className="mt-1  gap-x-6 sm:mt-0">
              <div className="border shadow-sm rounded-md min-h-12 w-full p-4 leading-3 mb-3">
                <span className="font-normal text-sm text-gray-800 ">
                  Assunto
                </span>
                <span className="font-normal text-sm text-gray-500 ml-3">
                  {replaceByTags(cfg.email_subject, true)}
                </span>
              </div>
              <div className="text-gray-900 border shadow-sm rounded-md min-h-16 w-full p-4 text-sm leading-3">
                {previewEmail(basehtml, {
                  title: cfg == null ? '' : cfg.email_title,
                  description: cfg == null ? '' : cfg.email_description,
                  primBut: cfg == null ? '' : cfg.email_button_primary_text,
                  secBut: cfg == null ? '' : cfg.email_button_secondary_text,
                })}
              </div>

              {/* 
              <div className="text-gray-900 border shadow-sm rounded-md min-h-16 min-w-full p-2 text-xs leading-3">
                {replaceByTags(
                  cfg.email_content == null ? '' : cfg.email_content
                )}
              </div> */}
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
}
