import React, { useEffect, useState } from "react";

import { ResponsiveContainer, AreaChart, Area, Tooltip, CartesianGrid, XAxis, YAxis } from 'recharts';
import AnalyticsFilter from "../filter/AnalyticsFilter";
import { getJson, blankResult, reqOk, loadingScreen } from '../../common/fetchdata';
import { GlobalStateContext } from '../../state/globalState';

import { getRequests as gt } from '../../common/apiEndpoints';
import NoDataState from "../state/NoDataState";

import { rgbToHex, hexToRgb, generateGradient } from "../chartColors"

const PaymentsChart = ({ setRawData, filters, setFilters }) => {

    const [data, setData] = React.useState([]);
    const [pdata, setPdata] = React.useState(blankResult);
    const [breakdowns, setBreakdowns] = React.useState([]);
    const [gradient, setGradient] = React.useState([]);

    let endColor = "#00416A"; // Red
    let startColor = "#E4E5E6";   // Blue

    const _gc = React.useContext(GlobalStateContext);
    useEffect(() => {
        loadingScreen(pdata, _gc)
    }, [pdata]);


    useEffect(() => {
        if (Object.keys(filters).length > 0)
            getJson(gt.bi, {
                ...pdata, params: filters
            }, setPdata);
    }, [filters]);

    useEffect(() => {

        if (reqOk(pdata)) {

            let _data = []
            if (filters.series == 'standart') {

                if (filters.breakdown == 'total') {
                    pdata.items.data.filtered_data.map(d => _data = [..._data, { date: d.date, total_value: d.total_value / 100 }])
                    setRawData(_data)
                }
                else {
                    let transformedData = {};
                    let _breakDowns = []
                    let _rawData = []
                    pdata.items.data.filtered_data.forEach(entry => {
                        _rawData = [..._rawData, { ...entry, total_value: entry.total_value / 100 }]
                        _breakDowns = [..._breakDowns, entry.breakdown]
                        if (!transformedData[entry.date]) {
                            transformedData[entry.date] = {
                                date: entry.date
                            };
                        }
                        transformedData[entry.date][entry.breakdown] = entry.total_value / 100;
                    });
                    _breakDowns = [...new Set(_breakDowns)];

                    let _gradient = generateGradient(startColor, endColor, _breakDowns.length);

                    setGradient(_gradient)
                    setBreakdowns(_breakDowns)
                    _data = Object.values(transformedData);
                    setRawData(_rawData)
                }
            }
            if (filters.series == 'comparative') {

            }
            console.log(_data)
            setData(_data)
        }
    }
        , [pdata]);


    return (
        <>
            <AnalyticsFilter filters={filters} setFilters={setFilters} />

            {data.length == 0 ? <div className="pt-16">
                <NoDataState />
            </div>
                :
                <ResponsiveContainer width="100%" height="80%" >

                    {filters.breakdown == 'total' ?
                        <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <defs>
                                <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorValueSec" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="date" label={{ value: 'Date', position: 'bottom' }} />
                            <YAxis label={{ value: 'Total Value (€)', angle: -90, position: 'insideLeft' }} tickFormatter={(tick) => `€ ${tick / 1000}k`} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip formatter={(value) => [`€${value}`, 'Total Value']} />
                            <Area type="monotone" dataKey="total_value" stroke="#8884d8" fillOpacity={1} fill="url(#colorValue)" />
                        </AreaChart>
                        :
                        <AreaChart
                            width={500}
                            height={400}
                            data={data}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip formatter={(x, value) => [`${x} €${value}`, 'Valor']} />
                            {breakdowns.map((b, k) => (

                                <Area type="monotone" dataKey={b} stackId="1" stroke={`${gradient[k]}`} fill={gradient[k]} />
                            ))}

                        </AreaChart>
                    }


                </ResponsiveContainer>
            }
        </>
    );
};

export default PaymentsChart;
