import React, { useState, useRef } from 'react';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { PuzzlePieceIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const AddField = ({ options, handleInsertField }) => {
  const [selfield, setSelField] = useState(null);
  return (
    <>
      <Listbox
        as="div"
        value={selfield}
        onChange={setSelField}
        className="flex-shrink-0"
      >
        {({ open }) => (
          <>
            <Listbox.Label className="sr-only">Inserir campo</Listbox.Label>
            <div className="relative">
              <Listbox.Button className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                <PuzzlePieceIcon
                  className={classNames(
                    selfield === null ? 'text-gray-600' : 'text-gray-600',
                    'h-5 w-5 flex-shrink-0 sm:-ml-1'
                  )}
                  aria-hidden="true"
                />
                {/*      <span
                  className={classNames(
                    selfield === null ? '' : 'text-gray-800',
                    'hidden truncate sm:ml-2 sm:block'
                  )}
                >
                </span> */}
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute left-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((opt, k) => (
                    <Listbox.Option
                      onClick={() => handleInsertField(opt.field)}
                      key={k}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-amber-100 text-amber-900' : 'bg-white',
                          'relative cursor-default select-none px-3 py-2'
                        )
                      }
                      value={opt}
                    >
                      <div className="flex items-center">
                        <span className="block truncate">{opt.desc}</span>
                      </div>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
};

const CommEditorEmailFields = ({ title, text, setText, options, rows = 1 }) => {
  const textareaRef = useRef();
  const handleInsertField = (field) => {
    const position = textareaRef.current.selectionEnd;
    var output = [text.slice(0, position), field, text.slice(position)].join(
      ''
    );
    setText(output);
  };

  return (
    <>
      <div className="space-y-2 sm:space-y-0 sm:py-2">
        <h2 className="font-sm text-gray-900 mb-2">{title}</h2>
        <div
          className={`relative text-gray-900 border shadow-sm rounded-md w-full p-2 leading-3 ${
            options.length > 0 ? 'pl-16' : 'pl-3'
          }`}
        >
          <div className="min-w-0 flex-1">
            <label htmlFor="comment" className="sr-only">
              {title}
            </label>
            <textarea
              rows={rows}
              ref={textareaRef}
              name="comment"
              id="comment"
              className={`block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6`}
              placeholder={`${title}...`}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          {options.length > 0 && (
            <div className="absolute inset-x-0 bottom-2 left-3 flex justify-between w-6">
              <AddField
                options={options}
                handleInsertField={handleInsertField}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};


export default CommEditorEmailFields;
