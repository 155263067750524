
import { useState, useEffect } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import NewActiveCallError from './NewActiveCallError';
import { apiUrl } from '../../common/apiEndpoints';
import { postJson, blankResult, reqOk } from '../../common/fetchdata';

export default function NewActiveCall() {

  let navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { search } = location;
  const [pres, setPres] = useState(blankResult);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    // capture page load
    const newSearchParams = new URLSearchParams(searchParams);
    const customerId = newSearchParams.get('customerId');
    if (/^-?\d+$/.test(customerId)) {
      createNewCall(customerId)
    }
    else {
      setIsError(true)
    }
  }, [search])

  function createNewCall(customerId) {
    postJson(apiUrl.createcall, {
      ...pres, payload: {
        customerId: customerId
      }
    }, setPres)
  }

  useEffect(() => {
    if (reqOk(pres)) {
      const callId = 1011323
      navigate(`/call/${callId}`, { replace: true });
    }
    else {
      setIsError(true)
    }

  }, [pres])

  return (
    <>
      {
        isError
          ? <NewActiveCallError />
          : <div className="flex mt-32">
            <div className="m-auto">
              <div className="m-auto relative flex h-10 w-10">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-10 w-10 bg-sky-500"></span>
              </div>
              <div className="mt-8 text-center font-medium text-sky-400">
                A criar a consulta...
                <NewActiveCallError />
              </div>
            </div>
          </div>
      }

    </>

  )
}
