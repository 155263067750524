import React from 'react';
import { Transition } from '@headlessui/react';
import { Fragment } from 'react';

const SpeedDialMenu = ({ dialMenu }) => {
  const [show, setShow] = React.useState(false);
  const [showLabel, setshowLabel] = React.useState(false);

  return (
    <>
      <div
        data-dial-init=""
        className="fixed right-6 bottom-6 group z-10"
        onMouseLeave={() => setShow(false)}
      >
        <Transition
          show={show}
          enter="transition ease-out duration-250"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <div
            id="speed-dial-menu-default"
            className={`${
              show ? 'flex flex-col ' : 'hidden'
            } items-center mb-4 space-y-2`}
          >
            {' '}
            {dialMenu.map((m, k) => (
              <Fragment key={k}>
                <button
                  onClick={m.click}
                  onMouseEnter={() => setshowLabel(m.text)}
                  onMouseLeave={() => setshowLabel(false)}
                  type="button"
                  className="relative w-[52px] h-[52px] text-gray-500 bg-white rounded-full border border-gray-200 dark:border-gray-600 hover:text-gray-900 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400"
                >
                  {m.icon}
                  {showLabel == m.text && (
                    <span className="text-gray-600 absolute block mb-px text-xs font-medium -translate-y-1/2 -left-32 top-1/2 w-28 text-right uppercase leading-4">
                      {m.text}
                    </span>
                  )}
                </button>
              </Fragment>
            ))}
          </div>
        </Transition>

        <button
          onMouseEnter={() => setShow(true)}
          onClick={() => setShow(!show)}
          type="button"
          data-dial-toggle="speed-dial-menu-default"
          aria-controls="speed-dial-menu-default"
          aria-expanded="false"
          className="flex items-center justify-center text-white bg-blue-700 rounded-full w-14 h-14 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800"
        >
          <svg
            aria-hidden="true"
            className="w-8 h-8 transition-transform group-hover:rotate-45"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
          <span className="sr-only">Open actions menu</span>
        </button>
      </div>
    </>
  );
};

export default SpeedDialMenu;
