import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { GlobalStateContext } from '../../state/globalState';
import { reqOk } from '../../common/fetchdata';
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');


function getTimeBasedGreeting() {
    const currentHour = moment().hour();

    if (currentHour >= 0 && currentHour < 12) {
        return 'Bom dia';
    } else if (currentHour >= 12 && currentHour < 17) {
        return 'Boa tarde';
    } else {
        return 'Boa noite';
    }
}

export default function WelcomeCard({ }) {
    const globalcontext = React.useContext(GlobalStateContext);
    const [user, setUser] = React.useState({});
    const [selDate, setSelDate] = React.useState(moment());

    React.useEffect(() => {
        if (reqOk(globalcontext.userData)) {
            setUser(globalcontext.userData.items.user);
        }
    }, [globalcontext]);

    function gotoDay() {

    }

    return (
        <div className="lg:flex lg:items-center lg:justify-between border-b">
            <div className="min-w-0 flex-1 p-4">
                <h2 className="text-sm font-semibold leading-7 text-gray-400 sm:truncate sm:text-3xl sm:tracking-tight">
                    {`${getTimeBasedGreeting()}`}
                    <span className="pl-1 text-gray-800"> {user.fullname}</span>
                </h2>

            </div>
            <div className="mt-5 flex lg:ml-4 lg:mt-0 ">
                <div className="flex items-center text-gray-900">
                 {/*    <button
                        onClick={() => gotoDay(-1)}
                        type="button"
                        className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                    >
                        <span className="sr-only">Previous month</span>
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button> */}
                    <div className="px-4 flex-auto text-sm font-semibold">{moment(selDate).format('dddd, D MMMM YYYY')}</div>
                 {/*    <button
                        type="button"
                        onClick={() => gotoDay(1)}
                        className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                    >
                        <span className="sr-only">Next month</span>
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </button> */}
                </div>
            </div>
        </div>
    )
}