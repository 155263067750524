import React, { useEffect, useState } from 'react';
import { getRequests as gt } from '../apiEndpoints';
import {
    useReactTable,
    getCoreRowModel,
    flexRender
} from '@tanstack/react-table';
import {
    CalendarDaysIcon,
    CheckCircleIcon,
    PencilIcon,
    PlusIcon,
    XMarkIcon
} from '@heroicons/react/20/solid'


import Datepicker from 'react-tailwindcss-datepicker';
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

export default function TreatmentSelectTable({
    setTreatment,
    callContext
}) {

    const [savedElements, setSavedElements] = useState(getInitialTreatments());
    const [data, setData] = useState([]);
    const [rowSelection, setRowSelection] = React.useState({})
    const [sortSettings, setSortSettings] = useState({ id: null, direction: null });
    const [pageSettings, setPageSettings] = useState({ page: 1, entries: 20 });
    const [globalFilter, setGlobalFilter] = useState([]);
    const [displayOpt, setDisplayOpt] = useState({
        entries: 0,
        initIdx: 0,
        lastIdx: 0,
        maxPage: 0,
        nResults: 0,
        nextPage: 0,
        page: 0,
        prevPage: 0,
        totalResults: 0,
    });

    function getInitialTreatments() {
        return (callContext.get_call_treatments != null && callContext.get_call_treatments.length > 0) ?
            callContext.get_call_treatments.map(
                t => {
                    return {
                        ...t.treatment_type,
                        datetm: moment(t.datetm).format(),
                        obs: t.obs,
                        price: t.price,
                        price_counter: t.price_counter,
                        price_benef: t.price_benef,
                        existing_id: t.id
                    }
                }
            ) : []
    }

    useEffect(() => {
        setTreatment(savedElements)
    }, [savedElements])

    useEffect(() => {
        let gFilt = ''
        globalFilter.map(f => {
            gFilt = `${gFilt}${f.filterName}=${f.filterValue}&`
        })
        if (gFilt.length > 0)
            gFilt = gFilt.slice(0, -1)

        const url = `${gt.treatmenttype}?page=${pageSettings.page}&entries=${pageSettings.entries}` +
            `${gFilt != '' ? `&${gFilt}` : ''}`;

        fetch(url)
            .then(res => res.json())
            .then(responseData => {
                setDisplayOpt(responseData)
                setData(responseData.data); // Assuming server returns data in 'items' property
            });
    }, [globalFilter, sortSettings, pageSettings]);


    const columns = React.useMemo(
        () => [

            {
                accessorFn: row => row.code,
                id: 'code',
                label: 'code',
                canFilter: true,
                cell: info => info.getValue(),
                headerClass: 'w-1/5'
            },
            {
                accessorFn: row => row.description,
                id: 'description',
                label: 'description',
                canFilter: true,
                cell: info => info.getValue(),
                headerClass: 'w-3/5'
            },
            {
                accessorFn: row => row.price,
                id: 'price',
                label: 'price',
                canFilter: false,
                cell: info => info.getValue(),
                headerClass: ''
            },
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
        },
        enableRowSelection: true, //enable row selection for all rows
        // enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        debugTable: true,
    });

    function getFilterVal(id) {
        const _gf = globalFilter.filter(f => f.filterName == id)
        if (_gf.length == 0)
            return ''

        else
            return _gf[0].filterValue

    }

    function updateFilter(id, val) {
        if (val != '') {
            setGlobalFilter((f) => ([...f.filter(fx => fx.filterName != id),
            {
                filterName: id,
                filterValue: val
            }
            ]))
        }
        else {
            setGlobalFilter((f) => ([...f.filter(fx => fx.filterName != id)]))
        }
    }

    function selectItem(row) {

        if (savedElements.filter(i => i.id == row.original.id).length == 0)
            setSavedElements((s) => ([...s, {
                ...data.filter(i => i.id == row.original.id)[0],
                datetm: moment(callContext.datetm).format(),
                obs: ''
            }]))
    }

    return (
        <div className="inline-block min-w-full align-middle space-y-4">
            <SelectedItems savedElements={savedElements} setSavedElements={setSavedElements} />
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 table-fixed">

                    <thead className="bg-gray-50">

                        <tr>
                            {table.getHeaderGroups().map(headerGroup => (
                                headerGroup.headers.map(header => (
                                    <th
                                        key={header.id}
                                        className={`px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${header.column.columnDef.headerClass}`}
                                    >
                                        <div>
                                            <label htmlFor="filtLab" className="block text-sm font-medium leading-6 text-gray-900">
                                                {header.column.columnDef.label}
                                            </label>

                                            {header.column.columnDef.canFilter && <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name={header.id}
                                                    id={header.id}
                                                    className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                                                    placeholder={header.column.columnDef.label}
                                                    value={getFilterVal(header.id)}
                                                    onChange={(e) => updateFilter(header.id, e.target.value)}
                                                />
                                            </div>}
                                        </div>
                                    </th>
                                ))
                            ))}
                            <th />
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {table.getRowModel().rows.map(row => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <td
                                        key={cell.id}
                                        className="px-2 py-1 text-sm whitespace-nowrap"
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                                <td>
                                    {savedElements.filter(i => i.id == row.original.id).length > 0 ? <button
                                        onClick={() => {
                                            console.log(row.original.id)
                                        }}
                                        type="button"
                                        className="rounded-xl px-2 py-1 text-xs font-semibold text-emerald-600 "
                                    >
                                        <CheckCircleIcon className=" h-4 w-4" aria-hidden="true" />
                                    </button>
                                        :
                                        <button
                                            onClick={() => selectItem(row)}
                                            type="button"
                                            className="rounded-xl  px-2 py-1 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                        >
                                            <PlusIcon className=" h-4 w-4" aria-hidden="true" />
                                        </button>
                                    }
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>


            <div className="flex items-center gap-2">
                <button
                    className="border rounded p-1"
                    onClick={() => setPageSettings(prev => ({ ...prev, page: 1 }))}
                >
                    {'<<'}
                </button>
                <button
                    className="border rounded p-1"
                    onClick={() => setPageSettings(prev => ({ ...prev, page: prev.page - 1 }))}
                >
                    {'<'}
                </button>
                <button
                    className="border rounded p-1"
                    onClick={() => setPageSettings(prev => ({ ...prev, page: prev.page + 1 }))}
                >
                    {'>'}
                </button>
                <button
                    className="border rounded p-1"
                    onClick={() => setPageSettings(prev => ({ ...prev, page: table.getPageCount() - 1 }))}
                >
                    {'>>'}
                </button>

                {/* ... rest of your pagination rendering ...*/}
            </div>
            <div>{table.getPrePaginationRowModel().rows.length} Rows</div>
            <div>{`Página ${displayOpt.page} of ${displayOpt.maxPage}`}</div>

        </div>
    );
}
// title={flexRender(header.column.columnDef.header, header.getContext())}



function SelectedItems({ savedElements, setSavedElements }) {

    const handleDateChange = (e, id) => {
        const _dt = moment(e.startDate, 'YYYY-MM-DD').format()
        const updatedElements = savedElements.map(s => {
            if (s.id === id) {
                return {
                    ...s,
                    datetm: _dt
                };
            }
            return s;
        });
        setSavedElements(updatedElements);
    };

    
    const handleObsChange = (e, id) => {
        const updatedElements = savedElements.map(s => {
            if (s.id === id) {
                return {
                    ...s,
                    obs: e
                };
            }
            return s;
        });
        setSavedElements(updatedElements);
    };

    return (
        <ul
            role="list"
            className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
        >
            {savedElements.map((el) => (
                <li key={el.id} className="relative flex justify-between gap-x-6 px-4 py-2 hover:bg-gray-50 sm:px-6">
                    <div className="w-full flex min-w-0 gap-x-4">

                        <div className="w-full min-w-0 flex-auto">
                            <div className="text-sm font-semibold leading-6 text-gray-900">


                                {el.description}

                            </div>
                            <div className="mt-1 flex text-xs leading-5 text-gray-500">
                                {el.code}
                            </div>

                            <div className="flex flex-row ">
                                <div className="p-6 space-y-6 text-sm font-normal">
                                    <div>Data</div>
                                    <div>Detalhes</div>
                                </div>
                                <div className="">

                                    <div className="mt-2 flex text-sm leading-5 text-gray-500">


                                        <div className="mt-2 flex rounded-md shadow-sm w-full">

                                            <div className="w-64">

                                                <div className="w-full rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                                                    {moment(el.datetm).format('DD-MM-YYYY')}
                                                </div>

                                            </div>

                                            <div className="pl-2 h-full w-12">

                                                <span className="isolate inline-flex rounded-md shadow-sm">
                                                    <div
                                                        className="relative inline-flex items-center rounded-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                                    >

                                                        <Datepicker
                                                            i18n={'pt'}
                                                            asSingle={true}
                                                            name={`datePicker_${el.id}`}
                                                            id={`datePicker_${el.id}`}
                                                            maxDate={new Date()}
                                                            containerClassName="fixed"
                                                            inputClassName={`block w-8 h-8 border-none bg-transparent cursor-pointer pl-12 outline-0 ring-0`}
                                                            toggleClassName="hidden"
                                                            value={moment(el.datetm).format('DD/MM/YYYY')}
                                                            onChange={(e) => handleDateChange(e, el.id)}
                                                            displayFormat={'DD/MM/YYYY'}
                                                        />
                                                        <PencilIcon className="h-5 w-5 text-cyan-800" aria-hidden="true" />
                                                    </div>


                                                </span>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="w-full mt-2 flex text-sm leading-5 text-gray-500">

                                        <div className="w-full rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">

                                            <input
                                                type="text"
                                                name="info"
                                                id="info"
                                                className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                placeholder="Informações opcionais..."
                                                onChange={(e) => handleObsChange(e.target.value, el.id)}
                                                value={el.obs}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*  */}

                        </div>
                    </div>
                    <div className="flex shrink-0 items-center gap-x-4">
                        <div className="hidden sm:flex sm:flex-col sm:items-end">
                            <p className="text-sm leading-6 text-gray-900">{el.price}</p>
                            <p className="mt-1 text-xs leading-5 text-gray-500">
                                {el.price_benef}
                            </p>
                        </div>
                        <button
                            onClick={() => {
                                setSavedElements((s) => (s.filter(i => i.id != el.id)))
                            }}
                            type="button"
                            className="cursor-pointer rounded-xl  px-2 py-1 text-xs font-semibold text-red-600 shadow-sm hover:bg-red-100"
                        >
                            <XMarkIcon className="h-5 w-5 flex-none " aria-hidden="true" />
                        </button>
                    </div>
                </li>
            ))}
        </ul>
    )
}