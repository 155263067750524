import React, { Fragment } from 'react';
import FormSlideover from '../../common/slideover/FormSlideover';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, StarIcon } from '@heroicons/react/20/solid';
import { formfield_newcustomerinsurance as fin_new } from '../../forms/formfield_customer';
import { putRequests as pt } from '../../common/apiEndpoints';
import { putJson, blankResult, reqOk } from '../../common/fetchdata';
import useToken from '../../hooks/useToken';

//import { formfield_editcustomerinsurance as fin_edit } from '../../forms/formfield_customer';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function CustomerInsurances({
  profile,
  handleRefresh,
  mode = 'manage',
  optSel,
  setOptSel
}) {
  const [edit_formData, edit_setFormData] = React.useState({});
  const [openAdd, setOpenAdd] = React.useState(false);
  const [formKey, setFormKey] = React.useState(0);
  const [formTempl, setFormTempl] = React.useState(null);
  const [putreq, setPutreq] = React.useState(blankResult);
  const { token } = useToken();

  const handleAddNewItem = () => {
    setFormTempl(fin_new);
    setTimeout(() => {
      setOpenAdd(true);
    }, '200');
  };

  const handleEditItem = (item) => {
    setFormTempl(fin_new);
    setTimeout(() => {
      setOpenAdd(true);
    }, '200');
  };

  const edit_okHandler = () => {
    setFormKey((k) => k + 1);
    setOpenAdd(false);
    handleRefresh();
  };

  const edit_errHandler = () => {
    console.error('Error processing request');
    setFormKey((k) => k + 1);
    setOpenAdd(false);
  };

  const setDefaultInsurance = (insuranceId) => {
    putJson(
      pt.customeredit,
      {
        ...putreq,
        token: token,
        id: profile.id,
        params: { type: 'customer_setdefaultinsurance' },
        payload: { insuranceId: insuranceId },
      },
      setPutreq
    );
  };

  React.useEffect(() => {
    if (reqOk(putreq)) handleRefresh();
  }, [putreq]);

  return (
    <>
      {formTempl != null && (
        <FormSlideover
          context={profile.id}
          fin={formTempl}
          key={formKey}
          open={openAdd}
          setOpen={setOpenAdd}
          formData={edit_formData}
          setFormData={edit_setFormData}
          okHandler={edit_okHandler}
          errHandler={edit_errHandler}
        />
      )}

      <div className="mt-1 gap-2 flex flex-row flex-wrap w-full">
        {mode == 'select' && (
          <div
            onClick={() => {
              if (mode == 'select') setOptSel(0);
            }}
            className={`w-full relative flex items-center space-x-3 rounded-lg border border-gray-300   bg-white px-2 py-1.5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400 cursor-pointer text-sm font-normal text-gray-900 ${optSel == 0 ? 'ring-offset-2 ring-2' : ''
              }`}
          >
            Particular
          </div>
        )}
        {profile.customer_insurances
          .sort(function (x, y) {
            return x.is_default === y.is_default ? 0 : x ? -1 : 1;
          })
          .map((ins, k) => (
            <div
              onClick={() => {
                if (mode == 'select') setOptSel(ins.id);
              }}
              key={k}
              className={`relative flex items-center rounded-lg border border-gray-300 bg-white px-2 py-1.5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400 w-full   ${optSel == ins.id ? 'ring-offset-2 ring-2' : ''
                } ${mode == 'select' ? 'cursor-pointer' : ''}`}
            >
              <div className="flex-shrink-0">
                {ins.insurances.avatar_url != null && (
                  <img
                    className="h-10 w-10 rounded-full"
                    src={`../static/uploads/${ins.insurances.avatar_url}`}
                    alt=""
                  />
                )}
              </div>
              <div className="min-w-0 flex-1 ml-3">
                <div className="focus:outline-none">
                  <span className="text-sm font-normal text-gray-900 truncate">
                    {ins.insurances.name}
                  </span>
                  <span className="ml-2 truncate text-sm text-gray-500">{ins.num}</span>
                  <span className="ml-2 truncate text-sm text-gray-500">{ins.expire}</span>
                </div>
              </div>
              <div>
                {ins.is_default && (
                  <StarIcon className="h-6 w-6 text-amber-400" />
                )}
              </div>
              {mode == 'manage' && (
                <div>
                  <Menu as="div" className="relative ">
                    <Menu.Button className="inline-flex items-center rounded-md pt-1 px-1 text-xs font-semibold text-gray-900">
                      <ChevronDownIcon
                        className="-mr-1 h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              onClick={() => setDefaultInsurance(ins.id)}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Definir Principal
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-red-700'
                              )}
                            >
                              Eliminar
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}
            </div>
          ))}
        {mode == 'manage' && (
          <div
            onClick={handleAddNewItem}
            className="w-12 relative flex items-center space-x-3 rounded-lg border border-gray-300 border-dashed  bg-white px-3 py-2 shadow-sm hover:border-gray-400 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 pt-1"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12.462 20.87c-.153 .047 -.307 .09 -.462 .13a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3a12 12 0 0 1 .11 6.37"></path>
              <path d="M16 19h6"></path>
              <path d="M19 16v6"></path>
            </svg>
          </div>
        )}
      </div>
    </>
  );
}
