import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Datepicker from 'react-tailwindcss-datepicker';
import moment from 'moment-timezone'
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import 'moment/dist/locale/pt';
moment.locale('pt');
import { options, getDateSpanFromUrl, getOptionFromUrl, dateSorterArg, dateSorterArgs } from '../data/messagingSelect'
import MessagingCardFilter from './MessagingCardFilter';


export default function MessagingSlideoverFilter({
    open,
    setOpen,
    searchParams
}) {

    let navigate = useNavigate();


    function changeDateSpan(startDate, endDate) {

        const newSearchParams = new URLSearchParams(searchParams);
        const currConfig = dateSorterArg(getOptionFromUrl(searchParams).pivot)

        if (startDate != null && endDate != null) {
            const dateSpan = `${moment(startDate).format('YYYY-MM-DD')},${moment(endDate).format('YYYY-MM-DD')}`
            newSearchParams.set(currConfig, dateSpan); // Example: changing endDate
        }
        else {
            newSearchParams.delete(currConfig);
        }
        const newURL = `${location.pathname}?${newSearchParams.toString()}`;
        navigate(newURL, { replace: true });
    }




    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                    Filtrar
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="h-full flex flex-1 flex-col justify-between">
                                            <div className="h-full divide-y divide-gray-200 px-4 sm:px-6">
                                                <div className="flex  flex-col space-y-6 pb-5 pt-6 divide-y divide-gray-200 px-4 sm:px-6">



                                                  {/*   <div className="mt-2 p-4">
                                                        <legend className="text-sm font-medium leading-6 text-gray-900">Período</legend>
                                                        <div className="mt-2 space-y-4 h-8">
                                                            <Datepicker
                                                                i18n={'pt'}
                                                                name={'datefilter'}
                                                                id={'datefilter'}
                                                                placeholder={'Período'}
                                                                value={getDateSpanFromUrl(searchParams)}
                                                                onChange={(e) => changeDateSpan(e.startDate, e.endDate)}
                                                                displayFormat={'DD/MM/YYYY'}
                                                                showShortcuts={true}
                                                                containerClassName="fixed w-72 text-gray-700"
                                                            />
                                                        </div>

                                                    </div> */}

                                                    <div className="mt-2 p-4">
                                                        <MessagingCardFilter />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
