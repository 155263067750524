import React, { useState } from 'react';
import TabNavigation from '../nav/tabs/TabNavigation';
import PaymentsChart from './boards/PaymentsChart'
import FilterPanel from './panels/FilterPanel';


export default function AnalyticsHome() {

    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({})

    const tabs = [
        {
            title: 'Dados',
            idx: 0,
            content: 
            <PaymentsChart 
                setRawData={setData}
                filters={filters}
                setFilters={setFilters}
            />,
            disabled: false,
        },
    ];

    return (
        <>
            <FilterPanel
                filters={filters}
                data={data}
                content={<TabNavigation tabs={tabs} />}
            />
        </>
    )
}
