import { useState, useEffect } from 'react'
import { RadioGroup } from '@headlessui/react'
import SelectProvider from '../../common/combobox/SelectProvider';
import SelectServtype from '../../common/combobox/SelectServtype';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function CalendarFilterMode({ handleFetch }) {


    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedServType, setSelectedServType] = useState(null);
    const plans = [
        { name: 'Todos os médicos e especialidades', selection: null },
        {
            name: 'Médico', selection: <SelectProvider
                showTitle={false}
                sel={selectedProvider}
                setSel={setSelectedProvider}
            />
        },
        {
            name: 'Especialidade', selection: <SelectServtype
                showTitle={false}
                sel={selectedServType}
                setSel={setSelectedServType}
            />
        },
    ]

    const [selected, setSelected] = useState(plans[0])

    useEffect(() => {
        if (selectedProvider != null) handleFetch({ provider: selectedProvider });
    }, [selectedProvider]);



    return (
        <RadioGroup value={selected} onChange={setSelected}>
            <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
            <div className="space-y-4">
                {plans.map((plan) => (
                    <RadioGroup.Option
                        key={plan.name}
                        value={plan}
                        className={({ active }) =>
                            classNames(
                                active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                                'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
                            )
                        }
                    >
                        {({ active, checked }) => (
                            <>
                                <span className="flex items-center">
                                    <span className="flex flex-col text-sm">
                                        <RadioGroup.Label as="span" className="font-medium text-gray-900">
                                            {plan.name}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description as="span" className="text-gray-500 mt-2">


                                        </RadioGroup.Description>
                                    </span>
                                </span>
                                {plan.selection != null && <RadioGroup.Description
                                    as="span"
                                    className="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right w-8/12"
                                >
                                    <span className=" text-gray-900">{plan.selection}</span>
                                </RadioGroup.Description>
                                }
                                <span
                                    className={classNames(
                                        active ? 'border' : 'border-2',
                                        checked ? 'border-indigo-600' : 'border-transparent',
                                        'pointer-events-none absolute -inset-px rounded-lg'
                                    )}
                                    aria-hidden="true"
                                />
                            </>
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    )
}
