import React from 'react';
import { getRequests as get } from '../../common/apiEndpoints';
import {
  getJson,
  blankResult,
  reqOk,
  getFirst,
} from '../../common/fetchdata';
import { useState, useEffect } from 'react';
import FormSlideover from '../../common/slideover/FormSlideover';

import {
  formfield_org_name as fin_name,
} from '../../forms/formfield_organization';

const CommAdvancedConfig = ({ token }) => {
  const [getRes, setGetRes] = useState(blankResult);
  const [putreq, setPutreq] = useState(blankResult);
  const [content, setContent] = useState({
    address_city: '',
    address_line1: '',
    address_postcode: '',
    email: '',
    name: '',
  });
  const [formTempl, setFormTempl] = React.useState(null);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [formKey, setFormKey] = React.useState(0);
  const [edit_formData, edit_setFormData] = React.useState({});

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (reqOk(getRes)) {
      setContent(getFirst(getRes));
    }
  }, [getRes]);

  useEffect(() => {
    if (reqOk(putreq)) {
      fetchData();
    }
  }, [putreq]);

  const fetchData = (args = {}) => {
    getJson(get.orgdetails, { ...getRes, token: token }, setGetRes);
  };

  const editTemplate = (temp) => {
    setFormTempl(temp);
    setTimeout(() => {
      setOpenAdd(true);
    }, '200');
  };
  const edit_okHandler = () => {
    setFormKey((k) => k + 1);
    setOpenAdd(false);
    fetchData();
  };

  const edit_errHandler = () => {
    console.error('Error processing request');
    setFormKey((k) => k + 1);
    setOpenAdd(false);
  };


  return (
    <>
      {formTempl != null && (
        <FormSlideover
          fin={formTempl}
          key={formKey}
          open={openAdd}
          setOpen={setOpenAdd}
          formData={edit_formData}
          setFormData={edit_setFormData}
          okHandler={edit_okHandler}
          errHandler={edit_errHandler}
        />
      )}

      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none mb-12">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Email
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Configurações para envio de email
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Configurações SMTP
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{content.name}</div>
                <button
                  onClick={() => editTemplate(fin_name)}
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Alterar
                </button>
              </dd>
            </div>
            
           
           
          </dl>
        </div>

       

        

        
      </div>
    </>
  );
};

export default CommAdvancedConfig;
