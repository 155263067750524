export const countryCodes = [
  { desc: 'Portugal', value: 'PRT', dial_code: '+351' },
    { desc: 'Afghanistan', value: 'AFG', dial_code: '+004' },
    { desc: 'Åland Islands', value: 'ALA', dial_code: '+248' },
    { desc: 'Albania', value: 'ALB', dial_code: '+008' },
    { desc: 'Algeria', value: 'DZA', dial_code: '+012' },
    { desc: 'American Samoa', value: 'ASM', dial_code: '+016' },
    { desc: 'Andorra', value: 'AND', dial_code: '+020' },
    { desc: 'Angola', value: 'AGO', dial_code: '+024' },
    { desc: 'Anguilla', value: 'AIA', dial_code: '+660' },
    { desc: 'Antarctica', value: 'ATA', dial_code: '+010' },
    { desc: 'Antigua and Barbuda', value: 'ATG', dial_code: '+028' },
    { desc: 'Argentina', value: 'ARG', dial_code: '+032' },
    { desc: 'Armenia', value: 'ARM', dial_code: '+051' },
    { desc: 'Aruba', value: 'ABW', dial_code: '+533' },
    { desc: 'Australia', value: 'AUS', dial_code: '+036' },
    { desc: 'Austria', value: 'AUT', dial_code: '+040' },
    { desc: 'Azerbaijan', value: 'AZE', dial_code: '+031' },
    { desc: 'Bahamas', value: 'BHS', dial_code: '+044' },
    { desc: 'Bahrain', value: 'BHR', dial_code: '+048' },
    { desc: 'Bangladesh', value: 'BGD', dial_code: '+050' },
    { desc: 'Barbados', value: 'BRB', dial_code: '+052' },
    { desc: 'Belarus', value: 'BLR', dial_code: '+112' },
    { desc: 'Belgium', value: 'BEL', dial_code: '+056' },
    { desc: 'Belize', value: 'BLZ', dial_code: '+084' },
    { desc: 'Benin', value: 'BEN', dial_code: '+204' },
    { desc: 'Bermuda', value: 'BMU', dial_code: '+060' },
    { desc: 'Bhutan', value: 'BTN', dial_code: '+064' },
    {
      desc: 'Bolivia (Plurinational State of)',
      value: 'BOL',
      dial_code: '+068',
    },
    {
      desc: 'Bonaire, Sint Eustatius and Saba',
      value: 'BES',
      dial_code: '+535',
    },
    { desc: 'Bosnia and Herzegovina', value: 'BIH', dial_code: '+070' },
    { desc: 'Botswana', value: 'BWA', dial_code: '+072' },
    { desc: 'Bouvet Island', value: 'BVT', dial_code: '+074' },
    { desc: 'Brazil', value: 'BRA', dial_code: '+076' },
    { desc: 'British Indian Ocean Territory', value: 'IOT', dial_code: '+086' },
    { desc: 'Brunei Darussalam', value: 'BRN', dial_code: '+096' },
    { desc: 'Bulgaria', value: 'BGR', dial_code: '+100' },
    { desc: 'Burkina Faso', value: 'BFA', dial_code: '+854' },
    { desc: 'Burundi', value: 'BDI', dial_code: '+108' },
    { desc: 'Cabo Verde', value: 'CPV', dial_code: '+132' },
    { desc: 'Cambodia', value: 'KHM', dial_code: '+116' },
    { desc: 'Cameroon', value: 'CMR', dial_code: '+120' },
    { desc: 'Canada', value: 'CAN', dial_code: '+124' },
    { desc: 'Cayman Islands', value: 'CYM', dial_code: '+136' },
    { desc: 'Central African Republic', value: 'CAF', dial_code: '+140' },
    { desc: 'Chad', value: 'TCD', dial_code: '+148' },
    { desc: 'Chile', value: 'CHL', dial_code: '+152' },
    { desc: 'China', value: 'CHN', dial_code: '+156' },
    { desc: 'Christmas Island', value: 'CXR', dial_code: '+162' },
    { desc: 'Cocos (Keeling) Islands', value: 'CCK', dial_code: '+166' },
    { desc: 'Colombia', value: 'COL', dial_code: '+170' },
    { desc: 'Comoros', value: 'COM', dial_code: '+174' },
    { desc: 'Congo', value: 'COG', dial_code: '+178' },
    {
      desc: 'Congo, Democratic Republic of the',
      value: 'COD',
      dial_code: '+180',
    },
    { desc: 'Cook Islands', value: 'COK', dial_code: '+184' },
    { desc: 'Costa Rica', value: 'CRI', dial_code: '+188' },
    { desc: "Côte d'Ivoire", value: 'CIV', dial_code: '+384' },
    { desc: 'Croatia', value: 'HRV', dial_code: '+191' },
    { desc: 'Cuba', value: 'CUB', dial_code: '+192' },
    { desc: 'Curaçao', value: 'CUW', dial_code: '+531' },
    { desc: 'Cyprus', value: 'CYP', dial_code: '+196' },
    { desc: 'Czechia', value: 'CZE', dial_code: '+203' },
    { desc: 'Denmark', value: 'DNK', dial_code: '+208' },
    { desc: 'Djibouti', value: 'DJI', dial_code: '+262' },
    { desc: 'Dominica', value: 'DMA', dial_code: '+212' },
    { desc: 'Dominican Republic', value: 'DOM', dial_code: '+214' },
    { desc: 'Ecuador', value: 'ECU', dial_code: '+218' },
    { desc: 'Egypt', value: 'EGY', dial_code: '+818' },
    { desc: 'El Salvador', value: 'SLV', dial_code: '+222' },
    { desc: 'Equatorial Guinea', value: 'GNQ', dial_code: '+226' },
    { desc: 'Eritrea', value: 'ERI', dial_code: '+232' },
    { desc: 'Estonia', value: 'EST', dial_code: '+233' },
    { desc: 'Eswatini', value: 'SWZ', dial_code: '+748' },
    { desc: 'Ethiopia', value: 'ETH', dial_code: '+231' },
    { desc: 'Falkland Islands (Malvinas)', value: 'FLK', dial_code: '+238' },
    { desc: 'Faroe Islands', value: 'FRO', dial_code: '+234' },
    { desc: 'Fiji', value: 'FJI', dial_code: '+242' },
    { desc: 'Finland', value: 'FIN', dial_code: '+246' },
    { desc: 'France', value: 'FRA', dial_code: '+250' },
    { desc: 'French Guiana', value: 'GUF', dial_code: '+254' },
    { desc: 'French Polynesia', value: 'PYF', dial_code: '+258' },
    { desc: 'French Southern Territories', value: 'ATF', dial_code: '+260' },
    { desc: 'Gabon', value: 'GAB', dial_code: '+266' },
    { desc: 'Gambia', value: 'GMB', dial_code: '+270' },
    { desc: 'Georgia', value: 'GEO', dial_code: '+268' },
    { desc: 'Germany', value: 'DEU', dial_code: '+276' },
    { desc: 'Ghana', value: 'GHA', dial_code: '+288' },
    { desc: 'Gibraltar', value: 'GIB', dial_code: '+292' },
    { desc: 'Greece', value: 'GRC', dial_code: '+300' },
    { desc: 'Greenland', value: 'GRL', dial_code: '+304' },
    { desc: 'Grenada', value: 'GRD', dial_code: '+308' },
    { desc: 'Guadeloupe', value: 'GLP', dial_code: '+312' },
    { desc: 'Guam', value: 'GUM', dial_code: '+316' },
    { desc: 'Guatemala', value: 'GTM', dial_code: '+320' },
    { desc: 'Guernsey', value: 'GGY', dial_code: '+831' },
    { desc: 'Guinea', value: 'GIN', dial_code: '+324' },
    { desc: 'Guinea-Bissau', value: 'GNB', dial_code: '+624' },
    { desc: 'Guyana', value: 'GUY', dial_code: '+328' },
    { desc: 'Haiti', value: 'HTI', dial_code: '+332' },
    {
      desc: 'Heard Island and McDonald Islands',
      value: 'HMD',
      dial_code: '+334',
    },
    { desc: 'Holy See', value: 'VAT', dial_code: '+336' },
    { desc: 'Honduras', value: 'HND', dial_code: '+340' },
    { desc: 'Hong Kong', value: 'HKG', dial_code: '+344' },
    { desc: 'Hungary', value: 'HUN', dial_code: '+348' },
    { desc: 'Iceland', value: 'ISL', dial_code: '+352' },
    { desc: 'India', value: 'IND', dial_code: '+356' },
    { desc: 'Indonesia', value: 'IDN', dial_code: '+360' },
    { desc: 'Iran (Islamic Republic of)', value: 'IRN', dial_code: '+364' },
    { desc: 'Iraq', value: 'IRQ', dial_code: '+368' },
    { desc: 'Ireland', value: 'IRL', dial_code: '+372' },
    { desc: 'Isle of Man', value: 'IMN', dial_code: '+833' },
    { desc: 'Israel', value: 'ISR', dial_code: '+376' },
    { desc: 'Italy', value: 'ITA', dial_code: '+380' },
    { desc: 'Jamaica', value: 'JAM', dial_code: '+388' },
    { desc: 'Japan', value: 'JPN', dial_code: '+392' },
    { desc: 'Jersey', value: 'JEY', dial_code: '+832' },
    { desc: 'Jordan', value: 'JOR', dial_code: '+400' },
    { desc: 'Kazakhstan', value: 'KAZ', dial_code: '+398' },
    { desc: 'Kenya', value: 'KEN', dial_code: '+404' },
    { desc: 'Kiribati', value: 'KIR', dial_code: '+296' },
    {
      desc: "Korea (Democratic People's Republic of)",
      value: 'PRK',
      dial_code: '+408',
    },
    { desc: 'Korea, Republic of', value: 'KOR', dial_code: '+410' },
    { desc: 'Kuwait', value: 'KWT', dial_code: '+414' },
    { desc: 'Kyrgyzstan', value: 'KGZ', dial_code: '+417' },
    {
      desc: "Lao People's Democratic Republic",
      value: 'LAO',
      dial_code: '+418',
    },
    { desc: 'Latvia', value: 'LVA', dial_code: '+428' },
    { desc: 'Lebanon', value: 'LBN', dial_code: '+422' },
    { desc: 'Lesotho', value: 'LSO', dial_code: '+426' },
    { desc: 'Liberia', value: 'LBR', dial_code: '+430' },
    { desc: 'Libya', value: 'LBY', dial_code: '+434' },
    { desc: 'Liechtenstein', value: 'LIE', dial_code: '+438' },
    { desc: 'Lithuania', value: 'LTU', dial_code: '+440' },
    { desc: 'Luxembourg', value: 'LUX', dial_code: '+442' },
    { desc: 'Macao', value: 'MAC', dial_code: '+446' },
    { desc: 'Madagascar', value: 'MDG', dial_code: '+450' },
    { desc: 'Malawi', value: 'MWI', dial_code: '+454' },
    { desc: 'Malaysia', value: 'MYS', dial_code: '+458' },
    { desc: 'Maldives', value: 'MDV', dial_code: '+462' },
    { desc: 'Mali', value: 'MLI', dial_code: '+466' },
    { desc: 'Malta', value: 'MLT', dial_code: '+470' },
    { desc: 'Marshall Islands', value: 'MHL', dial_code: '+584' },
    { desc: 'Martinique', value: 'MTQ', dial_code: '+474' },
    { desc: 'Mauritania', value: 'MRT', dial_code: '+478' },
    { desc: 'Mauritius', value: 'MUS', dial_code: '+480' },
    { desc: 'Mayotte', value: 'MYT', dial_code: '+175' },
    { desc: 'Mexico', value: 'MEX', dial_code: '+484' },
    {
      desc: 'Micronesia (Federated States of)',
      value: 'FSM',
      dial_code: '+583',
    },
    { desc: 'Moldova, Republic of', value: 'MDA', dial_code: '+498' },
    { desc: 'Monaco', value: 'MCO', dial_code: '+492' },
    { desc: 'Mongolia', value: 'MNG', dial_code: '+496' },
    { desc: 'Montenegro', value: 'MNE', dial_code: '+499' },
    { desc: 'Montserrat', value: 'MSR', dial_code: '+500' },
    { desc: 'Morocco', value: 'MAR', dial_code: '+504' },
    { desc: 'Mozambique', value: 'MOZ', dial_code: '+508' },
    { desc: 'Myanmar', value: 'MMR', dial_code: '+104' },
    { desc: 'Namibia', value: 'NAM', dial_code: '+516' },
    { desc: 'Nauru', value: 'NRU', dial_code: '+520' },
    { desc: 'Nepal', value: 'NPL', dial_code: '+524' },
    { desc: 'Netherlands', value: 'NLD', dial_code: '+528' },
    { desc: 'New Caledonia', value: 'NCL', dial_code: '+540' },
    { desc: 'New Zealand', value: 'NZL', dial_code: '+554' },
    { desc: 'Nicaragua', value: 'NIC', dial_code: '+558' },
    { desc: 'Niger', value: 'NER', dial_code: '+562' },
    { desc: 'Nigeria', value: 'NGA', dial_code: '+566' },
    { desc: 'Niue', value: 'NIU', dial_code: '+570' },
    { desc: 'Norfolk Island', value: 'NFK', dial_code: '+574' },
    { desc: 'North Macedonia', value: 'MKD', dial_code: '+807' },
    { desc: 'Northern Mariana Islands', value: 'MNP', dial_code: '+580' },
    { desc: 'Norway', value: 'NOR', dial_code: '+578' },
    { desc: 'Oman', value: 'OMN', dial_code: '+512' },
    { desc: 'Pakistan', value: 'PAK', dial_code: '+586' },
    { desc: 'Palau', value: 'PLW', dial_code: '+585' },
    { desc: 'Palestine, State of', value: 'PSE', dial_code: '+275' },
    { desc: 'Panama', value: 'PAN', dial_code: '+591' },
    { desc: 'Papua New Guinea', value: 'PNG', dial_code: '+598' },
    { desc: 'Paraguay', value: 'PRY', dial_code: '+600' },
    { desc: 'Peru', value: 'PER', dial_code: '+604' },
    { desc: 'Philippines', value: 'PHL', dial_code: '+608' },
    { desc: 'Pitcairn', value: 'PCN', dial_code: '+612' },
    { desc: 'Poland', value: 'POL', dial_code: '+616' },
    { desc: 'Puerto Rico', value: 'PRI', dial_code: '+630' },
    { desc: 'Qatar', value: 'QAT', dial_code: '+634' },
    { desc: 'Réunion', value: 'REU', dial_code: '+638' },
    { desc: 'Romania', value: 'ROU', dial_code: '+642' },
    { desc: 'Russian Federation', value: 'RUS', dial_code: '+643' },
    { desc: 'Rwanda', value: 'RWA', dial_code: '+646' },
    { desc: 'Saint Barthélemy', value: 'BLM', dial_code: '+652' },
    {
      desc: 'Saint Helena, Ascension and Tristan da Cunha',
      value: 'SHN',
      dial_code: '+654',
    },
    { desc: 'Saint Kitts and Nevis', value: 'KNA', dial_code: '+659' },
    { desc: 'Saint Lucia', value: 'LCA', dial_code: '+662' },
    { desc: 'Saint Martin (French part)', value: 'MAF', dial_code: '+663' },
    { desc: 'Saint Pierre and Miquelon', value: 'SPM', dial_code: '+666' },
    {
      desc: 'Saint Vincent and the Grenadines',
      value: 'VCT',
      dial_code: '+670',
    },
    { desc: 'Samoa', value: 'WSM', dial_code: '+882' },
    { desc: 'San Marino', value: 'SMR', dial_code: '+674' },
    { desc: 'Sao Tome and Principe', value: 'STP', dial_code: '+678' },
    { desc: 'Saudi Arabia', value: 'SAU', dial_code: '+682' },
    { desc: 'Senegal', value: 'SEN', dial_code: '+686' },
    { desc: 'Serbia', value: 'SRB', dial_code: '+688' },
    { desc: 'Seychelles', value: 'SYC', dial_code: '+690' },
    { desc: 'Sierra Leone', value: 'SLE', dial_code: '+694' },
    { desc: 'Singapore', value: 'SGP', dial_code: '+702' },
    { desc: 'Sint Maarten (Dutch part)', value: 'SXM', dial_code: '+534' },
    { desc: 'Slovakia', value: 'SVK', dial_code: '+703' },
    { desc: 'Slovenia', value: 'SVN', dial_code: '+705' },
    { desc: 'Solomon Islands', value: 'SLB', dial_code: '+090' },
    { desc: 'Somalia', value: 'SOM', dial_code: '+706' },
    { desc: 'South Africa', value: 'ZAF', dial_code: '+710' },
    {
      desc: 'South Georgia and the South Sandwich Islands',
      value: 'SGS',
      dial_code: '+239',
    },
    { desc: 'South Sudan', value: 'SSD', dial_code: '+728' },
    { desc: 'Spain', value: 'ESP', dial_code: '+724' },
    { desc: 'Sri Lanka', value: 'LKA', dial_code: '+144' },
    { desc: 'Sudan', value: 'SDN', dial_code: '+729' },
    { desc: 'Suriname', value: 'SUR', dial_code: '+740' },
    { desc: 'Svalbard and Jan Mayen', value: 'SJM', dial_code: '+744' },
    { desc: 'Sweden', value: 'SWE', dial_code: '+752' },
    { desc: 'Switzerland', value: 'CHE', dial_code: '+756' },
    { desc: 'Syrian Arab Republic', value: 'SYR', dial_code: '+760' },
    { desc: 'Taiwan, Province of China', value: 'TWN', dial_code: '+158' },
    { desc: 'Tajikistan', value: 'TJK', dial_code: '+762' },
    { desc: 'Tanzania, United Republic of', value: 'TZA', dial_code: '+834' },
    { desc: 'Thailand', value: 'THA', dial_code: '+764' },
    { desc: 'Timor-Leste', value: 'TLS', dial_code: '+626' },
    { desc: 'Togo', value: 'TGO', dial_code: '+768' },
    { desc: 'Tokelau', value: 'TKL', dial_code: '+772' },
    { desc: 'Tonga', value: 'TON', dial_code: '+776' },
    { desc: 'Trinidad and Tobago', value: 'TTO', dial_code: '+780' },
    { desc: 'Tunisia', value: 'TUN', dial_code: '+788' },
    { desc: 'Turkey', value: 'TUR', dial_code: '+792' },
    { desc: 'Turkmenistan', value: 'TKM', dial_code: '+795' },
    { desc: 'Turks and Caicos Islands', value: 'TCA', dial_code: '+796' },
    { desc: 'Tuvalu', value: 'TUV', dial_code: '+798' },
    { desc: 'Uganda', value: 'UGA', dial_code: '+800' },
    { desc: 'Ukraine', value: 'UKR', dial_code: '+804' },
    { desc: 'United Arab Emirates', value: 'ARE', dial_code: '+784' },
    {
      desc: 'United Kingdom of Great Britain and Northern Ireland',
      value: 'GBR',
      dial_code: '+826',
    },
    { desc: 'United States of America', value: 'USA', dial_code: '+840' },
    {
      desc: 'United States Minor Outlying Islands',
      value: 'UMI',
      dial_code: '+581',
    },
    { desc: 'Uruguay', value: 'URY', dial_code: '+858' },
    { desc: 'Uzbekistan', value: 'UZB', dial_code: '+860' },
    { desc: 'Vanuatu', value: 'VUT', dial_code: '+548' },
    {
      desc: 'Venezuela (Bolivarian Republic of)',
      value: 'VEN',
      dial_code: '+862',
    },
    { desc: 'Viet Nam', value: 'VNM', dial_code: '+704' },
    { desc: 'Virgin Islands (British)', value: 'VGB', dial_code: '+092' },
    { desc: 'Virgin Islands (U.S.)', value: 'VIR', dial_code: '+850' },
    { desc: 'Wallis and Futuna', value: 'WLF', dial_code: '+876' },
    { desc: 'Western Sahara', value: 'ESH', dial_code: '+732' },
    { desc: 'Yemen', value: 'YEM', dial_code: '+887' },
    { desc: 'Zambia', value: 'ZMB', dial_code: '+894' },
    { desc: 'Zimbabwe', value: 'ZWE', dial_code: '+716' },
  ];
  