import moment from 'moment-timezone'
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import 'moment/dist/locale/pt';
moment.locale('pt');



const reason = [
    { id: 'info-act-app', label: 'Informações sobre Consultas marcadas' },
    { id: 'info-chg-app', label: 'Alteração/Cancelamento de consulta' },
    { id: 'info-exa', label: 'Exames e análises Clínicas' },
    /*     { id: 'info-exa-snd', label:  'Enviar resultados de Exames' }, */
    { id: 'info-exa-req', label: 'Pedidos de resultados de exames' },
    { id: 'info-oth', label: 'Outros assuntos' }
]

export default function MessagingContactContent({ item }) {
    return (

        <div>
            <div className="sm:flex sm:items-baseline sm:justify-between">
                <h3 className="text-base font-medium">
                    <span className="text-gray-600">{
                        reason.filter(r => r.id == item.contact_reason).length > 0 ?
                            reason.filter(r => r.id == item.contact_reason)[0].label :
                            item.contact_reason
                    }</span>
                </h3>
                <p className="mt-1 whitespace-nowrap text-sm text-gray-600 sm:ml-3 sm:mt-0">
                    <time dateTime={item.created_at}>{`${moment(item.created_at).format('DD/MM/YYYY H:mm')}`}<span className="text-xs text-gray-700 pl-2">{`${moment(item.created_at).fromNow()}`}</span></time>
                </p>
            </div>

            <div className="mt-6 text-xs">
                <dl className="grid grid-cols-1 md:grid-cols-5 sm:grid-cols-3">
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Paciente</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-600 sm:mt-2">{item.customers ? item.customers.fullname : `${item.first_name} ${item.last_name}`}</dd>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2"><span className="pr-2 font-normal">NIF</span>{item.customers ? item.customers.tax_id_ref : `${item.tax_id_ref}`}</dd>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{item.email ? item.email : ``}</dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-4 sm:px-0">
                        <span className="text-sm text-gray-900 pr-2 font-medium">Notas:</span><span className="text-sm text-gray-800">{item.notes}</span>
                    </div>
                </dl>

            </div>
        </div>
    )
}





