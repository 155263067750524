
 export function removeUnusedCriteria(fObj) {
    let fdef = {};
    Object.keys(fObj).forEach(function(key) {
        // key: the name of the object key
        // index: the ordinal position of the key within the object 
        if (fObj[key] !== '') {
            fdef[key] = fObj[key];
          }
    });
    return fdef;
  }