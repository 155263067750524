import React from 'react';

import TableEngineTw from '../../common/table/TableEngineTw';
import { GlobalStateContext } from '../../state/globalState';
import { reqOk } from '../../common/fetchdata';
import { tableconfig as tbcfg } from '../../common/configs/tableconfig_customerweb';

const CustomerWebListing = () => {
  const globalcontext = React.useContext(GlobalStateContext);

  return (
    <>
      <TableEngineTw
        cfg={tbcfg}
        items={
          reqOk(globalcontext.contextFcn.customerWeb.data) &&
          globalcontext.contextFcn.customerWeb.data.items
        }
        fetchData={globalcontext.contextFcn.customerWeb.fetch}
      />
    </>
  );
};

export default CustomerWebListing;
