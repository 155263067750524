import React, { useEffect } from 'react';
import { Fragment } from 'react';

export default function RadioSelectModule({
  field,
  formData,
  setFormData,
  formVal,
  setFormVal }) {
  useEffect(() => {
    if (formData[field.name] == '') {
      setFormData({
        ...formData,
        [field.name]: {
          sel: null,
          selLabel: '',
        },
      });
    }
  }, []);



  // initialize
  useEffect(() => {
    setFormVal((f) => ({
      ...f, [field.name]: {
        ...formVal[field.name],
        isTouched: false,
        isValid: isValid(formData[field.name])
      }
    }))
  }, [])


  // on update
  useEffect(() => {
    if (formData[field.name] !== '')
      setFormVal((f) => ({
        ...f, [field.name]: {
          ...formVal[field.name],
          isTouched: true,
          isValid: isValid(formData[field.name])
        }
      }))
  }, [formData[field.name]])

  function isValid(dt) {
    if (!field.required) return true
    else return formData[field.name].sel != null
  }

  return (
    <>
      <fieldset>
        <div className="mt-6 space-y-6">
          {field.fields.map((f, k) => (
            <div key={k} className="flex items-center gap-x-3">
              <input
                id={f.id}
                name={field.name}
                checked={
                  typeof formData[field.name] === 'object' &&
                    formData[field.name] !== null &&
                    formData[field.name].sel == f.id
                    ? true
                    : false
                }
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [field.name]: { sel: f.id, selLabel: f.label },
                  })
                }
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                id={f.id}
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {f.label}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
      {
        (formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) &&
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {'validationerr' in field ? field.validationerr : 'Escolha uma opção'}
        </p>

      }
    </>
  );
}
