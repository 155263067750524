import React, { Fragment } from 'react';
import RoundAvatar from '../../common/card/RoundAvatar';
import NavUserActionMenu from '../NavUserActionMenu';
import { GlobalStateContext } from '../../state/globalState';
import NavNotifications from '../notification/NavNotifications'

import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';


export default function NavHeader({ user }) {
  const globalcontext = React.useContext(GlobalStateContext);


  return (
    <>

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <div className="relative flex flex-1">
          {globalcontext.contextFcn.userData.canReadResource("search") &&
            <>
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                type="search"
                name="search"
                autoFocus="autofocus"
                placeholder="Procurar..."
                autoComplete="off"
                value={globalcontext.contextFcn.panel.search.str}
                onChange={(e) => {
                  globalcontext.contextFcn.panel.search.setStr(e.target.value)
                }}
                onClick={(e) => { globalcontext.contextFcn.panel.search.open() }}
              />
            </>}
        </div>
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <NavNotifications />


          {/* Separator */}
          <div
            className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
            aria-hidden="true"
          />

          {/* Profile dropdown */}

          <div className="relative">
            <NavUserActionMenu
              content={
                <div className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">Abrir menu utilizador</span>

                  <RoundAvatar
                    size={2}
                    avatarUrl={user != null && user.avatar_url_sm}
                    firstName={user != null && user.name}
                    lastName={user != null && user.lastname}
                  />
                  <span className="hidden lg:flex lg:items-center">
                    <span
                      className="ml-4 text-sm font-semibold leading-6 text-gray-900 truncate"
                      aria-hidden="true"
                    >
                      {`${user.name}`}
                    </span>
                    <ChevronDownIcon
                      className="ml-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
