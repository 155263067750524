import React, { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid';
import { usePopper } from 'react-popper'
import {
    ArrowPathIcon,
    ChartPieIcon,
    CursorArrowRaysIcon,
    FingerPrintIcon,
    SquaresPlusIcon,
} from '@heroicons/react/24/outline';


const callsToAction = [
    { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
    { name: 'Contact sales', href: '#', icon: PhoneIcon },
];

export default function AvatarBubble({ buttonContent }) {


    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom', // or your desired placement
        strategy: 'fixed', // or 'absolute' depending on your requirement
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    boundary: 'viewport',
                },
            },
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['top', 'right', 'left'], // other placements to flip to
                },
            },
        ],
    });

    return (
        <Popover className="relative">
            <Popover.Button>{buttonContent}</Popover.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className="z-10 mt-5 flex w-screen max-w-max"
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                >
                    <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                        <div className="p-4">
                           
                           Content
                        </div>
                        <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                            {callsToAction.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                                >
                                    <item.icon
                                        className="h-5 w-5 flex-none text-gray-400"
                                        aria-hidden="true"
                                    />
                                    {item.name}
                                </a>
                            ))}
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
}
