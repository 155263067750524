import React from 'react';
import { getRequests as gt, putRequests as pu } from '../common/apiEndpoints';
import { countryCodes } from '../label/countrycodes';
import { afilliations } from '../label/customer';
import {
  phoneDesc
} from '../label/phoneDesc';


const rows = [
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'name',
    title: 'Nome',
    placeholder: 'Primeiro nome...',
    id: 'newcustomer__name',
    map: true,
    validation: (f) => f != null && f.length > 0,
    validationerr: 'Nome Inválido.',
  },
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'lastname',
    title: 'Último Nome',
    placeholder: 'Último nome...',
    id: 'newcustomer__lastname',
    map: true,
    validation: (f) => f != null && f.length > 0,
    validationerr: 'Nome Inválido.',
  },
  {
    selector: 'select',
    required: true,
    options: [
      { value: 'F', desc: 'Feminino' },
      { value: 'M', desc: 'Masculino' },
      { value: 'U', desc: 'Indefinido' },
    ],
    name: 'gender',
    title: 'Género',
    placeholder: 'Escolha',
    id: 'newcustomer__gender',
    fieldTitle: 'desc',
    noselectTxt: 'Escolha...',
    map: true,
  },
  {
    selector: 'daterangepicker',
    required: true,
    selectType: 'single',
    allowPast: true,
    name: 'dateofbirth',
    title: 'Data de Nascimento',
    placeholder: 'Escolha',
    id: 'newcustomer__dateofbirth',
    map: true,
  },
  {
    selector: 'select',
    required: true,
    options: [
      { value: 'S', desc: 'Solteiro' },
      { value: 'C', desc: 'Casado' },
      { value: 'D', desc: 'Divorciado' },
      { value: 'V', desc: 'Viúvo' },
      { value: 'P', desc: 'Separado' },
    ],
    name: 'civil_state',
    title: 'Estado civil',
    placeholder: 'Escolha',
    id: 'newcustomer__civil_state',
    noselectTxt: 'Escolha...',
    fieldTitle: 'desc',
    map: true,
  },
  {
    selector: 'select',
    required: true,
    options: countryCodes,
    name: 'nationality',
    title: 'Nacionalidade',
    placeholder: 'Escolha',
    id: 'newcustomer__nationality',
    noselectTxt: 'Escolha...',
    fieldTitle: 'desc',
    map: true,
  },
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'tax_id_ref',
    title: 'NIF',
    placeholder: 'NIF Nacional',
    id: 'newcustomer__tax_id_ref',
    map: true,
    validation: (f) => f != null && f.length > 0,
    validationerr: 'NIF Inválido.',
  },
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'nid_id_ref',
    title: 'CC',
    placeholder: 'CC Nacional',
    id: 'newcustomer_nid_id_ref',
    map: true,
    validation: (f) => f != null && f.length > 0,
    validationerr: 'CC Inválido.',
  },
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'nhc_id_ref',
    title: 'Número SNS',
    placeholder: 'Número do documentos',
    id: 'newcustomer__nhc_id_ref',
    map: true,
    validation: (f) => f != null && f.length > 0,
    validationerr: 'Número SNS Inválido.',
  },
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'ss_id_ref',
    title: 'Número SS',
    placeholder: 'Segurança Social',
    id: 'newcustomer__ss_id_ref',
    map: true,
    validation: (f) => f != null && f.length > 0,
    validationerr: 'Número SS Inválido.',
  },
  {
    selector: 'select',
    required: false,
    options: [
      { value: 'P', desc: 'Passaporte' },
      { value: 'C', desc: 'Carta de Condução' },
      { value: 'D', desc: 'Documento Identificação' },
    ],
    title: 'Documento Alternativo',
    placeholder: 'Escolha',
    map: true,
    name: 'alt_id_type',
    id: 'newcustomer__alt_id_type',
    noselectTxt: 'Escolha...',
    fieldTitle: 'desc',
  },
  {
    selector: 'select',
    required: false,
    options: countryCodes,
    name: 'alt_id_country',
    title: 'País emissor',
    placeholder: 'Escolha',
    id: 'newcustomer__alt_id_country',
    noselectTxt: 'Escolha...',
    fieldTitle: 'desc',
    map: true,
  },
  {
    selector: 'input',
    required: false,
    type: 'text',
    name: 'alt_id_doc_num',
    title: 'Número',
    placeholder: 'Número documento',
    id: 'newcustomer__alt_id_doc_num',
    map: true,
    validation: (f) => f != null && f.length > 0,
    validationerr: 'Número de documento Inválido.',
  },
  {
    selector: 'input',
    required: true,
    class: 'col-12 col-sm-6',
    type: 'email',
    name: 'email',
    title: 'Email',
    placeholder: 'Email',
    id: 'newcustomer__email',
    map: true,
    validation: (f) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(f).toLowerCase()
      ),
    validationerr: 'Email inválido.',
  },
  {
    selector: 'stackedit',
    required: false,
    name: 'insurance',
    title: 'Seguros',
    id: 'insurance',
    map: true,
    stack: {
      title: 'name',
      subtitle: 'name',
      listItemView: (e) => {
        return (
          <>
            <span className="truncate font-medium">
              {e.insurance.name}
            </span>
            <span className="flex-shrink-0 text-gray-400">
              {e.insuranceid}
            </span>
          </>
        );
      },
      fields: [
        {
          selector: 'selectinsurance',
          required: true,
          name: 'insurance',
          title: 'Seguro',
          noselectTxt: ' Escolha...',
          id: 'insurance',
          map: true,
        },
        {
          selector: 'input',
          required: true,
          class: '',
          type: 'text',
          name: 'insurance__id',
          title: 'Id',
          placeholder: 'Número d...',
          id: 'insurance__id',
          map: true,
          validation: (f) => f != null && f.length > 0,
          validationerr: 'Nome Inválido.',
        },
      ],
    },
  },
  {
    selector: 'phoneinput',
    required: true,
    countryList: countryCodes,
    defaultCountry: 'PRT',
    placeholder: 'Escolha...',
    placeholderNum: '910000000',
    type: 'text',
    name: 'mobile_phone',
    title: 'Telemóvel',
    id: 'mobile_phone',
    map: true,
  }
];

const af_type_row =   {
  selector: 'select',
  title: 'Afiliação',
  name: 'affiliation',
  required: true,
  fieldTitle: 'desc',
  fieldSubTitle: null,
  noselectTxt: 'Escolha...',
  id: 'affiliation__afil',
  optionType: 'static',
  options: afilliations.filter(a => (a.value != 'MOTHER' &&  a.value !='FATHER')),
  map: true,
}

const af_type_row_disabled =   {
  selector: 'select',
  title: 'Afiliação',
  name: 'affiliation',
  required: true,
  disabled: true,
  fieldTitle: 'desc',
  fieldSubTitle: null,
  noselectTxt: 'Escolha...',
  id: 'affiliation__afil',
  optionType: 'static',
  options: afilliations,
  map: true,
}

const af_data_rows = [

  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'name',
    title: 'Nome',
    placeholder: 'Marco',
    id: 'affiliation__name',
    map: true,
    validation: (f) => f != null && f.length > 0,
    validationerr: 'Nome Inválido.',
  },
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'lastname',
    title: 'Apelidos',
    placeholder: 'Sousa',
    id: 'affiliation__lastname',
    map: true,
    validation: (f) => f != null && f.length > 0,
    validationerr: 'Nome Inválido.',
  },
  {
    selector: 'input',
    type: 'email',
    name: 'email',
    title: 'Email',
    placeholder: 'Email',
    id: 'affiliation__email',
    map: true,
    validation: (f) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(f).toLowerCase()
      ),
    validationerr: 'Email inválido.',
  },
  {
    selector: 'phoneinput',
    required: true,
    countryList: countryCodes,
    defaultCountry: 'PRT',
    placeholder: 'Escolha...',
    placeholderNum: '910000000',
    type: 'text',
    name: 'mobile_phone',
    title: 'Telemóvel',
    id: 'affiliation__mobile_phone',
    map: true,
  },
];



const phone_contact_rows = [
  {
    selector: 'select',
    title: 'Descrição',
    name: 'mobile_phone_des',
    required: true,
    fieldTitle: 'label',
    fieldSubTitle: null,
    noselectTxt: ' Escolha...',
    id: 'newcustomer__mobile_phone_desc',
    optionType: 'static',
    options: phoneDesc,
    map: true,
  },
  {
    selector: 'phoneinput',
    required: true,
    countryList: countryCodes,
    defaultCountry: 'PRT',
    placeholder: 'Escolha...',
    placeholderNum: '910000000',
    type: 'text',
    name: 'mobile_phone',
    title: 'Telemóvel',
    id: 'newcustomer__mobile_phone',
    map: true,
  },
];

const address_contact_rows = [
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'address_line1',
    title: 'Morada',
    placeholder: 'Nome da rua',
    id: 'address_contact_address_line1',
    map: true,
    validation: (f) => f != null && f.length > 0,
    validationerr: 'Morada Inválida.',
  },
  {
    selector: 'input',
    required: false,
    type: 'text',
    name: 'address_line2',
    title: 'Morada (detalhes)',
    placeholder: 'Número da porta/andar/ ...',
    id: 'address_contact_address_line2',
    map: true,
  },  
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'address_postcode',
    title: 'Código Postal',
    placeholder: '1900-000',
    id: 'address_contact_address_postcode',
    map: true,
    validation: (f) => f.length > 3,
    validationerr: 'Código postal inválido',
  },
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'address_city',
    title: 'Cidade',
    placeholder: 'Lisboa',
    id: 'address_contact_address_city',
    map: true,
    validation: (f) => f.length > 2,
    validationerr: 'Cidade Inválida.',
  },
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'address_region',
    title: 'Região',
    placeholder: 'Lisboa',
    id: 'address_contact_address_region',
    map: true,
    validation: (f) => f.length > 2,
    validationerr: 'Região Inválida.',
  },
  {
    selector: 'select',
    fieldTitle: 'desc',
    fieldSubTitle: 'value',
    noselectTxt: ' Escolha...',
    required: true,
    optionType: 'static',
    options: countryCodes,
    name: 'address_country',
    title: 'País',
    id: 'address_contact_address_country',
    map: true,
  },
];

const email_contact_rows = [
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'email',
    title: 'Email',
    placeholder: 'joao@medzi.io',
    id: 'email',
    map: true,
    validation: (f) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(f).toLowerCase()
      ),
    validationerr: 'Email inválido.',
  },
]

export const formfield_newcustomer = {
  title: 'Novo Paciente',
  description: 'Adicionar um novo paciente',
  apiEndpoint: '/api/customer',
  method: 'POST',
  preloadForm: false,
  childFetch: [
    { key: 'insurance', endpoint: gt.insurance },
    { key: 'spaces', endpoint: gt.spaces },
  ],
  name: '',
  id: '',
  submitLabel: 'Adicionar',
  rows: rows,
};

export const formfield_editcustomer = {
  title: 'Editar Espaço',
  description: 'Edite as características do espaço',
  apiEndpoint: '/api/customers',
  method: 'PUT',
  preloadForm: true,
  childFetch: [{ key: 'insurance', endpoint: gt.insurance }],
  name: '',
  id: '',
  submitLabel: 'Editar',
  deleteAction: {
    label: 'Eliminar',
  },
  rows: rows,
};

export const formfield_newcustomerinsurance = {
  title: 'Novo Seguro',
  description: 'Adicionar seguros ou subsistemas',
  apiEndpoint: pu.customeredit,
  method: 'PUT',
  preloadForm: false,
  reqArg: { type: 'customer_newinsurance' },
  childFetch: [{ key: 'insurance', endpoint: gt.insurance }],
  name: '',
  id: '',
  submitLabel: 'Adicionar',
  rows: rows.filter((r) => ['insurance'].includes(r.name)),
};

export const formfield_editpersonaldata = {
  title: 'Alterar dados',
  description: '',
  apiEndpoint: pu.customeredit,
  method: 'PUT',
  preloadForm: true,
  reqArg: { type: 'customer_editpersonaldata' },
  name: '',
  id: '',
  submitLabel: 'Editar',
  rows: rows.filter((r) =>
    [
      'name',
      'lastname',
      'gender',
      'dateofbirth',
      'civil_state',
      'nationality',
      'tax_id_ref',
      'nid_id_ref',
      'nhc_id_ref',
      'ss_id_ref',
      'alt_id_doc_num',
      'alt_id_type',
      'alt_id_country',
    ].includes(r.name)
  ),
};

export const formfield_newafiliationdata ={
  title: 'Nova afiliação',
  description: '',
  apiEndpoint: pu.customermanager,
  method: 'POST',
  preloadForm: false,
  reqArg: { type: 'customer_editafiliationdata' },
  name: '',
  id: '',
  submitLabel: 'Editar',
  rows: [af_type_row, ...af_data_rows],
}

export const formfield_editafiliationdata = {
  title: 'Editar afiliação',
  description: '',
  apiEndpoint: pu.customermanager,
  method: 'PUT',
  preloadForm: true,
  reqArg: { type: 'customer_editafiliationdata' },
  name: '',
  id: '',
  submitLabel: 'Editar',
  rows: [af_type_row_disabled, ...af_data_rows],
};

export const formfield_newcontactdata = {
  title: 'Alterar dados',
  description: '',
  apiEndpoint: pu.customeredit,
  method: 'PUT',
  preloadForm: false,
  reqArg: { type: 'customer_newcontactdata' },
  name: '',
  id: '',
  submitLabel: 'Editar',
  rows: phone_contact_rows,
};

export const formfield_editaddressdata = {
  title: 'Alterar dados',
  description: '',
  apiEndpoint: pu.customeredit,
  method: 'PUT',
  preloadForm: false,
  reqArg: { type: 'customer_editaddressdata' },
  name: '',
  id: '',
  submitLabel: 'Editar',
  rows: address_contact_rows,
}

export const formfield_editemaildata = {
  title: 'Alterar dados',
  description: '',
  apiEndpoint: pu.customeredit,
  method: 'PUT',
  preloadForm: true,
  reqArg: { type: 'customer_editemaildata' },
  name: '',
  id: '',
  submitLabel: 'Editar',
  rows: email_contact_rows,
}