import React, { useState } from 'react';
import TableEngineTw from '../common/table/TableEngineTw';

import { reqOk, blankResult, getJson } from '../common/fetchdata';
import { getRequests as gt } from '../common/apiEndpoints';
import { tableconfig as tbcfg } from '../common/configs/tableconfig_servicesubtype';
import useToken from '../hooks/useToken';


const servicesubtypePage = () => {
  const { token } = useToken();
  const [getRes, setGetRes] = React.useState(blankResult);
  const fetchData = (args = {}) => {
    getJson(gt.servicesubtype, { ...getRes, token: token, params: args }, setGetRes);
  };


  return (
    <>
 
      <TableEngineTw
        cfg={tbcfg}
        items={reqOk(getRes) && getRes.items}
        fetchData={fetchData}
        refreshHandler={fetchData}
      />
    </>
  );
};

export default servicesubtypePage;
