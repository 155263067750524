import { Fragment, useEffect, useState } from 'react'
import { tzbe, rgxtz } from '../../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');
import { intToEurSign } from '../../../common/comjs'


export default function CallPaymentsSummaryCard({ billing }) {
    const [payments, setPayments] = useState([])

    useEffect(() => {
        if (billing != null && billing.length > 0) {
            let _paym = []
            billing.map(t => t.payments.map(p => {
                _paym = [..._paym, p]
            }))
            setPayments(_paym)
        }
    }, [billing])

    return (
        <div className="">
            {payments.length == 0 ?
                <div className="text-center">
                    <h3 className="mt-2 text-sm font-semibold text-gray-900">Sem dados</h3>
                    <p className="mt-1 text-sm text-gray-500">Não existem pagamentos registados.</p>
                    <div className="mt-6">
                    </div>
                </div>
                :
                <table className="w-full text-left">
                    <thead className="sr-only">
                        <tr>
                            <th>Pagamentos</th>
                            <th>Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((thisPaym) => (
                            <tr key={thisPaym.id}>
                                <td className="relative py-5 pr-6">
                                    <div className="flex gap-x-6">

                                        <div className="flex-auto">
                                            <div className="flex items-start gap-x-3">
                                                <div className="text-md font-medium leading-6 text-gray-900">
                                                    {intToEurSign(thisPaym.value)}
                                                </div>

                                                {thisPaym.paid ? <div className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                                                    Pago
                                                </div> :
                                                    <div className="rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-600/20">
                                                        Não pago
                                                    </div>
                                                }


                                            </div>
                                            <div className="mt-1 text-xs leading-5 text-gray-500"> {`Pagamento com ${thisPaym.payment_method}`}</div>
                                        </div>
                                    </div>
                                    <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                    <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                </td>
                                <td className="py-5 text-right">
                                    <div className="flex justify-end">
                                    <time dateTime="2023-01-31">{moment(thisPaym.datetm_payment).format('D MMM  YYYY, HH:mm')}</time>
                                    </div>

                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            }
        </div>
    )
}
