import React from 'react';
import { GlobalStateContext } from '../state/globalState';

import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

const SummaryCard = ({ data }) => {
  return (
    <>
      <div className="flex-grow pt-5 pl-5 pr-5 pb-3">
        <div className="flex justify-between items-start">
          <header>
            <div className="flex mb-2">
              <div className="mt-1 pr-1">
                <a
                  className="inline-flex text-gray-800 hover:text-gray-900"
                  href="#0"
                >
                  <h2 className="text-xl leading-snug justify-center font-semibold">
                    {`${data.customers_web.name} ${data.customers_web.lastname}`}
                  </h2>
                </a>
                <div className="flex items-center mt-2">
                  <span className="text-sm font-medium text-gray-400 -mt-0.5 mr-1">
                    {data.customers_web.is_online ? (
                      <span className="text-green-500">Online</span>
                    ) : (
                      <span>{`Offline - Último login ${moment(
                        data.customers_web.last_login
                      ).fromNow()}`}</span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </header>
          <div className="relative inline-flex flex-shrink-0">
            <button className="text-gray-400 hover:text-gray-500 rounded-full focus:outline-none focus-within:ring-2">
              <span className="sr-only">Menu</span>
              <svg
                className="w-8 h-8 fill-current"
                viewBox="0 0 32 32"
              >
                <circle cx={16} cy={16} r={2} />
                <circle cx={10} cy={16} r={2} />
                <circle cx={22} cy={16} r={2} />
              </svg>
            </button>
          </div>
        </div>
        <div className="text-sm text-gray-700">
          <div className="flex flex-row py-2 text-gray-900 whitespace-nowrap dark:text-white">
            <div className="relative text-xs mr-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-phone"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
              </svg>

              <span className="sr-only">Telemóvel</span>
              <div
                className={`absolute inline-flex items-center justify-center w-3 h-3 font-bold text-white border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900  ${
                  data.customers_web &&
                  (data.customers_web.phone_confirmed
                    ? 'bg-green-500'
                    : 'bg-red-500')
                }`}
              ></div>
            </div>
            <div className="ml-2">
              {data.customers_web &&
                `(+${data.customers_web.phone_cc}) ${data.customers_web.phone_loc}`}
            </div>
          </div>

          <div className="flex flex-row py-2 text-gray-900 whitespace-nowrap dark:text-white">
            <div className="relative text-xs mr-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-mail"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z"></path>
                <path d="M3 7l9 6l9 -6"></path>
              </svg>

              <span className="sr-only">Email</span>
              <div
                className={`absolute inline-flex items-center justify-center w-3 h-3 font-bold text-white border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900  ${
                  data.customers_web &&
                  (data.customers_web.email_confirmed
                    ? 'bg-green-500'
                    : 'bg-red-500')
                }`}
              ></div>
            </div>
            <div className="ml-2">
              {data.customers_web && `${data.customers_web.email}`}
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200">
        <div className="flex divide-x divide-gray-200r">
          <a
            className="block flex-1 text-center text-sm text-indigo-500 hover:text-indigo-600 font-medium px-3 py-4"
            href="#0"
          >
            <div className="flex items-center justify-center">
              {/*    <svg
                  className="w-4 h-4 fill-current flex-shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L8.9 12H8c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                </svg> */}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M3.43 2.524A41.29 41.29 0 0110 2c2.236 0 4.43.18 6.57.524 1.437.231 2.43 1.49 2.43 2.902v5.148c0 1.413-.993 2.67-2.43 2.902a41.202 41.202 0 01-5.183.501.78.78 0 00-.528.224l-3.579 3.58A.75.75 0 016 17.25v-3.443a41.033 41.033 0 01-2.57-.33C1.993 13.244 1 11.986 1 10.573V5.426c0-1.413.993-2.67 2.43-2.902z"
                  clipRule="evenodd"
                />
              </svg>
              <span>Enviar mensagem</span>
            </div>
          </a>
          {/*     <a
              className="block flex-1 text-center text-sm text-gray-600 hover:text-gray-800 font-medium px-3 py-4 group"
              href="#0"
            >
              <div className="flex items-center justify-center">
                <svg
                  className="w-4 h-4 fill-current text-gray-400 group-hover:text-gray-500 flex-shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
                </svg>
                <span>Edit Profile</span>
              </div>
            </a> */}
        </div>
      </div>
    </>
  );
};

const NotAvailableSummary = ({ data, globalcontext }) => {
  return (
    <>
      <div className="flex-grow pt-5 pl-5 pr-5 pb-3">
        <div className="flex justify-between items-start">
          <header>
            <div className="flex mb-2">
              <div className="mt-1 pr-1">
                <h2 className="text-md text-red-900 leading-snug justify-center font-semibold">
                  Utilizador não registado
                </h2>
              </div>
            </div>
          </header>
        </div>
        <div className="text-sm text-gray-700">
          <button
            onClick={() =>
              globalcontext.contextFcn.customerWeb.invite(
                data.id,
                'sms',
                data.contact_fields
              )
            }
            className="flex flex-row py-2 text-gray-900 whitespace-nowrap dark:text-white hover:bg-gray-100  rounded-sm p-2"
          >
            <div className="relative text-xs mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=""
                width={16}
                height={16}
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M11 3h10v8h-3l-4 2v-2h-3z"></path>
                <path d="M15 16v4a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1h2"></path>
                <path d="M10 18v.01"></path>
              </svg>

              <span className="sr-only">Telemóvel</span>
            </div>
            <div className="ml-1 text-xs">Convidar por SMS</div>
          </button>

          <button
            onClick={() =>
              globalcontext.contextFcn.customerWeb.invite(
                data.id,
                'email',
                data.contact_fields
              )
            }
            className="flex flex-row py-2 text-gray-900 whitespace-nowrap dark:text-white hover:bg-gray-100  rounded-sm p-2"
          >
            <div className="relative text-xs mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=""
                width={16}
                height={16}
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z"></path>
                <path d="M3 7l9 6l9 -6"></path>
              </svg>

              <span className="sr-only">Email</span>
            </div>
            <div className="ml-1 text-xs">Convidar por email</div>
          </button>
        </div>
      </div>
    </>
  );
};

export default function CustomerWebSummaryCard({ data, popup }) {
  const globalcontext = React.useContext(GlobalStateContext);

  return (
    <>
      <div
        className={`max-w-sm mx-auto bg-white ${
          popup ? 'shadow-lg' : ''
        } rounded-sm border border-gray-200`}
      >
        <div className="flex flex-col h-full">
          {data.customers_web ? (
            <SummaryCard data={data} />
          ) : (
            <NotAvailableSummary data={data} globalcontext={globalcontext} />
          )}
        </div>
      </div>
    </>
  );
}
