import React, { Fragment, useRef, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { XMarkIcon, CalendarIcon } from '@heroicons/react/20/solid';
import MonthCalendarSummary from './MonthCalendarSummary';
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

export default function MonthCalendarPopup({
  buttonContent,
  selDate,
  selDay,
  setSelDate,
  setSelDay,
  allowPast,
  providerId
}) {

  const popRef = useRef(null);

  useEffect(() => {
    popRef.current.click()
  },[selDay])

  return (
    <Popover className="relative">
      <Popover.Button ref={popRef}>{buttonContent}</Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute right-0 z-10 mt-5 flex w-screen max-w-max " >
  
            <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
              <div className="p-4">
                <MonthCalendarSummary
                  selDate={selDate}
                  selDay={selDay}
                  setSelDate={setSelDate}
                  setSelDay={setSelDay}
                  allowPast={allowPast}
                  providerId={providerId}
                />
              </div>
              <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                <a
                  href="#"
                  onClick={() => setSelDay(moment())}
                  className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                >
                  <CalendarIcon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                  Hoje
                </a>
                <Popover.Button className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100">
                  <XMarkIcon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                  Fechar
                </Popover.Button>
              </div>
            </div>
        
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
