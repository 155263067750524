import React, { useState } from 'react';
import TinyMceEditor from '../../../common/TinyMceEditor';
import {
  getRequests as gt,
  postRequests as pt,
} from '../../../common/apiEndpoints';
import {
  getJson,
  postJson,
  blankResult,
  reqOk,
} from '../../../common/fetchdata';
import { comm_email_field, replaceByTags } from '../../../label/comms';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { PuzzlePieceIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}



const EmailBaseModelEditor = ({ token }) => {
  //const [htmlContent, setHtmlContent] = React.useState(text);
  const [selfield, setSelField] = useState(null);
  const [text, setText] = useState(null);
  const editorRef = React.useRef(null);
  const [basehtml, set_basehtml] = React.useState(blankResult);
  const [basehtml_post, set_basehtml_post] = React.useState(blankResult);

  React.useEffect(() => {
    getJson(
      gt.globalconfig,
      { ...basehtml, token: token, params: { config: 'baseEmail' } },
      set_basehtml
    );
  }, []);

  React.useEffect(() => {
    if (reqOk(basehtml)) setText(basehtml.items.data);
  }, [basehtml]);

  const handleSaveChanges = () => {
    let txtcontent = '';
    if (editorRef.current) {
      txtcontent = editorRef.current.getContent();
      setText(txtcontent);
      postJson(
        pt.globalconfig,
        { ...basehtml_post, token: token, payload: { baseEmail: txtcontent } },
        set_basehtml_post
      );
    }
    //handleSave(txtcontent);
  };

  const handleInsertField = (field) => {
    editorRef.current.execCommand('mceInsertContent', false, field);
  };

  return (
    <div className="relative">
      <div className="overflow-hidden rounded-lg border border-gray-100 shadow-sm ">
        <TinyMceEditor
          content={text}
          editorRef={editorRef}
          height={300}
          toolbarOptions={'| code'}
        />
        <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <div>
            <Listbox
              as="div"
              value={selfield}
              onChange={setSelField}
              className="flex-shrink-0"
            >
              {({ open }) => (
                <>
                  <Listbox.Label className="sr-only">
                    Inserir campo
                  </Listbox.Label>
                  <div className="relative">
                    <Listbox.Button className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                      <PuzzlePieceIcon
                        className={classNames(
                          selfield === null ? 'text-gray-600' : 'text-gray-600',
                          'h-5 w-5 flex-shrink-0 sm:-ml-1'
                        )}
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          selfield === null ? '' : 'text-gray-800',
                          'hidden truncate sm:ml-2 sm:block'
                        )}
                      >
                        Inserir campo
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute bottom-0 left-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {comm_email_field.map((opt, k) => (
                          <Listbox.Option
                            onClick={() => handleInsertField(opt.field)}
                            key={k}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? 'bg-amber-100 text-amber-900'
                                  : 'bg-white',
                                'relative cursor-default select-none px-3 py-2'
                              )
                            }
                            value={opt}
                          >
                            <div className="flex items-center">
                              <span className="block truncate">{opt.desc}</span>
                            </div>
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
          <div className="flex-shrink-0">
            <button
              onClick={handleSaveChanges}
              type="submit"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>

      <div className="space-y-2 px-4 p-4  sm:space-y-0 sm:px-6 sm:py-5">
        <h2 className="text-base font-medium text-gray-900 mb-3">
          Pré-visualização
        </h2>
        <div className="text-gray-900 border shadow-sm rounded-md min-h-16 w-full p-4 text-sm leading-3">
          {text != null && replaceByTags(text, true)}
        </div>
      </div>
    </div>
  );
};

export default EmailBaseModelEditor;
