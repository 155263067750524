import React from 'react';
import { Listbox } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function PhoneInputModule({
  field,
  childDep,
  formData,
  setFormData,
}) {
  return (
    <>
      <div className="sm:col-span-2 grid grid-cols-4 w-full">
        <div className="col-span-2">
          <Listbox
            value={
              formData[field.name] != null &&
              formData[field.name] != '' &&
              'cc' in formData[field.name] &&
              formData[field.name].cc != null
                ? formData[field.name].cc
                : ''
            }
            onChange={(e) => {
              setFormData({
                ...formData,
                [field.name]: {
                  ...formData[field.name],
                  cc: e,
                },
              });
            }}
          >
            {({ open }) => (
              <>
                <div className="relative pr-3">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <span className="block truncate">
                      {formData[field.name] != null &&
                      formData[field.name] != '' &&
                      'cc' in formData[field.name] &&
                      formData[field.name].cc != null &&
                      formData[field.name].cc.value != null
                        ? field.countryList.filter(
                            (o) => o.value == formData[field.name].cc.value
                          )[0] != null
                          ? `(${
                              field.countryList.filter(
                                (o) => o.value == formData[field.name].cc.value
                              )[0].dial_code
                            }) ${
                              field.countryList.filter(
                                (o) => o.value == formData[field.name].cc.value
                              )[0].desc
                            }`
                          : ''
                        : field.placeholder}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {field.countryList.map((person) => (
                        <Listbox.Option
                          key={person.value}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'bg-indigo-600 text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={person}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                {`(${person.dial_code}) ${person.desc}`}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        <div className="col-span-2">
          <input
            type={field.type}
            name={field.name}
            placeholder={field.placeholderNum}
            id={field.id}
            onChange={(e) => {
              setFormData({
                ...formData,
                [field.name]: {
                  ...formData[field.name],
                  loc: e.target.value,
                },
              });
            }}
            className={`block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-white ${
              'validation' in field && formData[field.name] != null
                ? field.validation(formData[field.name])
                  ? 'is-valid is-valid-lite'
                  : ''
                : ''
            } `}
            value={
              formData[field.name] != null &&
              formData[field.name] != '' &&
              'loc' in formData[field.name] &&
              formData[field.name].loc != null
                ? formData[field.name].loc
                : ''
            }
          />
        </div>
      </div>
    </>
  );
}
