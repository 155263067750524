

import { postRequests as post } from '../common/apiEndpoints';
import axios from 'axios';



// Axios instance

/**


// Response interceptor
api.interceptors.response.use(
    response => response,   // Simply return the response if it's successful
    async error => {
        const originalRequest = error.config;

        // If the response status is 401 (Unauthorized) and the original request wasn't a refresh token request
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;  // Mark the request as retried
            //console.log('here')
            // Attempt to refresh the token
            try {
                // Make a request to the refresh token endpoint
                // Note: Adjust this to match the exact path of your refresh token endpoint
                const response = await api.post('/refresh', {}, {
                    withCredentials: true  // This ensures the request includes the HTTPOnly refresh token cookie
                });

                // If refresh was successful, update the access token in local storage
                localStorage.setItem('access_token', response.data.access_token);

                // Update the Authorization header with the new access token
                api.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;

                // Retry the original request with the updated access token
                return api(originalRequest);
            } catch (refreshError) {
                // Handle the error, for example by redirecting to a login page, or showing a message to the user
                console.error('Unable to refresh token', refreshError);
            }
        }

        // If the error was not 401 or the refresh token request also failed, reject the original request
        return Promise.reject(error);
    }
);

export default async function handleRefreshToken(token){


    const interval = setInterval(async () => {
        try {
            // Refresh the token
            const response = await api.post('/refresh', {}, {
                withCredentials: true  // This ensures the request includes the HTTPOnly refresh token cookie
            });

            // If refresh was successful, update the access token in local storage
            localStorage.setItem('access_token', response.data.access_token);

            // Update the Authorization header with the new access token
            api.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;

        } catch (error) {
            // Handle error, for example by logging out the user, or showing a message
            console.error('Error refreshing token', error);
        }
    }, 5 * 1000);  // 5 minutes in milliseconds

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
    

}

 */
export async function getRefreshToken() {

    // Set default configuration
    axios.defaults.xsrfCookieName = 'csrf_refresh_token';
    axios.defaults.xsrfHeaderName = 'X-CSRF-TOKEN';
    await axios.post(post.refreshToken, {
        data: {}
    }, {
        withCredentials: true  // This ensures the request includes the HTTPOnly refresh token cookie
    })
}


export default async function handleRefreshToken(token) {


    const interval = setInterval(async () => {

        await getRefreshToken()

    },
        1000 * 60 * 5
    );

    //clearInterval(interval); // thanks @Luca D'Amico

}