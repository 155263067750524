
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
import { useState, useEffect } from 'react';
moment.locale('pt');

import { AreaChart, Area, Line, Legend, Label, BarChart, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import { getJson, blankResult, reqOk } from '../common/fetchdata';
import { apiUrl } from '../common/apiEndpoints';
import { rgbToHex, hexToRgb, generateGradient } from "../analytics/chartColors"
import MessagingChartMenu from './MessagingChartMenu';


export default function MessagingCharts() {

  const [statsData, setStatsData] = useState(blankResult)
  const [historyData, setHistoryData] = useState({});

  const [data, setData] = useState([]);
  const [breakdowns, setBreakdowns] = useState([]);
  const [gradient, setGradient] = useState([]);
  const [filters, setFilters] = useState({
    nDays: 30,
    dataset: 'history'
  })


  const [chartSelected, setChartSelected] = useState('phoneCall')

  let endColor = "#075985"; // 
  let startColor = "#38BDF8";   // 


  useEffect(() => {
    if (reqOk(statsData)) {

      let _dataPhoneCalls = {}
      let _dataRequest = {}

      // Phone Calls
      const callIncConn = statsData.items.call_history.inbound_connected
      const callIncNotConn = statsData.items.call_history.inbound_not_connected
      const callOutConn = statsData.items.call_history.outbound_connected
      const callOutNotConn = statsData.items.call_history.outbound_not_connected
      let chartData = []
      callIncConn.forEach((c, i) => {
        chartData.push({
          name: c[0],
          callIncConnected: c[1],
          callIncNotConnected: callIncNotConn[i][1],
          callOutConnected: callOutConn[i][1],
          callOutNotConnected: callOutNotConn[i][1],
          pickupRate: Math.round(100*(c[1] / (c[1] + callIncNotConn[i][1])))
        })
      });
      _dataPhoneCalls.data = chartData
      //setData(chartData)

      let _breakDowns = [
        { axisId: 'primary', chartType: 'bar', key: 'callIncConnected', name: 'Recebidas' },
        { axisId: 'primary', chartType: 'bar', key: 'callIncNotConnected', name: 'Não atendidas' },
        { axisId: 'primary', chartType: 'bar', key: 'callOutConnected', name: 'Realizadas (atendidas)' },
        { axisId: 'primary', chartType: 'bar', key: 'callOutNotConnected', name: 'Realizadas (não atendidas)' },
        { axisId: 'secondary', chartType: 'line', key: 'pickupRate', name: 'Taxa de atendimento' }
      ]
      _dataPhoneCalls.breakDown = _breakDowns

      //setBreakdowns(_breakDowns)
      let _gradient = generateGradient(startColor, endColor, _breakDowns.length);
      _dataPhoneCalls.gradient = _gradient

      //setGradient(_gradient)

      // ## Booking Requests ##
      const reqBooking = statsData.items.requests_history.booking
      const reqInfo = statsData.items.requests_history.info
      chartData = []
      reqBooking.forEach((c, i) => {
        chartData.push({
          name: c[0],
          booking: c[1],
          info: reqInfo[i][1]
        })
      });
      _dataRequest.data = chartData
      _breakDowns = [
        { axisId: 'primary', chartType: 'bar', key: 'booking', name: 'Pedidos de Marcação' },
        { axisId: 'primary', chartType: 'bar', key: 'info', name: 'Pedidos informação/alteração' },
      ]
      _dataRequest.breakDown = _breakDowns

      //setBreakdowns(_breakDowns)
      _gradient = generateGradient(startColor, endColor, _breakDowns.length);
      _dataRequest.gradient = _gradient

      setHistoryData({
        phoneCall: _dataPhoneCalls,
        requests: _dataRequest
      })

    }
  }, [statsData])

  function handleRefreshData(filter) {
    getJson(apiUrl.messagingstats, { ...statsData, params: filter }, setStatsData)
  }

  function selectData() {
    setData(historyData[chartSelected].data)
    setBreakdowns(historyData[chartSelected].breakDown)
    setGradient(historyData[chartSelected].gradient)
  }

  useEffect(() => {
    handleRefreshData(filters)
  }, [])


  useEffect(() => {
    if (reqOk(statsData)) {
      selectData()
    }
  }, [chartSelected, historyData])


  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex h-80">
      <div className="p-3">
        <MessagingChartMenu chartSelected={chartSelected} setChartSelected={setChartSelected} />
      </div>
      <ResponsiveContainer className="w-full h-full">
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="primary" />
          {
            (breakdowns.map(b => b.axisId).indexOf("secondary") > -1) && <YAxis yAxisId="secondary" orientation="right"  tickFormatter={(tick) => `${tick}%`} domain={[0, 100]}/>
          }
          {/* 
          label={{ value: 'Total Value (€)', angle: -90, position: 'insideLeft' }} tickFormatter={(tick) => `€ ${tick / 1000}k`} />
           */}
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          {breakdowns.map((b, k) => {
            if (b.chartType == 'bar')
              return <Bar yAxisId={b.axisId} name={b.name} key={k} type="monotone" dataKey={b.key} stackId="1" stroke={`${gradient[k]}`} fill={gradient[k]} />
            if (b.chartType == 'line')
              return <Line yAxisId={b.axisId} type="monotone" name={b.name} key={k} dataKey={b.key} stroke={`${gradient[k]}`} strokeWidth="2"/>
          })}
        </ComposedChart >
      </ResponsiveContainer>
    </div >
  )
}
