import React from 'react';
import {
    CalendarDaysIcon,
    CreditCardIcon,
    UserCircleIcon,
} from '@heroicons/react/20/solid'
import { intToEurSign } from '../../../common/comjs'
import { tzbe, rgxtz } from '../../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

export default function CallPaymentCard({
    billing
}) {


    return (
        <>

            {billing.payments.length > 0 &&
                billing.payments.map((p, pk) =>
                    <div key={pk} className="lg:col-start-3 lg:row-end-1">
                        <h2 className="sr-only">Summary</h2>
                        <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                            <dl className="flex flex-wrap">
                                <div className="flex-auto pl-6 pt-6">
                                    <dt className="text-sm font-semibold leading-6 text-gray-900">Valor</dt>
                                    <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">{intToEurSign(p.value)}</dd>
                                </div>
                                <div className="flex-none self-end px-6 pt-4">
                                    <dt className="sr-only">Status</dt>
                                    {p.paid ? <dd className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                                        Pago
                                    </dd> :
                                        <dd className="rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-600/20">
                                            Não pago
                                        </dd>
                                    }
                                </div>
                                <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                    <dt className="flex-none">
                                        <span className="sr-only">Client</span>
                                        <UserCircleIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                                    </dt>
                                    <dd className="text-sm font-medium leading-6 text-gray-900">Alex Curren</dd>
                                </div>
                                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                    <dt className="flex-none">
                                        <span className="sr-only">Due date</span>
                                        <CalendarDaysIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                                    </dt>
                                    <dd className="text-sm leading-6 text-gray-500">
                                        <time dateTime="2023-01-31">{moment(p.datetm_payment).format('D MMM  YYYY, HH:mm')}</time>
                                    </dd>
                                </div>
                                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                    <dt className="flex-none">
                                        <span className="sr-only">Status</span>
                                        <CreditCardIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                                    </dt>
                                    <dd className="text-sm leading-6 text-gray-500">{`Pagamento com ${p.payment_method}`}</dd>
                                </div>
                            </dl>
                            <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                                <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                                    Download receipt <span aria-hidden="true">&rarr;</span>
                                </a>
                            </div>
                        </div>
                    </div>
                )

            } </>)
}