import { useState, useEffect } from 'react'
import { EmailNotValidatedAlert } from "./emailverify/EmailNotValidatedAlert";

import { CheckCircleIcon } from '@heroicons/react/20/solid';
import {
  getJson,
  getFirst,
  blankResult,
  reqOk,
  putJson,
} from '../common/fetchdata';
import {
  getRequests as gt,
  putRequests as pu
} from '../common/apiEndpoints';
import TextInputModal from '../common/modal/TextInputModal';

export default function MyProfileEditData() {
    const [usrdata, setUsrdata] = useState(blankResult)
    const [savedata, setSaveData] = useState(blankResult)
    const [inpModalShow_name, setinpModalShow_name] = useState(false)
    const [inpModalShow_email, setinpModalShow_email] = useState(false)
  
    function fetchUsrData() {
      getJson(gt.myuserconfig, { ...usrdata }, setUsrdata);
    }
    function saveChanges(saveData) {
      putJson(pu.myuserconfig, { ...savedata, payload: { ...saveData } }, setSaveData);
    }
  
    useEffect(() => {
      fetchUsrData()
    }, []);
  
    useEffect(() => {
      if (reqOk(savedata))
        fetchUsrData()
    }, [savedata]);


  return (
    <>
      {reqOk(usrdata) && (
        <TextInputModal
          title={"Nome completo"}
          open={inpModalShow_name}
          setOpen={setinpModalShow_name}
          initValue={[
            { label: "Primeiro nome", id: "firstname", value: reqOk(usrdata) && getFirst(usrdata).name },
            { label: "Último nome", id: "lastname", value: reqOk(usrdata) && getFirst(usrdata).lastname },
          ]}
          saveValue={(e) => saveChanges(e)}
        />
      )}

      {reqOk(usrdata) && (
        <TextInputModal
          title={"Email"}
          open={inpModalShow_email}
          setOpen={setinpModalShow_email}
          initValue={[{ label: "Email", id: "email", value: reqOk(usrdata) && getFirst(usrdata).email }]}
          saveValue={(e) => saveChanges(e)}
        />
      )}
      <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">O meu perfil</h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">Dados Pessoais</p>

            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Tipo perfil</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{reqOk(usrdata) && getFirst(usrdata).user_roles.name}</div>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Utilizador</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{reqOk(usrdata) && getFirst(usrdata).username}</div>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Nome completo</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{reqOk(usrdata) && getFirst(usrdata).fullname}</div>
                  <button
                    type="button"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                    onClick={() => setinpModalShow_name(true)}
                  >
                    Alterar
                  </button>
                </dd>
              </div>

              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900 flex">
                    <span>{reqOk(usrdata) && getFirst(usrdata).email}</span>
                    <span className="ml-2">
                      {reqOk(usrdata) && getFirst(usrdata).email_confirmed && (
                        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                      )}
                    </span>{" "}
                  </div>
                  <button
                    type="button"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                    onClick={() => setinpModalShow_email(true)}
                  >
                    Alterar
                  </button>
                </dd>
              </div>
              {reqOk(usrdata) && !getFirst(usrdata).email_confirmed && <EmailNotValidatedAlert />}

              
            </dl>
          </div>
        </div>
      </main>
    </>
  );
}
