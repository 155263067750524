import { MegaphoneIcon, PhoneArrowUpRightIcon, PhoneArrowDownLeftIcon, PhoneXMarkIcon } from "@heroicons/react/20/solid";

import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

export default function MessagingCallEventMessageContent({ item }) {
    return (


            <dl className="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-3 w-full sm:px-6 lg:px-8">

                <div className="px-4 sm:col-span-1 sm:px-0">
                    <div className="flex">
                        <div>
                            {item.phone_contact_status == 'inbound:connected' && <PhoneArrowDownLeftIcon className="w-5 h-5 text-lime-800" />}
                            {item.phone_contact_status == 'outbound:connected' && <PhoneArrowUpRightIcon className="w-5 h-5 text-lime-800" />}
                            {item.phone_contact_status == 'inbound:not-connected' && <PhoneXMarkIcon className="w-5 h-5 text-red-800" />}
                            {item.phone_contact_status == 'outbound:not-connected' && <PhoneArrowUpRightIcon className="w-5 h-5 text-red-800" />}
                        </div>
                        <div className="pl-3 ">
                            {item.phone_contact_status == 'inbound:connected' && 'Recebida'}
                            {item.phone_contact_status == 'outbound:connected' && 'Efetuada'}
                            {item.phone_contact_status == 'inbound:not-connected' && 'Perdida'}
                            {item.phone_contact_status == 'outbound:not-connected' && 'Efetuada (não atendida)'}
                        </div>
                    </div>
                </div>
                <div className="px-4 sm:col-span-1 sm:px-0 text-xs">
                    <dd className="mt-1 text-gray-700 sm:mt-2">{`${moment(item.start_date).format('DD/MM/YYYY HH:mm')}`}
                        <span className="pl-2 text-gray-700 font-normal">{`(${moment(item.start_date).fromNow()})`}</span>
                    </dd>

                </div>
                <div className="px-4 sm:col-span-1 sm:px-0 text-xs">
                    <dt className="font-medium text-gray-900">Duração
                        <span className="pl-2 text-gray-700 font-normal">{`${(item.duration_waiting_sec / 60).toFixed(2)} min.`}</span></dt>

                </div>
                <div className="px-4 sm:col-span-1 sm:px-0 text-xs">
                    <dt className="font-medium text-gray-900">Tempo em espera
                        <span className="pl-2 text-gray-700 font-normal">{`${item.duration_sec} seg.`}</span></dt>
                </div>



            </dl>
    )
}
