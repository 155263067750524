import React, { useState } from 'react';
import { BookmarkSquareIcon, CalendarDaysIcon, LifebuoyIcon } from '@heroicons/react/24/outline'

//landingstats
const resources = [
  { name: 'Marcar', description: 'Consultas ou exames', href: '#', icon: LifebuoyIcon },
 ]


export default function HomeLeftSidebar() {

  return (

    <div className="">
         {resources.map((item) => (
           <div key={item.name} className="group relative flex gap-x-3 rounded-lg p-3 hover:bg-gray-50">
             <div className="mt-2 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
               <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
             </div>
             <div>
               <a href={item.href} className="font-semibold text-gray-900 text-sm">
                 {item.name}
                 <span className="absolute inset-0" />
               </a>
               <p className="mt-1 text-gray-600 text-xs">{item.description}</p>
             </div>
           </div>
         ))}
       </div>
  );
}
