import React, { useState } from 'react';
import NotImplementedScreen from '../common/emptystate/NotImplementedScreen';

const PrescriptionListing = () => {


  return (
    <>
      <NotImplementedScreen />
    </>
  );
};

export default PrescriptionListing;
