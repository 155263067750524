import React, { useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { GlobalStateContext } from '../../state/globalState';
import { useNavigate } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { Combobox, Dialog } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { UsersIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import RecentItemsList from './RecentItemsList';

const recent = [];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SearchPopup({
  show,
  setShow,
  query,
  setQuery,
  lookupMode,
}) {
  const globalcontext = React.useContext(GlobalStateContext);
  const [people, setPeople] = useState([]);
  const [searchMode, setSearchMode] = useState('all');
  const [recentResults, setRecentResults] = useState({
    provider: [],
    customer: []
  });
  const navigate = useNavigate();

  
  React.useEffect(() => {
    setRecentResults({
      provider: globalcontext.contextFcn.userDataConfig.getRecent('provider', 5),
      customer: globalcontext.contextFcn.userDataConfig.getRecent('customer', 5)
    })
  }, [show, query, searchMode]);


  React.useEffect(() => {
    if (query != '')
      globalcontext.contextFcn.request.postsearch.do({
        searchStr: query,
        mode: lookupMode ? lookupMode.universe : searchMode,
      });
  }, [query, searchMode]);

  React.useEffect(() => {
    const _res = globalcontext.contextFcn.request.postsearch.res;
    let resPpl = [];
    if (_res.customer && _res.customer.length > 0) {
      _res.customer.map(
        (r) =>
        (resPpl = [
          ...resPpl,
          {
            ...r,
            name: `${r.name} ${r.lastname}`,
            personRole: 'customer',
            personRoleLabel: 'Paciente'
          },
        ])
      );
    }
    if (_res.user && _res.user.length > 0) {
      _res.user.map(
        (r) =>
        (resPpl = [
          ...resPpl,
          {
            ...r,
            name: `${r.name} ${r.lastname}`,
            personRole: 'provider',
            personRoleLabel: 'Médico'
          },
        ])
      );
    }
    setPeople(resPpl);
  }, [globalcontext.contextFcn.request.postsearch.res]);


  function CustomerDetailCard({ sel }) {
    return (
      <>
        <div className="flex-none p-6 text-center">
          <span className="relative inline-block">
            {sel.avatar_url == null ? (
              <span className="inline-flex h-16 w-16 items-center justify-center rounded-full bg-gray-500">
                <span className="text-3xl font-medium leading-none text-white">
                  {sel.initials}
                </span>
              </span>
            ) : (
              <img
                className="mx-auto h-16 w-16 rounded-full"
                src={`../static/uploads/${sel.avatar_url}`}
                alt=""
              />
            )}

            {sel.customers_web != null ? (
              sel.customers_web.is_online ? (
                <span className="absolute right-0 top-0 block h-3 w-3 rounded-full bg-green-400 ring-2 ring-white" />
              ) : (
                <span className="absolute right-0 top-0 block h-3 w-3 rounded-full bg-red-500 ring-2 ring-white" />
              )
            ) : (
              <span className="absolute right-0 top-0 block h-3 w-3 rounded-full bg-gray-400 ring-2 ring-white" />
            )}
          </span>
          <h2 className="mt-3 font-semibold text-gray-900">{sel.name}</h2>
          {/*    <p className="text-sm leading-6 text-gray-500">{activeOption.role}</p> */}
        </div>
        <div className="flex flex-auto flex-col justify-between p-6">
          <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
            <dt className="col-end-1 font-semibold text-gray-900">NIF</dt>
            <dd className="truncate">
              <span>{sel.tax_id_ref}</span>
            </dd>
            <dt className="col-end-1 font-semibold text-gray-900">Telefone</dt>
            <dd>
              {sel.searchable_phone.trim() != '' && sel.searchable_phone.trim()}
              {/*   {sel.phone_contact.main_mob_phone_cc != null &&
                sel.contact_fields.main_mob_phone_loc != null &&
                `(+${sel.contact_fields.main_mob_phone_cc}) ${sel.contact_fields.main_mob_phone_loc}`} */}
            </dd>
            <dt className="col-end-1 font-semibold text-gray-900">Email</dt>
            <dd className="truncate">
              <span className="text-indigo-600 underline">
                {sel.searchable_email.trim() != '' && sel.searchable_email.trim()}
              </span>
            </dd>
          </dl>

          {lookupMode.mode == 'select' ? (
            <button
              onClick={() => globalcontext.contextFcn.parkVar.set(lookupMode.parkRef, sel)}
              type="button"
              className="mt-6 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Escolher
            </button>
          ) : (
            <div className="grid grid-cols-2 gap-4">
              <button
                type="button"
                onClick={() =>
                  navigate(`/customer/${sel.id}`, { replace: true })
                }
                className="mt-6 w-full rounded-md bg-white px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 text-gray-900 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Ver Ficha
              </button>
              <button
                type="button"
                className="mt-6 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Marcar Consulta
              </button>
            </div>
          )}
        </div>
      </>
    );
  }

  function ProviderDetailCard({ sel }) {
    function getSpecialty(person) {
      let sstr = '';
      if ('servtype' in person) {
        person.servtype.map((s, j) => (sstr = sstr + s.name + ', '));
        return sstr.substring(0, sstr.length - 2);
      }
      return ''
    }
    return (
      <>
        provider
        <div className="flex-none p-6 text-center">
          <span className="relative inline-block">
            {sel.avatar_url == null ? (
              <span className="inline-flex h-16 w-16 items-center justify-center rounded-full bg-gray-500">
                <span className="text-3xl font-medium leading-none text-white">
                  {sel.initials}
                </span>
              </span>
            ) : (
              <img
                className="mx-auto h-16 w-16 rounded-full"
                src={`../static/uploads/${sel.avatar_url}`}
                alt=""
              />
            )}
            {sel.is_online ? (
              <span className="absolute right-0 top-0 block h-3 w-3 rounded-full bg-green-400 ring-2 ring-white" />
            ) : (
              <span className="absolute right-0 top-0 block h-3 w-3 rounded-full bg-red-500 ring-2 ring-white" />
            )}
          </span>
          <h2 className="mt-3 font-semibold text-gray-900">{sel.name}</h2>
          <p className="text-sm leading-6 text-gray-500">{getSpecialty(sel)}</p>
        </div>
        <div className="flex flex-auto flex-col justify-between p-6">
          <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
            <dt className="col-end-1 font-semibold text-gray-900">NIF</dt>
            <dd className="truncate">
              <span>{sel.tax_id_ref}</span>
            </dd>
            <dt className="col-end-1 font-semibold text-gray-900">Telefone</dt>
            <dd>
              {sel.phone_cc != null &&
                sel.phone_loc != null &&
                `(+${sel.phone_cc}) ${sel.phone_loc}`}
            </dd>
            <dt className="col-end-1 font-semibold text-gray-900">Email</dt>
            <dd className="truncate">
              <span className="text-indigo-600 underline">{sel.email}</span>
            </dd>
          </dl>
          <div className="grid grid-cols-2 gap-4">
            <button
              type="button"
              onClick={() => navigate(`/provider/${sel.id}`, { replace: true })}
              className="mt-6 w-full rounded-md bg-white px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 text-gray-900 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Ver Ficha
            </button>
            <button
              type="button"
              className="mt-6 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Marcar Consulta
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <Transition.Root
      show={show}
      as={Fragment}
      afterLeave={() => setQuery('')}
      appear
    >
      <Dialog as="div" className="relative z-40" onClose={setShow}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto pb-4 sm:pb-6 md:pb-20 pt-2">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox
                onChange={(person) => (window.location = person.profileUrl)}
              >
                {({ activeOption }) => (
                  <>
                    <div className="relative">
                      <MagnifyingGlassIcon
                        className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <Combobox.Input
                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                        placeholder="Search..."
                        onChange={(event) => setQuery(event.target.value)}
                      />
                    </div>

                    {(query === '' || people.length > 0) && (
                      <Combobox.Options
                        as="div"
                        static
                        hold
                        className="flex divide-x divide-gray-100"
                      >
                        <div
                          className={classNames(
                            'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto',
                            activeOption && 'sm:h-96'
                          )}
                        >
                          <div className="flex flex-col divide-y">
                            {!lookupMode && (
                              <div className=" px-6 py-2">
                                <button
                                  onClick={() => setSearchMode('all')}
                                  type="button"
                                  className={`rounded-full px-2.5 py-1 text-xs ring-1 ring-inset mr-3 ${searchMode == 'all'
                                    ? 'bg-gray-100 font-semibold text-gray-900 ring-gray-300'
                                    : 'bg-white font-normal text-gray-700 hover:bg-gray-50 ring-gray-200'
                                    }`}
                                >
                                  Tudo
                                </button>
                                <button
                                  onClick={() => setSearchMode('provider')}
                                  type="button"
                                  className={`rounded-full px-2.5 py-1 text-xs ring-1 ring-inset ring-gray-300 mr-3 ${searchMode == 'provider'
                                    ? 'bg-gray-100 font-semibold text-gray-900'
                                    : 'bg-white font-normal text-gray-700 hover:bg-gray-50 '
                                    }`}
                                >
                                  Médicos
                                </button>
                                <button
                                  onClick={() => setSearchMode('customer')}
                                  type="button"
                                  className={`rounded-full px-2.5 py-1 text-xs ring-1 ring-inset ring-gray-300 mr-3 ${searchMode == 'customer'
                                    ? 'bg-gray-100 font-semibold text-gray-900'
                                    : 'bg-white font-normal text-gray-700 hover:bg-gray-50 '
                                    }`}
                                >
                                  Pacientes
                                </button>
                              </div>
                            )}
                            <div className=" px-6 py-4">
                              {query === '' && (
                                <h2 className="mb-4 mt-2 text-xs font-semibold text-gray-500">
                                  Recent searches<br />

                                  <RecentItemsList recentResults={recentResults} />

                                </h2>
                              )}
                              <div className="-mx-2 text-sm text-gray-700">
                                {(query === '' ? recent : people).map(
                                  (person, k) => (
                                    <Combobox.Option
                                      as="div"
                                      key={k}
                                      value={person}
                                      className={({ active }) =>
                                        classNames(
                                          'flex cursor-default select-none items-center rounded-md p-2',
                                          active && 'bg-gray-100 text-gray-900'
                                        )
                                      }
                                    >
                                      {({ active }) => (
                                        <>
                                          <img
                                            src={person.imageUrl}
                                            alt=""
                                            className="h-6 w-6 flex-none rounded-full"
                                          />
                                          <span className="ml-3 flex-auto truncate">
                                            {person.name}
                                            <span className="ml-2 truncate text-gray-500">
                                              {person.personRoleLabel}
                                            </span>
                                          </span>
                                          {active && (
                                            <ChevronRightIcon
                                              className="ml-3 h-5 w-5 flex-none text-gray-400"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </>
                                      )}
                                    </Combobox.Option>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {activeOption && (
                          <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                            <>

                              {activeOption.personRole == 'provider' && (
                                <ProviderDetailCard sel={activeOption} />
                              )}
                            </>
                            <>
                              {activeOption.personRole == 'customer' && (
                                <CustomerDetailCard sel={activeOption} />
                              )}
                            </>
                          </div>
                        )}
                      </Combobox.Options>
                    )}
                    {query !== '' && people.length === 0 && (
                      <div className="px-6 py-14 text-center text-sm sm:px-14">
                        <UsersIcon
                          className="mx-auto h-6 w-6 text-gray-400"
                          aria-hidden="true"
                        />
                        <p className="mt-4 font-semibold text-gray-900">
                          No people found
                        </p>
                        <p className="mt-2 text-gray-500">
                          We couldn’t find anything with that term. Please try
                          again.
                        </p>
                      </div>
                    )}
                  </>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
