
import { Fragment, useState } from 'react'
import {
    AcademicCapIcon,
    EllipsisVerticalIcon,
} from '@heroicons/react/20/solid'
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
import Avatar from '../../common/card/Avatar';
import CallStateLabel from '../calltags/CallStateLabel';
moment.locale('pt');

import { Disclosure, Menu, Transition } from '@headlessui/react'
import { ArrowUpCircleIcon, Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import StethoscopeIcon from '../../common/icon/StethoscopeIcon'
import UserIcon from '../../common/icon/UserIcon'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function CallPageHeader({ tabs, call, selPage, navigateTo }) {


    return (

        <Disclosure as="nav" className="bg-white shadow">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <div className="flex">
                                <div className="flex flex-col flex-shrink-0 items-center text-sm font-bold pr-6 text-gray-800">
                                    <div className="pr-4 pt-2">Registo de consulta</div>
                                    <div className="pt-1"><CallStateLabel call={call} /></div>
                                </div>
                                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}

                                    {tabs.map((t, k) =>
                                        <a
                                            key={k}
                                            onClick={() => navigateTo(t.key)}
                                            href="#"
                                            className={classNames("inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium",
                                                selPage == t.key ? "border-indigo-500 text-gray-900" : "border-transparent  text-gray-500 hover:border-gray-300 hover:text-gray-700")}
                                        >
                                            {t.title}
                                        </a>
                                    )}
                                </div>
                            </div>


                            <div className="hidden sm:ml-6 xl:flex sm:items-center gap-x-6">
                                {call.users != null &&
                                    <div className="border-l-2 border-gray-100 flex items-center gap-x-2 h-full pl-3">
                                        <span className="text-xs mr-2"><StethoscopeIcon className="w-6 h-6" /></span>
                                        <Avatar sz={10} person={call.users} />
                                        <div>
                                            <h3 className="text-sm font-semibold tracking-tight text-gray-900">{call.users.fullname}</h3>
                                            <p className="text-xs font-semibold text-indigo-600">{call.users.servtypes_strlist}</p>
                                        </div>
                                    </div>
                                }

                                {call.customers != null &&
                                    <div className="border-l-2 border-gray-100  flex items-center gap-x-2 h-full pl-3 truncate">
                                        <span className="text-xs mr-2"><UserIcon className="w-6 h-6" /></span>
                                        <Avatar sz={10} person={call.customers} />
                                        <div>
                                            <h3 className="text-sm font-semibold tracking-tight text-gray-900">{call.customers.fullname}</h3>
                                            <p className="text-xs font-semibold text-indigo-600">{call.customers.tax_id_ref}</p>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="-mr-2 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="sm:hidden relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 pb-3 pt-2">
                            {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                            <Disclosure.Button
                                as="a"
                                href="#"
                                className="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
                            >
                                Dashboard
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href="#"
                                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                            >
                                Team
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href="#"
                                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                            >
                                Projects
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href="#"
                                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                            >
                                Calendar
                            </Disclosure.Button>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
        /*     <header className="fixed w-full bg-white">
                <div className="absolute inset-0 -z-10 overflow-hidden" aria-hidden="true">
                    <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
                        <div
                            className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
                            style={{
                                clipPath:
                                    'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                            }}
                        />
                    </div>
                    <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
                </div>
    
                <div className="mx-auto max-w-7xl px-4 py-2 sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                        <div className="flex items-center gap-x-6">
    
                            <h1>
                                <div className="text-sm text-gray-500">
    
                                    <span className="text-base font-semibold text-gray-900 ml-2">{moment(call.datetm).format('D MMM  YYYY, HH:mm')}</span>
                                </div>
                                <div className="mt-1 text-xs font-normal text-gray-500 flex flex-row">
                                    <div className=" mr-4 pt-3">Consulta #<span className="text-gray-700">{call.id}</span> </div>
                                    
                                    <CallStateLabel call={call}/>
                                </div>
                            </h1>
                        </div>
                        <div className="flex items-center gap-x-6">
    
                            {call.users != null &&
                                <div className="flex items-center gap-x-6">
                                    <Avatar sz={16} person={call.users} />
                                    <div>
                                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{call.users.fullname}</h3>
                                        <p className="text-sm font-semibold leading-6 text-indigo-600">{call.users.servtypes_strlist}</p>
                                    </div>
                                </div>
    
                            }
    
                        </div>
                        <div className="flex items-center gap-x-6">
    
    
                            <div className="flex items-center gap-x-6">
                                <Avatar sz={16} person={call.customers} />
                                <div>
                                    <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{call.customers.fullname}</h3>
                                    <p className="text-sm font-semibold leading-6 text-indigo-600">{`NIF: ${call.customers.tax_id_ref}`}</p>
                                </div>
                            </div>
    
                        </div>
                        <div className="flex items-center gap-x-4 sm:gap-x-6">
                            <button type="button" className="hidden text-sm font-semibold leading-6 text-gray-900 sm:block">
                                Copy URL
                            </button>
                            <a href="#" className="hidden text-sm font-semibold leading-6 text-gray-900 sm:block">
                                Edit
                            </a>
                            <a
                                href="#"
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Send
                            </a>
    
                            <Menu as="div" className="relative sm:hidden">
                                <Menu.Button className="-m-3 block p-3">
                                    <span className="sr-only">More</span>
                                    <EllipsisVerticalIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                </Menu.Button>
    
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    type="button"
                                                    className={classNames(
                                                        active ? 'bg-gray-50' : '',
                                                        'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900'
                                                    )}
                                                >
                                                    Copy URL
                                                </button>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                        active ? 'bg-gray-50' : '',
                                                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                    )}
                                                >
                                                    Edit
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>
            </header> */

    )
}