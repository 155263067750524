import React, { Fragment, useState } from 'react';
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import { postJson, blankResult } from '../common/fetchdata';
import { postRequests as pt } from '../common/apiEndpoints';
import CalendarAddSlot from '../call/CalendarAddSlot';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';

import MonthCalendarPopup from './MonthCalendarPopup';

function SlotOkMessage({ selSlot, validSlot, goToNext }) {
  return (
    <div className="rounded-md bg-blue-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-700">
            {`Escolhido dia ${moment(selSlot.start).format(
              'DD/MM/YYYY'
            )} das ${moment(selSlot.start).format('HH:mm')} às ${moment(
              selSlot.end
            ).format('HH:mm')}. `}
          </p>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <button
            onClick={goToNext}
              type="button"
              className={`whitespace-nowrap ml-4 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  cursor-pointer bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-500  text-white '`}
            >
              Confirmar
              <span className="ml-2" aria-hidden="true">
                {' '}
                &rarr;
              </span>
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}

const NewBookingCalendar = ({ token, selection, setSelection }) => {

  const [selDay, setSelDay] = useState(moment());
  const [selDate, setSelDate] = useState(moment());
  const [selSlot, setSelSlot] = React.useState(0);
  const [validSlot, setValidSlot] = React.useState(blankResult);
  const [calendarSlots, setCalendarSlots] = React.useState([]);
  const calendarRef = React.createRef();

  React.useEffect(() => {
    const apical = calendarRef.current.getApi();
    if (selDay) {
      apical.gotoDate(selDay.format());
    }
  }, [selDay]);

  const goToNext = () => {
    setSelection({ ...selection, step: selection.step + 1 });
  };


  const slotValidationTest = () => {
    if (selSlot != 0) {
      postJson(
        pt.slotvalidation,
        {
          ...validSlot,
          token: token,
          payload: { selection: selection, slot: selSlot },
        },
        setValidSlot
      );
    }
  };

  React.useEffect(() => {
    if (validSlot.state == 2) {
      if (validSlot.error) {
        const selSlEv = {
          ...selSlot,
          backgroundColor: '#d89090',
          borderColor: '#c42020',
          textColor: '#3d0a0a',
          title: 'Indisponível',
          slotType: 'selected',
        };
        setCalendarSlots([
          ...calendarSlots.filter((s) => s.slotType != 'selected'),
          selSlEv,
        ]);
        setSelection({
          ...selection,
          steps: [
            ...selection.steps.filter((s) => s.key != 'slot'),
            {
              ...selection.steps.filter((s) => s.key == 'slot')[0],
              completed: false,
              slot: '',
            },
          ],
        });
      }
      if (!validSlot.error) {
        const selSlEv = {
          ...selSlot,
          backgroundColor: '#2f9340',
          borderColor: '#2f9340',
          textColor: '#ffffff',
          title: 'Marcação',
          slotType: 'selected',
        };
        setCalendarSlots([
          ...calendarSlots.filter((s) => s.slotType != 'selected'),
          selSlEv,
        ]);
        setSelection({
          ...selection,
          steps: [
            ...selection.steps.filter((s) => s.key != 'slot'),
            {
              ...selection.steps.filter((s) => s.key == 'slot')[0],
              completed: true,
              slot: selSlot.start,
            },
          ],
        });
      }
    }
  }, [validSlot]);


  React.useEffect(() => {
    slotValidationTest();
  }, [selSlot]);

  React.useEffect(() => {
    if (
      selection.steps.filter((s) => s.key == 'slot')[0].completed &&
      selSlot == 0
    ) {
      setSelSlot({
        ...selSlot,
        start: selection.steps.filter((s) => s.key == 'slot')[0].slot,
        startStr: selection.steps.filter((s) => s.key == 'slot')[0].slot,
        end: moment(selection.steps.filter((s) => s.key == 'slot')[0].slot)
          .add(selection.durationMin, 'minutes')
          .format(),
        endStr: moment(selection.steps.filter((s) => s.key == 'slot')[0].slot)
          .add(selection.durationMin, 'minutes')
          .format(),
      });
    }
  }, [selection]);


  return (
    <div className="flex flex-col ">
      <div className="w-full bg-gray-50 px-4 sm:px-6 py-3">
        <header className="flex flex-none items-center justify-between px-6 py-4">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            <time dateTime="2022-01">{moment(selDate).format('MMMM/YYYY')}</time>
          </h1>
          <div className="flex items-center">
            <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
              <div
                className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300"
                aria-hidden="true"
              />
              <button
                type="button"
                className="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
              >
                <span className="sr-only">Semana Anterior</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="hidden px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
              >
                Hoje
              </button>
              <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                type="button"
                className="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
              >
                <span className="sr-only">Próxima semana</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden md:ml-4 md:flex md:items-center">
              <MonthCalendarPopup
                buttonContent={
                  <>
                    {' '}
                    <div
                      type="button"
                      className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Ir para
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </>
                }
                allowPast={false}
                selDate={selDate}
                selDay={selDay}
                setSelDate={setSelDate}
                setSelDay={setSelDay}
                providerId={selection.providerId}
              />
            </div>
            <Menu as="div" className="relative ml-6 md:hidden">
              <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Open menu</span>
                <EllipsisHorizontalIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  content responsive
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </header>
        {validSlot.statusCode == 200 && <SlotOkMessage selSlot={selSlot} validSlot={validSlot} goToNext={goToNext}/>}
      </div>

      <div className="">
        <CalendarAddSlot
          selDay={selDay}
          token={token}
          context={selection.providerId}
          selSlot={selSlot}
          setSelSlot={setSelSlot}
          durationNewEventMin={selection.durationMin}
          calendarSlots={calendarSlots}
          setCalendarSlots={setCalendarSlots}
          calendarRef={calendarRef}
        />
      </div>
    </div>
  );
};

export default NewBookingCalendar;
