import React from 'react';
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
import { Link } from 'react-router-dom';
import CallActionMenu from '../../call/controls/CallActionMenu';
import InputBoxFiltering from '../table/filters/InputBoxFiltering';
import SelectMultipleServtype from '../../common/combobox/SelectMultipleServtype';

moment.locale('pt');

export const tableconfig = {
  title: 'Fichas de Paciente',
  pickable: false,
  pickSubmitTxt: 'Select',
  searchable: false,
  columns: [
    {
      key: 'avatar_url_sm',
      class: 'pr-0 w-4',
      label: '',
      valueformat: (e, row) => {
        const initials = e == null ? row.initials : '';
        return (
          <>
            {e == null ? (
              <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <span className="font-medium text-gray-600 dark:text-gray-300">
                  {initials}
                </span>
              </div>
            ) : (
              <img
                className="w-10 h-10 rounded-full"
                src={`../static/uploads/${e}`}
                alt="Rounded avatar"
              />
            )}
          </>
        );
      },
    },
    {
      key: 'gid',
      class: '',
      label: 'ID',
    },
    {
      key: 'fullname',
      class: '',
      label: 'Nome',
      filter: {
        component: InputBoxFiltering,
        fieldSort: 'fullname',
        fieldOrig: 'value',
        fieldOrigLabel: 'label',
        fieldDest: 'fullname',
        fieldLabel: 'Nome'
      },
      valueformat: (e, row) => {
        return (
          <Link className="" to={`/provider/${row.id}`}>
            {e}
          </Link>
        );
      },
    },
    {
      key: 'servtypes_strlist',
      class: '',
      label: 'Especialidades',
      filter: {
        component: SelectMultipleServtype,
        fieldSort: 'servtypeId',
        fieldOrig: 'value',
        fieldOrigLabel: 'label',
        fieldDest: 'servtype',
        fieldLabel: 'Especialidade'
      },
      valueformat: (s, row) => {
        /* let sstr = '';
        s.map((s, j) => (sstr = sstr + s.name + ', '));
        return sstr.substring(0, sstr.length - 2); */
        return s
      },
    },
    {
      key: 'username',
      slideoverView: false,
      label: 'Utilizador',
    },
    {
      key: 'email',
      slideoverView: false,
      label: 'Utilizador',
    },
/*     {
      key: 'centers',
      class: '',
      label: 'Centros',
      valueformat: (s, row) => {
        let sstr = '';
        s.map((s, j) => (sstr = sstr + s.name + ', '));
        return sstr.substring(0, sstr.length - 2);
      },
    }, */
    {
      key: 'n_calls',
      class: '',
      label: 'Marcações',
    },
    {
      key: 'is_online',
      class: '',
      label: 'Estado',
      valueformat: (e, row) => {
        return (
          <>
            <div className="flex items-center">
              {e ? (
                <>
                  <div
                    className={
                      'text-green-400 bg-green-400/10 flex-none rounded-full p-1'
                    }
                  >
                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                  </div>
                  <div className="ml-2 text-xs hidden sm:block">Online</div>
                </>
              ) : (
                <>
                  <div
                    className={
                      'text-red-400 bg-red-400/10 flex-none rounded-full p-1'
                    }
                  >
                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                  </div>
                  <div className="ml-2 text-xs hidden sm:block">Offline</div>
                </>
              )}
            </div>
          </>
        );
      },
    },
    {
      key: 'id',
      label: '',
      valueformat: (e, row, refreshHandler) => {
        return (
          <>
            <CallActionMenu data={row} refreshHandler={refreshHandler} />
          </>
        );
      },
    }
  ],
  filter: {
    initFilter: {
      entries: 10,
      page: 1
    },
  },
};
