import React from 'react';

const CustomerPhoto = ({ profile }) => {
  return (
    <div className="relative">
      <span className="mx-auto inline-block">
        {profile.avatar_url == null ? (
          <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-500">
            <span className="text-xs font-medium leading-none text-white">
              {profile.initials}
            </span>
          </span>
        ) : (
          <img
            className="mx-auto h-12 w-12 flex-shrink-0 rounded-full"
            src={`../static/uploads/${profile.avatar_url}`}
            alt=""
          />
        )}
        {profile.customers_web != null ? (
          profile.customers_web.is_online ? (
            <span className="absolute right-0 top-0 block h-3 w-3 rounded-full bg-green-400 ring-2 ring-white" />
          ) : (
            <span className="absolute right-0 top-0 block h-3 w-3 rounded-full bg-red-500 ring-2 ring-white" />
          )
        ) : (
          <span className="absolute right-0 top-0 block h-3 w-3 rounded-full bg-gray-400 ring-2 ring-white" />
        )}
      </span>
    </div>
  );
};

export default CustomerPhoto;
