import React from "react";
import CustomerMessaging from "../client/Messaging/CustomerMessaging";
import { getJson, blankResult } from "../common/fetchdata";
import { getRequests as gt } from "../common/apiEndpoints";
import useToken from "../hooks/useToken";

const InboxPage = ({  dest_uid }) => {

  const { token } = useToken();
  const [selectedPerson, setSelectedPerson] = React.useState(0);
  const [getRes, setGetRes] = React.useState(blankResult);
  const [nConv, setNConv] = React.useState(0);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (args = {}) => {
    getJson(gt.messaging, { ...getRes, token: token, params: args }, setGetRes);
  };

  return (
    <>
      <div className="container-xl">
        <div className="page-header d-print-none">
          <div className="row g-2 align-items-center mt-3">
            <div className="col">
              <h2 className="page-title">Caixa de Entrada</h2>
              <div className="text-muted mt-1">{nConv} conversações</div>
            </div>
            <div className="col-12 col-md-auto ms-auto d-print-none"></div>
          </div>
        </div>
      </div>
      <div className="page-body w-full">
        <div className="container-xl">
          <CustomerMessaging
            msgThreads={getRes}
            token={token}
            dest_uid={dest_uid}
            setNConv={setNConv}
            refreshHandler={fetchData}
          />
        </div>
      </div>
    </>
  );
};

export default InboxPage;
