import { useState } from 'react'
import { blankResult } from '../common/fetchdata';
import { actionAiDocumentInterpret, canInterpretDocument } from '../call/addons/actionAiDocumentInterpret'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function FileContextMenu({ fileSelected }) {

  const [action, setAction] = useState(blankResult)

  return (
    <div className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
      <div className="py-1">
        <div>
          {canInterpretDocument(fileSelected.filename) && <a
            href="#"
            onClick={() => actionAiDocumentInterpret(fileSelected , action, setAction)}
            className={classNames(
              'hover:bg-gray-100 hover:text-gray-900 text-gray-700',
              'block px-4 py-2 text-sm'
            )}
          >
            Account settings {console.log(fileSelected)}
          </a>}
        </div>
        <div>

          <a
            href="#"
            className={classNames(
              'hover:bg-gray-100 hover:text-gray-900 text-gray-700',
              'block px-4 py-2 text-sm'
            )}
          >
            Support
          </a>
        </div>
        <a
          href="#"
          className={classNames(
            'hover:bg-gray-100 hover:text-gray-900 text-gray-700',
            'block px-4 py-2 text-sm'
          )}
        >
          License
        </a>
      </div>
    </div>
  )
}
