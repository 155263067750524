import React from 'react';
import { useEffect } from 'react';
import { Listbox } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SelectModule({
  field,
  formData,
  setFormData,
  formVal,
  setFormVal
}) {

  
  // initialize
  useEffect(() => {
    setFormVal((f) => ({
      ...f, [field.name]: {
        ...formVal[field.name],
        isTouched: false,
        isValid: isValid(formData[field.name])
      }
    }))
  }, [])


  // on update
  useEffect(() => {
    if (formData[field.name] !== '')
      setFormVal((f) => ({
        ...f, [field.name]: {
          ...formVal[field.name],
          isTouched: true,
          isValid: isValid(formData[field.name])
        }
      }))
  }, [formData[field.name]])


  function isValid(dt) {
    if (!field.required) return true
    else return dt != ''
  }

  return (
    <>
      <Listbox
        disabled={'disabled' in field && field.disabled ? true : false}
        value={formData[field.name] != null ? formData[field.name] : ''}
        onChange={(e) =>
          setFormData({
            ...formData,
            [field.name]: e,
          })
        }
      >
        {({ open }) => (
          <>
            <div className="relative ">
              <Listbox.Button 
              className={`relative cursor-default py-1.5 pl-3 pr-10 text-left focus:outline-none block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ` +
              ` ${(formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'}  `}
            value={formData[field.name] != null ? formData[field.name] : ''}

              >
                <span className="block truncate">
                  {formData[field.name] != null &&
                  (formData[field.name] != '') &
                    (formData[field.name].value != null)
                    ? field.options.filter(
                        (o) => o.value == formData[field.name].value
                      )[0] != null
                      ? field.options.filter(
                          (o) => o.value == formData[field.name].value
                        )[0][field.fieldTitle]
                      : ''
                    : field.noselectTxt}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">

                  {field.options != null && field.options.map((person, k) => (
                    <Listbox.Option
                      key={k}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={person}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {person[field.fieldTitle]}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>

      {
        (formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) &&
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {'validationerr' in field ? field.validationerr : 'Valor inválido'}
        </p>

      }
    </>
  );
}
