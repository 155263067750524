import { getRequests as gt } from '../common/apiEndpoints';
import { countryCodes } from '../label/countrycodes';

const rows = [
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'name',
    title: 'Primeiro nome',
    placeholder: 'João',
    id: 'customermanager__name',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Nome Inválido.',
  },
  {
    selector: 'input',
    required: true,
    type: 'text',
    name: 'lastname',
    title: 'Último nome',
    placeholder: 'Silva',
    id: 'customermanager__lastname',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Nome Inválido.',
  },
  {
    selector: "input",
    required: true,
    type: "email",
    name: "email",
    title: "Email",
    placeholder: "joao@medzi.io",
    id: "customermanager__email",
    map: true,
    validation: (f) => 
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(f).toLowerCase()), 
    validationerr: "Email inválido.",
  },

  {
    selector: 'phoneinput',
    required: true,
    countryList: countryCodes,
    defaultCountry: 'PRT',
    placeholder: 'Escolha...',
    placeholderNum: '910000000',
    type: 'text',
    name: 'mobile_phone',
    title: 'Telemóvel',
    id: 'customermanager__hone_loc',
    map: true,
  },

];

export const formfield_newcustomermanager = {
  title: 'Novo Gestor',
  description: 'Adicione uma pessoa responsável por gerir as marcações',
  apiEndpoint: gt.customermanager,
  method: 'POST',
  preloadForm: false,
  name: '',
  id: '',
  submitLabel: 'Adicionar',
  rows: rows,
};

export const formfield_editcustomermanager = {
  title: 'Editar Gestor',
  description: 'Edite o gestor de marcações',
  apiEndpoint: gt.customermanager,
  method: 'PUT',
  preloadForm: true,
  name: '',
  id: '',
  submitLabel: 'Editar',
  deleteAction: {
    label: 'Eliminar',
  },
  rows: rows,
};
