import React from "react";
import { GlobalStateContext } from '../../state/globalState';
import { getJson, blankResult } from "../../common/fetchdata";
import { JitsiMeeting } from "@jitsi/react-sdk";

const CallVideoContainer = ({ callInfo, jitsiToken }) => {
  const globalcontext = React.useContext(GlobalStateContext);
  const [getRes, setGetRes] = React.useState(blankResult);
  const handleReadyToClose = () => {
    /* eslint-disable-next-line no-alert */
   alert('call finished')
  };
  return (
  <>
      {/*   <JitsiMeeting
            jwt={globalcontext.activeCall.items.current.jtoken}
            domain={globalcontext.activeCall.items.current.jdomain}
            roomName={globalcontext.activeCall.items.current.unique_ref}
            configOverwrite={{
              startWithAudioMuted: false,
              startWithVideoMuted: false,
            }}
            userInfo={{
              displayName: `${globalcontext.activeCall.items.current.customers.name}`,
            }}
            onApiReady={(externalApi) => {
              // here you can attach custom event listeners to the Jitsi Meet External API
              // you can also store it locally to execute commands
            }}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.height = "400px";
            }}
            onReadyToClose={handleReadyToClose}
          />  */}
          </>
  );
};

export default CallVideoContainer;
