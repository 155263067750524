import React from 'react';
import SelectModule from './SelectModule';
import InputModule from './InputModule';
import SelectCustomerWasWithModule from './SelectCustomerWasWithModule';
import SelectProviderModule from './SelectProviderModule';
import SelectCenterModule from './SelectCenterModule';
import SelectInsuranceModule from './SelectInsuranceModule';
import SelectSpaceFeaturesModule from './SelectSpaceFeaturesModule';
import SelectServtypeModule from './SelectServtypeModule';
import RadioSelectModule from './RadioSelectModule';
import ToggleModule from './ToggleModule';
import DatePickerModule from './DatePickerModule';
import StackEditModule from './StackEditModule';
import SelectAsyncModule from './SelectAsyncModule';
import ReactSelectModule from './ReactSelectModule';
import EmojiPickerModule from './EmojiPickerModule';
import PhoneInputModule from './PhoneInputModule';
import ListEditModule from './ListEditModule';
import StartEndTimeInputModule from './StartEndTimeInputModule';
import AvatarModule from './AvatarModule';
import TinyMceModule from './TinyMceModule'
import MultiFileUploadModule from './MultiFileUploadModule';
import SelectUserRole from './SelectUserRole';
import AutocompleteOnBeModule from './AutocompleteOnBeModule';

export function showConditionally(formData, templ) {

  if (!('conditional' in templ)) return true;
  else {
    if (
      formData[templ.conditional.parentField] &&
      formData[templ.conditional.parentField].value ==
      templ.conditional.parentValue
    )
      return true;
    else return false;
  }
}

export function conditionallyRender(
  templ, 
  formData, 
  setFormData, 
  context = 0, 
  setFormVal = () => { }, 
  formVal = {},
  removeIds = []  
  ) {
  let component = <></>;

  if (typeof formData[templ.name] == 'undefined') return <></>

  if (templ.selector == 'tinymce')
  component = (
    <TinyMceModule
      field={templ}
      formData={formData}
      setFormData={setFormData}
      setFormVal={setFormVal}
      formVal={formVal}
    />
  );

  
  if (templ.selector == 'input')
    component = (
      <InputModule
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
      />
    );

  if (templ.selector == 'daterangepicker')
    component = (
      <DatePickerModule
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
      />
    );

  if (templ.selector == 'reactselect')
    component = (
      <ReactSelectModule
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
        removeOptions={removeIds}
      />
    );

  if (templ.selector == 'emojipicker')
    component = (
      <EmojiPickerModule
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
      />
    );

  if (templ.selector == 'select')
    component = (
      <SelectModule
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
        removeOptions={removeIds}
      />
    );

  if (templ.selector == 'select-async')
    component = (
      <SelectAsyncModule
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
        removeOptions={removeIds}
      />
    );

  if (templ.selector == 'listedit')
    component = (
      <ListEditModule
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
        removeOptions={removeIds}
      />
    );

  if (templ.selector == 'phoneinput')
    component = (
      <PhoneInputModule
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
      />
    );


  if (templ.selector == 'startendtime')
    component = (
      <StartEndTimeInputModule
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
      />
    );

  if (templ.selector == 'selectwaswith')
    component = (
      <SelectCustomerWasWithModule
        context={context}
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
      />
    );

    if (templ.selector == 'selectuserrole')
    component = (
      <SelectUserRole
        context={context}
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
      />
    );

  if (templ.selector == 'radioselect')
    component = (
      <RadioSelectModule
        context={context}
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
      />
    );

    if (templ.selector == 'toggle')
    component = (
      <ToggleModule
        context={context}
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
      />
    );
   
  if (templ.selector == 'selectspacefeatures')
    component = (
      <SelectSpaceFeaturesModule
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
        removeOptions={removeIds}
      />
    );

    if (templ.selector == 'servicetype')
    component = (
      <SelectServtypeModule
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
        removeOptions={removeIds}
      />
    );


    

  if (templ.selector == 'selectprovider')
    component = (
      <SelectProviderModule
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
        removeOptions={removeIds}
      />
    );

    if (templ.selector == 'selectinsurance')
    component = (
      <SelectInsuranceModule
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
        removeOptions={removeIds}
      />
    );

    if (templ.selector == 'selectcenter')
    component = (
      <SelectCenterModule
        field={templ}
        formData={formData}
        setFormData={setFormData}
        setFormVal={setFormVal}
        formVal={formVal}
        removeOptions={removeIds}
      />
    );


  if (templ.selector == 'stackedit')
    component = (
      <StackEditModule
        formData={formData}
        setFormData={setFormData}
        field={templ}
        context={context}
        setFormVal={setFormVal}
        formVal={formVal}
      />
    );

    if (templ.selector == 'avatar')
    component = (
      <AvatarModule
        formData={formData}
        setFormData={setFormData}
        field={templ}
        context={context}
        setFormVal={setFormVal}
        formVal={formVal}
      />
    );

    if (templ.selector == 'multifile')
    component = (
      <MultiFileUploadModule
        formData={formData}
        setFormData={setFormData}
        field={templ}
        context={context}
        setFormVal={setFormVal}
        formVal={formVal}
      />
    );

    if (templ.selector == 'autocompletebe')
    component = (
      <AutocompleteOnBeModule
        formData={formData}
        setFormData={setFormData}
        field={templ}
        context={context}
        setFormVal={setFormVal}
        formVal={formVal}
      />
    );

  if ('conditional' in templ) {
    if (showConditionally(formData, templ)) return component;
    else return <></>;
  } else {
    return component;
  }
}
