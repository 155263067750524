export const callStatusLabel = [
  { status: -1, class:'bg-gray-50 text-gray-600 ring-gray-500/10', label: '?', key: 'UNKNOWN' },
  { status: 0,  class:'bg-yellow-50 text-yellow-800 ring-yellow-600/20', label: 'Marcado', key: 'BOOKED' },
  { status: 1,  class:'bg-blue-50 text-blue-700 ring-blue-700/10', label: 'Completo', key: 'COMPLETED' },
  { status: 2,  class:'bg-purple-50 text-purple-700 ring-purple-700/10', label: 'Desmarcado', key: 'CANCELBOOK' },
  { status: 3,  class:'text-red-700 ring-red-600/10 bg-red-50', label: 'Falta', key: 'MISSED' },
  { status: 4,  class:'bg-green-50 text-green-700 ring-green-600/20', label: 'Em curso', key: 'ONGOING' },
  { status: 5,  class:'text-red-700 ring-red-600/10 bg-red-50', label: 'Em espera', key: 'WAITING' },
  { status: 6,  class:'text-red-700 ring-red-600/10 bg-red-50', label: 'Desistência', key: 'ABANDONEMENT' },
  { status: 7,  class:'text-red-700 ring-red-600/10 bg-red-50', label: 'Cancelado', key: 'CANCELLED' },
  { status: 8,  class:'text-red-700 ring-red-600/10 bg-red-50', label: 'Check-out', key: 'CHECKOUT' }
];

export const callActivityStateLabel = [
  {  class:'', label: 'Marcação', labelExt: 'Marcação da consulta', key: 'BOOKING' },
  {  class:'', label: 'Marcação', labelExt: 'Marcação da consulta', key: 'BOOKED' },
  {  class:'', label: 'Alteração', labelExt: 'Cancelada (automaticamente)', key: 'CANCELLED' },
  {  class:'', label: 'Desmarcada', labelExt: 'Pelo Paciente', key: 'CANCELBOOK_PAC' },
  {  class:'', label: 'Desmarcada', labelExt: 'Pelo Médico', key: 'CANCELBOOK_PRV' },
  {  class:'', label: 'Desistência', labelExt: 'O paciente desistiu', key: 'ABANDONEMENT' },
  {  class:'', label: 'Falta', labelExt: 'O paciente faltou', key: 'MISSED' },
  {  class:'', label: 'Check-in', labelExt: 'Check-in efetuado', key: 'CHECKIN' },
  {  class:'', label: 'Início', labelExt: 'Início da consulta', key: 'STARTCALL' },
  {  class:'', label: 'Término', labelExt: 'Fim da consulta', key: 'ENDCALL' },
  {  class:'', label: 'Início', labelExt: 'Início da consulta', key: 'START' },
];
/* 
export const getCallStatus = (status) => {
  if (status == null)
    return {
      color: 'blue',
      twcolor: 'sky',
      sign: 'primary',
      label: '?',
      allowCompleted: false,
      allowCancelled: false,
      allowMissed: false,
      allowStart: false,
    };
  if (status == 0)
    return {
      color: 'blue',
      twcolor: 'sky',
      sign: 'primary',
      label: callStatusLabel[0].label,
      allowCompleted: true,
      allowCancelled: true,
      allowMissed: true,
      allowStart: true,
    };
  if (status == 1)
    return {
      color: 'red',
      twcolor: 'red',
      sign: 'danger',
      label: callStatusLabel[1].label,
      allowCompleted: false,
      allowCancelled: false,
      allowMissed: false,
      allowStart: false,
    };
  if (status == 2)
    return {
      color: 'green',
      twcolor: 'green',
      sign: 'success',
      label: callStatusLabel[2].label,
      allowCompleted: false,
      allowCancelled: false,
      allowMissed: false,
      allowStart: false,
    };
  if (status == 3)
    return {
      color: 'yellow',
      twcolor: 'amber',
      sign: 'warning',
      label: callStatusLabel[3].label,
      allowCompleted: false,
      allowCancelled: false,
      allowMissed: false,
      allowStart: false,
    };
  if (status == 4)
    return {
      color: 'purple',
      twcolor: 'violet',
      sign: 'secondary',
      label: callStatusLabel[4].label,
      allowCompleted: true,
      allowCancelled: true,
      allowMissed: true,
      allowStart: false,
    };
  if (status == 5)
    return {
      color: 'purple',
      twcolor: 'pink',
      sign: 'secondary',
      label: callStatusLabel[5].label,
      allowCompleted: true,
      allowCancelled: true,
      allowMissed: true,
      allowStart: false,
    };
};
 */