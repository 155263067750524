import moment from 'moment-timezone'
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import 'moment/dist/locale/pt';
moment.locale('pt');

export default function MessagingBookRequestMessageContent({ item }) {
    return (

        <div>

            <div className="sm:flex sm:items-baseline sm:justify-between">
                <h3 className="text-base font-medium">
                    <span className="text-gray-600">Pedido de Marcação</span>
                </h3>
                <p className="mt-1 whitespace-nowrap text-sm text-gray-600 sm:ml-3 sm:mt-0">
                    <time dateTime={item.created_at}>{`${moment(item.created_at).format('DD/MM/YYYY H:mm')}`}<span className="text-xs text-gray-700 pl-2">{`${moment(item.created_at).fromNow()}`}</span></time>
                </p>
            </div>


            <div className="mt-6 text-xs">

                <dl className="grid grid-cols-1 md:grid-cols-5 sm:grid-cols-3">
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Consulta</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-600 sm:mt-2">Especialidade</dd>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{item.booking_request_servtype_b != null ? item.booking_request_servtype_b.name : ''}</dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Médico</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{item.provider_name}</dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Paciente</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-600 sm:mt-2">{item.customers ? item.customers.fullname: `${item.first_name} ${item.last_name}`}</dd>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2"><span className="pr-2 font-normal">NIF</span>{item.customers ? item.customers.tax_id_ref : `${item.tax_id_ref}`}</dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Período Preferencial</dt>
                        <ol>
                            <li className="mt-1 text-sm leading-6 text-gray-600 sm:mt-2">
                                <span className="text-xs text-gray-600 pr-2">1</span>
                                {`${moment(item.date_1).format('DD/MM/YYYY')} - ${item.period_1 == 'morning' ? 'Manhã' : item.period_1 == 'afternoon' ? 'Tarde' : 'Qualquer'}`}</li>
                            <li className="mt-1 text-sm leading-6 text-gray-600 sm:mt-2">
                                <span className="text-xs text-gray-600 pr-2">2</span>
                                {`${moment(item.date_2).format('DD/MM/YYYY')} - ${item.period_2 == 'morning' ? 'Manhã' : item.period_2 == 'afternoon' ? 'Tarde' : 'Qualquer'}`}</li>
                            <li className="mt-1 text-sm leading-6 text-gray-600 sm:mt-2">
                                <span className="text-xs text-gray-600 pr-2">3</span>
                                {`${moment(item.date_3).format('DD/MM/YYYY')} - ${item.period_3 == 'morning' ? 'Manhã' : item.period_3 == 'afternoon' ? 'Tarde' : 'Qualquer'}`}</li>
                        </ol>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Seguro</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{`${item.insurances ? item.insurances.name : item.insurance_other}`}</dd>
                    </div>


                </dl>
                <span className="text-sm text-gray-900 pr-2 font-medium">Notas:</span><span className="text-sm text-gray-800">{item.notes}</span>
            </div>
        </div>
    )
}





