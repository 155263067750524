import React, { useEffect, useState } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import moment from 'moment-timezone'
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import 'moment/dist/locale/pt';
moment.locale('pt');

const AnalyticsDateRangeFilter = ({ filters, setFilters }) => {


  return (
    <div className="w-64">
      <Datepicker
        i18n={'pt'}
        asSingle={false}
        name={'filterByDate'}
        id={'filterByDate'}
        placeholder={'Intervalo de datas...'}
        containerClassName=""
        inputClassName={`w-full rounded-md border-0 p-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 font-thin sm:leading-6 bg-white`}
        toggleClassName=""
        value={{ startDate: filters.rangeStart, endDate: filters.rangeEnd }}
        onChange={(e) => {
          if (e.startDate == null && e.endDate == null) {
            setFilters({
              ...filters,
              rangeStart: moment().subtract(360, "days").format('YYYY-MM-DD'),
              rangeEnd: moment().format('YYYY-MM-DD')
            });
          }
          else {
            setFilters({
              ...filters,
              rangeStart: e.startDate,
              rangeEnd: e.endDate
            });
          }
        }}
        displayFormat={'DD/MM/YY'}
      />
    </div>
  );
};

export default AnalyticsDateRangeFilter;
