//import { formfield_editspace as fin } from '../../forms/formfield_space';
import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
import UserActionMenu from '../../user/UserActionMenu';
import InputBoxFiltering from '../table/filters/InputBoxFiltering';
import { formfield_edituser as f_edit } from '../../forms/formfield_user';
moment.locale('pt');

export const tableconfig = {
  edit: {
    showEditButton: true,
    type: 'slideover',
    template: f_edit,
  },
  view: {
    title: 'Dados do utilizador',
    description: '',
    type: 'slideover',
    template: {
      nCols: 2,
      data: [
        {
          colSpan: 1,
          key: 'id',
          label: 'ID',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'name',
          label: 'Nome',
          valueformat: (e) => {
            return e;
          },
        },
      ],
    },
  },
  columns: [
    {
      key: 'gid',
      slideoverView: true,
      label: 'GID',
    },
    {
      key: 'fullname',
      slideoverView: true,
      label: 'Nome',
      filter: {
        component: InputBoxFiltering,
        fieldSort: 'fullname',
        fieldOrig: 'value',
        fieldOrigLabel: 'label',
        fieldDest: 'fullname',
        fieldLabel: 'Nome'
      },
    },
    {
      key: 'is_provider',
      slideoverView: true,
      label: 'Médico',
      valueformat: (e) => {
        return (
          <>
            {e ? (
              <CheckCircleIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                aria-hidden="true"
              />
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      key: 'is_active',
      slideoverView: true,
      label: 'Ativo',
      valueformat: (e) => {
        return (
          <>
            {e ? (
              <CheckCircleIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                aria-hidden="true"
              />
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      key: 'user_roles',
      slideoverView: true,
      label: 'Perfil',
      valueformat: (e) => {
        return e != null ? e.name : null;
      },
    },
    {
      key: 'last_access',
      slideoverView: true,
      label: 'Último acesso',
      valueformat: (e, row) => {
        return e == null ? 'Nunca' : row.is_online ? 'Online' : moment(e).fromNow();
      },
    },
    {
      key: 'id',
      label: '',
      valueformat: (e, row, refreshHandler) => {
        return (
          <>
            <UserActionMenu data={row} refreshHandler={refreshHandler} />
          </>
        );
      },
    },
  ],
  filter: {
    initFilter: {
      entries: 10,
      page: 1,
    },
    fields: [],
  },
};
