import React from 'react';
import { initSteps } from './booking';

export default function NewBookingControls({ selection, setSelection }) {
  const confirmCall = () => {

    let steps = selection.steps
    for (let i = 1; i < steps.length + 1; i++) {
      let _cStep = steps.filter((s) => s.id == i)[0]
      if (_cStep.id >= 4) {
        _cStep.completed = true
      }
    }
    setSelection({ ...selection, confirmed: true, step: 5, steps: steps });
  };

  const resetProcess = () => {
    setSelection(initSteps);
  };

  const goToNext = () => {
    setSelection({ ...selection, step: selection.step + 1 });
  };

  const canProceed = selection.steps.filter((s) => s.id == selection.step)[0]
    .completed;

  return (
    <div className="flex flex-shrink-0 justify-between  w-full">
      <div>
        <button
          type="button"
          onClick={resetProcess}
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
        >
          Reiniciar
        </button>
      </div>
      <div>
        {selection.step < selection.steps.length - 1 && (
          <button
            type="button"
            onClick={goToNext}
            disabled={!canProceed}
            className={`ml-4 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  ${
              canProceed
                ? ' cursor-pointer bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-500  text-white '
                : 'bg-gray-100 text-gray-300 cursor-not-allowed'
            }`}
          >
            Seguinte
          </button>
        )}

        {selection.step == selection.steps.length - 1 && (
          <button
            type="button"
            onClick={confirmCall}
            className="ml-4 inline-flex justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm bg-green-700 hover:bg-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-700"
          >
            Confirmar
          </button>
        )}
      </div>
    </div>
  );
}
