import React, { Fragment, useContext } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid';
import {
  BellIcon,
  BellSlashIcon,
  InboxIcon,
  PhoneXMarkIcon,
} from '@heroicons/react/24/outline';
import {
  supportNotification,
  notificationsEnabled,
  notificationState,
  enableNotifications
} from '../../state/notifications/notificationLib'
import { GlobalStateContext } from '../../state/globalState';


export default function NavNotifications({ }) {
  const globalcontext = useContext(GlobalStateContext);
  const notifications = globalcontext.contextFcn.userData.data.items.notifications;


  function notificationEl() {

    return (notificationState() == 'default' ?
      <button type="button"
        className="" onClick={() => enableNotifications(notificationsEnabled)}>Habilitar</button>
      : (notificationState() == 'denied' ?
        <span className="text-red-600 font-medium">Negado</span>
        : (notificationState() == 'granted' ?
          <span className="text-emerald-700 font-medium">Permitido</span>
          : (''))))
  }

  const callsToAction = [
    { name: notificationEl(), href: '#', icon: PlayCircleIcon },
    { name: 'Contact sales', href: '#', icon: PhoneIcon },
  ];

  return (
    <Popover className="relative">
      <Popover.Button>
        <>
      {/*     <div
            className="relative -m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
          >
            <span className="absolute top-1 right-0 h-4 w-4 rounded-full bg-red-600 flex justify-center items-center items text-[8px] text-white">M</span>
            <span className="sr-only">View notifications</span>
            {notificationState() == 'granted' ?
              <BellIcon className="h-6 w-6" aria-hidden="true" /> :
              <BellSlashIcon className="h-6 w-6" aria-hidden="true" />
            }

          </div> */}
        </>
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-2/3 tr px-4">
          <div className="w-screen max-w-sm flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
              {(notifications && 'NEW_BOOK_REQUEST' in notifications) &&
                <div
                  className="group relative flex gap-x-4 rounded-lg p-3 hover:bg-gray-50"
                >
                  <div className="relative mt-1 flex h-6 w-6 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">

                    <span className="absolute -top-2 -right-2 h-4 w-4 rounded-full bg-red-600 flex justify-center items-center items text-xs text-white">{notifications.NEW_BOOK_REQUEST}</span>
                    <InboxIcon
                      className="h-5 w-5 text-gray-600 group-hover:text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <a href="#" className="font-semibold text-gray-900">
                      Contactos e Pedidos de Marcação
                      <span className="absolute inset-0" />
                    </a>
                    <p className="mt-1 text-gray-600">...</p>
                  </div>
                </div>
              }

            </div>
            <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">

              <a

                href="#"
                className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
              >
                {notificationState() == 'granted' ?
                  <BellIcon className="h-6 w-6" aria-hidden="true" /> :
                  <BellSlashIcon className="h-6 w-6" aria-hidden="true" />
                }
                {notificationEl()}
              </a>
             {/*  <a

                href="#"
                className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
              >
                {notificationState() == 'granted' ?
                  <BellIcon className="h-6 w-6" aria-hidden="true" /> :
                  <BellSlashIcon className="h-6 w-6" aria-hidden="true" />
                }
                {notificationEl()}
              </a> */}

            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
