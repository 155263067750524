import React from 'react';
import { getRequests as gt } from '../common/apiEndpoints';

const weekdays = [
  { id: 1, value: 1, label: 'Segunda-feira' },
  { id: 2, value: 2, label: 'Terça-feira' },
  { id: 3, value: 3, label: 'Quarta-feira' },
  { id: 4, value: 4, label: 'Quinta-feira' },
  { id: 5, value: 5, label: 'Sexta-feira' },
  { id: 6, value: 6, label: 'Sábado' },
  { id: 7, value: 7, label: 'Domingo' },
];

const rows = [
  {
    selector: 'input',
    required: true,
    class: '',
    type: 'text',
    name: 'name',
    title: 'Nome',
    placeholder: 'Nome do espaço...',
    id: 'newcenter__name',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Nome Inválido.',
  },
  {
    selector: 'stackedit',
    required: true,
    name: 'oper_hours',
    title: 'Horas',
    id: 'newcenter_slots',
    map: true,
    stack: {
      title: 'label',
      subtitle: 'name',
      listItemView: (e) => {
        
        const weekdayId = (typeof  e.weekday === 'object') ? e.weekday.id : e.weekday
        return (
          <>
          <span className="truncate font-medium">{`${e.oper_open}-${e.oper_close}`}</span>
            <span className="flex-shrink-0 text-gray-400">{` ${weekdays.filter((w) => w.id == weekdayId)[0]
              .label
              }`}</span>
          </>
        );
      },
      fields: [
        {
          selector: 'select',
          title: 'Dia da semana',
          name: 'weekday',
          required: true,
          fieldTitle: 'label',
          fieldSubTitle: null,
          noselectTxt: ' Escolha...',
          id: 'newcenter_slots_weekday',
          optionType: 'static',
          options: weekdays,
          map: true,
        },
        {
          selector: 'input',
          required: true,
          type: 'time',
          name: 'oper_open',
          title: 'Início',
          placeholder: 'Início',
          id: 'newcenter_slots_slotstart',
          map: true,
          validation: (f) => f.length > 0,
          validationerr: 'Valor inválido.',
        },
        {
          selector: 'input',
          required: true,
          type: 'time',
          name: 'oper_close',
          title: 'Fim',
          placeholder: 'Fim',
          id: 'newcenter_slots_slotend',
          map: true,
          validation: (f) => f.length > 0,
          validationerr: 'Valor inválido.',
        },
      ],
    },
  },
];

export const formfield_newcenter = {
  title: 'Novo Centro',
  description: 'Adicione um novo centro',
  apiEndpoint: gt.center,
  method: 'POST',
  preloadForm: false,
  name: '',
  id: '',
  submitLabel: 'Adicionar',
  rows: rows,
};

export const formfield_editcenter = {
  title: 'Editar Centro',
  description: 'Edite as características do centro',
  apiEndpoint: gt.center,
  method: 'PUT',
  preloadForm: true,
  name: '',
  id: '',
  submitLabel: 'Editar',
  deleteAction: {
    label: 'Eliminar',
  },
  rows: rows,
};
