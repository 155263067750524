import React from "react";
import moment from "moment";
import MessageSidebar from "./MessageSidebar";
import MessageContent from "./MessageContent";
import MessageSendbox from "./MessageSendbox";

import { getJson, postJson, blankResult, reqOk, getFirst } from "../../common/fetchdata";
import { getRequests as gt } from "../../common/apiEndpoints";
import { postRequests as pt } from "../../common/apiEndpoints";

const CustomerMessaging = ({ token, msgThreads, dest_uid, setNConv, refreshHandler }) => {
  const [getDestRes, setGetDestRes] = React.useState(blankResult);
  const [getThread, setGetThread] = React.useState(blankResult);

  const [thMsg, setthMsg] = React.useState([]);
  const [destinationUids, setDestinationUids] = React.useState([]);
  const [destination, setDestination] = React.useState([]);
  const [focusOnThread, setFocusOnThread] = React.useState(0);

  React.useEffect(() => {
    if (dest_uid != null) {
      setDestinationUids(dest_uid);
      getJson(
        gt.userbyuid,
        { ...getDestRes, token: token, params: { uid: dest_uid } },
        setGetDestRes
      );
    }
  }, [dest_uid]);

  // Set focus when sending to a specific UID
  React.useEffect(() => {
    if (thMsg.length > 0) {
      // by default focus on first
      fetchThreadById(thMsg[0].thread_id);
    }
  }, [thMsg]);

  const handleChangeDestination = (mtid) => {
    setFocusOnThread(mtid);
    fetchThreadById(mtid);
  };

  const fetchThreadById = (mtid) => {
    getJson(
      gt.messagingthread,
      { ...getThread, token: token, params: { threadId: mtid } },
      setGetThread
    );
  };

  React.useEffect(() => {
    if (reqOk(getThread)) {
      setDestination(getThread.items.data);
    }
  }, [getThread]);

  React.useEffect(() => {
    if (reqOk(getDestRes) && reqOk(msgThreads) && focusOnThread == 0) {
      let new_dest = {
        conversation_type: "dm",
        destination: [
          {
            avatar_url: getDestRes.items.data.avatar_url_sm,
            uid: getDestRes.items.data.uid,
            initials: getDestRes.items.data.initials,
            lastname: getDestRes.items.data.lastname,
            name: getDestRes.items.data.name,
            type: getDestRes.items.data.type,
          },
        ],
        last_update: moment(),
        n_messages: 0,
        thread_id: 0,
      };
      let existMsgTh = msgThreads.items.data;
      let newMsgTh = [];
      existMsgTh.forEach((msTh) => {
        let skip = false;
        if (msTh.conversation_type == new_dest.conversation_type) {
          const _dest = msTh.destination;
          if (_dest.length == 1 && _dest[0].uid == getDestRes.items.data.uid) {
            skip = true;
          }
        }
        if (!skip) {
          newMsgTh = [...newMsgTh, msTh];
        } else {
          new_dest.thread_id = msTh.thread_id;
          new_dest.n_messages = msTh.n_messages;
        }
      });
      const msgThr = [new_dest, ...newMsgTh]
      setthMsg(msgThr);
      setNConv(msgThr.length);
      setFocusOnThread(new_dest.thread_id);
    }
    if (dest_uid == null && reqOk(msgThreads)) {
      const msgThr = [...msgThreads.items.data]
      setthMsg(msgThr);
      setNConv(msgThr.length);
      if(msgThr.length > 0){
        setFocusOnThread(msgThr[0].thread_id);
      }
    }

    if (focusOnThread > 0) {
      handleChangeDestination(focusOnThread);
    }
  }, [getDestRes, msgThreads]);

  return (
    <div className="col-12">
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-4 col-sm-4 col-md-4 col-xl-4">
              <MessageSidebar thMsg={thMsg} selectedThread={focusOnThread} handleChangeDestination={handleChangeDestination} />
            </div>

            <div className="col-8 col-sm-8 col-md-8 col-xl-8">
              <MessageContent destination={destination} />

              <MessageSendbox
                token={token}
                destination={destination}
                destinationUids={destinationUids}
                refreshHandler={refreshHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerMessaging;
