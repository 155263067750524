export default function isImageExtension(extension) {
    if (
        extension == 'gif' ||
        extension == 'jpg' ||
        extension == 'jpeg' ||
        extension == 'jfif' ||
        extension == 'pjpeg' ||
        extension == 'pjp' ||
        extension == 'png' ||
        extension == 'svg' ||
        extension == 'webp' ||
        extension == 'bmp' ||
        extension == 'tif' ||
        extension == 'tiff'
    )
        return true
    else return false
}