

import { formfield_editexam as fin } from '../../forms/formfield_exams';

export const tableconfig = {
  edit: {
    showEditButton: true,
    type: 'slideover',
    template: fin,
  },
  view: {
    title: 'Detalhes do Exame/Análise',
    description: '',
    type: 'slideover',
    template: {
      nCols: 2,
      data: [
        {
          colSpan: 1,
          key: 'id',
          label: 'ID',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'description',
          label: 'Descrição',
          valueformat: (e) => {
            return e;
          },
        },
      ],
    },
  },
  columns: [
    {
      key: 'id',
      slideoverView: true,
      label: 'ID',
    },
    {
      key: 'code',
      slideoverView: true,
      label: 'Código',
    },
    {
      key: 'description',
      slideoverView: true,
      label: 'Descrição',
    },
    {
      key: 'type',
      slideoverView: true,
      label: 'Tipo',
    },
  ],

  filter: {
    initFilter: {
      entries: 10,
      page: 1
    },
    fields: [
      
    ],
  },
};
