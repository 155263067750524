import React, { useEffect, useState } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { reqOk, blankResult, getJson } from '../common/fetchdata';
import { getRequests as gt } from '../common/apiEndpoints';

import FormSlideover from '../common/slideover/FormSlideover';
import { formfield_uploadfile as fin } from "../forms/formfield_uploadfile";
import {
    HeartIcon,
} from '@heroicons/react/24/outline'
import {
    Bars4Icon,
    PencilIcon,
    CloudArrowUpIcon,
    Squares2X2Icon as Squares2X2IconMini,
} from '@heroicons/react/20/solid'
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

const tabs = [
    { name: 'Todos', href: '#', current: true },
    { name: 'Consulta', href: '#', current: false },
    { name: 'Médico', href: '#', current: false },
    { name: 'Paciente', href: '#', current: false },
]

const currentFile_default = {
    name: 'IMG_4985.HEIC',
    size: '3.9 MB',
    source:
        'https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80',
    information: {
        'Uploaded by': 'Marie Culver',
        Created: 'June 8, 2020',
        'Last modified': 'June 8, 2020',
        Dimensions: '4032 x 3024',
        Resolution: '72 x 72',
    },
    sharedWith: [
        {
            id: 1,
            name: 'Aimee Douglas',
            imageUrl:
                'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=1024&h=1024&q=80',
        },
        {
            id: 2,
            name: 'Andrea McMillan',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=oilqXxSqey&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    ],
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function isImageExtension(extension){
    if (
        extension == 'gif' ||
        extension == 'jpg' ||
        extension == 'jpeg' ||
        extension == 'jfif' ||
        extension == 'pjpeg' ||
        extension == 'pjp' ||
        extension == 'png' ||
        extension == 'svg' ||
        extension == 'webp' ||
        extension == 'bmp' ||
        extension == 'tif' ||
        extension == 'tiff' 
    )
    return true
    else return false
}

export default function FileListingPage({ callId = null, providerId = null, customerId = null }) {
    const [getRes, setGetRes] = useState(blankResult);
    const [getResMd, setGetResMd] = useState(blankResult);
    const [filelist, setFilelist] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);

    // Upload file
    const [edit_formData, edit_setFormData] = useState({});
    const [openAdd, setOpenAdd] = useState(false);
    const [formKey, setFormKey] = useState(0);

    const fetchData = (args = {}) => {
        if (callId != null)
            args = { ...args, callId: callId }
        if (providerId != null)
            args = { ...args, providerId: providerId }
        if (customerId != null)
            args = { ...args, customerId: customerId }
        getJson(gt.fileManager, { ...getRes, params: args }, setGetRes);
    };


    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (reqOk(getRes)) {
            setFilelist(getRes.items.data)
        }
    }, [getRes])

    function handleGetMetadata(fileKey) {
        getJson(gt.fileMetaData, { ...getResMd, params: { fileKey: fileKey } }, setGetResMd);
    }
    useEffect(() => {
        if (reqOk(getResMd)) {
            setCurrentFile({
                ...currentFile_default,
                ...getResMd.items.data
            })

            console.log(getResMd.items.data)
        }
    }, [getResMd])


    const edit_okHandler = (context, result) => {
        setFormKey((k) => k + 1)
        setOpenAdd(false)
        fetchData()
    };

    const edit_errHandler = (context, result) => {
        console.error('Error processing request')
        setFormKey((k) => k + 1)
        setOpenAdd(false)
    };

    return (
        <>

            <FormSlideover
                context={callId}
                fin={fin}
                key={formKey}
                open={openAdd}
                setOpen={setOpenAdd}
                formData={edit_formData}
                setFormData={edit_setFormData}
                okHandler={edit_okHandler}
                errHandler={edit_errHandler}
            />

            <div className="flex flex-1 flex-col overflow-hidden">

                {/* Main content */}
                <div className="flex flex-1 items-stretch overflow-hidden">
                    <main className="flex-1 overflow-y-auto">
                        <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
                            <div className="flex">
                                <h1 className="flex-1 text-2xl font-bold text-gray-900">Ficheiros</h1>
                                <div className="ml-6 flex items-center rounded-lg bg-gray-100 p-0.5 sm:hidden">
                                    <button
                                        type="button"
                                        className="rounded-md p-1.5 text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                    >
                                        <Bars4Icon className="h-5 w-5" aria-hidden="true" />
                                        <span className="sr-only">Use list view</span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-0.5 rounded-md bg-white p-1.5 text-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                    >
                                        <Squares2X2IconMini className="h-5 w-5" aria-hidden="true" />
                                        <span className="sr-only">Use grid view</span>
                                    </button>
                                </div>
                                <div className="ml-6 flex items-center rounded-lg bg-gray-100 p-0.5 sm:hidden">
                                    <button
                                        type="button"
                                        onClick={() => setOpenAdd(true)}
                                        className="rounded-md p-1.5 text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                    >
                                        <CloudArrowUpIcon className="h-5 w-5" aria-hidden="true" />
                                        <span className="sr-only">Upload file</span>
                                    </button>
                                </div>
                            </div>

                            {/* Tabs */}
                            {(callId == null && providerId == null && customerId == null) && <div className="mt-3 sm:mt-2">
                                <div className="sm:hidden">
                                    <label htmlFor="tabs" className="sr-only">
                                        Select a tab
                                    </label>
                                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                                    <select
                                        id="tabs"
                                        name="tabs"
                                        className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                        defaultValue="Recently Viewed"
                                    >
                                        <option>Recently Viewed</option>
                                        <option>Recently Added</option>
                                        <option>Favorited</option>
                                    </select>
                                </div>
                                <div className="hidden sm:block">
                                    <div className="flex items-center border-b border-gray-200">
                                        <nav className="-mb-px flex flex-1 space-x-6 xl:space-x-8" aria-label="Tabs">

                                            {tabs.map((tab) => (
                                                <a
                                                    key={tab.name}
                                                    href={tab.href}
                                                    aria-current={tab.current ? 'page' : undefined}
                                                    className={classNames(
                                                        tab.current
                                                            ? 'border-indigo-500 text-indigo-600'
                                                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                        'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                                                    )}
                                                >

                                                    {tab.name}
                                                </a>
                                            ))}
                                        </nav>
                                        <div className="ml-6 hidden items-center rounded-lg bg-gray-100 p-0.5 sm:flex">
                                            <button
                                                type="button"
                                                className="rounded-md p-1.5 text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                            >
                                                <Bars4Icon className="h-5 w-5" aria-hidden="true" />
                                                <span className="sr-only">Use list view</span>
                                            </button>
                                            <button
                                                type="button"
                                                className="ml-0.5 rounded-md bg-white p-1.5 text-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                            >
                                                <Squares2X2IconMini className="h-5 w-5" aria-hidden="true" />
                                                <span className="sr-only">Use grid view</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {/* Gallery */}
                            <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
                                <h2 id="gallery-heading" className="sr-only">
                                    Recently viewed
                                </h2>
                                <ul
                                    role="list"
                                    className="grid grid-cols-4 gap-x-4 gap-y-8 sm:grid-cols-6 sm:gap-x-6 md:grid-cols-8 lg:grid-cols-6 xl:grid-cols-8 xl:gap-x-8"
                                >
                                    {filelist.map((file, idx) => (
                                        <li key={idx} className="relative">
                                            <div
                                                onClick={() => handleGetMetadata(file.file_path)}
                                                className={classNames(
                                                    file.current
                                                        ? 'ring-2 ring-indigo-500 ring-offset-2'
                                                        : 'focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100',
                                                    'aspect-w-7 aspect-h-8 group block w-full overflow-hidden rounded-lg bg-gray-100'
                                                )}
                                            >
                                                <FileIcon extension={file.extension} {...defaultStyles[file.extension]} />

                                                <button type="button" className="absolute inset-0 focus:outline-none">
                                                    <span className="sr-only">View details for {file.filename}</span>
                                                </button>
                                            </div>
                                            <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                                                {file.filename}
                                            </p>
                                            <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.human_readable_size}</p>
                                        </li>
                                    ))}
                                </ul>
                            </section>
                        </div>
                    </main>

                    {/* Details sidebar */}
                    <aside className="hidden w-96 overflow-y-auto border-l border-gray-200 bg-white p-8 lg:block">
                        <div className="space-y-6 pb-16">
                            {currentFile != null &&

                                <>

                                    <div>

                                        {/* IMAGE PREVIEW */}
                                        <div className="aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg">
                                            { isImageExtension(currentFile.meta.extension) && 
                                                <img src={currentFile.download} alt="" className="object-cover" />}
                                        </div>
                                        <div className="mt-4 flex items-start justify-between">
                                            <div>
                                                <h2 className="text-lg font-medium text-gray-900">
                                                    <span className="sr-only">Details for </span>
                                                    {currentFile.meta.filename_extension}
                                                </h2>
                                                <p className="text-sm font-medium text-gray-500">{currentFile.meta.readable_size}</p>
                                            </div>
                                            <button
                                                type="button"
                                                className="relative ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                            >
                                                <span className="absolute -inset-1.5" />
                                                <HeartIcon className="h-6 w-6" aria-hidden="true" />
                                                <span className="sr-only">Favorite</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <h3 className="font-medium text-gray-900">Detalhes</h3>
                                        <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">

                                            <div className="flex justify-between py-3 text-sm font-medium">
                                                <dt className="text-gray-500">Extensão</dt>
                                                <dd className="whitespace-nowrap text-gray-900">{currentFile.meta.extension.toUpperCase()}</dd>
                                            </div>
                                            <div className="flex justify-between py-3 text-sm font-medium">
                                                <dt className="text-gray-500">Última modificação</dt>
                                                <dd className="whitespace-nowrap text-gray-900">{moment(currentFile.meta.ResponseMetadata.HTTPHeaders["last-modified"]).format('HH:mm, DD/MM/YYYY')}</dd>
                                            </div>
                                            <div className="flex justify-between py-3 text-sm font-medium">
                                                <dt className="text-gray-500">Data de criação</dt>
                                                <dd className="whitespace-nowrap text-gray-900">{moment(currentFile.meta.ResponseMetadata.HTTPHeaders.date).format('HH:mm, DD/MM/YYYY')}</dd>
                                            </div>
                                        </dl>
                                    </div>
                                    <div>
                                        <h3 className="font-medium text-gray-900">Descrição</h3>
                                        <div className="mt-2 flex items-center justify-between">
                                            <p className="text-sm italic text-gray-500">Adicionar uma descrição...</p>
                                            <button
                                                type="button"
                                                className="relative flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                            >
                                                <span className="absolute -inset-1.5" />
                                                <PencilIcon className="h-5 w-5" aria-hidden="true" />
                                                <span className="sr-only">Add description</span>
                                            </button>
                                        </div>
                                    </div>
                                    {/* <div>
                                        <h3 className="font-medium text-gray-900">Partilhado com</h3>
                                        <ul role="list" className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                                            {currentFile.sharedWith.map((person) => (
                                                <li key={person.id} className="flex items-center justify-between py-3">
                                                    <div className="flex items-center">
                                                        <img src={person.imageUrl} alt="" className="h-8 w-8 rounded-full" />
                                                        <p className="ml-4 text-sm font-medium text-gray-900">{person.name}</p>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        Remove<span className="sr-only"> {person.name}</span>
                                                    </button>
                                                </li>
                                            ))}
                                            <li className="flex items-center justify-between py-2">
                                                <button
                                                    type="button"
                                                    className="group -ml-1 flex items-center rounded-md bg-white p-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                >
                                                    <span className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-300 text-gray-400">
                                                        <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                    <span className="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">
                                                        Share
                                                    </span>
                                                </button>
                                            </li>
                                        </ul>
                                    </div> */}
                                    <div className="flex gap-x-3">
                                        <a
                                            href={currentFile.download}
                                            type="button"
                                            className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Download
                                        </a>
                                        <a
                                            href={currentFile.delete}
                                            type="button"
                                            className="flex-1 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        >
                                            Delete
                                        </a>
                                    </div>

                                </>}
                        </div>
                    </aside>
                </div>
            </div>
        </>
    )
}



