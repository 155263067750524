import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Transition } from '@headlessui/react'

// Tabs
import CallHomeContentInfo from './dashboard/CallHomeContentInfo'
import VCallActions from './video/VCallActions';
import AppointmentReminder from './controls/AppointmentReminder';

import TabNavigation from '../nav/tabs/TabNavigation';
import CallManagerAlert from './callmanager/CallManagerAlert';
import CallBillingPage from './billing/CallBillingPage';
import CallTextNotes from './CallTextNotes';
/* import CallFileList from './CallFileList'; */
import {
  getJson,
  putJson,
  blankResult,
  getFirst,
  reqOk,
} from '../common/fetchdata';
import { getRequests as gt } from '../common/apiEndpoints';
import useToken from '../hooks/useToken';
import { useParams } from 'react-router-dom';
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

import SoapRecords from './soap/SoapRecords';
import CallPageHeader from './header/CallPageHeader';
import CallSelectTreatmentsSlideover from './treatments/CallSelectTreatmentsSlideover';
import FileListingPage from '../filemanager/FileListingPage';
import TabNavigationControl from '../nav/tabs/TabNavigationControl';
import ActiveCallDashboard from './activecall/ActiveCallDashboard';


const CallPage = () => {

  const location = useLocation();
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const { search } = location;

  const { token } = useToken();
  const { id } = useParams();
  const context = id;

  const editorRef = useRef(null);
  const [txtContent, setTxtContent] = useState('');
  const [openTmSel, setOpenTmSel] = useState(false);
  const [getRes, setGetRes] = useState(blankResult);
  const [getStsRes, setGetStsRes] = useState(blankResult);
  const [putreq, setPutreq] = useState(blankResult);

  const startPage = 'home'
  const [selPage, setSelPage] = useState(startPage);
  // Add a state to track the content that should currently be displayed
  const [currentContent, setCurrentContent] = useState(<></>);



  const fetchData = () => {
    getJson(
      gt.call,
      { ...getRes, token: token, params: { callId: context } },
      setGetRes
    );
  };

  const tabs = [
    {
      title: 'Início',
      key: 'home',
      idx: 0,
      content: <> {reqOk(getRes) &&
        <CallHomeContentInfo
          call={getFirst(getRes)}
          editTtm={() => setOpenTmSel(true)}
        />
      }</>,
      disabled: false,
    },

    {
      title: 'Ficheiros',
      key: 'files',
      idx: 1,
      content: (
        <>
          {reqOk(getRes) &&
            <FileListingPage callId={getFirst(getRes).id} />
          }</>
      ),
      disabled: false,
    },
    {
      title: 'SOAP',
      key: 'medical',
      idx: 2,
      content: <ActiveCallDashboard call={reqOk(getRes) && getFirst(getRes)} />,
      disabled: !(reqOk(getRes) && getFirst(getRes).has_soap),
    },
    {
      title: 'Faturação',
      key: 'billing',
      idx: 3,
      content: <>
        {reqOk(getRes) &&
          <CallBillingPage
            call={getFirst(getRes)}
            refreshHandler={fetchData}
          />
        }

      </>,
      disabled: !(reqOk(getRes) && getFirst(getRes).get_billing_info != null),
    },
  ];


  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // capture page load
    const newSearchParams = new URLSearchParams(searchParams);
    const currentView = newSearchParams.get('view');
    if (currentView != null &&
      tabs.map(t => t.key).indexOf(currentView) > -1) {
      setSelPage(tabs.filter(t => t.key == currentView)[0].key)
    }
  }, [search])


  // Transition Effect

  

  useEffect(() => {
    if (reqOk(getRes)) {
      setTxtContent(getFirst(getRes).call_notes);

      if (getFirst(getRes).servsubtype != null && getFirst(getRes).servsubtype.is_remote) obtainCallStatistics();
    }
  }, [getRes]);

  useEffect(() => {
    if (reqOk(putreq)) {
      fetchData();
    }
  }, [putreq]);




  const obtainCallStatistics = () => {
    getJson(
      gt.logcallstat,
      { ...getStsRes, token: token, params: { callId: context } },
      setGetStsRes
    );
  };

  function navigateToTab(key) {

    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.delete('view');
    newSearchParams.set('view', key);
    navigate(`${location.pathname}?${newSearchParams.toString()}`);

    setTimeout(() => {
      setCurrentContent(selPage);
    }, 1000); // Match your leaving transition duration

  }


  return (
    <>

      {reqOk(getRes) &&
        <div className="flex flex-col">

          {getFirst(getRes).customer_managers != null && (
            <CallManagerAlert ent={getFirst(getRes).customer_managers} />
          )}

          <CallSelectTreatmentsSlideover
            context={getFirst(getRes)}
            open={openTmSel}
            setOpen={setOpenTmSel}
            errHandler={() => {
              setOpenTmSel(false)
            }}
            okHandler={() => {
              fetchData()
              setOpenTmSel(false)
            }}
          />

          <CallPageHeader tabs={tabs} selPage={selPage} navigateTo={navigateToTab} call={getFirst(getRes)} />
          {
            tabs.map(t =>
              <Transition
                key={t.key}
                show={t.key === selPage}
                enter="transition-opacity duration-1000"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-1000"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {t.content}
              </Transition>
            )
          }


        </div>}
    </>
  );
};

export default CallPage;
