import { useState, useContext, useEffect } from 'react';
import {
    PencilSquareIcon,
    TagIcon
} from '@heroicons/react/24/outline'
import LeadAssignmentMenu from '../actions/LeadAssignmentMenu'
import { doStatusChange, statusLabel } from '../actions/leadTags'
import { GlobalStateContext } from '../../state/globalState';
import { blankResult, reqOk } from "../../common/fetchdata";
import { loadingScreen } from '../../common/fetchdata';
import { updateLeadsList } from '../data/messagingSelect'
import { useNavigate,  useSearchParams } from 'react-router-dom';

const navigation = [
    { name: 'Adicionar nota', clickref: (e) => console.log(e), icon: PencilSquareIcon, current: false },
    { name: 'Atribuir', clickref: (e) => console.log(e), icon: TagIcon, current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MessagingContextMenu({ selectBatch, setSelectBatch }) {
    const globalcontext = useContext(GlobalStateContext);
    const [pres, setPres] = useState(blankResult);

    let navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        loadingScreen(pres, globalcontext)
        if(reqOk(pres)){
            updateLeadsList(searchParams, navigate)
            setSelectBatch([])
        }
    }, [pres]);



    return (


        <div className="flex justify-center items-center pt-12">
            <div className="bg-gray-50 p-6 rounded-lg shadow-lg w-64">
                <nav className="flex flex-1 flex-col" aria-label="Sidebar">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <div className="text-sm font-semibold leading-6 text-gray-400">{
                            `${selectBatch.length} lead(s) seleccionada(s)`}

                            <button
                                type="button"
                                onClick={() => setSelectBatch([])}
                                className="mt-3 rounded bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                            >
                                Remover seleção
                            </button>
                        </div>
                        <li>
                            <div className="text-xs font-semibold leading-6 text-gray-400">Operações em lote</div>
                            <ul role="list" className="-mx-2 space-y-1">
                                <li className="relative h-10">
                                    <LeadAssignmentMenu
                                        selectedTopic={selectBatch.map(id => ({ id }))}
                                        handleRefresh={() => console.log('refresh')}
                                        actionBtn={
                                            <a
                                                href="#"
                                                onClick={(e) => console.log(e)}
                                                className={classNames(
                                                    'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                )}
                                            >
                                                <TagIcon
                                                    className={classNames('h-6 w-6 shrink-0')}
                                                    aria-hidden="true"
                                                />
                                                Atribuir
                                            </a>
                                        }
                                    />
                                </li>
                                {/* {navigation.map((item) => (
                                    <li key={item.name}>
                                        <a
                                            href="#"
                                            onClick={(e) => item.clickref(e)}
                                            className={classNames(
                                                item.current
                                                    ? 'bg-gray-50 text-indigo-600'
                                                    : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                                    'h-6 w-6 shrink-0'
                                                )}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                            {item.count ? (
                                                <span
                                                    className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                                                    aria-hidden="true"
                                                >
                                                    {item.count}
                                                </span>
                                            ) : null}
                                        </a>
                                    </li>
                                ))} */}
                            </ul>
                        </li>

                        <li>
                            <div className="text-xs font-semibold leading-6 text-gray-400">Definir estado</div>
                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                                {statusLabel.map((item) => (
                                    <li key={item.name}>
                                        <a
                                            href="#"
                                            onClick={() => doStatusChange(
                                                globalcontext,
                                                item.value,
                                                selectBatch.map(id => ({ id })),
                                                pres,
                                                setPres)}
                                            className={classNames(
                                                'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                            )}
                                        >
                                            <span
                                                className={classNames(
                                                    'text-gray-400  group-hover:border-indigo-600 group-hover:text-indigo-600',
                                                    'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium',
                                                    item.bg
                                                )}
                                            >
                                                {item.initial}
                                            </span>
                                            <span className="truncate">{item.name}</span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>







    )
}
