import React from 'react';

export const tableconfig = {
  title: 'Clientes Web',
  pickable: false,
  pickSubmitTxt: 'Select',
  searchable: false,
  columns: [
    {
      key: 'name',
      label: 'Nome',
    },
    {
      key: 'age',
      label: 'Idade',
    },
    {
      key: 'n_calls',
      label: 'Marcações',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'phone',
      label: 'Telemóvel',
    },
  ],
  button: {
    label: 'Ver',
    href: (r) => {
      return '/customer/' + r.id;
    },
  },
  expandField: 'customers',
  expandTab: (_r) => {
    return (
      <>
        <div className="row">
          <div className="col">
            <div className="datagrid-item">
              <div className="datagrid-title">Nome</div>
              <div className="datagrid-content">{`${_r.name} ${_r.lastname}`}</div>
            </div>
          </div>
          <div className="col">
            <div className="datagrid-item">
              <div className="datagrid-title"></div>
              <div className="datagrid-content">{_r.gender}</div>
            </div>
          </div>
          <div className="col">
            <div className="datagrid-item">
              <div className="datagrid-title">Idade</div>
              <div className="datagrid-content">{_r.phone_loc}</div>
            </div>
          </div>
        </div>
      </>
    );
  },
  filter: {
    initFilter: {
      entries: 10,
      page: 1,
      customerName: '',
      customerEmail: '',
      customerPhone: '',
    },
    fields: [
      {
        autocomplete: 'off',
        placeholder: '',
        element: 'input',
        type: 'text',
        inputgrouptext: 'Nome',
        formname: 'customerName',
      },
      {
        autocomplete: 'off',
        placeholder: '',
        element: 'input',
        type: 'text',
        inputgrouptext: 'Email',
        formname: 'customerEmail',
      },
      {
        autocomplete: 'off',
        placeholder: '',
        element: 'input',
        type: 'text',
        inputgrouptext: 'Telemóvel',
        formname: 'customerPhone',
      },
    ],
  },
};
