import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { errorToast, successToast } from '../../toasts';

import { FileIcon, defaultStyles } from 'react-file-icon';
import { UserCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'

const reExt = /(?:\.([^.]+))?$/;

function _arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};


export default function MultifileUpload({ setSelectedFiles, acceptedFiletypes }) {
  const [submitFile, setSubmitFile] = useState([])

  const {
    acceptedFiles,
    fileRejections,
    open,
    getRootProps,
    getInputProps
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: acceptedFiletypes,
    onDrop: useCallback(acceptedFiles => {
      const reader = new FileReader();
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          
          const blob = new Blob([reader.result], { type: file.type });
          const blobUrl = URL.createObjectURL(blob);
        
          console.log(blob.size)
          setSubmitFile(sf => ([...sf, {
            blob: blob,
            blobUrl: blobUrl,
            success: true,
            path: file.path,
            filetype: file.type,
            info: '',
          }]))

        };
        reader.readAsArrayBuffer(file);
      });

      successToast(`${acceptedFiles.length} ficheiro(s) adicionado(s)`)
    }, [])
  });

  const clearFiles = () => {
    successToast(`Ficheiros removidos`)
    setSubmitFile([]);  // Clear the files array when the clear button is clicked
  };

  const removeFile = (path) => {
    successToast(`Ficheiro removido`)
    setSubmitFile(f => ([...f.filter(fp => fp.path != path)]));  // Clear the files array when the clear button is clicked
  };

  const editFileInfo = (e, path) => {
    setSubmitFile(f => (
      f.map(fp =>
        fp.path === path
          ? { ...fp, info: e }
          : fp
      )
    ));
  };

  useEffect(() => {
    if(submitFile.length == 0){
      setSelectedFiles(submitFile)
    }
    else{
      let submObjs = {}
      submitFile.forEach((element, idx) => {
        submObjs = {...submObjs,
          [`file${idx}-blob`]: element.blob,
          [`file${idx}-path`]: element.path,
          [`file${idx}-info`]: element.info,
          [`file${idx}-filetype`]: element.filetype,
        }
      });
      setSelectedFiles(submObjs)
    }
    
  },[submitFile])

  const acceptedFileItems = submitFile.map(file => (  // Update to use files state instead of acceptedFiles

    <div key={file.path} className="col-span-full py-6 gap-y-3 flex flex-col">
      <div className="mt-2 flex flex-row items-center gap-x-3">
        <div className="w-8 h-8 text-gray-300">
          <FileIcon extension={reExt.exec(file.path)[1]} {...defaultStyles[reExt.exec(file.path)[1]]} />
        </div>

        <div>
          {file.path}
        </div>
        <button
          onClick={() => removeFile(file.path)}
          type="button"
          className="rounded-md bg-white px-1.5 py-1.5 text-sm font-bold text-red-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <XMarkIcon className="w-4" />
        </button>
      </div>
      <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
        <label htmlFor="name" className="block text-xs font-medium text-gray-900">
          Descrição
        </label>
        <input
          type="text"
          name="name"
          id="name"
          className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder="Informações..."
          value={file.info}
          onChange={(e) => editFileInfo(e.target.value, file.path)}
        />
      </div>
    </div>

  ));



  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Arraste os ficheiros para aqui ou clique no botão abaixo.</p>
        <div className="space-x-3 p-3">
          <button
            type="button"
            onClick={open}
            className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Adicionar ficheiros
          </button>

          {submitFile.length > 0 && <button
            type="button"
            onClick={clearFiles}
            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Remover todos
          </button>}
        </div>

      </div>
      <aside>
        <ul className="divide-y divide-gray-100">{acceptedFileItems}</ul>
      </aside>
    </section>
  );
}


