import { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';

const leadOptions = [
  { key: 'ONGOING', name: 'Aberto/Pendente' },
  { key: 'DONE', name: 'Fechado' }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function MessagingCardFilter() {

  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  function ticketStatus() {
    let _status = searchParams.get('status');
    if (_status == null)
      return 'ONGOING'
    return _status
  }

  const [mem, setMem] = useState(ticketStatus)

  function changeTicketStatus(mode) {

    let _status = searchParams.get('status');
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('_k');

    if (mode == 'ONGOING') newSearchParams.set('status', mode);
    if (mode == 'DONE') newSearchParams.set('status', mode);

    const uniqueKey = new Date().getTime();
    newSearchParams.set('_k', uniqueKey);
    const newURL = `${location.pathname}?${newSearchParams.toString()}`;

    navigate(newURL, { replace: true });
    /* 
    

    if (_status) _status = _status.split(',')
    else _status = []

    if (ev) {
      // add
      if (_status.indexOf(id) == -1) _status = [..._status, id]
    }
    else {
      // remove
      if (_status.indexOf(id) > -1) _status = _status.filter(e => e !== id)
    }

    _status = _status.toString()
    const newSearchParams = new URLSearchParams(searchParams);
    
    const newURL = `${location.pathname}?${newSearchParams.toString()}`;
    navigate(newURL, { replace: true }); */
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <h2 className="text-sm font-medium leading-6 text-gray-900">Estado</h2>

      </div>

      <div className="mt-2">
        <div className="sr-only">Choose a memory option</div>
        <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
          {leadOptions.map((option) => (
            <div
              onClick={() => {
                changeTicketStatus(option.key)
                setMem(option.key)
              }
              }
              key={option.name}
              value={option}
              className={classNames(
                'cursor-pointer focus:outline-none',
                mem == option.key
                  ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                  : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                'flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1'
              )}
            >
              <div as="span">{option.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
