import { useState } from 'react'
import { TableCellsIcon } from '@heroicons/react/20/solid'
import DataTableSlideover from './DataTableSlideover'

export default function FilterPanel({ content, filters, data }) {
    const [openSo, setOpenSo] = useState(false)

    return (
        <>
            <div className="flex min-h-full flex-col">


                {/* 3 column wrapper */}
                <div className="mx-auto w-full grow lg:flex">
                    {/* Left sidebar & main wrapper */}
                    <div className="flex-1 xl:flex">

                        <div className="xl:flex-1 p-4">
                            {content}
                        </div>
                    </div>

                    <div className="hidden lg:block shrink-0 border-t border-gray-200 py-6 px-1 lg:w-12 lg:border-l lg:border-t-0 lg:pr-8 xl:pr-6 bg-slate-100">
                        <div className="h-10 w-10 flex items-center justify-center rounded-lg cursor-pointer hover:text-gray-800 hover:bg-white  hover:duration-300 hover:ease-linear focus:bg-white">
                            <TableCellsIcon className="h-6 w-6" onClick={() => setOpenSo(true)} />
                            <DataTableSlideover open={openSo} setOpen={setOpenSo} filters={filters} data={data} />
                        </div>{' '}
                    </div>
                </div>
            </div>
        </>
    )
}
