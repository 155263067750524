import React, { Fragment, useContext, useEffect, useState } from 'react';
import { GlobalStateContext } from '../../state/globalState';
import { PaperClipIcon } from '@heroicons/react/20/solid';
import { PencilIcon } from '@heroicons/react/24/outline';
import AvatarXs from '../../common/card/AvatarXs';
import AvatarXXS from '../../common/card/AvatarXXS';
import { reqOk, blankResult, getJson, postJson } from '../../common/fetchdata';
import { getRequests as gt } from '../../common/apiEndpoints';
import { postRequests as po } from '../../common/apiEndpoints';
import useToken from '../../hooks/useToken';
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function NoContent() {
  return (
    <div className="text-center mt-6">

    </div>
  );
}

export default function CallReceptionNotes({ callId }) {
  const globalcontext = useContext(GlobalStateContext);
  const { token } = useToken();
  const [msgStack, setMsgStack] = useState(blankResult);
  const [msgSubmit, setMsgSubmit] = useState(blankResult);
  const [message, setMessage] = useState('');

  const fetchData = () => {
    getJson(
      gt.receptionnote,
      { ...msgStack, token: token, params: { callId: callId } },
      setMsgStack
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    postJson(
      po.receptionnote,
      {
        ...msgSubmit,
        token: token,
        payload: { callId: callId, content: message },
      },
      setMsgSubmit
    );
    setMessage('');
  };

  const user = globalcontext.contextFcn.userData.data.items.user;

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (reqOk(msgSubmit)) fetchData();
  }, [msgSubmit]);

  return (
    <>
      <div className="flex h-full flex-col ">
        <ul role="list" className="space-y-6">
          {reqOk(msgStack) &&
            (msgStack.items.data.length == 0 ? (
              <NoContent />
            ) : (
              msgStack.items.data.map((msg, k) => (
                <li key={k} className="relative flex gap-x-4">
                  <div
                    className={classNames(
                      k === msgStack.items.data.length - 1
                        ? 'h-6'
                        : '-bottom-6',
                      'absolute left-0 top-0 flex w-6 justify-center'
                    )}
                  >
                    <div className="w-px bg-gray-200" />
                  </div>
                  <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                    <div className="flex justify-between gap-x-4">
                      {msg.users != null && <div className="py-0.5 text-xs leading-5 text-gray-500">
                        <span className="font-medium text-gray-900">
                          {msg.users.fullname}
                        </span>{' '}
                        escreveu
                      </div>}
                      <time
                        dateTime={msg.created_at}
                        className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                      >
                        {moment(msg.created_at).fromNow()}
                      </time>
                    </div>
                    <p className="text-sm leading-6 text-gray-500">
                      {msg.content}
                    </p>
                  </div>
                </li>
              ))
            ))}
        </ul>
      </div>

      <div className="px-4 py-4">
        <div className="flex gap-x-3 w-full">
          <AvatarXs person={user} />
          <form className="relative flex-auto" onSubmit={handleSubmit}>
            <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
              <label htmlFor="comment" className="sr-only">
                Deixe uma nota
              </label>
              <textarea
                rows={2}
                name="comment"
                id="comment"
                className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Deixe uma nota"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
              <div className="flex items-center space-x-5">
                <div className="flex items-center">
                  <button
                    type="button"
                    className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                  >
                    <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Attach a file</span>
                  </button>
                </div>
                <div className="flex items-center"></div>
              </div>
              <button
                type="submit"
                className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
