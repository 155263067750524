import React, { Fragment } from 'react';
import { getJson, blankResult, getFirst, reqOk } from '../common/fetchdata';
import { getRequests as gt } from '../common/apiEndpoints';
import useToken from '../hooks/useToken';
import {
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function EventDetail({ selEvent }) {
  const { token } = useToken();
  const [getRes, setGetRes] = React.useState(blankResult);

  React.useEffect(() => {
    fetchData();
  }, [selEvent]);

  const fetchData = () => {
    getJson(
      gt.call,
      { ...getRes, token: token, params: { callId: selEvent.id } },
      setGetRes
    );
  };
  return (
    <>
      {reqOk(getRes) && (
        <>
          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="mt-4">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {getFirst(getRes).customers.avatar_url == null ? (
                      <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                        <span className="text-sm font-medium leading-none text-white">
                          {getFirst(getRes).customers.initials}
                        </span>
                      </span>
                    ) : (
                      <img
                        className="mx-auto w-8 flex-shrink-0 rounded-sm"
                        src={`../static/uploads/${
                          getFirst(getRes).customers.avatar_url
                        }`}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="ml-4">
                    <h3 className="text-sm font-semibold leading-6 text-gray-900">
                      {`${getFirst(getRes).customers.name} ${
                        getFirst(getRes).customers.lastname
                      }`}
                    </h3>
                    <p className="text-sm font-light text-gray-500">
                      <a href="#">{`${
                        getFirst(getRes).customers.tax_id_ref
                      }`}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="border-b border-gray-200 bg-white py-5">
            <div className="min-w-0 flex-1">
              <span className="text-md font-bold leading-7 text-gray-900 sm:truncate">
                {`${getFirst(getRes).servsubtype.name}`}
              </span>
              <span className="text-sm font-normal leading-7 text-gray-700 sm:truncate">
                {` ${getFirst(getRes).servsubtype.servtype.name}`}
              </span>
              <div className="mt-1 flex flex-col  space-y-3">
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  {` ${moment(getFirst(getRes).datetm).format('H:mm')}`}
                  <span className="ml-3 text-xs font-normal leading-7 text-gray-700 truncate">{` ${
                    getFirst(getRes).servsubtype.duration_min
                  } min`}</span>
                </div>
                {/* <div className="mt-2 flex items-center text-sm text-gray-500">
                  <BriefcaseIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  Full-time
                </div> */}
                {/*  <div className="mt-2 flex items-center text-sm text-gray-500">
                  <MapPinIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  Remote
                </div> */}
              </div>
            </div>
          </div>

          <div className="border-b border-gray-200 bg-white py-5">
            <div className="flex">
              <button
                type="button"
                className="text-xs inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <CheckIcon
                  className="-ml-0.5 mr-1.5 h-4 w-4"
                  aria-hidden="true"
                />
                Check-in
              </button>

              {/* Dropdown */}
              <Menu as="div" className="relative ml-3 ">
                <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                  Mais
                  <ChevronDownIcon
                    className="-mr-1 ml-1.5 h-4 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700'
                          )}
                        >
                          Edit
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700'
                          )}
                        >
                          View
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </>
      )}
    </>
  );
}
