export const smsStatus =  [
    { status :'ENROUTE', state: 'PENDING' },
    { status :'SCHEDULED', state: 'PENDING' },
    { status :'DELIVERED', state: 'DELIVERED' },
    { status :'EXPIRED', state: 'FAILED' },
    { status :'UNDELIVERABLE', state: 'FAILED' },
    { status :'ACCEPTED', state: 'DELIVERED' },
    { status :'REJECTED', state: 'FAILED' },
    { status :'SKIPPED', state: 'FAILED' },
    { status :'BUFFERED', state: 'PENDING' },
    { status :'UNKNOWN', state: 'PENDING' },
]