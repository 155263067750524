import React, { useState, createRef, useEffect } from 'react';
import CallCalendar from '../call/CallCalendar';
import CalendarWrapper from '../call/calendar/CalendarWrapper';

const ProviderCalendarSummary = ({ context, gotoCalls }) => {
  const calendarRef = createRef();
  const [currentViewRange, setCurrentViewRange] = useState({
    start: null,
    end: null,
  });

  useEffect(() => {
    calendarRef.current.getApi().changeView('timeGridDay');
  }, [calendarRef])

  return (
    <section aria-labelledby="applicant-information-title">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-2 py-4 sm:px-6">
          <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
            Calendário
          </h2>
        </div>
        <div className="border-t border-gray-200 pb-2 py-0 sm:px-6 h-96">
          <CalendarWrapper
            showViewSelectionControl={false}
            showDateRangeLabel={false}
            calendarRef={calendarRef}
            currentViewRange={currentViewRange}
            calendar={
              <CallCalendar
                initialView={'timeGridWeek'}
                calendarRef={calendarRef}
                context={context}
                currentViewRange={currentViewRange}
                setCurrentViewRange={setCurrentViewRange}
              />
            }
          />
        </div>
        <div>
          <a
            href="#"
            onClick={() => gotoCalls()}
            className="block bg-gray-50 px-4 py-4 text-center text-sm font-medium text-gray-500 hover:text-gray-700 sm:rounded-b-lg"
          >
            Ver mais
          </a>
        </div>
      </div>
    </section>

  );
};

export default ProviderCalendarSummary;
