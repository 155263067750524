import React from 'react';
import { GlobalStateContext } from '../state/globalState';
import { UserPlusIcon } from '@heroicons/react/24/solid';
import CustomerCard from '../client/CustomerCard';
import CustomerInsurances from '../client/dashboard/CustomerInsurances';

const PickCustomer = ({ token, handleConfirm }) => {
  const globalcontext = React.useContext(GlobalStateContext);
  const parkRef = 'newBookingPickCustomer';
  const [optSel, setOptSel] = React.useState(null);
  const [customer, setCustomer] = React.useState(null);

  React.useEffect(() => {
    globalcontext.contextFcn.parkVar.reset(parkRef);
  }, []);

  React.useEffect(() => {
    if (globalcontext.contextFcn.parkVar.get(parkRef).val) {
      const c = globalcontext.contextFcn.parkVar.get(parkRef).val;
      setCustomer(c);
    }
  }, [globalcontext.contextFcn.parkVar.get(parkRef)]);

  React.useEffect(() => {
    if (optSel != null && optSel >= 0) {
      handleConfirm(customer, optSel);
    }
  }, [optSel]);

  return (
    <>
      {customer == null && (
        <button
          onClick={() =>
            globalcontext.contextFcn.panel.search.open({
              universe: 'customer',
              mode: 'select',
              parkRef: parkRef,
            })
          }
          type="button"
          className="relative block w-full h-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <UserPlusIcon className="mx-auto h-12 w-12 text-gray-400" />

          <span className="mt-2 block text-sm font-semibold text-gray-900">
            Seleccione o Paciente
          </span>
        </button>
      )}
      {customer != null && (
        <div className="grid place-items-center w-full space-y-6 px-4 sm:px-16">
          <div className="flex flex-col ">
            <CustomerCard person={customer} />

            <div>
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  <span className="" />
                  {customer.fullname}
                </p>
                <p className="mt-1 flex text-xs leading-5 text-gray-500">
                  <span className="relative truncate hover:underline">
                    {customer.email}
                  </span>
                </p>
              </div>
            </div>
            <button
              onClick={() =>
                globalcontext.contextFcn.panel.search.open({
                  universe: 'customer',
                  mode: 'select',
                  parkRef: parkRef,
                })
              }
              type="button"
              className="mt-3 inline-flex items-center justify-center rounded-md bg-white px-1 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Alterar
            </button>
            {/*    <button
              onClick={() => handleConfirm(customer)}
              type="button"
              className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Confirmar
            </button> */}
          </div>
          <div className="border-b w-full" />
          <div className="pb-4" >
            <CustomerInsurances
              profile={customer}
              mode={'select'}
              optSel={optSel}
              setOptSel={setOptSel}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PickCustomer;
