/* eslint-disable no-unused-vars */
import React from 'react';
import CallNotes from '../call/callnotes/CallNotes';
import { reqOk } from '../common/fetchdata';
import { Transition } from '@headlessui/react';

const CallTools = ({ token, viewPanel, navExpanded }) => {
  const [app, setApp] = React.useState([]);
  const [files, setFiles] = React.useState([]);

  return (
    <>
      <div
        className={
          `mb-3 mr-3 fixed z-10 bottom-0 ` +
          `  duration-500 flex flex-col justify-between transition-all  ${
            navExpanded ? 'right-64' : 'right-12'
          }`
        }
      >
       

        <Transition
          show={viewPanel == 2}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-300"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-300"
          leaveTo="opacity-0"
        >
          <CallNotes token={token} />
        </Transition>
      </div>
    </>
  );
};

export default CallTools;
