import { countryCodes } from './countrycodes';

const gender = [
  { value: 'F', desc: 'Feminino' },
  { value: 'M', desc: 'Masculino' },
  { value: 'U', desc: 'Indefinido' },
];

export const afilliations = [
  { idx: 1, value: 'MOTHER', desc: 'Mãe' },
  { idx: 2, value: 'FATHER', desc: 'Pai' },
  { idx: 3, value: 'STEPMOTHER', desc: 'Madrasta' },
  { idx: 4, value: 'STEPFATHER', desc: 'Padrasto' },
  { idx: 5, value: 'SON', desc: 'Filho(a)' },
  { idx: 6, value: 'UNCLE', desc: 'Tio' },
  { idx: 7, value: 'AUNT', desc: 'Tia' },
  { idx: 8, value: 'TUTOR', desc: 'Tutor' },
  { idx: 9, value: 'OTHER', desc: 'Outro' },
]

export const personal_data = [
  { key: 'name', local: 'pt', order: 1, label: 'Nome' },
  { key: 'lastname', local: 'pt', order: 2, label: 'Último Nome' },
  {
    key: 'gender',
    local: 'pt',
    order: 3,
    label: 'Género',
    format: (e) => {
      return gender.filter((c) => c.value == e)[0].desc;
    },
  },
  { key: 'dateofbirth', local: 'pt', order: 4, label: 'Data de Nascimento' },
  { key: 'age', local: 'pt', order: 5, label: 'Idade' },
  {
    key: 'nationality',
    local: 'pt',
    order: 6,
    label: 'Nacionalidade',
    format: (e) => {
      return countryCodes.filter((c) => c.value == e)[0].desc;
    },
  },
  { key: 'civil_state', local: 'pt', order: 7, label: 'Estado Civil' },
  { key: 'pt_tax_no', local: 'pt', order: 20, label: 'NIF' },
  { key: 'nid_id_ref', local: 'pt', order: 21, label: 'CC' },
  { key: 'nhc_id_ref', local: 'pt', order: 22, label: 'Número Beneficiário' },
  { key: 'ss_id_ref', local: 'pt', order: 23, label: 'Segurança Social' },
  { key: 'alt_id_type', local: 'pt', order: 30, label: 'Doc. alternativo' },
  { key: 'alt_id_doc_num', local: 'pt', order: 31, label: 'ID Documento' },
  { key: 'alt_id_country', local: 'pt', order: 32, label: 'País Documento' },
];

export const afiliation_data = [
  { key: 'father_name', local: 'pt', order: 1, label: 'Nome Pai' },
  { key: 'father_lastname', local: 'pt', order: 2, label: 'Último Nome Pai' },
  { key: 'mother_name', local: 'pt', order: 3, label: 'Nome Mãe' },
  { key: 'mother_lastname', local: 'pt', order: 4, label: 'Último Nome Mãe' },
];

export const address_data = [
  { key: 'address_line1', local: 'pt', order: 1, label: 'Morada' },
  { key: 'address_line2', local: 'pt', order: 2, label: 'Morada (detalhes)' },
  { key: 'address_postcode', local: 'pt', order: 3, label: 'Código Postal' },
  { key: 'address_city', local: 'pt', order: 4, label: 'Cidade' },
  { key: 'address_region', local: 'pt', order: 5, label: 'Região' },
  {
    key: 'address_country',
    local: 'pt',
    order: 6,
    label: 'País',
    format: (e) => {
      let cLabel = countryCodes.filter((c) => c.value == e)
      cLabel = cLabel.length == 0 ? e : cLabel[0].desc
      return cLabel
    },
  },
];
