import React from 'react';
import { FunnelIcon } from '@heroicons/react/20/solid';

const SpeedDialFilter = ({ onClickAction, nFilters }) => {
  return (
    <>
      <div data-dial-init="" className="fixed right-6 bottom-6 group z-10">
        <div className="relative">

          {(nFilters != null && nFilters > 0) && <span className="absolute -top-2 -right-2 h-6 w-6 rounded-full bg-red-600 flex justify-center items-center items text-sm text-white">{nFilters}</span>
          }
          <button
            onClick={onClickAction}
            type="button"
            data-dial-toggle="speed-dial-menu-default"
            aria-controls="speed-dial-menu-default"
            aria-expanded="false"
            className="flex items-center justify-center text-white hover:text-slate-400 bg-blue-700 rounded-full w-14 h-14 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800"
          >
            <FunnelIcon className="w-8 h-8" />
            <span className="sr-only">Filtrar</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default SpeedDialFilter;
