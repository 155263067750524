import { useState } from 'react'



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  
export default function CustomerAdvancedOptions() {


    return (
        <>
            <main className="px-4 sm:px-6 lg:flex-auto pb-8">
                <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Configurações Avançadas</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-500">
                            A edição das seguintes opções apenas terá efeitos sobre o Paciente seleccionado.
                        </p>

                        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                          
                           
                            <div className="pt-6 sm:flex">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Eliminar</dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                    <div className="text-gray-900">Esta ação removerá a ficha de utilizador assim com as consultas e eventuais dados associados</div>
                                    <button type="button" className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400">
                                        Sim, apagar a ficha do paciente
                                    </button>
                                </dd>
                            </div>
                        </dl>
                    </div>

                   

                  
                </div>
            </main>
        </>
    )
}