import React, { useEffect } from 'react';
import { Switch } from '@headlessui/react'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
export default function ToggleModule({
  field,
  formData,
  setFormData,
  formVal,
  setFormVal }) {

  // default value
  useEffect(() => {
    if (formData[field.name] == '') {
      setFormData({
        ...formData,
        [field.name]: false,
      });
    }
  }, []);


  // initialize
  useEffect(() => {
    setFormVal((f) => ({
      ...f, [field.name]: {
        ...formVal[field.name],
        isTouched: false,
        isValid: isValid(formData[field.name])
      }
    }))
  }, [])


  // on update
  useEffect(() => {
    if (formData[field.name] !== '')
      setFormVal((f) => ({
        ...f, [field.name]: {
          ...formVal[field.name],
          isTouched: true,
          isValid: isValid(formData[field.name])
        }
      }))
  }, [formData[field.name]])

  function isValid(dt) {
    if (!field.required) return true
    else return formData[field.name] != null && formData[field.name] !== ''
  }



  return (
    <>
      <Switch
        checked={formData[field.name]}
        onChange={(e) =>
          setFormData({
            ...formData,
            [field.name]: e,
          })


        }
        className={classNames(
          formData[field.name] ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
        )}
      >
        <span className="sr-only">Toggle</span>
        <span
          className={classNames(
            formData[field.name] ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        >
          <span
            className={classNames(
              formData[field.name] ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
            )}
            aria-hidden="true"
          >
            <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            className={classNames(
              formData[field.name] ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
            )}
            aria-hidden="true"
          >
            <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </Switch>
      {
        (formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) &&
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {'validationerr' in field ? field.validationerr : 'Escolha uma opção'}
        </p>

      }
    </>
  );
}
