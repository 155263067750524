import { getRequests as gt } from '../common/apiEndpoints';

const rows = [
  {
    selector: 'input',
    required: true,
    class: '',
    type: 'text',
    name: 'name',
    title: 'Nome',
    placeholder: 'Nome do espaço...',
    id: 'newspace__name',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Nome Inválido.',
  },

  {
    selector: 'input',
    required: true,
    class: '',
    type: 'text',
    name: 'area',
    title: 'Área',
    placeholder: 'Area do espaço...',
    id: 'newspace__area',
    map: true,
    validation: (f) =>
      /^\d+$/.test(String(f)) && f != "",
    validationerr: 'Área inválida',
  },
  {
    selector: 'stackedit',
    required: false,
    name: 'space_features',
    title: 'Seguros',
    id: 'space_features',
    map: true,
    stack: {
      title: 'name',
      subtitle: 'name',
      listItemView: (e) => {
        let item = e
        if (!('space_features' in item))
          item = {
            space_features: item

          }
        return (
          <>
            <span className="truncate font-medium">
              {item.space_features.name}
            </span>

          </>
        );
      },
      fields: [
        {
          selector: 'selectspacefeatures',
          required: true,
          name: 'space_features',
          title: 'Equipamentos',
          noselectTxt: ' Escolha...',
          id: 'space_features',
          map: true,
        }
      ],
    },
  },
];

export const formfield_newspace = {
  title: 'Novo Espaço',
  description: 'Adicione um novo espaço à clínica',
  apiEndpoint: '/api/spaces',
  method: 'POST',
  preloadForm: false,
  name: '',
  id: '',
  submitLabel: 'Adicionar',
  rows: rows,
};

export const formfield_editspace = {
  title: 'Editar Espaço',
  description: 'Edite as características do espaço',
  apiEndpoint: '/api/spaces',
  method: 'PUT',
  preloadForm: true,
  name: '',
  id: '',
  submitLabel: 'Editar',
  deleteAction: {
    label: 'Eliminar',
  },
  rows: rows,
};
