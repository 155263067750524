import React from 'react';

const Avatar = ({ person, sz, fontSz = 'base',  avatarSm = false }) => {
  return (
    <>
      {person.avatar_url_sm != null ? (
        <img
          className={`w-${sz} h-${sz} rounded-full`}
          src={`../static/uploads/${avatarSm ? person.avatar_url_sm : person.avatar_url}`}
          alt="Rounded avatar"
        ></img>
      ) : (
        <div className={`relative inline-flex items-center justify-center w-${sz} h-${sz} overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600`}>
          <span className={`font-medium text-gray-600 dark:text-gray-300 text-${fontSz}`}>
            {person.initials}
          </span>
        </div>
      )}
    </>
  );
};

export default Avatar;
