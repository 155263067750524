import React from 'react';
import { Fragment } from 'react';
import CustomerPhoto from './CustomerPhoto';

import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';
import TabNavigation from '../../nav/tabs/TabNavigation';
//import CommunicationPage from '../../messaging/communication/CommunicationPage';

import CallListing from '../../call/CallListing';
import { getJson, blankResult, getFirst, reqOk } from '../../common/fetchdata';
import { getRequests as gt } from '../../common/apiEndpoints';
import PaymentListing from '../../payment/PaymentListing';
import PrescriptionListing from '../../prescription/PrescriptionListing';
import useToken from '../../hooks/useToken';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { GlobalStateContext } from '../../state/globalState';

import CustomerPersonalData from './CustomerPersonalData';
import CustomerAdvancedOptions from './CustomerAdvancedOptions';
import CustomerSummaryPage from './CustomerSummaryPage';
import TabNavigationControl from '../../nav/tabs/TabNavigationControl';

export default function CustomerHome() {
  const globalcontext = React.useContext(GlobalStateContext);
  
  let navigate = useNavigate();
  const { token } = useToken();
  const [getRes, setGetRes] = React.useState(blankResult);
  const { id } = useParams();
  const context = id;
  const [sel, setSel] = React.useState(0);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (args = {}) => {
    getJson(
      gt.customer,
      { ...getRes, token: token, params: { customerId: context } },
      setGetRes
    );
  };

  React.useEffect(() => {
    if (reqOk(getRes))
      globalcontext.contextFcn.userDataConfig.setBookmark('customer', getFirst(getRes))
  }, [getRes])


  function gotoCalls(){
    setSel(2)
  }

  function gotoPersonDetails(){
    setSel(1)
  }

  const tabs = [
    {
      title: 'Resumo',
      idx: 0,
      content: (
        <>
          {reqOk(getRes) && (
            <CustomerSummaryPage
              profile={getFirst(getRes)}
              handleRefresh={fetchData}
              gotoCalls={gotoCalls}
              gotoPersonDetails={gotoPersonDetails}
            />
          )}
        </>
      ),
      disabled: false,
    },
    {
      title: 'Dados Pessoais',
      idx: 1,
      content: (
        <>
          {reqOk(getRes) && (
            <CustomerPersonalData
              profile={getFirst(getRes)}
              handleRefresh={fetchData}
            />
          )}
        </>
      ),
      disabled: false,
    },
/*     {
      title: 'Comunicações',
      idx: 1,
      content: <CommunicationPage />,
      disabled: false,
    }, */
    {
      title: 'Marcações',
      idx: 2,
      content: (
        <CallListing
          context={{ group: 'customer', ref: context, field: 'customerId' }}
        />
      ),
      disabled: false,
    },
    {
      title: 'Prescrições',
      idx: 3,
      content: (
        <PrescriptionListing
          token={token}
          showBreadcrumbs={false}
          showLeftFilter={false}
          defaultFilter={{ customerId: context }}
        />
      ),
      disabled: false,
    },
    {
      title: 'Pagamentos',
      idx: 4,
      content: (
        <PaymentListing
          context={context}
        />
      ),
      disabled: false,
    },
    {
      title: 'Avançadas',
      idx: 5,
      content: (
        <CustomerAdvancedOptions
          context={context}
        />
      ),
      disabled: false,
    }
  ];



  return (
    <>
      <div className="flex h-full">
        <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
          <article>
            <div className="mx-auto px-4 sm:px-6 lg:px-8 w-full">
              <section aria-labelledby="profile-overview-title w-full">
                <div className="overflow-hidden">
                  <h2 className="sr-only" id="profile-overview-title">
                    Perfil
                  </h2>

                  <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="sm:flex sm:space-x-5">
                      <div className="flex-shrink-0">
                        {reqOk(getRes) && (
                          <CustomerPhoto profile={getFirst(getRes)} />
                        )}
                      </div>
                      <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                        <p className="text-xl font-bold text-gray-900 sm:text-xl">
                          {reqOk(getRes) &&
                            `${getFirst(getRes).name} ${getFirst(getRes).lastname
                            }`}
                        </p>
                        <p className="text-sm font-medium text-gray-600">
                          {reqOk(getRes) && 'Paciente' }
                        </p>
                      </div>
                    </div>

                    <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                      <button
                        type="button"
                        className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        <EnvelopeIcon
                          className="-ml-0.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        Mensagem
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        <PhoneIcon
                          className="-ml-0.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        Ligar
                      </button>
                      <button
                        onClick={() =>
                          navigate(`/call/new?customerId=${getFirst(getRes).id}`)
                        }
                        type="button"
                        className="inline-flex justify-center gap-x-1.5 rounded-md  px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300  bg-indigo-600  text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="-ml-0.5 h-5 w-5 text-gray-100"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M12.5 21h-6.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5"></path>
                          <path d="M16 3v4"></path>
                          <path d="M8 3v4"></path>
                          <path d="M4 11h16"></path>
                          <path d="M16 19h6"></path>
                          <path d="M19 16v6"></path>
                        </svg>
                        Marcar
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/* Tabs */}
            <div className="border-b border-gray-200">
              <nav className="mx-auto w-full px-4 sm:px-6 lg:px-8">
                <TabNavigationControl sel={sel} setSel={setSel} tabs={tabs} />
              </nav>
            </div>
          </article>
        </main>
      </div>
    </>
  );
}
