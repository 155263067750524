import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, PlayIcon } from '@heroicons/react/20/solid';
import { GlobalStateContext } from '../../state/globalState';
import useToken from '../../hooks/useToken';
import { putJson, blankResult, reqOk } from '../../common/fetchdata';
import { putRequests as put } from '../../common/apiEndpoints';
import { 
        ArrowPathRoundedSquareIcon, 
        ArrowRightOnRectangleIcon, 
        ArrowUturnLeftIcon, 
        BanknotesIcon, 
        DocumentTextIcon, 
        NoSymbolIcon, 
        PlayCircleIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function CallActionMenu({ data, refreshHandler }) {
  const globalcontext = React.useContext(GlobalStateContext);
  const { token } = useToken();
  const [putreq, setPutreq] = React.useState(blankResult);

  const editData = (args = {}) => {
    putJson(
      put.call,
      { ...putreq, token: token, payload: { ...args, callId: data.id } },
      setPutreq
    );
  };

  React.useEffect(() => {
    if (reqOk(putreq)) refreshHandler();
  }, [putreq]);


  const doAction = (alertType, message, action) => {
    // type, title, message, okTrigg
    globalcontext.contextFcn.panel.modal.open(
      alertType,
      message,
      () => editData({ callState: action })
    );
  };

  function actionVisible(action) {
    if (data.get_call_available_actions == null) return false
    return data.get_call_available_actions.map(a => a == action).filter(a => a == true).length > 0
  }

  const actions = [
    {
      visible: actionVisible('MISSED'), separator: false, icoClass: 'text-red-700',
      icon: ArrowUturnLeftIcon, label: 'Falta',
      click: () => doAction('danger', 'Confirma que o paciente faltou à consulta?', 'MISSED')
    },
    {
      visible: actionVisible('CANCELBOOK_PAC'), separator: false,
      icoClass: 'text-sky-700', icon: NoSymbolIcon, label: 'Desmarcar (paciente)',
      click: () => doAction('danger', 'Confirma que a consulta foi desmarcada a pedido do paciente?', 'CANCELBOOK_PAC')
    },
    {
      visible: actionVisible('CANCELBOOK_PRV'), separator: false,
      icoClass: 'text-sky-700', icon: NoSymbolIcon, label: 'Desmarcar (médico)',
      click: () => doAction('danger', 'Confirma que a consulta foi desmarcada a pedido do médico?', 'CANCELBOOK_PRV')
    },
    {
      visible: actionVisible('ABANDONEMENT'), separator: true,
      icoClass: 'text-red-700', icon: ArrowRightOnRectangleIcon, label: 'Desistência',
      click: () => doAction('danger', 'Confirma que o paciente desistiu da consulta?', 'ABANDONEMENT')
    },
    {
      visible: actionVisible('RESCHEDULE'), separator: true,
      icoClass: 'text-sky-700', icon: ArrowPathRoundedSquareIcon, label: 'Remarcar',
      click: () => console.log('checkin')
    },
    {
      visible: actionVisible('CHECKIN'), separator: false,
      icoClass: 'text-emerald-500', icon: CheckIcon, label: 'Check-in',
      click: () => doAction('info', 'Confirma o Check-in do paciente?', 'CHECKIN')
    },
    {
      visible: actionVisible('STARTCALL'), separator: true,
      icoClass: 'text-emerald-500', icon: PlayCircleIcon, label: 'Iniciar',
      click: () => doAction('info', 'Confirma o início da consulta?', 'STARTCALL')
    },
    {
      visible: actionVisible('ENDCALL'), separator: false,
      icoClass: 'text-emerald-500', icon: PlayCircleIcon, label: 'Terminar',
      click: () => doAction('info', 'Confirma o término da consulta?', 'ENDCALL')
    },
    {
      visible: actionVisible('PAYCALL'), separator: false,
      icoClass: '', icon: BanknotesIcon, label: 'Pagar',
      click: () => globalcontext.contextFcn.customer.actions.callPayment(data)
    },
    {
      visible: actionVisible('ATTENDANCEREP'), separator: false,
      icoClass: '', icon: DocumentTextIcon, label: 'Justificativo Presença',
      click: () => globalcontext.contextFcn.customer.actions.attendanceReport(data)
    },
  ]

  function visibleActions() {
    return actions.filter(a => a.visible == true).length
  }

  return (
    <>
      <Menu as="div" className="relative ">
        <Menu.Button className="inline-flex items-center rounded-md bg-white px-2 py-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
          <ChevronDownIcon
            className=" h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {visibleActions() > 0 ? actions.map((ac, ai) =>

              <Fragment key={ai}>
                {ac.visible && <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      onClick={ac.click}
                      className={`${classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )} ${ac.separator ? 'border-b' : ''}`}
                    >
                      <span className="flex flex-row">
                        <ac.icon className={`h-5 w-5 mr-2 ${ac.icoClass}`} />
                        {ac.label}
                      </span>

                    </a>
                  )}
                </Menu.Item>}
              </Fragment>
            ) :
              <div className='block px-4 py-2 text-sm text-gray-700'>Sem opções disponíveis</div>}

          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
