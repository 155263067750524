import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { formfield_editcallstate as fin } from '../../forms/formfield_callstate';

export const tableconfig = {
  edit: {
    showEditButton: true,
    type: 'slideover',
    template: fin,
  },
  view: {
    title: 'Estados de Consulta',
    description: '',
    type: 'slideover',
    template: {
      nCols: 2,
      data: [
        {
          colSpan: 1,
          key: 'id',
          label: 'ID',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'name',
          label: 'Nome',
          valueformat: (e) => {
            return e;
          },
        },
      ],
    },
  },
  columns: [
    {
      key: 'icon',
      slideoverView: true,
      label: 'Ícone',
      valueformat: (e) => {
        return <span className="text-2xl">{e}</span>;
      },
    },
    {
      key: 'key',
      slideoverView: true,
      label: 'ID',
    },
    {
      key: 'is_system',
      slideoverView: true,
      label: 'Sistema',
      valueformat: (e) => {
        return   <>
        {e ? (
          <CheckCircleIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
            aria-hidden="true"
          />
        ) : (
          ''
        )}
      </>;
      },
    },
    {
      key: 'label',
      slideoverView: true,
      label: 'Descrição',
    },
  ],
  filter: {
    initFilter: {
      entries: 10,
      page: 1,
    },
    fields: [
      
    ],
  },
};
