import React, { useEffect, useState  } from 'react';
import MultifileUpload from './fileupload/MultifileUpload';

export default function MultiFileUploadModule({
  field,
  formData,
  setFormData,
  formVal,
  setFormVal
}) {

  const [submitFile, setSubmitFile] = useState([])


  // initialize
  useEffect(() => {
    setFormVal((f) => ({
      ...f, [field.name]: {
        ...formVal[field.name],
        isTouched: false,
        isValid: isValid(formData[field.name])
      }
    }))
  }, [])


  // on update
  useEffect(() => {
    if (formData[field.name] !== '')
      setFormVal((f) => ({
        ...f, [field.name]: {
          ...formVal[field.name],
          isTouched: true,
          isValid: isValid(formData[field.name])
        }
      }))
  }, [formData[field.name]])

  function isValid(dt) {
    if (!field.required) return true
    else return dt != ''
  }

  useEffect(() => {
    setFormData({ ...formData, [field.name]: submitFile });
  },[submitFile])
  

  return (
    <>
  
      <MultifileUpload
        setSelectedFiles={setSubmitFile}
        acceptedFiletypes={field.acceptedFiletypes}
      /> 
    </>
  );
}
