
export function hexToRgb(hex) {
    let bigint = parseInt(hex.slice(1), 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return [r, g, b];
}

export function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function generateGradient(colorA, colorB, steps) {
    let [rA, gA, bA] = hexToRgb(colorA);
    let [rB, gB, bB] = hexToRgb(colorB);

    let gradientColors = [];

    for (let i = 0; i < steps; i++) {
        let ratio = i / (steps - 1);

        let r = Math.round(rA + ratio * (rB - rA));
        let g = Math.round(gA + ratio * (gB - gA));
        let b = Math.round(bA + ratio * (bB - bA));

        gradientColors.push(rgbToHex(r, g, b));
    }

    return gradientColors;
}