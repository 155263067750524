



import { countryCodes } from '../label/countrycodes';
import { postRequests as pt } from '../common/apiEndpoints';

const phonecentral_newinterna_fields = [
  {
    selector: 'input',
    required: true,
    class: '',
    type: 'text',
    name: 'description',
    title: 'Descrição',
    placeholder: 'João',
    id: 'description',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Descrição Inválida.',
  },
  {
    selector: 'select',
    required: true,
    options: [
      { desc: 'Não se aplica', value: '#NA#', dial_code: '' },
      ...countryCodes
    ],
    name: 'phone_cc',
    title: 'Indicativo',
    placeholder: 'Escolha',
    id: 'phone_cc',
    fieldTitle: 'desc',
    noselectTxt: 'Escolha...',
    map: true,
  },
  {
    selector: 'input',
    required: true,
    class: '',
    type: 'text',
    name: 'phone_loc',
    title: 'Telefone',
    placeholder: '910202021',
    id: 'phone_loc',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Telefone Inválido.',
  },
 
  {
    selector: 'toggle',
    name: 'hide_outgoing',
    title: 'Ocultar realizadas',
    subtitle: '',
    id: 'hide_outgoing',
    map: true
  },
  {
    selector: 'toggle',
    name: 'is_private',
    title: 'Número Privado',
    subtitle: '',
    id: 'is_private',
    map: true
  },
  {
    selector: 'toggle',
    name: 'is_extension',
    title: 'É uma extensão?',
    subtitle: '',
    id: 'is_extension',
    map: true
  },
]


export const formfield_phonecentral_newinternal = {
  title: "Novo Número Interno",
  apiEndpoint: pt.phonecentralmanagerinternal,
  successMessage: "Alterações guardadas",
  method: "POST",
  preloadForm: false,
  name: "",
  id: "",
  submitLabel: "Guardar",
  rows: [
   ...phonecentral_newinterna_fields
  ],
};

