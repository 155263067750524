import React from 'react';
import { postRequests as post } from "../common/apiEndpoints";

const weekdays = [
  { id: 1, value: 1, label: 'Segunda' },
  { id: 2, value: 2, label: 'Terça' },
  { id: 3, value: 3, label: 'Quarta' },
  { id: 4, value: 4, label: 'Quinta' },
  { id: 5, value: 5, label: 'Sexta' },
  { id: 6, value: 6, label: 'Sábado' },
  { id: 7, value: 7, label: 'Domingo' },
];

export const formfield_newavailability = {
  title: 'Adicionar',
  description: 'Adicione períodos de disponibilidade',
  apiEndpoint: post.calendarslots,
  method: 'POST',
  preloadForm: false,
  name: '',
  id: '',
  submitLabel: 'Adicionar',
  rows: [

    {
      selector: 'daterangepicker',
      required: true,
      name: 'daterange',
      selectType: 'range',
      allowPast: false,
      title: 'Intervalo',
      placeholder: 'Intervalo de datas',
      id: 'newavailability_daterange',
      map: true,
    },
    {
      selector: 'reactselect',
      required: true,
      name: 'weekdays',
      title: 'Dias da semana',
      placeholder: 'Dias da semana',
      id: 'newavailability_weekdays',
      optType: 'static',
      staticOpt: weekdays,
      defValues: [1,2,3,4,5],
      map: true,
    },
    {
      selector: 'stackedit',
      required: true,
      name: 'slots',
      title: 'Horas',
      id: 'newavailability_slots',
      map: true,
      stack: {
        title: 'name',
        subtitle: 'name',
        listItemView: (e) => {
          return (
            <>
              <span className="truncate font-medium">{`Início: ${e.slotstart}`}</span>
              <span className="flex-shrink-0 text-gray-400">{`Fim: ${e.slotend}`}</span>
            </>
          );
        },
        fields: [
          {
            selector: 'input',
            required: true,
            type: 'time',
            name: 'slotstart',
            title: 'Início',
            placeholder: 'Início',
            id: 'newavailability_slots_slotstart',
            map: true,
            validation: (f) => f.length > 0,
          },
          {
            selector: 'input',
            required: true,
            type: 'time',
            name: 'slotend',
            title: 'Fim',
            placeholder: 'Fim',
            id: 'newavailability_slots_slotend',
            map: true,
            validation: (f) => f.length > 0,
          },
        ],
      },
    },
  ],
};
