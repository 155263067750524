export function userConfigDefaultData(version) {
    return (
        {
            version: version,
            recentResults: {
                providerList: [],
                customerList: [],
                treatmentsList: [],
                invoicesList: [],
            }
        })
}