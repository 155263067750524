import React from 'react';
import { useEffect } from 'react';
import Select from 'react-select';

export default function ReactSelectModule({
  field,
  isValid,
  formData,
  setFormData,
  formVal,
  setFormVal
}) {

  // initialize
  useEffect(() => {
    setFormVal((f) => ({
      ...f, [field.name]: {
        ...formVal[field.name],
        isTouched: false,
        isValid: isValid()
      }
    }))
  }, [])


  // on update
  useEffect(() => {
    if (formData[field.name] !== '')
      setFormVal((f) => ({
        ...f, [field.name]: {
          ...formVal[field.name],
          isTouched: true,
          isValid: isValid()
        }
      }))
  }, [formData[field.name]])


  function isValid(){
    if(!field.required) return true
    else return !(formData[field.name].length == 0)
  }

  return (
    <>
    {}
      <Select
        isMulti
        name={field.name}
        id={field.id}
        value={formData[field.name] != null ? formData[field.name] : ''}
        className={`block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6 bg-white text-gray-900 placeholder:text-gray-400 ${(formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) ? ' ring-red-300 focus:ring-red-500' : 'ring-gray-300 focus:ring-indigo-600'} `}
        classNamePrefix="select"
        onChange={(e) => {
          setFormData({
            ...formData,
            [field.name]: e,
          });
        }}
        options={field.optType == 'static' ? field.staticOpt : []}
        noOptionsMessage={() => 'Sem opções disponíveis'}
      />
      {
        (formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) &&
        <p className="mt-2 text-sm text-red-600" id="email-error">
          Escolha pelo menos uma opção
        </p>

      }
    </>
  );
}
