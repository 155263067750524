import React from "react";
import AvatarWithDesc from "../../common/card/AvatarWithDesc";

export default function RecentItemsList({ recentResults }) {


    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 mt-4">

            <div>

                <ul className="space-y-3">
                    {recentResults != null && recentResults.customer.map((rr, idx) =>
                        <li key={idx}>
                            <AvatarWithDesc person={rr.content} desc={"Paciente"} />
                        </li>
                    )}
                </ul>

            </div>
            <div>
                <ul className="space-y-3">
                    {recentResults != null && recentResults.provider.map((rr, idx) =>
                        <li key={idx}>
                            <AvatarWithDesc person={rr.content} desc={"Médico"} />
                        </li>
                    )}
                </ul>
            </div>

        </div>

    )
}