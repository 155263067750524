import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { errorToast, successToast } from '../../toasts';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import './upload-photo-styles.css';
import { MagnifyingGlassIcon, ArrowPathIcon } from '@heroicons/react/20/solid';
import getCroppedImg from './cropImage';

export default function PhotoUpload({ setImage }) {
  const [photoFile, setPhotoFile] = useState({ success: false });

  //CROPPER
  const [preview, setPreview] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const dzRef = React.useRef(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  /*   const onReset = () => {
    setPreview();
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);
    setCroppedAreaPixels(null);
    setCroppedImage(null);
    setResult(blankResult);
    setPhotoFile({ success: false });
  }; */

  const submitCroppedImage = useCallback(async () => {
    try {
      const _croppedImage = await getCroppedImg(
        preview,
        croppedAreaPixels,
        rotation
      );
      let blob = await fetch(_croppedImage).then((r) => r.blob());
      setImage(blob);
    } catch (e) {
      errorToast(JSON.stringify(e));
    }
  }, [croppedAreaPixels, rotation]);

  React.useEffect(() => {
    if (preview != null && croppedAreaPixels != null && rotation != null)
      submitCroppedImage();
  }, [preview, croppedAreaPixels, rotation]);

  //AUTO LOAD PIC
  useEffect(() => {
    if (!photoFile.success) {
      setPreview(undefined);
      return;
    }
    if (photoFile.success) {
      const objectUrl = URL.createObjectURL(photoFile.blob);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [photoFile]);

  function _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const focusedStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          errorToast('A imagem ultrapassa o tamanho máximo!');
        }

        if (err.code === 'file-invalid-type') {
          errorToast('A imagem não é válido!');
        }
      });
    });

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => errorToast('Leitura da imagem abortada!');
      reader.onerror = () => errorToast('Erro na leitura da imagem!');
      reader.onload = () => {
        const binaryStr = reader.result;

        const base64 = _arrayBufferToBase64(binaryStr);
        const blob = b64toBlob(base64);
        const blobUrl = URL.createObjectURL(blob);
        setPhotoFile({ blob: blob, blobUrl: blobUrl, success: true });
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    maxFiles: 1,
    maxSize: 2621440,
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <div>
        <div className={`${photoFile.success ? 'hidden' : ''}`}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />

            <div className={`text-center text-xs`} ref={dzRef}>
              {photoFile.success
                ? 'Clique para mudar a doto.'
                : 'Arraste a foto para aqui ou clique para escolher o ficheiro. Apenas imagens até 2.5MB são permitidas.'}
            </div>
          </div>
        </div>

        {photoFile.success && (
          <div>
            <div className="container">
              <div className="row">
                <div className="flex space-x-4 flex-row relative mb-2 w-full h-52">
                  <div className="relative w-72 gap-3">
                    {' '}
                    <Cropper
                      image={preview}
                      crop={crop}
                      zoom={zoom}
                      rotation={rotation}
                      aspect={1}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                      onRotationChange={setRotation}
                    />
                  </div>

                  <div className=" w-28">
                    <dl className="flex flex-wrap">
                      <div className="flex-auto">
                        <dt className="pt-3 grid place-items-center">
                          <button
                            onClick={() => dzRef.current.click()}
                            type="button"
                            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            Alterar
                          </button>
                        </dt>
                      </div>

                      <div className="mt-4 flex w-full flex-none border-t border-gray-900/5 pt-6">
                        <dt className="flex-none">
                          <span className="sr-only">Zoom</span>
                          <MagnifyingGlassIcon
                            className="h-6 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </dt>
                        <dd className="pt-0.5 pl-1">
                          <input
                            type="range"
                            className="range range-xs range-primary"
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            onChange={(e) => setZoom(e.target.value)}
                          />
                        </dd>
                      </div>

                      <div className="mt-6 flex w-full flex-none border-t border-gray-900/5 pt-6">
                        <dt className="flex-none">
                          <span className="sr-only">Rodar</span>
                          <ArrowPathIcon
                            className="h-6 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </dt>
                        <dd className="pt-0.5 pl-1">
                          <input
                            type="range"
                            className="range range-xs range-primary"
                            value={rotation}
                            min={0}
                            max={359}
                            step={1}
                            onChange={(e) => setRotation(e.target.value)}
                          />
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
