import axios from "axios";

import { apiEndpoint, postRequests as post } from "../common/apiEndpoints";
import { errorToast, successToast } from "../common/toasts";


const authProvider = {

  isAuthenticated: false,
  async signin(callback, recaptchaToken, username, password, otpCode, fingerprint, passwordResetToken, setToken) {
    let res = {};
    try {
      
      axios.defaults.withCredentials = true
      res = await axios({
        method: "POST",
        url: post.login,
        data: { recaptchaToken: recaptchaToken, 
          username: username, 
          password: password, 
          otpCode: otpCode, 
          fingerprint: fingerprint,
          passwordResetToken
        },
      });

      if (res.status == 200 && res.data.is_authenticated && res.data.response_token) {
        authProvider.isAuthenticated = true;
        setToken(res.data.response_token);
        callback({ ...res.data, error: false });
      } else {
        callback({ ...res.data, error: false });
      }
    } catch (reason) {
      console.log(reason)
      callback({ error: true, message: reason.response.data.message });
    }
  },


  async signout(callback, removeToken) {
    authProvider.isAuthenticated = false;
    const res = await axios({ method: "POST", url: apiEndpoint + "/auth/logout" });
    if (res.status == 200) {
      removeToken();
      callback();
    }
  },

  
};

export { authProvider };
