import React, { useState, createRef, useEffect } from 'react';
import CalendarWrapper from './CalendarWrapper';
import CallCalendar from '../CallCalendar';
import { callsToEvent } from '../../common/calendar/calendarfunctions'


export default function CalendarPage({
  setDateFcn,
  callEvents }) {

  const calendarRef = createRef();
  const [currentViewRange, setCurrentViewRange] = useState({
    start: null,
    end: null,
  });
  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    if (currentViewRange.start != null & currentViewRange.end != null && setDateFcn) setDateFcn(currentViewRange)
  }, [currentViewRange])



  useEffect(() => {
    if (callEvents) {
      setCalendarEvents(callsToEvent(callEvents))
    }
  }, [callEvents])

  return (
    <>
      <CalendarWrapper
        calendarRef={calendarRef}
        currentViewRange={currentViewRange}
        calendar={
          <CallCalendar
            initialView={'timeGridDay'}
            calendarRef={calendarRef}
            currentViewRange={currentViewRange}
            setCurrentViewRange={setCurrentViewRange}
            events={calendarEvents}
          />
        }
      />
    </>
  );
}
