import { Fragment, useEffect, useState } from 'react'
import { PlusIcon } from '@heroicons/react/20/solid';
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');



export default function CallTreatmentsList({ callTreatments, newHandler, groupByDay = true }) {

    return (
        <div className="">
            {callTreatments == null ?
                <div className="text-center">

                    <h3 className="mt-2 text-sm font-semibold text-gray-900">Sem dados</h3>
                    <p className="mt-1 text-sm text-gray-500">Adicione um novo tratamento.</p>
                    <div className="mt-6">
                        <button
                            onClick={newHandler}
                            type="button"
                            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >

                            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                            Novo
                        </button>
                    </div>
                </div>
                :
                <table className="w-full text-left">
                    <thead className="sr-only">
                        <tr>
                            <th>Tratamento</th>
                            <th>Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        {callTreatments.map((treatment) => (
                            <tr key={treatment.id}>
                                <td className="relative py-5 pr-6">
                                    <div className="flex gap-x-6">

                                        <div className="flex-auto">
                                            <div className="flex items-start gap-x-3 flex-col">
                                                <div className="text-sm font-medium leading-6 text-gray-900">

                                                    <div className="tooltip text-left" data-tip={treatment.obs}>
                                                        {treatment.treatment_type != null ? treatment.treatment_type.description : ''}
                                                    </div>


                                                </div>
                                                <div className="mt-1 text-xs leading-5 text-gray-500">{treatment.servtype != null ? treatment.servtype.name : 'N/A'}</div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                    <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                </td>
                                <td className="py-5 text-right">
                                    <div className="flex justify-end">
                                        <span
                                            className="text-xs font-small leading-6 text-indigo-600 hover:text-indigo-500"
                                        >
                                            {moment(treatment.datetm).format('D MMMM  YYYY')}

                                        </span>
                                    </div>

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
        </div>
    )
}
