import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

export default function CalendarActionMenu({ show, hide, slot }) {
  return (
    <div className="">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Transition
              as={Fragment}
              show={show}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 mt-3  max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="flex flex-row-reverse">
                  <div className="ml-1 -translate-y-1">
                    <button
                      type="button"
                      className="bg-white rounded-md p-1 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                      onClick={hide}
                    >
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="w-40">
                    <div className="relative overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="bg-gray-50 p-4">
                        <span className="flex items-center">
                          <span className="text-xs font-medium text-gray-900">
                            {`Início: ${moment(slot.start).format('H:mm')}`}
                          </span>
                        </span>
                      </div>

                      <div className="relative  bg-white p-6">
                        <a
                          href="#"
                          className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <div className="">
                            <p className="text-sm font-medium text-gray-900">
                              Marcar consulta
                            </p>
                          </div>
                        </a>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
