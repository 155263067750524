import React from 'react';
import { postRequests as post } from '../common/apiEndpoints';
import { afilliations } from '../label/customer';

export const formfield_customerattendance = {
  title: 'Alterar dados',
  description: '',
  apiEndpoint: post.customeractions,
  method: 'POST',
  preloadForm: false,
  reqArg: { type: 'customer_attendancereport' },
  name: '',
  id: '',
  submitLabel: 'Obter',
  rows: [
    {
      selector: 'daterangepicker',
      required: true,
      name: 'date',
      selectType: 'single',
      allowPast: true,
      title: 'Data',
      placeholder: 'Data',
      id: 'attendancerep__date',
      map: true,
    },
    {
      selector: 'startendtime',
      required: true,
      name: 'time',
      title: 'Horas',
      id: 'attendancerep__time',
      map: true,
    },
    {
      selector: 'stackedit',
      required: true,
      name: 'reason',
      title: 'Motivo Ausência',
      id: 'reason',
      map: true,
      stack: {
        title: 'name',
        subtitle: 'name',
        listItemView: (e) => {
          return (
            <>
              <span className="truncate font-medium">{e.reason.desc}</span>
              <span className="flex-shrink-0 text-gray-400">{e.reason.value == 'O' ? e.reason_other : ''}</span>
            </>
          );
        },
        fields: [
          {
            selector: 'select',
            required: true,
            options: [
              { value: 'T', desc: 'Tratamento' },
              { value: 'C', desc: 'Consulta médica' },
              { value: 'E', desc: 'Exame complementar de diagnóstico' },
              { value: 'O', desc: 'Outro (indicar)' },
            ],
            name: 'reason',
            title: 'Motivo',
            placeholder: 'Escolha',
            id: 'attendance_reason',
            noselectTxt: 'Escolha...',
            fieldTitle: 'desc',
            map: true,
          },
          {
            selector: 'input',
            required: true,
            type: 'text',
            conditional: {
              parentField: 'reason',
              parentValue: 'O',
            },
            name: 'reason_other',
            title: '',
            placeholder: 'Indique o motivo da ausência...',
            id: 'attendance_other_reason',
            map: true,
            validation: (f) => f.length > 0,
            validationerr: 'Valor inválido',
          },
        ],
      },
    },
    {
      selector: 'stackedit',
      name: 'waswith',
      title: 'Acompanhante',
      id: 'waswith',
      map: true,
      stack: {
        title: 'name',
        subtitle: 'name',
        listItemView: (e) => {
          return (
            <>
              <span className="truncate font-medium">{e.affiliation.name}</span>
              <span className="flex-shrink-0 text-gray-400">
                {e.affiliation.affiliation.value == 'OTHER' ? e.affiliation.customAffiliation
                  :
                  afilliations.filter(
                    (a) => a.value == e.affiliation.affiliation.value
                  )[0].desc
                }
              </span>
            </>
          );
        },
        fields: [
          {
            selector: 'selectwaswith',
            required: true,
            name: 'affiliation',
            title: 'Acompanhado por',
            id: 'affiliation',
          },
        ],
      },
    },
    {
      selector: 'radioselect',
      required: true,
      name: 'deliverymethod',
      title: 'Enviar',
      subtitle: 'Como deseja receber o documento?',
      id: 'deliverymethod',
      map: true,
      fields: [
        {
          label: 'Descarregar',
          id: 'download',
        },
        {
          label: 'Enviar por email',
          id: 'sendemail',
        },
      ],
    },
  ],
};

