
import React from 'react'
import { formfield_editinsurance as fin } from '../../forms/formfield_insurance';

export const tableconfig = {
  edit: {
    showEditButton: true,
    type: 'slideover',
    template: fin,
  },
  view: {
    title: 'Detalhes do Seguro',
    description: '',
    type: 'slideover',
    template: {
      nCols: 2,
      data: [
        {
          colSpan: 1,
          key: 'id',
          label: 'ID',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'name',
          label: 'Nome',
          valueformat: (e) => {
            return e;
          },
        },
      ],
    },
  },
  columns: [
    {
      key: 'avatar_url',
      class: 'pr-0 w-4',
      label: '',
      valueformat: (e, row) => {
        return (
          <>
            {e == null ? (
              <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <span className="font-medium text-gray-600 dark:text-gray-300">
                  {`${row.name[0]}`}
                </span>
              </div>
            ) : (
              <img
                className="w-10 h-10 rounded-sm"
                src={`../static/uploads/${e}`}
                alt="Rounded avatar"
              />
            )}
          </>
        );
      },
    },
    {
      key: 'name',
      slideoverView: true,
      label: 'Nome',
    },
  ],

  filter: {
    initFilter: {
      entries: 10,
      page: 1
    },
    fields: [
      
    ],
  },
};
