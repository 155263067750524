import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, FunnelIcon } from '@heroicons/react/20/solid';
import ReactSlider from 'react-slider';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}


const TableFilter = ({
  cfg,
  tfilter,
  setFilter,
  resetFilter,
  downloadResults,
  filtLabel,
  setFiltLabel
}) => {


  const tabOptions = [
    { name: 'Descarregar', click: () => downloadResults(), current: true },
    { name: 'Importar', click: '#', current: false },
    { name: 'Combinar', click: '#', current: false },
  ];

  function removeTag(tag) {
    setFiltLabel((f) => {
      const newData = { ...f };
      delete newData[tag];
      return newData;
    });
    setFilter((f) => {
      const newData = { ...f };
      delete newData[tag];
      return newData;
    });
  }

  useEffect(() => {
    let cFiltLbl = filtLabel || {}
    cfg.columns.map(
      c => {
        if ('filter' in c && c.filter.fieldDest in tfilter) {
          // Add to labels
          let labels = ''
          tfilter[c.filter.fieldDest].map(l => {
            let labelValue = ''
            if ('getLabelFromValue' in c.filter) {
              const transl = c.filter.getLabelFromValue.filter(k => k.key == l)
              if (transl.length > 0) labelValue = transl[0].label
            }
            else {
              labelValue = l
            }
            labels = labels + labelValue + ', '
          })

          const label = c.filter.fieldLabel
          if (c.filter.fieldDest in cFiltLbl){
            delete cFiltLbl[c.filter.fieldDest]
          }
            
          cFiltLbl = { ...cFiltLbl, [c.filter.fieldDest]: { label: label, value: labels.slice(0, -2) } }
          setFiltLabel(cFiltLbl)
        }
      }
    )
    const filtElems = cfg.columns
  }, [tfilter])


  return (
    <>

      <div className="">
        {/* Filters */}
        <Disclosure
          as="section"
          aria-labelledby="filter-heading"
          className="grid items-center border-b border-t border-gray-200"
        >
          <h2 id="filter-heading" className="sr-only">
            Filtros
          </h2>
          <div className="relative col-start-1 row-start-1 py-4">
            <div className="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">

              {filtLabel != null && Object.keys(filtLabel).length > 0 &&
                <>

                  <div>

                    <div className="group flex items-center font-medium text-gray-700">
                      <FunnelIcon
                        className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      {Object.keys(filtLabel).length == 1
                        ? '1 filtro'
                        : `${Object.keys(filtLabel).length} filtros`}
                    </div>
                  </div>
                  <div className="pl-6">
                    <button
                      onClick={() => {
                        resetFilter();
                        setFiltLabel(null);
                      }}
                      type="button"
                      className="text-gray-500"
                    >
                      Mostrar tudo
                    </button>
                  </div>
                </>
              }

              <div className="pl-6 space-x-2">
                {filtLabel != null &&
                  Object.keys(filtLabel).map((f, k) => (
                    <div
                      key={k}
                      className="inline-flex items-center gap-x-0.5 rounded-md bg-yellow-100 px-2 py-1"
                    >
                      <span className="text-xs font-medium text-yellow-400">
                        {filtLabel[f].label}
                      </span>
                      <span className="pl-2 text-xs font-medium text-yellow-800">
                        {filtLabel[f].value}
                      </span>

                      <button
                        onClick={() => removeTag(f)}
                        type="button"
                        className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-yellow-600/20"
                      >
                        <span className="sr-only">Remove</span>
                        <svg
                          viewBox="0 0 14 14"
                          className="h-3.5 w-3.5 stroke-yellow-800/50 group-hover:stroke-yellow-800/75"
                        >
                          <path d="M4 4l6 6m0-6l-6 6" />
                        </svg>
                        <span className="absolute -inset-1" />
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="col-start-1 row-start-1 py-4">
            <div className="mx-auto flex max-w-7xl justify-end px-4 sm:px-6 lg:px-8">
              <Menu as="div" className="relative inline-block">
                <div className="flex">
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    Opções
                    <ChevronDownIcon
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {tabOptions.map((option) => (
                        <Menu.Item key={option.name}>
                          {({ active }) => (
                            <a
                              href="#"
                              onClick={option.click}
                              className={classNames(
                                option.current
                                  ? 'font-medium text-gray-900'
                                  : 'text-gray-500',
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              {option.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </Disclosure>
      </div>
    </>
  );
};

export default TableFilter;
