
import { apiUrl, postRequests as pt } from "../../common/apiEndpoints";
import { postJson } from "../../common/fetchdata";

export const doAssignmentChange = (globalcontext, userId, userName, selectedTopic, pres, setPres) => {
    // type, title, message, okTrigg
    let selTopic = ''
    if (Array.isArray(selectedTopic))
        selTopic = selectedTopic.map(obj => obj.id).toString()
    else
        selTopic = selectedTopic.id

    globalcontext.contextFcn.panel.modal.open(
        'info',
        `Confirma a assignação a ${userName}?`,
        () => postJson(apiUrl.threadassignment, {
            ...pres, payload: {
                threadId: selTopic,
                assignee: userId
            }
        }, setPres)
    );
};

export const doStatusChange = (globalcontext, action, selectedTopic, pres, setPres) => {
    // type, title, message, okTrigg
    let selTopic = ''
    if (Array.isArray(selectedTopic))
        selTopic = selectedTopic.map(obj => obj.id).toString()
    else
        selTopic = selectedTopic.id

    globalcontext.contextFcn.panel.modal.open(
        'info',
        'Confirma a alteração do estado?',
        () => postJson(pt.messagingstatuschange, {
            ...pres, payload: {
                threadId: selTopic,
                status: action
            }
        }, setPres)
    );
};

export const statusLabel = [
    { value: 'CLOSED', name: 'Fechado', href: '#', bg: 'bg-emerald-200', color: 'emerald-200'  },
    { value: 'PENDING', name: 'Pendente', href: '#', bg: 'bg-yellow-200', color: 'yellow-200'  },
    { value: 'OPEN', name: 'Aberto', href: '#', bg: 'bg-red-200', color: 'red-200'  },
]
