import React from "react";
import { Fragment } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon, MagnifyingGlassIcon, ShoppingBagIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

const products = [{
    id: 1,
    name: 'Micro Backpack',
    description:
        'Are you a minimalist looking for a compact carry option? The Micro Backpack is the perfect size for your essential everyday carry items. Wear it like a backpack or carry it like a satchel for all-day use.',
    href: '#',
    price: '$70.00',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/order-history-page-03-product-01.jpg',
    imageAlt:
        'Moss green canvas compact backpack with double top zipper, zipper front pouch, and matching carry handle and backpack straps.',
}]

function centToEuroFormat(e) {
    return parseInt(e) == 0 ? '-' : `${(parseInt(e) / 100).toFixed(2)} €`
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PaymentSummary({ paymInfo }) {
    return (
        <main className="">

            <section aria-labelledby="recent-heading" className="mt-16">
                <h2 id="recent-heading" className="sr-only">
                    Recent orders
                </h2>
                <div className="mx-auto max-w-7xl sm:px-2 lg:px-8">
                    <div className="mx-auto max-w-2xl space-y-8 sm:px-4 lg:max-w-4xl lg:px-0">
                        {paymInfo.map((paym) => (
                            <div
                                key={paym.id}
                                className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border"
                            >
                                <h3 className="sr-only">
                                    Pagamento efetuado em <time dateTime={paym.datetm_payment}>
                                        {moment(paym.datetm_payment).format('HH:mm') == '00:00' ?
                                            moment(paym.datetm_payment).format('DD/MM/YYYY')
                                            : moment(paym.datetm_payment).format('DD/MM/YYYY HH:mm')}</time>
                                </h3>

                                <div className="flex items-center border-b border-gray-200 p-4 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-6">
                                    <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
                                        <div>
                                            <dt className="font-medium text-gray-900">Nº Recibo</dt>
                                            <dd className="mt-1 text-gray-500">{paym.receipt_num}</dd>
                                        </div>
                                        <div className="hidden sm:block">
                                            <dt className="font-medium text-gray-900">Data Pagamento</dt>
                                            <dd className="mt-1 text-gray-500">
                                                <time dateTime={paym.datetm_payment}>
                                                    {moment(paym.datetm_payment).format('HH:mm') == '00:00' ?
                                                        moment(paym.datetm_payment).format('DD/MM/YYYY')
                                                        : moment(paym.datetm_payment).format('DD/MM/YYYY HH:mm')}
                                                </time>
                                            </dd>
                                        </div>
                                        <div>
                                            <dt className="font-medium text-gray-900">Valor Total</dt>
                                            <dd className="mt-1 font-medium text-gray-900">{centToEuroFormat(paym.value)}</dd>
                                        </div>
                                    </dl>

                                    <Menu as="div" className="relative flex justify-end lg:hidden">
                                        <div className="flex items-center">
                                            <Menu.Button className="-m-2 flex items-center p-2 text-gray-400 hover:text-gray-500">
                                                <span className="sr-only">Options for order {paym.id}</span>
                                                <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
                                            </Menu.Button>
                                        </div>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href="#"
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                    'block px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                View
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href="#"
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                    'block px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                Invoice
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>

                                    <div className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">

                                        <a
                                            href="#"
                                            className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-2.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            <span>Obter Fatura</span>
                                            <span className="sr-only">for order {paym.id}</span>
                                        </a>
                                    </div>
                                </div>

                                {/* Products */}
                                <h4 className="sr-only">Items</h4>
                                <ul role="list" className="divide-y divide-gray-200">
                                    {products.map((product) => (
                                        <li key={product.id} className="p-4 sm:p-6">
                                            <div className="flex items-center sm:items-start">
                                                <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-lg bg-gray-200 sm:h-40 sm:w-40">
                                                    <img
                                                        src={product.imageSrc}
                                                        alt={product.imageAlt}
                                                        className="h-full w-full object-cover object-center"
                                                    />
                                                </div>
                                                <div className="ml-6 flex-1 text-sm">
                                                    <div className="font-medium text-gray-900 sm:flex sm:justify-between">
                                                        <h5>{product.name}</h5>
                                                        <p className="mt-2 sm:mt-0">{product.price}</p>
                                                    </div>
                                                    <p className="hidden text-gray-500 sm:mt-2 sm:block">{product.description}</p>
                                                </div>
                                            </div>

                                            <div className="mt-6 sm:flex sm:justify-between">
                                                <div className="flex items-center">
                                                    <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                                                    <p className="ml-2 text-sm font-medium text-gray-500">
                                                        Processado por {paym.user_book.fullname}
                                                    </p>
                                                </div>

                                                <div className="mt-6 flex items-center space-x-4 divide-x divide-gray-200 border-t border-gray-200 pt-4 text-sm font-medium sm:ml-4 sm:mt-0 sm:border-none sm:pt-0">
                                                    <div className="flex flex-1 justify-center">
                                                        <a
                                                            href={product.href}
                                                            className="whitespace-nowrap text-indigo-600 hover:text-indigo-500"
                                                        >
                                                            View product
                                                        </a>
                                                    </div>
                                                    <div className="flex flex-1 justify-center pl-4">
                                                        <a href="#" className="whitespace-nowrap text-indigo-600 hover:text-indigo-500">
                                                            Buy again
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </main>



    )
}
