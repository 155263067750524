import React, { Fragment } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { getJson, blankResult, reqOk } from '../fetchdata';
import { Listbox, Transition } from '@headlessui/react';
import useToken from '../../hooks/useToken';
import { getRequests as gt } from '../apiEndpoints';
import { afilliations } from '../../label/customer';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SelectCustomerManager({
  customerId,
  sel,
  setSel,
  showTitle = true,
  removeOptions = [],
}) {
  const { token } = useToken();
  const [submitResult, setSubmitResult] = React.useState(blankResult);

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    console.log();
  }, [submitResult]);

  const getData = () => {
    getJson(
      gt.customer,
      { ...submitResult, token: token, params: { id: customerId } },
      setSubmitResult
    );
  };

  const handleSelectPerson = (e) => {
    setSel(e);
  };

  const applyRemoveUsedOpts = (options) =>
    removeOptions.length > 0
      ? options.filter((p) => !removeOptions.includes(p.id))
      : options;

  const filteredPeople =
    reqOk(submitResult) && submitResult.items.data.length > 0
      ? applyRemoveUsedOpts([
          ...submitResult.items.data[0].customer_managers,
          {
            affiliation: { value: 'OTHER' },
          },
        ])
      : [];

  return (
    <>
      <Listbox value={sel} onChange={handleSelectPerson}>
        {({ open }) => (
          <>
            <div className="relative ">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <span className="block truncate">
                  {sel.affiliation != null && sel.affiliation.value != null
                    ? afilliations.filter(
                        (v) => v.value == sel.affiliation.value
                      )[0].desc
                    : 'Escoha...'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredPeople.length > 0 &&
                    filteredPeople.map((person, k) => (
                      <Listbox.Option
                        key={k}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'bg-indigo-600 text-white'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={person}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {
                                afilliations.filter(
                                  (v) => v.value == person.affiliation.value
                                )[0].desc
                              }
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
}
