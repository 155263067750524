import React, { useContext } from 'react'
import { GlobalStateContext } from '../../state/globalState';
import moment from 'moment-timezone'
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import 'moment/dist/locale/pt';
moment.locale('pt');
import { smsStatus } from '../../common/messaging/messagingVars'
import { CheckIcon, XMarkIcon, ArrowUpRightIcon } from '@heroicons/react/20/solid';

export default function MessagingCustomerCommContent({ item, selectedTopic }) {
    const globalcontext = useContext(GlobalStateContext);
    let user = globalcontext.contextFcn.userData.data.items.user;
    user = (typeof user != 'undefined') ? user : {}

    function getState(status) {
        const smsState = smsStatus.filter(s => s.status == status)
        if (smsState.length == 0) return 'PENDING'
        else return smsState[0].state
    }
    return (


        <dl className="grid grid-cols-2 w-full sm:px-6 lg:px-8">

            {item.outgoing_direction && <div />}
            <div className="flex items-start gap-2.5">
                <div className={`flex flex-col gap-1 w-full  ${item.outgoing_direction ? 'items-end' : 'items-start'}`}>
                    <div className="flex items-center space-x-2 rtl:space-x-reverse">
                        <div className=" ">
                            <span className="text-sm font-semibold text-gray-900">

                                {
                                    !item.outgoing_direction ? <>
                                        <span className="text-xs">Recebido por</span>
                                    </>
                                        : user.fullname
                                }
                            </span>
                            <span className="pl-2 text-xs font-medium"> {` ${item.channel}`}</span>


                        </div>
                        <time
                            dateTime={moment(item.created_at).format()}
                            className="text-sm font-normal text-gray-500 "
                        >
                            {moment(item.created_at).fromNow()}
                        </time>
                    </div>
                    <div className={`flex flex-col leading-1.5 p-4 rounded-xl ` +
                        `${item.outgoing_direction ? 'rounded-br-none bg-gray-100 ' : 'rounded-bl-none bg-sky-100 '}` +
                        `${getState(item.status) == 'FAILED' ? 'border-red-200 bg-red-100' : 'border-gray-200'}`}>

                        {item.channel == 'EMAIL' ?
                            <div className="text-sm text-gray-600" dangerouslySetInnerHTML={{__html: item.content}} />
                            :
                            <p className="text-sm font-normal text-gray-900 ">
                                {`${item.content}`}
                            </p>
                        }

                    </div>
                    <span className="text-xs font-normal text-gray-500 ">
                        {
                            item.outgoing_direction && <>
                                {getState(item.status) == 'DELIVERED' && <div className="flex"><CheckIcon className="h-5 w-5 text-emerald-800 pr-2" /><span className="pt-0.5">Entregue</span></div>}
                                {getState(item.status) == 'PENDING' && <div className="flex"><ArrowUpRightIcon className="h-5 w-5 text-orange-700 pr-2" /><span className="pt-0.5">Em entrega</span></div>}
                                {getState(item.status) == 'FAILED' && <div className="flex"><XMarkIcon className="h-5 w-5 text-red-600 pr-2" /><span className="pt-0.5">Falhou</span></div>}
                            </>
                        }

                    </span>
                </div>
            </div>
        </dl>
    )
}
