import React, { Fragment } from 'react';
import FormSlideover from '../../common/slideover/FormSlideover';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, StarIcon } from '@heroicons/react/20/solid';
import { formfield_newinsurance as fin_new } from '../../forms/formfield_provider';
import { putRequests as pt } from '../../common/apiEndpoints';
import { putJson, blankResult, reqOk } from '../../common/fetchdata';
import useToken from '../../hooks/useToken';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ProviderInsuranceCards = ({ profile, refreshHandler }) => {
  const [edit_formData, edit_setFormData] = React.useState({});
  const [openAdd, setOpenAdd] = React.useState(false);
  const [formKey, setFormKey] = React.useState(0);
  const [formTempl, setFormTempl] = React.useState(null);
  const [putreq, setPutreq] = React.useState(blankResult);
  const { token } = useToken();

  const handleAddNewItem = () => {
    setFormTempl(fin_new);
    setTimeout(() => {
      setOpenAdd(true);
    }, '200');
  };

  const handleEditItem = (item) => {
    setFormTempl(fin_new);
    setTimeout(() => {
      setOpenAdd(true);
    }, '200');
  };

  const edit_okHandler = () => {
    setFormKey((k) => k + 1);
    setOpenAdd(false);
    refreshHandler();
  };

  const edit_errHandler = () => {
    console.error('Error processing request');
    setFormKey((k) => k + 1);
    setOpenAdd(false);
  };

  const setDefaultInsurance = (insuranceId) => {
    putJson(
      pt.customeredit,
      {
        ...putreq,
        token: token,
        id: profile.id,
        params: { type: 'customer_setdefaultinsurance' },
        payload: { insuranceId: insuranceId },
      },
      setPutreq
    );
  };

  React.useEffect(() => {
    if (reqOk(putreq)) refreshHandler();
  }, [putreq]);

  return (
    <>
      {formTempl != null && (
        <FormSlideover
          context={profile.id}
          fin={formTempl}
          key={formKey}
          open={openAdd}
          setOpen={setOpenAdd}
          formData={edit_formData}
          setFormData={edit_setFormData}
          okHandler={edit_okHandler}
          errHandler={edit_errHandler}
        />
      )}
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 border-b border-t border-gray-200 py-4">
        <h2 className="mb-3 text-md font-medium text-gray-500">
          Seguros e Protocolos
        </h2>
        <div className="mt-1 gap-4 flex flex-row flex-wrap">
          {profile.insurances &&
            profile.insurances
              .sort(function (x, y) {
                return x.is_default === y.is_default ? 0 : x ? -1 : 1;
              })
              .map((ins, k) => (
                <div
                  key={k}
                  className={`relative flex items-center rounded-lg border border-gray-300 bg-white px-2 py-3 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400 w-52`}
                >
                  <div className="flex-shrink-0">
                    {ins.avatar_url != null && (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={`../static/uploads/${ins.avatar_url}`}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="min-w-0 flex-1 ml-3">
                    <div className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm font-medium text-gray-900">
                        {ins.name}
                      </p>
                    </div>
                  </div>

                  <div>
                    <Menu as="div" className="relative ">
                      <Menu.Button className="inline-flex items-center rounded-md  px-1 py-2 text-xs font-semibold text-gray-900">
                        <ChevronDownIcon
                          className="-mr-1 h-6 w-6 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-red-700'
                                )}
                              >
                                Eliminar
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              ))}

          <div
            onClick={handleAddNewItem}
            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 border-dashed  bg-white px-16 sm:px-2 md:px-3 lg:px-4 xl:px-5 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400 cursor-pointer w-16"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 pt-1"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12.462 20.87c-.153 .047 -.307 .09 -.462 .13a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3a12 12 0 0 1 .11 6.37"></path>
              <path d="M16 19h6"></path>
              <path d="M19 16v6"></path>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProviderInsuranceCards;
