import React, { Fragment, useEffect, useRef } from 'react';

import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');
import useToken from '../hooks/useToken';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { getJson, blankResult } from '../common/fetchdata';
import { getRequests as gt } from '../common/apiEndpoints';
import { postRequests as pt } from '../common/apiEndpoints';
import { deleteRequests as dt } from '../common/apiEndpoints';
import './daycalendar.css';

export default function HomeDayTimeline({ selDay, context, setSelEvent }) {
  const options = { addSlot: true, deleteSlot: true };
  const { token } = useToken();
  const appRangeModal = React.useRef(null);
  const appEventModal = React.useRef(null);
  const calendarComponentRef = React.createRef();

  const [evType, setEvType] = React.useState(3);
  const [selSlot, setSelSlot] = React.useState(0);
  const [calSelRng, setCalSelRng] = React.useState(0);
  const [selectDoc, setSelectDoc] = React.useState(null);
  const [getRes_slot, setGetRes_slot] = React.useState(blankResult);
  const [getRes_prov, setGetRes_prov] = React.useState(blankResult);

  const handleCalendarSelection = (slot) => {
    setCalSelRng(slot);
    console.log(slot)
   
  };

  const handleClickAppSlot = (slot) => {
    let _ev = getRes_slot.items.data.filter(e => e.id == slot.event._def.publicId)[0]
    setSelEvent(_ev)
  };

  function renderEventContent(eventInfo) {
    //console.log(event._def.extendedProps.customerName)
    console.log(eventInfo.event._def.extendedProps.customerName)
    return (
      <div className="p-1 flex flex-row w-full">
        <div className="w-full text-xs font-light">
         {eventInfo.event._def.extendedProps.customerName}
        </div>
        <div className=""> </div>
      </div>
    );
  }

  const handleChangeSelectDoc = (selectedOption) => {
    setSelectDoc(selectedOption);
    fetchProviderSlots(selectedOption.value, evType);
  };

  const fetchProviderSlots = (providerId, startDateTime, endDateTime, type = 1) => {


    let geturl = '';
    if (type == 1) geturl = gt.appointment;
    if (type == 2) geturl = gt.calendarslots;
    if (type == 3) geturl = gt.schedule;
    getJson(
      geturl,
      {
        ...getRes_slot,
        token: token,
        params: { providerId: providerId, start_dt:startDateTime, end_dt:endDateTime  },
      },
      setGetRes_slot
    );
  };

/*   React.useEffect(() => {
    if (
      getRes_prov.state == 2 &&
      !getRes_prov.error &&
      getRes_prov.items.data.length > 0
    ) {
      if (context) {
        const selDoc = {
          value: context,
          label: getRes_prov.items.data.filter((d) => d.id == context)[0].name,
        };
        setSelectDoc(selDoc);
        fetchSlots(context);
      } else {
        const selDoc = {
          value: getRes_prov.items.data[0].id,
          label: getRes_prov.items.data[0].name,
        };
        setSelectDoc(selDoc);
        fetchSlots(getRes_prov.items.data[0].id);
      }
    }
  }, [getRes_prov]); */

  React.useEffect(() => {
    const apical = calendarComponentRef.current.getApi();
    if (selDay) {
      apical.gotoDate(selDay.format());
    }
  }, [selDay]);

  React.useEffect(() => {
    if (context.id != null && context.type == 'provider') {
      const startTime = moment(selDay).startOf('day').format()
      const endTime = moment(selDay).endOf('day').format()
      fetchProviderSlots(context.id, startTime, endTime);
    }
  }, [context, selDay]);


  return (
      <FullCalendar
        ref={calendarComponentRef}
        height={200}
        locale={'pt'}
        allDaySlot={false}
        weekends={true}
        hiddenDays={[]}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          right: 'timeGridWeek',
        }}
        selectable={true}
        initialView="timeGrid"
        events={getRes_slot.items.data && getRes_slot.items.data}
        eventClick={(e) => handleClickAppSlot(e)}
        eventContent={renderEventContent} // custom render function
        buttonText={{
          today: 'hoje',
          month: 'mês',
          week: 'semana',
          day: 'dia',
          list: 'lista',
        }}
        select={handleCalendarSelection}
      />
  );
}
