import React from 'react';
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import { GlobalStateContext } from '../../state/globalState';
import ChatContainer from './ChatContainer';
import { Transition } from '@headlessui/react';
import 'moment/locale/pt'; // without this line it didn't work
moment.locale('pt');

const ChatBox = () => {
  const globalcontext = React.useContext(GlobalStateContext);

  return (
    <div
      className={
        `mb-3 mr-3 fixed z-10 bottom-0 ` +
        `  duration-500 flex flex-col justify-between transition-all  ${
          globalcontext.contextFcn.panel.sidebar.expand.isExpanded
            ? 'right-64'
            : 'right-12'
        }`
      }
    >
      <Transition
        show={globalcontext.contextFcn.panel.chatbox.visible.view}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-300"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-300"
        leaveTo="opacity-0"
      >
        <div className="min-h-[500px] flex flex-col items-center justify-center text-gray-800 p-4">
          {/* Component Start */}
          <div className="flex flex-col flex-grow w-full max-w-xl bg-white shadow-xl rounded-lg overflow-hidden">
            <ChatContainer globalcontext={globalcontext} />
          </div>
          {/* Component End  */}
        </div>
      </Transition>
    </div>
  );
};

export default ChatBox;
