import React from 'react';
import Draggable from 'react-draggable';
import CallVideoContainer from '../../call/video/CallVideoContainer'

const VideoCallContainer = ({ isCallActive }) => {
  const [isExpanded, setExpanded] = React.useState(true);

  return (
    <>
      {isCallActive && (
        <Draggable>
          <div
            className={
              `w-96 fixed z-10 m-4 left-0 bottom-0 ` +
              ` shadow-md flex flex-col justify-between rounded-sm ${
                isExpanded ? 'h-64' : 'h-12'
              } bg-sky-800`
            }
          >
            <div
              className="absolute right-0 top-0 translate-x-2 -translate-y-8"
              onClick={() => setExpanded(!isExpanded)}
            >
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none font-medium rounded-full text-sm p-1 text-center inline-flex items-center mr-2 dark:hover:bg-blue-700"
              >
                {isExpanded ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                    />
                  </svg>
                )}

                <span className="sr-only">Maximizar/Minimizar</span>
              </button>
            </div>
            <CallVideoContainer />
          </div>
        </Draggable>
      )}
    </>
  );
};

export default VideoCallContainer;
