import React, { useEffect } from "react";

import { ResponsiveContainer, AreaChart, Area, Tooltip, CartesianGrid, XAxis, YAxis } from 'recharts';
import PaymentSummary from "./summary/PaymentSummary";
import { getJson, blankResult, reqOk } from '../common/fetchdata';
import { getRequests as gt } from '../common/apiEndpoints';
import NotImplementedScreen from '../common/emptystate/NotImplementedScreen';


const stats = [
  { id: 1, name: 'Creators on the platform', value: '8,000+' },
  { id: 2, name: 'Flat platform fee', value: '3%' },
  { id: 3, name: 'Uptime guarantee', value: '99.9%' },
  { id: 4, name: 'Paid out to creators', value: '$70M' },
]


const PaymentListing = ({ context }) => {

  const [payments, setPayments] = React.useState(blankResult);
  const [data, setData] = React.useState([]);


/*   useEffect(() => {
    getJson(gt.bi, {
      ...payments, params: {
        metric: 'payments', rangeStart: '2013-01-01', rangeEnd: '2023-07-01', granularity: 'year'
      }
    }, setPayments);
  }, []);

  useEffect(() => {

    if (reqOk(payments)) {
      let _data = []
      payments.items.data.map(d => _data = [..._data, { date: d.date, total_value: d.total_value / 100 }])
      setData(_data)
    }
  }
    , [payments]); */
  return (
    <>

      <NotImplementedScreen />

    </>
  );
};

export default PaymentListing;
