import React from 'react';
import SelectMultipleFilterCS from './SelectMultipleFilterCS';
import { callStatusLabel } from '../../call/getCallStatus';

const SelectMultipleCallStatus = ({  expanded, sel, setSel }) => {
  let selvalue = [];
  callStatusLabel.map(
    (p) => (selvalue = [...selvalue, { value: p.key, label: p.label }])
  );

  return (
    <>
      <SelectMultipleFilterCS sel={sel} setSel={setSel} inputData={selvalue} />
    </>
  );
};

export default SelectMultipleCallStatus;
