

import CustomerCallSummaryModule from './CustomerCallSummaryModule'


/* 
Add additional features as comment box, attachments
https://tailwindui.com/components/application-ui/page-examples/detail-screens?include=archived 
*/

export default function CustomerSummaryPage({ profile, gotoCalls, gotoPersonDetails }) {
    return (
        <>
            <div className="min-h-full ">
                <main className="pb-10">
                    <div className="mx-auto grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                            {/* Description list*/}
                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
                                            Dados Pessoais
                                        </h2>
                                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Dados relevantes do Paciente</p>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Nome completo</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{profile.fullname}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Género</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{profile.gender}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Idade</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{profile.age}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">NIF</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{profile.tax_id_ref}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Email</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{profile.searchable_email}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Telefone</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{profile.searchable_phone}</dd>
                                            </div>
                                            <div className="sm:col-span-3">
                                                <dt className="text-sm font-medium text-gray-500">Comentários</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {'Sem notas'}
                                                </dd>
                                            </div>

                                        </dl>
                                    </div>
                                    <div>
                                        <a
                                            href="#"
                                            onClick={() => gotoPersonDetails()}
                                            className="block bg-gray-50 px-4 py-4 text-center text-sm font-medium text-gray-500 hover:text-gray-700 sm:rounded-b-lg"
                                        >
                                            Ver mais
                                        </a>
                                    </div>
                                </div>
                            </section>

                        </div>
                        <CustomerCallSummaryModule profile={profile} gotoCalls={gotoCalls}/>
                    </div>
                </main>
            </div>
        </>
    )
}
