const rows = [
  {
    selector: 'input',
    required: true,
    class: '',
    type: 'text',
    name: 'name',
    title: 'Nome',
    placeholder: 'Nome do equipamento...',
    id: 'name',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Nome Inválido.',
  }
];




export const formfield_newspacefeatures = {
  title: 'Novo Equipamento',
  description: 'Adicione um novo equipamento à clínica',
  apiEndpoint: '/api/space-features',
  method: 'POST',
  preloadForm: false,
  name: '',
  id: '',
  submitLabel: 'Adicionar',
  rows: rows,
};

export const formfield_editspacefeatures = {
  title: 'Editar Equipamento',
  description: 'Edite as características do espaço',
  apiEndpoint: '/api/space-features',
  method: 'PUT',
  preloadForm: true,
  name: '',
  id: '',
  submitLabel: 'Editar',
  deleteAction: {
    label: 'Eliminar',
  },
  rows: rows,
};
