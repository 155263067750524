import React, { useState, useRef, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import QRCode from 'qrcode.react';
import AndroidIcon from '../common/icon/AndroidIcon';
import IosIcon from '../common/icon/IosIcon';


function MfaInstall({  handleNext }) {
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState({ make: '', url: '' })

    const items = [
        {
            title: 'Android',
            qrUrl: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
            icon: AndroidIcon
        },
        {
            title: 'Apple iOS',
            qrUrl: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
            icon: IosIcon,
        }
    ]

    function openModal(os) {

        setSelected({ make: os.title, qrUrl: os.qrUrl })
        setOpen(true)
    }


    function PhoneOsSelect() {

        const cancelButtonRef = useRef(null);

        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={setOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm">
                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">

                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-base font-semibold leading-6 text-gray-900"
                                                >
                                                    {`${selected.make}`}
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <QRCode size="270" value={selected.qrUrl} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">

                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        );
    }

    return (
        <div className="Enrollment">

            <PhoneOsSelect
                open={open}
                setOpen={setOpen}
                title={selected.make}
                qrUrl={selected.url}
            />

            <h2>Deverá instalar o Google Authenticator (ou similar) no seu telemóvel para validar a sua identidade e reforçar a segurança de acesso. </h2>
            <h2 className="mt-2 text-black">Sistema operativo do seu telemóvel:</h2>


            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 my-6">
                {items.map((item, k) => (
                    <button
                        onClick={() => openModal(item)}
                        key={k}
                        className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                    >
                        <div className="flex-shrink-0">
                            <item.icon className={'w-12 h-12'} />
                        </div>
                        <div className="min-w-0 flex-1">
                            <a href="#" className="focus:outline-none">
                                <span className="absolute inset-0" aria-hidden="true" />
                                <p className="text-sm font-medium text-gray-900">{item.title}</p>
                                <p className="truncate text-sm text-gray-500">{item.role}</p>
                            </a>
                        </div>
                    </button>
                ))}
            </div>

            <button
                type="button"
                onClick={handleNext}
                className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                Já instalei
                <ArrowRightIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </button>
        </div>
    );
}

export default MfaInstall;