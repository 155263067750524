import React, { useEffect } from 'react';
import axios from 'axios';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { conditionallyRender, showConditionally } from './conditionallyRender';
import { csrfToken } from '../fetchdata'



import './reactselect.css';

import {
  postJson,
  putJson,
  getJson,
  deleteJson,
  blankResult,
  reqOk,
} from '../../common/fetchdata';
import useToken from '../../hooks/useToken';
import { errorToast, successToast } from '../toasts';



export default function FormSlideover({
  open,
  setOpen,
  fin,
  context,
  payloadContext,
  formData,
  setFormData,
  okHandler,
  errHandler,
  preloadSrc,
}) {
  const [submitResult, setSubmitResult] = React.useState(blankResult);
  const [preload, setPreload] = React.useState(blankResult);
  const [formVal, setFormVal] = React.useState({});
  // use form_fcn.js
  const preLoadData = () => {
    if (fin.preloadForm) {
      if (typeof preloadSrc != 'undefined') {
        const fData = preloadFinputFields(preloadSrc);
        setFormData({ ...fData });
      } else {
        let addParams = fin.reqArg == null ? {} : fin.reqArg;
        addParams = context ? { ...addParams, id: context } : { ...addParams };
        getJson(
          fin.apiEndpoint,
          { ...preload, params: { ...addParams } },
          setPreload
        );
      }
    } else {
      let fData = {};
      let fDataValidation = {};
      fin.rows.map((f) => {
        if (f.map) {
          let val = '';
          if (f.name == 'icon') val = f.default;
          fData = { ...fData, [f.name]: val };
          if ('validation' in f) {
            fDataValidation = { ...fDataValidation, [f.name]: false };
          }
        }
      });

      // Manage default values
      fin.rows.map((_r) => {
        if (
          _r.selector == 'reactselect' &&
          'defValues' in _r &&
          _r.optType == 'static'
        ) {
          const defVal = _r.defValues;
          const allVal = _r.staticOpt.filter((v) => defVal.includes(v.id));
          fData = { ...fData, [_r.name]: allVal };
        }
      });

      //setFormVal(fDataValidation);
      setFormData(fData);
    }
  };

  React.useEffect(() => {
    if (open) preLoadData();
  }, [context, fin, open]);


  React.useEffect(() => {
    if (preload.state == 2 && !preload.error) {
      let res = preload.items.data[0];
      const fData = preloadFinputFields(res);
      setFormData({ ...fData });
    }
  }, [preload]);


  const preloadFinputFields = (res) => {

    let formFields = [];
    fin.rows.map((f) => (formFields = [...formFields, f.name]));

    let fData = formData;
    Object.keys(res).forEach(function (key, index) {
      if (formFields.includes(key)) {
        let _val = res[key];

        // Not sure why is this here
        //if (Array.isArray(res[key])) {
        //  _val = res[key].map((a) => a.id);
        //  //setListItems(res[key]);
        //}

        fData = { ...fData, [key]: _val };
      }
    });
    console.log(fData)
    return fData;
  };

  React.useEffect(() => {
    if (submitResult.state === 2) {
      if (submitResult.statusCode === 200) {
        okHandler(submitResult.items.id, submitResult.items.data);
      } else {
        errHandler();
      }
    }
  }, [submitResult]);

  function canSubmit() {
    let _fields = []
    let _newFormVal = {}
    Object.keys(formVal).forEach(function (key, index) {
      _fields = [..._fields, formVal[key].isValid]
      _newFormVal = { ..._newFormVal, [key]: { ...formVal[key], isTouched: true } }
    });
    setFormVal(_newFormVal)
    const uniqueVal = [...new Set(_fields.map(item => item))]
    if (uniqueVal.length > 1) return false
    else {
      if (uniqueVal[0]) return true
      else return false
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (canSubmit()) {

      const payloadType = 'payloadType' in fin ? fin.payloadType : 'json';
      if (payloadType == 'formdata') {
        uploadFormData();
      }
      if (payloadType == 'json') {
        let fData = {};
        let fPayload = {};

        if ('reqBody' in fin) fPayload = { ...fPayload, ...fin.reqBody };
        else fPayload = { ...formData, context: context };

        fData = { ...submitResult, payload: fPayload };

        let params = {}
        if ('reqArg' in fin)
          params = { ...params, ...fin.reqArg }
        if (typeof (payloadContext) != 'undefined')
          params = { ...params, ...payloadContext }

        if (Object.keys(params).length > 0)
          fData = {
            ...fData,
            params: new URLSearchParams(params).toString(),
          };

        if (fin.method == 'POST')
          postJson(fin.apiEndpoint, fData, setSubmitResult);
        if (fin.method == 'PUT') {
          const putReq =
            typeof context != 'undefined' && context != null
              ? { ...fData, id: context }
              : { ...fData };
          putJson(fin.apiEndpoint, putReq, setSubmitResult);
        }
      }
    }
  };

  const uploadFormData = async () => {


    const _formData = new FormData();
    let submFormData = {};
    if ('' in fin) submFormData = { ...formData, ...fin.reqBody };
    else submFormData = formData;

    let endpoint = fin.apiEndpoint;
    if ('reqArg' in fin) {
      let u = new URLSearchParams(fin.reqArg).toString();
      if (u.length > 0) {
        endpoint = `${endpoint}?${u}`;
      }
    }



    Object.keys(submFormData).forEach(function (key, index) {
      const tVar = submFormData[key]
      if (
        typeof tVar === 'object' &&
        !Array.isArray(tVar) &&
        tVar !== null
      ) {
        Object.keys(tVar).forEach(function (key, index) {
          _formData.append(key, tVar[key])
        })
      }
      else {
        _formData.append(key, tVar)
      }
    });

    console.log(submFormData)

    _formData.append('context', context);
    const reqHeaders = {
      'Content-Type': 'multipart/form-data',
      'X-CSRF-TOKEN': csrfToken()
    };
    let result = {};



    if (fin.method == 'PUT')
      result = await axios.put(endpoint, _formData, {
        headers: reqHeaders,
        withCredentials: true,
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
        },
        onDownloadProgress: (progressEvent) => {
          const progress =
            50 + (progressEvent.loaded / progressEvent.total) * 50;
        },
      });
    if (fin.method == 'POST')

      result = await axios.post(endpoint, _formData, {
        headers: reqHeaders,
        withCredentials: true,
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
        },
        onDownloadProgress: (progressEvent) => {
          const progress =
            50 + (progressEvent.loaded / progressEvent.total) * 50;
        },
      });

    if (result.status == 200) {
      successToast(result.data.message)
      okHandler(context, result.data);
    }
    else {
      errorToast(result.data.message)
      errHandler(context, result.data);
    }
  };

  const handleDelete = () => {
    deleteJson(
      fin.apiEndpoint,
      { ...submitResult, id: context },
      setSubmitResult
    );
  };

  // Handle validation when form is fired with some field filled
  // Example: new customer with name already choosen from autocomplete
  // force fData validation on these fields
  /* React.useEffect(() => {
    //if formVal has values
    if (Object.keys(formVal).length > 0 && formData) {
      let fDataValidation = formVal;
      Object.keys(formData).forEach(function (key, index) {
        if (key in formVal) {
          if (formData[key] !== undefined) {
            const valRes = fin.rows
              .filter((f) => f.name == key)[0]
              .validation(formData[key]);
            fDataValidation = { ...fDataValidation, [key]: valRes };
          } else fDataValidation = { ...fDataValidation, [key]: false };
        }
      });
      setFormVal(fDataValidation);
    }
  }, [formData]); */











  // Child Data Management

  /*   React.useEffect(() => {
      if (open && 'childFetch' in fin) manageChildDeps();
    }, [fin, open]);
  
    const manageChildDeps = () => {
      let promises = [];
      let promiseKey = [];
      fin.childFetch.map((_d) => {
        const promise = axios.get(_d.endpoint, {
          headers: { Authorization: `Bearer ${token}` },
        });
        promises = [...promises, promise];
        promiseKey = [...promiseKey, { endpoint: _d.endpoint, key: _d.key }];
      });
      Promise.all(promises).then(function (resp) {
        let respVal = [];
        resp.map((r) => {
          const respurl = r.request.responseURL;
          const key = promiseKey.filter((c) => respurl.includes(c.endpoint))[0]
            .key;
          respVal = [...respVal, { key: key, data: r.data.data }];
        });
        setChildDep(respVal);
      });
    }; */

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-2xl">
                  <form
                    onSubmit={handleSubmit}
                    className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                  >
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll pt-12">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              {fin.title}
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              {fin.description}
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="text-gray-400 hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

                        {Object.keys(formData).length > 0 &&
                          fin.rows.map((_r, k) => (
                            <div key={`${k}`} className={`${_r.class}`}>


                              {showConditionally(formData, _r) && (

                                <>
                                  <div className={`space-y-2 px-4 sm:grid sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 ${(_r.fullwidth == null) ? 'sm:grid-cols-3' : 'sm:grid-cols-2'}`}>
                                    {
                                      _r.fullwidth == null &&
                                      <>
                                        <div>
                                          <label
                                            id={_r.id}
                                            className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                          >
                                            {_r.title}
                                            <p className="mt-1 text-xs leading-6 text-gray-400">
                                              {_r.subtitle}
                                            </p>
                                          </label>
                                        </div>
                                      </>
                                    }


                                    <div className="sm:col-span-2">

                                      {conditionallyRender(
                                        _r,
                                        formData,
                                        setFormData,
                                        context,
                                        setFormVal,
                                        formVal
                                      )}
                                    </div>
                                  </div>

                                </>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* Action buttons */}

                    <div className="flex flex-shrink-0 justify-between px-4 py-4">
                      <div>
                        {'deleteAction' in fin && (
                          <div
                            onClick={handleDelete}
                            className="cursor-pointer ml-4 inline-flex justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                          >
                            {fin.deleteAction.label}
                          </div>
                        )}

                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                          onClick={() => setOpen(false)}
                        >
                          Cancelar
                        </button>
                      </div>
                      <div>
                        {'secondaryButton' in fin && (
                          <button
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                          >
                            {fin.secondaryButton.label}
                          </button>
                        )}
                        <button
                          type="submit"
                          className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        >
                          {fin.submitLabel}
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
