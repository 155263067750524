import React, { useEffect } from 'react'
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import {
    CheckIcon,
    PhoneXMarkIcon,
    QuestionMarkCircleIcon
} from '@heroicons/react/20/solid'

import { loadingScreen } from '../../common/fetchdata';
import { blankResult, reqOk } from "../../common/fetchdata";
import { GlobalStateContext } from '../../state/globalState';
import { doStatusChange } from './leadTags'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const moods = [
    { name: 'Aberto', value: 'OPEN', icon: PhoneXMarkIcon, iconColor: 'text-white', bgColor: 'bg-red-500' },
    { name: 'Fechado', value: 'CLOSED', icon: CheckIcon, iconColor: 'text-white', bgColor: 'bg-green-400' },
    { name: 'Pendente', value: 'PENDING', icon: QuestionMarkCircleIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400' },
]


export default function LeadStatusMenu({ selectedTopic, handleRefresh, actionBtn }) {

    const globalcontext = React.useContext(GlobalStateContext);
    const [pres, setPres] = useState(blankResult);
    const [selected, setSelected] = useState({ value: null })

    useEffect(() => {
        loadingScreen(pres, globalcontext)
    }, [pres]);

    useEffect(() => {
        if (reqOk(pres)) {
            handleRefresh()
        }
    }, [pres])


    return (

        <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
                <>
                    <Listbox.Label className="sr-only">Estado</Listbox.Label>
                    <div className="relative">
                        <Listbox.Button className="relative -ml-px hidden items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 sm:inline-flex">
                            {actionBtn}
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {moods.map((mood) => (
                                    <Listbox.Option
                                        onClick={() => doStatusChange(
                                            globalcontext,
                                            mood.value,
                                            selectedTopic,
                                            pres,
                                            setPres
                                        )}
                                        key={mood.value}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'bg-gray-200 text-gray-900' : 'text-gray-900',
                                                'group flex w-full items-center rounded-md p-3 text-sm cursor-pointer'
                                            )
                                        }
                                        value={mood}
                                    >
                                        <div className="flex items-center">
                                            <div
                                                className={classNames(
                                                    mood.bgColor,
                                                    'flex h-6 w-6 items-center justify-center rounded-full'
                                                )}
                                            >
                                                <mood.icon
                                                    className={classNames(mood.iconColor, 'h-4 w-4 flex-shrink-0')}
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <span className="ml-3 block truncate font-normal">{mood.name}</span>
                                        </div>
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
} 