import React, { useEffect, useState } from 'react';
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

import useToken from '../hooks/useToken';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';

import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import CalendarActionMenu from './calendar/CalendarActionMenu';
import CalendarSlideoverFilter from './calendar/CalendarSlideoverFilter';
import SpeedDialFilter from '../common/SpeedDialFilter';

import {
  getJson,
  postJson,
  deleteJson,
  blankResult,
  reqOk,
} from '../common/fetchdata';
import { getRequests as gt } from '../common/apiEndpoints';
import { postRequests as pt } from '../common/apiEndpoints';
import { deleteRequests as dt } from '../common/apiEndpoints';

import { callStatusLabel } from './getCallStatus'
import {
  centersMaxOpenHours,
  fcSlotMinTime,
  fcSlotMaxTime,
} from '../common/calendar/calendarfunctions';

import '../calendar/fc-styles.css';

const CallCalendar = ({
  events,
  context,
  calendarRef,
  currentViewRange,
  setCurrentViewRange,
  initialView
}) => {

  const { token } = useToken();
  const [selSlot, setSelSlot] = useState(0);
  const [calSelRng, setCalSelRng] = useState(0);
  const [ctrOpenHours, setCtrOpenHours] = useState([]);
  const [getRes_slot, setGetRes_slot] = useState(blankResult);

  const [calFilter, setCalFilter] = useState({});
  const [calEvents, setCalEvents] = useState([]);

  // Filter
  const [openso_filter, setOpenso_filter] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);

  const [currentView, setCurrentView] = useState(initialView);

  const [actionMenuBtn, setActionMenuBtn] = useState({
    x: null,
    y: null,
  });




  useEffect(() => {
    if (events != null && events.length > 0) {
      setCalEvents([...events]);
    }
  }, [events]);



  /*   useEffect(() => {
      const hasProviderFilter =
        'provider' in calFilter && calFilter.provider.id != null;
      const hasContext = context != null;
      const hasProvider = hasProviderFilter || hasContext;
      if (
        currentViewRange.start != null &&
        currentViewRange.start != null &&
        hasProvider
      ) {
        fetchSlots(context);
      }
    }, [currentViewRange, calFilter, context]); */

  useEffect(() => {
    if (reqOk(getRes_slot)) {
      const call_slot = getRes_slot.items.events;
      const free_slot = getRes_slot.items.slots;
      const unav_slot = getRes_slot.items.unavailability;
      setCalEvents([...call_slot, ...free_slot, ...unav_slot]);
    }
  }, [getRes_slot]);

  useEffect(() => {
    if (selectedProvider != null) {
      console.log(selectedProvider);
    }
    /*  if (
      getRes_prov.state == 2 &&
      !getRes_prov.error &&
      getRes_prov.items.data.length > 0
    ) {
      let selDoc = {};
      let centers = [];
      const data = getRes_prov.items.data;
      if (context) {
        selDoc = {
          value: context,
          label: data.filter((d) => d.id == context)[0].name,
        };
        if (data.filter((d) => d.id == context)[0].centers != null)
          centers = data.filter((d) => d.id == context)[0].centers;
        fetchSlots(context);
      } else {
        selDoc = {
          value: data[0].id,
          label: data[0].name,
        };

        fetchSlots(data[0].id);
        if (data[0].centers != null) centers = data[0].centers;
      }
      setCtrOpenHours(centersMaxOpenHours(centers));
      setSelectDoc(selDoc);
    } */
  }, [selectedProvider]);

  /*   const fetchSlots = (providerId = null) => {
      const provId = providerId == null ? calFilter.provider.id : providerId;
      setSelectedProvider(provId);
      getJson(
        gt.calendarevents,
        {
          ...getRes_slot,
          token: token,
          params: {
            providerId: provId,
            start: calFilter.start,
            end: calFilter.end,
            ...currentViewRange,
          },
        },
        setGetRes_slot
      );
    }; */

  const handleClickAppSlot = (e) => {
    const thisSlot = getRes_slot.items.data.filter(
      (s) =>
        s.id == e.event.id && s.slotType == e.event._def.extendedProps.slotType
    );
    setSelSlot(thisSlot);
    console.log(e);
  };

  const handleCalendarSelection = (e) => {
    setCalSelRng(e);
    const jsEv = e.jsEvent;
    setActionMenuBtn({ x: jsEv.pageX, y: jsEv.pageY });
    console.log(e);
  };

  const handleSetViewRange = (e) => { };

  function renderEventContent(eventInfo) {
    // https://fullcalendar.io/docs/event-constraint-demo
    // https://codepen.io/pen?editors=0010
    let evType =
      eventInfo.event._def.groupId == 'booking_slot' ? 'slot' : 'event';
    const custName = eventInfo.event._def.extendedProps.customerName;
    const servType = eventInfo.event._def.extendedProps.serviceType;
    const providerName = eventInfo.event._def.extendedProps.sourceObj.users != null ? eventInfo.event._def.extendedProps.sourceObj.users.fullname : ''
    return (
      <>
        {evType == 'event' && (
          <div className="lg:tooltip w-full" data-tip={`Especialidade: ${servType}. Médico: ${providerName}`}>
            <div className="flex flex-row w-full">
              <div className="w-full text-xs">
                <b>
                  {moment(eventInfo.event._instance.range.start).format('H:mm')}
                </b>
                <span className="truncate">{` ${custName}`}</span>
              </div>
              <div className="text-[10px] uppercase p-1">
                {eventInfo.event._def.extendedProps.status != null ?
                  callStatusLabel.filter(c => c.key == eventInfo.event._def.extendedProps.status)[0].label
                  : ''}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div
        style={{
          zIndex: 100,
          display: actionMenuBtn.x != null ? 'block' : 'none',
          top: actionMenuBtn.y != null ? `${actionMenuBtn.y - 50}px` : '0px',
          left: actionMenuBtn.x != null ? `${actionMenuBtn.x - 50}px` : '0px',
          position: 'absolute',
          backgroundColor: 'black',
        }}
        className=""
      >
        <CalendarActionMenu
          slot={calSelRng}
          show={actionMenuBtn.x != null}
          hide={() =>
            setActionMenuBtn({
              x: null,
              y: null,
            })
          }
        />
      </div>

      <SpeedDialFilter onClickAction={() => setOpenso_filter(true)} />

      <CalendarSlideoverFilter
        open={openso_filter}
        setOpen={setOpenso_filter}
        handleFetch={(arg) => setCalFilter(arg)}
      />

      <FullCalendar
        locale={'pt'}
        allDaySlot={false}
        slotMinTime={fcSlotMinTime(currentView, ctrOpenHours)}
        slotMaxTime={fcSlotMaxTime(currentView, ctrOpenHours)}
        weekends={true}
        hiddenDays={[]}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        selectable={true}
        initialView={initialView}
        events={calEvents}
        eventOverlap={false}
        eventClick={(e) => handleClickAppSlot(e)}
        eventContent={renderEventContent} // custom render function
        /* slotLabelContent={(e) => {
          console.log(e);
          return <>H</>;
        }} */
        /* slotLaneContent={(e) => {
          console.log(e);
          return <>Oix</>;
        }} */
        nowIndicator={true}
        datesSet={(e) => {
          if (
            e.startStr != currentViewRange.start &&
            e.endStr != currentViewRange.end
          ){

            setCurrentViewRange({ start: e.startStr, end: e.endStr });
          }
        }}
        businessHours={true}
        buttonText={{
          today: 'hoje',
          month: 'mês',
          week: 'semana',
          day: 'dia',
          list: 'lista',
        }}
        select={handleCalendarSelection}
        ref={calendarRef}
      />
    </>
  );
};

export default CallCalendar;
