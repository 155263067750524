import { userConfigDefaultData } from './userConfigDefaultData'

const CUR_VERSION = 'v1.0'
const MAX_BOOKMARKS = 10

const typeAccessor = [
    { type: 'provider', acc: 'providerList' },
    { type: 'customer', acc: 'customerList' },
]

function readUserData() {
    return (JSON.parse(localStorage.getItem('userConfigData')))
}

export function manageUserConfig() {

    let uConfig = readUserData()

    if (uConfig == null || (uConfig != null && uConfig.version != CUR_VERSION)) {
        localStorage.setItem('userConfigData', JSON.stringify(userConfigDefaultData(CUR_VERSION)))
    }


    //localStorage.setItem('userConfigData' , JSON.stringify(userConfigDefaultData(CUR_VERSION)));
    //


}

function getAccessor(itemType) {
    let accessor = null
    const tAcc = typeAccessor.filter(t => t.type == itemType)
    if (tAcc.length == 1) accessor = tAcc[0].acc
    return accessor
}

export function setConfigBookmark(itemType, itemContent) {
    let usrData = readUserData()
    let accessor = getAccessor(itemType)

    if (accessor != null) {
        let resList = usrData.recentResults[accessor]
        let newBookmark = {
            accessedDate: Date.now(),
            content: itemContent
        }
        resList = [newBookmark, ...resList]
        usrData = {
            ...usrData,
            recentResults: {
                ...usrData.recentResults,
                [accessor]: resList
            }
        }
        localStorage.setItem('userConfigData', JSON.stringify(usrData));
    }
}

export function getRecentBookmarks(type, nResults = 5) {
    let usrData = readUserData()
    let accessor = getAccessor(type)

    if (accessor != null && usrData != null) {
        
        let res = usrData.recentResults[accessor]
        res = res.sort(function(a,b){
            return new Date(a.accessedDate) - Date(b.accessedDate);
          });
        res = res.filter((v,i,a)=>a.findIndex(v2=>(v2.content.id===v.content.id))===i)
        res = res.slice(0, Math.min(res.length, nResults));
        
        return res

    }
    return null
}