import * as React from 'react';
import { GlobalStateContext } from '../state/globalState';

import { errorToast, successToast } from "../common/toasts";
import { apiEndpoint } from "./apiEndpoints"
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

export function csrfToken() {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${'csrf_access_token'}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const notAuthorized = (response) => {
  //window.location.href = '/login';

  if (response.errorCode == 'no-authorization-token') {
    localStorage.removeItem("token")
    if (window.location.pathname != '/login')
      window.location.href = '/login'
  }

  if (response.errorCode == 'token-expired') {
    localStorage.removeItem("token")
    if (window.location.pathname != '/login')
      window.location.href = '/login'
  }

  if (response.tokenErrorCode == -1) {
    localStorage.removeItem("token")
    if (window.location.pathname != '/login')
      window.location.href = '/login'
  }
};



export function loadingScreen(data, context) {

  if (data.state == 1) {
    context.contextFcn.loading.setIsLoading()
  }
  if (data.state == 2) {
    context.contextFcn.loading.setLoaded()
  }


}

export async function postJson(endpoint, result, setResult) {

  setResult({ ...result, state: 1 });
  let u = new URLSearchParams(result.params).toString();
  if (u.length > 0) {
    endpoint = `${endpoint}?${u}`;
  }

  return new Promise(function (resolve, reject) {
    fetch(`${endpoint}`, {
      method: "POST",
      body: JSON.stringify(result.payload),
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-TOKEN': csrfToken()
        //Authorization: `Bearer ${result.token}`,
      },
    })
      .then(function (response) {
        let message = "";

        if (response.status === 200) {
          response.text().then((r) => {
            let items = JSON.parse(r);
            setResult({
              ...result,
              error: !response.ok,
              items: items,
              msg: message,
              statusCode: response.status,
              state: 2,
            });
            if (result.showSucToast) successToast(items.message);
          });
        } else {
          response.text().then((r) => {
            let items = [];
            try {
              items = JSON.parse(r);
            } catch (err) {
              console.log(err)
            }

            message = response.status + " " + response.statusText + " " + r;
            setResult({
              ...result,
              error: !response.ok,
              items: items,
              msg: message,
              statusCode: response.status,
              state: 2,
            });

            if (response.status == 401) notAuthorized(items);
            else if (result.showErrToast) errorToast(items.message);
          });
        }
      })
      .catch(function (error) {
        setResult({
          ...result,
          error: true,
          state: 2,
          msg: `Erro: ${error.message}`,
        });
      });
  });
}

export async function putJson(endpoint, result, setResult) {
  setResult({ ...result, state: 1 });

  if (/^[a-z0-9]+$/i.test(result.id)) {
    endpoint = endpoint + "/" + result.id;
  }
  let u = new URLSearchParams(result.params).toString();
  if (u.length > 0) {
    endpoint = `${endpoint}?${u}`;
  }

  return new Promise(function (resolve, reject) {
    fetch(`${endpoint}`, {
      method: "PUT",
      body: JSON.stringify(result.payload),
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-TOKEN': csrfToken()
      },
    })
      .then(function (response) {
        let message = "";

        if (response.status === 200) {
          response.text().then((r) => {
            let items = JSON.parse(r);
            setResult({
              ...result,
              error: !response.ok,
              items: items,
              msg: message,
              statusCode: response.status,
              state: 2,
            });
            if (result.showSucToast) successToast(items.message);
          });
        } else {
          response.text().then((r) => {
            let items = [];
            try {
              items = JSON.parse(r);
            } catch (err) {
              console.log(err)
            }

            message = response.status + " " + response.statusText + " " + r;
            setResult({
              ...result,
              error: !response.ok,
              items: items,
              msg: message,
              statusCode: response.status,
              state: 2,
            });
            if (response.status == 401) notAuthorized(items);
            else if (result.showErrToast) errorToast(items.message);
          });
        }
      })
      .catch(function (error) {
        setResult({
          ...result,
          error: true,
          state: 2,
          msg: `Erro: ${error.message}`,
        });
      });
  });
}

export async function getExcelFile(endpoint, result, outFilename = 'DataExport') {

  if (/^[a-z0-9]+$/i.test(result.id)) {
    endpoint = endpoint + '/' + result.id;
  }
  let u = new URLSearchParams({ ...result.params, mode: 'download' }).toString();
  console.log(u)
  if (u.length > 0) {
    endpoint = `${endpoint}?${u}`;
  }
  let headers = {
    'X-CSRF-TOKEN': csrfToken()
  };
  fetch(endpoint, {
    method: 'GET',
    headers: headers,
  })
    .then((res) =>
      res.blob()
    )
    .then((data) => {
      console.log(data)
      outFilename = `${moment().format('YYYYDDMM_HHmm')}_${outFilename}.xlsx`
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(data);
      a.download = outFilename;
      a.click();
    })
}

export async function getJson(endpoint, result, setResult) {

  setResult({ ...result, state: 1 });

  if (/^[a-z0-9]+$/i.test(result.id)) {
    endpoint = endpoint + "/" + result.id;
  }
  let u = new URLSearchParams(result.params).toString();
  if (u.length > 0) {
    endpoint = `${endpoint}?${u}`;
  }

  return fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      'X-CSRF-TOKEN': csrfToken()
    },
  })
    .then(function (response) {
      let message = "";
      if (response.status === 200) {
        response.text().then((r) => {
          let items = JSON.parse(r);
          setResult({
            ...result,
            error: !response.ok,
            items: items,
            msg: message,
            statusCode: response.status,
            state: 2,
          });
        });
      } else {
        response.text().then((r) => {
          let items = { 
            message: null
          }
          try {
            items = JSON.parse(r);
            message = response.status + " " + response.statusText + " " + r;
            setResult({
              ...result,
              error: !response.ok,
              items: items,
              msg: items.message,
              statusCode: response.status,
              state: 2,
            });
          } catch (error) {
            setResult({
              ...result,
              error: !response.ok,
              items: {},
              msg: "Erro de servidor",
              statusCode: response.status,
              state: 2,
            });
          }

          if (response.status == 401) notAuthorized(items);
          else if (result.showErrToast) errorToast(items.message != null ? items.message : 'Erro de servidor');
        });
      }
    })
    .catch(function (error) {
      setResult({
        ...result,
        error: true,
        msg: `Erro: ${error.message}`,
        state: 2,
      });
    });
}

export async function deleteJson(endpoint, result, setResult) {
  setResult({ ...result, state: 1 });

  if (/^[a-z0-9]+$/i.test(result.id)) {
    endpoint = endpoint + "/" + result.id;
  }
  let u = new URLSearchParams(result.params).toString();
  if (u.length > 0) {
    endpoint = `${endpoint}?${u}`;
  }

  return fetch(endpoint, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      'X-CSRF-TOKEN': csrfToken()
    },
  })
    .then(function (response) {
      let message = "";
      if (response.status === 200) {
        response.text().then((r) => {
          let items = JSON.parse(r);
          setResult({
            ...result,
            error: !response.ok,
            items: items,
            msg: message,
            statusCode: response.status,
            state: 2,
          });
          if (result.showSucToast) successToast(items.message);
        });
      } else {
        response.text().then((r) => {
          let items = JSON.parse(r);
          message = response.status + " " + response.statusText + " " + r;
          setResult({
            ...result,
            error: !response.ok,
            items: items,
            msg: message,
            statusCode: response.status,
            state: 2,
          });
          if (response.status == 401) notAuthorized(items);
          else if (result.showErrToast) errorToast(items.message);
        });
      }
    })
    .catch(function (error) {
      setResult({
        ...result,
        error: true,
        msg: `Erro: ${error.message}`,
        state: 2,
      });
    });
}

export async function postFileJson(endpoint, result, setResult, filecontent) {
  var data = new FormData();

  data.append("blob", filecontent, "filename");
  for (var key in result.payload) {
    if (key in result.payload) {
      data.append(key, result.payload[key]);
    }
  }

  setResult({ ...result, state: 1 });
  return new Promise(function (resolve, reject) {
    fetch(`${endpoint}`, {
      method: "POST",
      body: data,
      headers: {
        Authorization: `Bearer ${result.token}`,
      },
    })
      .then(function (response) {
        let message = "";

        if (response.status === 200) {
          response.text().then((r) => {
            let items = JSON.parse(r);
            setResult({
              ...result,
              error: !response.ok,
              items: items,
              msg: message,
              statusCode: response.status,
              state: 2,
            });
            if (result.showSucToast) successToast(items.message);
          });
        } else {
          response.text().then((r) => {
            let items = [];
            try {
              items = JSON.parse(r);
            } catch (err) {
              console.log(err)
            }

            message = response.status + " " + response.statusText + " " + r;
            setResult({
              ...result,
              error: !response.ok,
              items: items,
              msg: message,
              statusCode: response.status,
              state: 2,
            });
            if (response.status == 401) notAuthorized(items);
            else if (result.showErrToast) errorToast(items.message);
          });
        }
      })
      .catch(function (error) {
        setResult({
          ...result,
          error: true,
          state: 2,
          msg: `Erro: ${error.message}`,
        });
      });
  });
}

export const blankResult = {
  id: [],
  token: "",
  showSucToast: true,
  showErrToast: true,
  statusCode: 0,
  error: false,
  msg: "",
  items: [],
  payload: [],
  params: [],
  state: 0,
};

export const reqOk = (reqobj) => {
  if (reqobj.state == 2 && !reqobj.error) return true;
  else return false;
};

export const getFirst = (reqobj) => {
  if (reqOk(reqobj)) return reqobj.items.data[0];
  else return [];
};

// State:
// 0: not requested
// 1: ongoing
// 2: finished
