import React, {useEffect, useState} from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import moment from 'moment-timezone'
import { tzbe, rgxtz } from '../../../state/globalui/timezoneConfig'
import 'moment/dist/locale/pt';
moment.locale('pt');

const SelectDateRangeFilter = ({  expanded, sel, setSel }) => {

  const [ dateRng, setDateRng] = useState({startDate: null, endDate: null})

  useEffect(() => {
    if(dateRng.startDate != null && dateRng.endDate != null){
     
      setSel([{
        value: moment(dateRng.startDate).format() + ',' + moment(dateRng.endDate).format(),
        label:  moment(dateRng.startDate).format('DD/MM/YYYY') + ' a ' + moment(dateRng.endDate).format('DD/MM/YYYY')
      }])
    }

  },[dateRng])

  return (
    <div className="">
      <Datepicker
        i18n={'pt'}
        asSingle={false}
        name={'filterByDate'}
        id={'filterByDate'}
        placeholder={'Intervalo de datas...'}
        containerClassName="fixed"
        inputClassName={`w-full rounded-md border-0 p-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 font-thin sm:leading-6 bg-white`}
        toggleClassName=""
        value={dateRng}
        onChange={(e) => {
          setDateRng(e);
        }}
        displayFormat={'DD/MM/YY'}
      />
    </div>
  );
};

export default SelectDateRangeFilter;
