import axios from 'axios';
import { errorToast } from '../../common/toasts';
import { getRequests as gt } from '../../common/apiEndpoints';

export const initSteps = {
  step: 1,
  confirmed: false,
  options: {
    isFree: true,
    paycost: 0,
    requireConfirmPaymentMin: 5,
    notify_sms: false,
    notify_email: false,
    request_exactLocation: false,
  },
  steps: [
    {
      id: 1,
      key: 'customer',
      name: 'Paciente',
      label: '',
      value: '',
      insuranceId: -1,
      completed: false,
      status: 'upcoming',
    },
    {
      id: 2,
      key: 'provider',
      name: 'Médico e Consulta',
      label: '',
      value: '',
      completed: false,
      status: 'upcoming',
    },
    {
      id: 3,
      key: 'slot',
      name: 'Horário',
      label: '',
      value: '',
      completed: false,
      status: 'upcoming',
    },
    {
      id: 4,
      key: 'option',
      name: 'Opções',
      label: '',
      value: '',
      completed: false,
      status: 'upcoming',
    },
    {
      id: 5,
      key: 'confirm',
      name: 'Confirmação',
      label: '',
      value: '',
      completed: false,
      status: 'upcoming',
    },
  ],
};

export async function getBookingStep(token, searchParams) {
  let providerid = searchParams.get('providerId');
  let servicestype = searchParams.get('servSt');
  let customerid = searchParams.get('customerId');
  let slot = searchParams.get('slot');

  let stepSel = initSteps

  let provider = null;
  let customer = null;
  let servicetype = null;

  if (providerid != null) {
    const req = await axios.get(gt.provider, {
      params: { providerId: providerid },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (req.data.data.length == 0)
      errorToast('A referência indicada para o Médico não é válida');
    provider = req.data.data[0];
  }
  if (servicestype != null) {
    const req = await axios.get(gt.servicesubtype, {
      params: { id: servicestype },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (req.data.data.length == 0)
      errorToast('A referência indicada para a especialidade não é válida');

    let provCheck = true;
    if (provider != null)
      provCheck =
        req.data.data[0].servtype.providers.filter((s) => s.id == provider.id)
          .length > 0;

    if (provCheck) servicetype = req.data.data[0];
    else
      errorToast(
        'A referência indicada para a especialidade não é válida para o médico indicado'
      );
  }
  if (customerid != null) {
    const req = await axios.get(gt.customer, {
      params: { providerId: customerid },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (req.data.data.length == 0)
      errorToast('A referência indicada para o Paciente não é válida');
    customer = req.data.data[0];
  }

  if (slot && slot != -1 && typeof slot != 'undefined' ) {
    let _step = stepSel.steps.filter((s) => s.key == 'slot')[0];
    _step = { ..._step, slot: slot, completed: true };
    stepSel = {
      ...stepSel,
      steps: [...stepSel.steps.filter((s) => s.key != 'slot'), _step],
    };
  }

  if (customer != null) {
    let _step = stepSel.steps.filter((s) => s.key == 'customer')[0];
    _step = {
      ..._step,
      label: customer.name,
      value: customer.id,
      completed: true,
    };
    stepSel = {
      ...stepSel,
      steps: [...stepSel.steps.filter((s) => s.key != 'customer'), _step],
    };
  }
  if (provider != null) {
    let _step = stepSel.steps.filter((s) => s.key == 'provider')[0];

    _step = {
      ..._step,
      label: provider.name,
      value: provider.id,
    };
    stepSel = {
      ...stepSel,
      providerId: provider.id,
      provider: provider,
      steps: [...stepSel.steps.filter((s) => s.key != 'provider'), _step],
    };
  }
  if (servicetype != null) {
    stepSel = {
      ...stepSel,
      optId: servicetype.servtype.id,
      opt: servicetype.servtype,
      subOptId: servicetype.id,
      subOpt: servicetype,
      durationMin: servicetype.duration_min,
      completed: true,
    };

    let _step = stepSel.steps.filter((s) => s.key == 'provider')[0];
    if (_step.value != '') {
      _step = { ..._step, completed: true };
      stepSel = {
        ...stepSel,
        steps: [...stepSel.steps.filter((s) => s.key != 'provider'), _step],
      };
    }
  }

  
  let currentStep = getCurrentStep(stepSel.steps)
  let stepArray = stepSel.steps
  stepArray.sort((a,b) => a.id - b.id);
  stepSel = { ...stepSel, steps: stepArray, step: currentStep };
  return stepSel;
}

export function getCurrentStep( steps ){

  let setOngoing = false;
  let currentStep = 1;

  for (let i = 1; i < steps.length + 1; i++) {
    let _cStep = steps.filter((s) => s.id == i)[0]
    if (!setOngoing && !_cStep.completed) {
      currentStep = _cStep.id;
      setOngoing = true;
    }
  }
  
  return currentStep
}

