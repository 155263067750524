import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { blankResult, postJson, reqOk } from '../../common/fetchdata'
import { useContext, useEffect, useState } from 'react'
import AvatarXs from '../../common/card/AvatarXs'
import { DocumentIcon } from '@heroicons/react/24/outline'


export default function RelatedContactList({ selectedTopic, data, handleAssignment }) {

    const [contacts, setContacts] = useState([])

    useEffect(() => {
        if (reqOk(data)) {
            const _contacts = data.items.phone_lookup.flatMap(entry =>
                entry.items.map(item => ({
                    _type: entry.type,
                    ...item
                }))
            );
            setContacts(_contacts)

        }
    }, [data])


    return (
        <>
            <div className="flex flex-col">
                <div className="">
                    <p className="text-sm font-semibold text-gray-900">Relacionar contatos</p>
                </div>
                <div className="pt-6">
                    {
                        reqOk(data) ?
                            <>
                                {
                                    contacts.length == 0
                                        ?
                                        <>
                                            <div className="text-center py-8">
                                                <DocumentIcon className="mx-auto h-12 w-12 text-gray-400" />
                                                <h3 className="mt-6 text-sm font-normal text-gray-900">Não existem contactos a ser relacionados</h3>
                                            </div>
                                        </>
                                        :
                                        <ul
                                            role="list"
                                            className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
                                        >
                                            {contacts.map((person, idx) => (
                                                <li key={idx}>
                                                    <a href="#" className="relative flex justify-between gap-x-6 px-4 py-3 hover:bg-gray-50" onClick={() => handleAssignment(person._type, person.id)}>
                                                        <div className="flex min-w-0 gap-x-4">
                                                            <div className="w-12">
                                                                <AvatarXs person={person} />
                                                                <div className="text-[10px] pt-2 truncate">
                                                                    Paciente
                                                                </div>
                                                            </div>
                                                            <div className="min-w-0 flex-auto">
                                                                <p className="text-xs font-semibold leading-6 text-gray-900">
                                                                    <span className="absolute inset-x-0 -top-px bottom-0" />
                                                                    {person.fullname}
                                                                </p>
                                                                <p className="mt-1 flex text-xs text-gray-500 truncate hover:underline">
                                                                    <span className="pr-2 text-gray-700">NIF</span>{person.tax_id_ref}
                                                                </p>
                                                                {
                                                                    person.dateofbirth != null && <p className="mt-1 flex text-xs text-gray-500 truncate hover:underline">
                                                                        <span className="pr-2 text-gray-700">Data Nascimento</span>{person.dateofbirth}
                                                                    </p>
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="flex shrink-0 items-center gap-x-4">

                                                            <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                                        </div>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>

                                }
                            </>
                            :
                            <>
                                A carregar...
                            </>
                    }
                </div>
            </div>
        </>
    )
}
