import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { usePopper } from 'react-popper';
//import { GlobalStateContext } from '../state/globalState';
import CustomerWebSummaryCard from './CustomerWebSummaryCard';

import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

export default function CustomerWebSummaryPopup(props) {
  let [referenceElement, setReferenceElement] = React.useState();
  let [popperElement, setPopperElement] = React.useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement);
  //  const globalcontext = React.useContext(GlobalStateContext);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className={`${open ? '' : 'text-opacity-90'}`}
          >
            {props.btn}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-xl"
            >
              <CustomerWebSummaryCard popup={true} data={props.data} />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
