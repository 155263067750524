import React from 'react';
import { Fragment } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import i18n from '@emoji-mart/data/i18n/pt.json';

export default function EmojiPickerModule({
  field,
  formData,
  setFormData,
}) {
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const emojiPickerBtnRef = React.useRef(null);

  const handleShowPickEmoji = (e) => {
    e.preventDefault();
    setTimeout(() => {
      setShowEmojiPicker(true);
    }, '100');
  };
  const handleEmojiClickOutside = (e) => {
    if (showEmojiPicker) setShowEmojiPicker(false);
  };
  const handlePickEmoji = (e) => {
    setFormData({ ...formData, icon: e.native });
    setShowEmojiPicker(false);
  };

  return (
    <>
      {showEmojiPicker && (
        <div className={`absolute right-10 z-10`}>
          <Picker
            data={data}
            i18n={i18n}
            locale={'pt'}
            searchPosition={'none'}
            skinTonePosition={'none'}
            previewPosition={'none'}
            onClickOutside={handleEmojiClickOutside}
            onEmojiSelect={handlePickEmoji}
            noCountryFlags={true}
          />
        </div>
      )}

      <button
        onClick={handleShowPickEmoji}
        ref={emojiPickerBtnRef}
        className="text-2xl text-gray-400 hover:text-gray-600"
      >
        {formData['icon']}
      </button>
    </>
  );
}
