import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { apiUrl } from '../../common/apiEndpoints';
import { getJson, blankResult, reqOk } from '../../common/fetchdata';
import MessagingLoadingTopics from '../MessagingLoadingTopics'
import MessagingContentSelectRange from './MessagingContentSelectRange';

export default function MessagingContentSlideover({
  open,
  setOpen,
  content,
  viewLeadFromDashboard,
  viewByOwner
}) {

  const [data, setData] = useState(blankResult)

  const rngOpts = [
    { name: 'Últimos 90 dias', value: 90 },
    { name: 'Últimos 30 dias', value: 30 },
    { name: 'Últimos 7 dias', value: 7 },
    { name: 'Últimas 24 horas', value: 1 },
  ]

  const [rangeSel, setRangeSel] = useState(rngOpts[1])


  function handleFetch(_filt) {
    getJson(apiUrl.threadsdata, { ...data, params: { filter: _filt, rangeDays: rangeSel.value } }, setData)
  }

  useEffect(() => {
    if (content)
      handleFetch(content)
  }, [content])

  function setRange(rng) {
    setRangeSel(rng)
    getJson(apiUrl.threadsdata, { ...data, params: { filter: content, rangeDays: rng.value } }, setData)
  }

  const transformData = (data) => {

    const sortedData = data.data.sort(function (a, b) {
      return b.count - a.count;
    })

    return sortedData.reduce((acc, curr) => {

      let { user_id, user_name, count, unanswered_comm } = curr;
      user_id == null ? user_id = 0 : user_id

      // Find existing user in accumulator
      let existingUser = acc.find(user => user.user_id === user_id);

      // If user doesn't exist, add them
      if (!existingUser) {
        acc.push({
          user_id,
          user_name,
          leads: {
            all: count,
            unanswered_comm: unanswered_comm ? count : 0
          }
        });
      } else {
        // If user exists, update their leads
        existingUser.leads.all += count;
        if (unanswered_comm) {
          existingUser.leads.unanswered_comm += count;
        }
      }

      return acc;
    }, []);
  };


  return (

    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6 pt-16">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Filtrar
                        </Dialog.Title>

                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pb-5 pt-6">
                          <div>
                            <div className="mt-2">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

                                <div className="flex">
                                  <div className="w-full">

                                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                                      {content == 'openleads'
                                        ? 'Leads abertas'
                                        : (content == 'closedleads'
                                          ? 'Leads fechadas'
                                          : '')}
                                    </h1>
                                  </div>
                                  <div className="m-auto">

                                    {content == 'openleads'
                                      ? <></>
                                      : (content == 'closedleads'
                                        ? <MessagingContentSelectRange sel={rangeSel} setSel={setRange} opts={rngOpts} />
                                        : <></>)}

                                  </div>

                                </div>
                                <div className="mt-3 shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg overflow-auto max-h-48">
                                  {
                                    !reqOk(data) ? <MessagingLoadingTopics /> :
                                      (data.items.data.length > 0 ?

                                        <table className="min-w-full divide-y divide-gray-300 ">
                                          <thead className="bg-gray-50">
                                            <tr>
                                              <th scope="col" className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                Contacto
                                              </th>

                                              <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">
                                                Todos
                                              </th>
                                              <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">
                                                Mensagens
                                              </th>

                                            </tr>
                                          </thead>
                                          <tbody className="divide-y divide-gray-200 bg-white">
                                            {transformData(data.items).map((lead, i) => (
                                              <tr key={i}>
                                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                  <a href="#" onClick={() => {
                                                    viewByOwner(lead.user_id == 0 ? -1 : lead.user_id,
                                                      null,
                                                      (content == 'openleads' ? 'OPEN,PENDING' : (content == 'closedleads' ? 'CLOSED' : ''))
                                                    )
                                                  }
                                                  } > {lead.user_name == null ? 'Não atribuído' : lead.user_name}</a>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{lead.leads.all}</td>
                                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{lead.leads.unanswered_comm}

                                                </td>

                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                        :
                                        <NoLeadsAssigned />)
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function NoLeadsAssigned() {
  return (
    <div className="text-center p-6">

      <h3 className="text-sm font-semibold text-gray-900">Sem Leads</h3>
      <p className="mt-1 text-sm text-gray-500">Não existem dados.</p>
    </div>
  )
}