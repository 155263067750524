export const globalUiResetState = {
    newPublicBookingContact: false,
    newPublicContactMessage: false,
    newPhoneCentralImport: false,
}

export function handleGlobalUiUpdate(data, globalUiUpdate, setGlobalUiUpdate) {

    //console.log(data)

    if (data.label == 'NEW_BOOK_REQUEST' || data.label == 'NEW_CONTACT_SMS' || data.label == 'NEW_CONTACT_EMAIL') {
        setGlobalUiUpdate((g) => ({ ...g, newPublicBookingContact: true }))
        setTimeout(() => {
            setGlobalUiUpdate((g) => ({ ...g, newPublicBookingContact: false }))
        }, "500");
    }
    
    if (data.label == 'NEW_PHONE_IMPORT') {
        setGlobalUiUpdate((g) => ({ ...g, newPhoneCentralImport: true }))
        setTimeout(() => {
            setGlobalUiUpdate((g) => ({ ...g, newPhoneCentralImport: false }))
        }, "500");
    }

    return ''
}

export function handleResetGlobalUiUpdate(field, globalUiUpdate, setGlobalUiUpdate) {
    setGlobalUiUpdate((u) => ({ ...u, [field]: false }))
}