
import { apiUrl } from '../common/apiEndpoints';

const rows = [

  {
    selector: 'multifile',
    required: true,
    name: 'file_upload',
    title: 'Anexar ficheiros',
    id: 'file_upload',
    map: true,
    fullwidth: true,
    acceptedFiletypes: {
      'image/jpeg': [],
      'image/png': [],
      'application/pdf': []
    },
  },



];


export const formfield_uploadfile = {
  title: 'Anexar ficheiros',
  apiEndpoint: apiUrl.filemanager,
  method: 'POST',
  payloadType: 'formdata',
  preloadForm: false,
  submitLabel: 'Adicionar',
  rows: rows,
};
