import React, { Fragment } from 'react'
import MessagingContentHeader from './MessagingContentHeader'
import MessagingNoTopicSelected from './MessagingNoTopicSelected'
import MessagingBookRequestMessageContent from './content/MessagingBookRequestMessageContent'
import MessagingCallEventMessageContent from './content/MessagingCallEventMessageContent'
import MessagingContactContent from './content/MessagingContactContent'
import MessagingCustomerCommContent from './content/MessagingCustomerCommContent'

import AvatarXs from '../common/card/AvatarXs';

import {
    ChevronUpIcon,
    CheckIcon,
    PhoneXMarkIcon,
    QuestionMarkCircleIcon,
} from '@heroicons/react/20/solid'

import { Disclosure, Transition } from '@headlessui/react'
import moment from 'moment-timezone'
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import 'moment/dist/locale/pt';
import MessagingCustomerInternalComment from './content/MessagingCustomerInternalComment'
import MessagingContextMenu from './context/MessagingContextMenu'
moment.locale('pt');


const moods = [
    { name: 'Aberto', value: 'OPEN', icon: PhoneXMarkIcon, iconColor: 'text-white', bgColor: 'bg-red-500' },
    { name: 'Fechado', value: 'CLOSED', icon: CheckIcon, iconColor: 'text-white', bgColor: 'bg-green-400' },
    { name: 'Pendente', value: 'PENDING', icon: QuestionMarkCircleIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400' },
]

const info_reason = [
    { id: 'info-act-app', label: 'Informações sobre Consultas marcadas' },
    { id: 'info-chg-app', label: 'Alteração/Cancelamento de consulta' },
    { id: 'info-exa', label: 'Exames e análises Clínicas' },
    /*     { id: 'info-exa-snd', label:  'Enviar resultados de Exames' }, */
    { id: 'info-exa-req', label: 'Pedidos de resultados de exames' },
    { id: 'info-oth', label: 'Outros assuntos' }
]

function LoadingTopics() {

    return (
        <div className="p-8">
            <div
                role="status"
                className="w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
            >
                <div className="flex items-center justify-between">
                    <div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
                        <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
                    </div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
                </div>

                <div className="flex items-center justify-between pt-4">
                    <div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
                        <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
                    </div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
                </div>
                <div className="flex items-center justify-between pt-4">
                    <div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
                        <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
                    </div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
                </div>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default function MessagingContent({ selectedTopic, topicData, selectBatch, setSelectBatch }) {

    return (
        <>

            {selectBatch.length > 0 ? <>
                
                <MessagingContextMenu selectBatch={selectBatch} setSelectBatch={setSelectBatch}/>

            </>

                :
                (Object.keys(selectedTopic).length === 0 && topicData.state == 0)
                    ? <MessagingNoTopicSelected />
                    : (
                        topicData.state == 1
                            ? <LoadingTopics />
                            : (
                                Object.keys(selectedTopic).length > 0 &&
                                <>
                                    <MessagingContentHeader selectedTopic={selectedTopic} />
                                    <div className="space-y-2 py-4 sm:space-y-4 ">
                                        {selectedTopic.allContact.map((item, key) => (

                                            <Fragment key={key} >

                                                {item.type == "customer_comm" &&
                                                    <MessagingCustomerCommContent item={item} selectedTopic={selectedTopic} />
                                                }

                                                {item.type == "status" &&
                                                    <div className="flex w-full justify-between rounded-lg px-4 text-left font-medium ">
                                                        <div className="inline-flex items-center justify-center w-full">
                                                            <hr className="w-12 h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
                                                            <span className=" px-3 font-medium text-gray-900 bg-white dark:text-white dark:bg-gray-900">
                                                                {
                                                                    item.status == 'ASSIGNMENT'
                                                                        ? `Assignado a ${item.assigned_user.fullname}`
                                                                        : moods.filter(s => s.value == item.status).length > 0 ? moods.filter(s => s.value == item.status)[0].name : 'Não definido'
                                                                }
                                                                <span className="text-xs pl-2 text-gray-600 font-normal">{` alterado por ${item.system_event ? 'Sistema' : ((item.users != null) ? item.users.fullname : '')}`}</span>
                                                            </span>
                                                            <hr className="w-12 h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
                                                        </div>
                                                    </div>
                                                }

                                                {item.type == "comment" &&
                                                    <MessagingCustomerInternalComment item={item} />
                                                }
                                                {item.type == "call_event" &&
                                                    <MessagingCallEventMessageContent item={item} />
                                                }
                                                {item.type == "booking_request" &&
                                                    <div className="px-4 pb-2 pt-4 text-sm text-gray-500">
                                                        <div className="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">

                                                            {
                                                                item.contact_reason == 'booking-request' ?
                                                                    <MessagingBookRequestMessageContent item={item} /> :
                                                                    <MessagingContactContent item={item} />

                                                            }

                                                        </div>
                                                    </div>
                                                }

                                                <div className="w-full h-0.5 divide-y bg-gray-50"> </div>
                                            </Fragment>
                                        ))}
                                    </div>
                                </>
                            )
                    )
            }
        </>
    )
}
