import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import { useRef, Fragment } from 'react';
import { usePopper } from 'react-popper';

import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone'
import 'moment/dist/locale/pt';
moment.locale('pt');

export default function PopoutDialog({ buttonContent, popoutContent, width = 64 }) {
    let [popperElement, setPopperElement] = React.useState();
    const popRef = useRef(null);
    let { styles, attributes } = usePopper(popRef, popperElement);

    return (
        <Popover className="relative">
            {({ open }) => (
                <>
                    <Popover.Button
                        ref={popRef}
                        className={`outline-none ${open ? '' : 'text-opacity-90'}`}
                        onMouseOver={() => popRef.current.click()}
                    >
                        {buttonContent}
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel
                            ref={setPopperElement}
                            onMouseLeave={() => popRef.current.click()}
                            style={styles.popper}
                            {...attributes.popper}
                            className={`absolute left-1/2 z-10 w-${width} mt-3 -translate-x-1/2 transform px-4`}
                        >
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative flex flex-col bg-white py-4 px-6">
                                    {popoutContent}
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
}
