import * as React from 'react';
import { Routes, Route, Link, Outlet } from 'react-router-dom';

import { AuthProvider, RequireAuth } from './hooks/useAuth';
import SigninPage from './auth/SigninPage';

import LoadAuthContext from './auth/LoadAuthContext';
import NewBookingHome from './call/newbooking/NewBookingHome';
import CallListing from './call/CallListing';

import CustomerListing from './client/CustomerListing';
import CustomerWebListing from './client/Web/CustomerWebListing';
import PrescriptionListing from './prescription/PrescriptionListing';
import CustomerHome from './client/dashboard/CustomerHome';
import ProviderPage from './provider/ProviderPage';
import InboxPage from './mailing/InboxPage';
import CallPage from './call/CallPage';
import ProviderListing from './provider/ProviderListing';
import ProfileEdit from './settings/ProfileEdit';
import SettingsMain from './settings/SettingsMain';
import InsurancePage from './insurance/InsurancePage';
import HomeDashboard from './home/HomeDashboard';


import ConfigServicesHome from './settings/services/ConfigServicesHome';
import PaymentListing from './payment/PaymentListing';
import SupportHome from './support/SupportHome';

import GuestHome from './_guest/GuestHome'
import GuestBookRequest from './_guest/GuestBookRequest'

import { useNavigate } from 'react-router-dom';
import { GlobalStateProvider } from './state/globalState';
import AnalyticsHome from './analytics/AnalyticsHome';
import FileListingPage from './filemanager/FileListingPage';
import MessagingHome from './messaging/MessagingHome';
import NewActiveCall from './call/activecall/NewActiveCall';

export default function App() {
  return (
    <AuthProvider>
      <GlobalStateProvider>
        <Routes>

          <Route element={<Layout />}>
            <Route path="/" element={<PublicPage />} />
            {/* Authentication & Login */}
            <Route path="/login" element={<SigninPage />} />
            <Route path="/welcome" element={<SigninPage />} />


            {/*  Public Pages */}
            <Route path="/app" element={<GuestHome />} />
            <Route path="/booking" element={<GuestBookRequest />} />

            {/* Protected Pages */}
            <Route
              path="/home"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    showBreadcrumbs={false}
                    noPadding={true}
                    content={<HomeDashboard />}
                  />
                </RequireAuth>
              }
            />

            <Route
              path="/call"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    content={<CallListing />}
                  />
                </RequireAuth>
              }
            />

            <Route
              path="/filemanager"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    content={<FileListingPage />}
                  />
                </RequireAuth>
              }
            />

            <Route
              path="/call/new"
              element={
                <RequireAuth>
                  <LoadAuthContext content={<NewBookingHome />} />
                </RequireAuth>
              }
            />

            <Route
              path="/call/newactive"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    noPadding={true} content={<NewActiveCall />} />
                </RequireAuth>
              }
            />

            <Route
              path="/call/:id"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    noPadding={true}
                    content={<CallPage />} />
                </RequireAuth>
              }
            />
            <Route
              path="/payment"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    content={<PaymentListing />}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/analytics"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    noPadding={true}
                    content={<AnalyticsHome />}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/bookrequest"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    noPadding={true}
                    content={<MessagingHome />}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/serviceconfig"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    content={<ConfigServicesHome />}
                  />
                </RequireAuth>
              }
            />

            <Route
              path="/prescription"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    content={<PrescriptionListing />}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/preferences"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    content={<SettingsMain />}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/insurance"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    content={<InsurancePage />}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/inbox"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    content={<InboxPage />}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/customer"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    content={<CustomerListing />}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/customer-web"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    content={<CustomerWebListing />}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/customer/:id"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    content={<CustomerHome />}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/provider"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    content={<ProviderListing />}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/provider/:id"
              element={
                <RequireAuth>
                  <LoadAuthContext
                    route="provider-page"
                    content={<ProviderPage />}
                  />
                </RequireAuth>
              }
            />

          </Route>
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <LoadAuthContext route="profile" content={<ProfileEdit />} />
              </RequireAuth>
            }
          />
          <Route
            path="/support"
            element={
              <RequireAuth>
                <LoadAuthContext content={<SupportHome />} />
              </RequireAuth>
            }
          />
        </Routes>
      </GlobalStateProvider>
    </AuthProvider>
  );
}

function Layout() {
  return (
    <Outlet />
  );
}

/* function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
} */

function PublicPage() {

  let navigate = useNavigate();
  React.useEffect(() => {
    navigate('/home', { replace: true });
  }, [])

  return <></>;
}
