import React, { useEffect } from 'react';
import PhotoUpload from './fileupload/PhotoUpload';

export default function InputModule({
  field,
  formData,
  setFormData,
  formVal,
  setFormVal
}) {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  // initialize
  useEffect(() => {
    setFormVal((f) => ({
      ...f, [field.name]: {
        ...formVal[field.name],
        isTouched: false,
        isValid: isValid(formData[field.name])
      }
    }))
  }, [])


  // on update
  useEffect(() => {
    if (formData[field.name] !== '')
      setFormVal((f) => ({
        ...f, [field.name]: {
          ...formVal[field.name],
          isTouched: true,
          isValid: isValid(formData[field.name])
        }
      }))
  }, [formData[field.name]])

  function isValid(dt) {
    if (!field.required) return true
    else return dt != ''
  }

  
  const handleSetImage = (img, id) => {
    setFormData({ ...formData, [id]: img });

    console.log(img)
  };


  return (
    <>
      <PhotoUpload
        setImage={(img) =>
          handleSetImage(img, field.name)
        }
      />
    </>
  );
}
