import React, { useEffect } from 'react';
import { useState } from 'react';
import SelectServtype from '../combobox/SelectServtype';

export default function SelectServtypeModule({
  field,
  formData,
  setFormData,
  formVal,
  setFormVal,
  removeOptions = []
}) {

  const [remId, setRemId] = useState([])

  const handleChange = (e) => {
    setFormData({ ...formData, [field.name]: e });
  };

  // initialize
  useEffect(() => {
    setFormVal((f) => ({
      ...f, [field.name]: {
        ...formVal[field.name],
        isTouched: false,
        isValid: isValid(formData[field.name])
      }
    }))
  }, [])

  // on update
  useEffect(() => {
    let rId = []
    removeOptions.map(i => {
      if ('id' in i) {
        rId = [...rId, i.id]
      }
      if ('servtypes' in i) {
        rId = [...rId, i.providers.id]
      }
    })
    setRemId(rId)
  }, [removeOptions])

  // on update
  useEffect(() => {
    if (formData[field.name] !== '')
      setFormVal((f) => ({
        ...f, [field.name]: {
          ...formVal[field.name],
          isTouched: true,
          isValid: isValid(formData[field.name])
        }
      }))
  }, [formData[field.name]])

  function isValid(dt) {
    if (!field.required) return true
    else return dt != ""
  }
  return (
    <>

      <SelectServtype
        sel={formData[field.name] != null ? formData[field.name] : ''}
        setSel={handleChange}
        showTitle={false}
        removeOptions={remId}
      />
      {
        (formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) &&
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {'validationerr' in field ? field.validationerr : 'Escolha uma opção'}
        </p>

      }
    </>
  );
}
