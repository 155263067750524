import React from 'react';

const TabNavigation = ({ tabs, defaultTab }) => {
  const [sel, setSel] = React.useState(0);

  React.useEffect(() => {
    if (defaultTab) setSel(defaultTab);
  }, [defaultTab, tabs]);


  return (
    <div className="flex flex-col space-y-3 h-full">
      <div>
        <div className="text-sm font-medium text-center text-gray-500 ">
          <ul className="flex flex-wrap -mb-px">
            {tabs.map((t, k) => (
              <li key={k} className="mr-2">
                {!t.disabled ? (
                  <a
                    href="#"
                    onClick={() => setSel(t.idx)}
                    className={
                      t.idx == sel
                        ? 'inline-block p-3 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active'
                        : 'inline-block p-3 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 '
                    }
                  >
                    {t.title}
                  </a>
                ) : (
                  <a className="inline-block p-3 text-gray-400 rounded-t-lg cursor-not-allowed ">
                    {t.title}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="p-2 h-full" id={sel} role="tabpanel">
        {tabs.filter((t) => t.idx == sel)[0].content}
      </div>
    </div>
  );
};

export default TabNavigation;
