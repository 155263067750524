import React from 'react';
import { useSearchParams } from 'react-router-dom';
import useToken from '../../hooks/useToken';
import NewBookingStepper from './NewBookingStepper';
import NewBookingBody from './NewBookingBody';
import NewBookingControls from './NewBookingControls';
import { getBookingStep } from './booking';

export default function NewBookingHome() {
  let [searchParams] = useSearchParams();
  const { token } = useToken();
  const [selection, setSelection] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      setSelection(await getBookingStep(token, searchParams));
    };
    fetchData().catch(console.error);
  }, []);


  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
        <div className="hidden sm:block col-span-1 sm:col-span-1">
          {selection != null && (
            <div className="overflow-hidden rounded-lg bg-gray-100">
              <div className="px-4 py-5 sm:p-6">
                {' '}
                <NewBookingStepper
                  selection={selection}
                  setSelection={setSelection}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col col-span-1 sm:col-span-3 w-full overflow-hidden rounded-lg bg-white shadow">
          <div className="grow w-full">
            {selection != null && (
              <NewBookingBody
                selection={selection}
                setSelection={setSelection}
              />
            )}
          </div>
          <div className=" grid place-items-center w-full bg-gray-50 px-4 py-4 sm:px-6">
            {selection != null && (
              <NewBookingControls
                selection={selection}
                setSelection={setSelection}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
