import React from 'react';
import { getJson, blankResult, reqOk } from '../fetchdata';
import { getRequests as gt } from '../apiEndpoints';
import useToken from '../../hooks/useToken';
import SelectMultipleFilterCS from './SelectMultipleFilterCS';

const SelectMultipleServtype = ({ expanded, sel, setSel }) => {
  const { token } = useToken();
  const [inputData, setInputData] = React.useState(null);
  const [getRes, setGetRes] = React.useState(blankResult);

  React.useEffect(() => {
    if (expanded)
      fetchInfo();
  }, [expanded]);

  const fetchInfo = () => {
    getJson(
      gt.servicetype,
      { ...getRes, token: token, params: { entries: 1000 } },
      setGetRes
    );
  };

  React.useEffect(() => {
    if (reqOk(getRes)) {
      let selvalue = [];
      getRes.items.data.map(
        (p) => (selvalue = [...selvalue, { value: p.id, label: p.name }])
      );
      selvalue = selvalue.sort(function (a, b) {
        return a.label.localeCompare(b.label);
      })
      setInputData(selvalue);
    }
  }, [getRes]);

  return (
    <>
      <SelectMultipleFilterCS sel={sel} setSel={setSel} inputData={inputData} />
    </>
  );
};

export default SelectMultipleServtype;
