import { Fragment, useEffect, useState } from 'react'
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
import { PlusIcon } from '@heroicons/react/20/solid';
moment.locale('pt');



export default function ExamsAnalysisList({ callExamsans, groupByDay = true }) {
    const [examsans, setExamsans] = useState([])

    useEffect(() => {
        if (callExamsans != null && callExamsans.length > 0) {
            let _dates = []
            callExamsans.map(t => {
                _dates = [..._dates, moment(t.datetm).startOf('day')]
            })
            _dates = _dates.sort((a, b) => a - b)
            _dates = [...new Set(_dates)];
            let tList = []
            _dates.map(_d => {
                tList = [...tList, { dateTime: _d.format('D MMMM  YYYY'), date: _d.fromNow(), examsans: callExamsans.filter(t => moment(t.datetm).isSame(_d, 'day')) }]
            })
            setExamsans(tList)
        }
    }, [callExamsans])

    return (
        <div className="">
            {callExamsans == null ?
                <div className="text-center">

                    <h3 className="mt-2 text-sm font-semibold text-gray-900">Sem dados</h3>
                    <p className="mt-1 text-sm text-gray-500">Faça o pedido exames ou análises.</p>
                    <div className="mt-6">
                        <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                            Novo
                        </button>
                    </div>
                </div>
                :
                <table className="w-full text-left">
                    <thead className="sr-only">
                        <tr>
                            <th>Tratamento</th>
                            <th>Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        {examsans.map((day) => (
                            <Fragment key={day.dateTime}>
                                {groupByDay && <tr className="text-sm leading-6 text-gray-900">
                                    <th scope="colgroup" colSpan={3} className="relative isolate py-2 font-semibold">
                                        <time dateTime={day.dateTime}>{day.date}</time>
                                        <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50/20" />
                                        <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50/20" />
                                    </th>
                                </tr>}
                                {day.examsans.map((examsan) => (
                                    <tr key={examsan.id}>
                                        <td className="relative py-5 pr-6">
                                            <div className="flex gap-x-6">

                                                <div className="flex-auto">
                                                    <div className="flex items-start gap-x-3 flex-col">
                                                        <div className="text-sm font-medium leading-6 text-gray-900">
                                                            {examsan.obs}
                                                        </div>
                                                        <div className="mt-1 text-xs leading-5 text-gray-500">{examsan.type == 'E' ? 'Exame' :
                                                            (examsan.type == 'A' ? 'Análises' : '')}</div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                            <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                        </td>
                                        <td className="py-5 text-right">
                                            <div className="flex justify-end">
                                            <span
                                                    className="text-xs font-small leading-6 text-indigo-600 hover:text-indigo-500"
                                                >
                                                    {day.dateTime}

                                                </span>
                                            </div>

                                        </td>
                                    </tr>
                                ))}
                            </Fragment>
                        ))}
                    </tbody>
                </table>
            }
        </div>
    )
}
