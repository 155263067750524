
import { formfield_editservicesubtype as fin } from '../../forms/formfield_servicesubtype';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

export const tableconfig = {
  edit: {
    showEditButton: true,
    type: 'slideover',
    template: fin,
  },
  view: {
    title: 'Tipo de consulta',
    description: '',
    type: 'slideover',
    template: {
      nCols: 2,
      data: [
        {
          colSpan: 1,
          key: 'id',
          label: 'ID',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'name',
          label: 'Nome',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'servtype',
          label: 'Especialidade',
          valueformat: (e) => {
            return e.name;
          },
        },
        {
          colSpan: 1,
          key: 'is_enabled',
          label: 'Ativo',
          valueformat: (e) => {
            return <>
              {e ? (
                <CheckCircleIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                  aria-hidden="true"
                />
              ) : (
                ''
              )}
            </>
          },
        },
        {
          colSpan: 1,
          key: 'duration_min',
          label: 'Duração',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'is_remote',
          label: 'Remoto',
          valueformat: (e) => {
            return (
              <>
                {e ? (
                  <CheckCircleIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                    aria-hidden="true"
                  />
                ) : (
                  ''
                )}
              </>
            )
          },
        },
      ],
    },
  },
  columns: [
    {
      slideoverView: true,
      key: 'id',
      label: 'ID',
      valueformat: (e) => {
        return e;
      },
    },
    {
      slideoverView: true,
      key: 'is_enabled',
      label: 'Ativo',
      valueformat: (e) => {
        return <>
          {e ? (
            <CheckCircleIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
              aria-hidden="true"
            />
          ) : (
            ''
          )}
        </>
      },
    },
    {
      slideoverView: true,
      key: 'name',
      label: 'Nome',
      valueformat: (e) => {
        return e;
      },
    },
    {
      slideoverView: true,
      key: 'servtype',
      label: 'Especialidade',
      valueformat: (e) => {
        return e.name;
      },
    },
    {
      slideoverView: true,
      key: 'duration_min',
      label: 'Duração',
      valueformat: (e) => {
        return e;
      },
    },
    {
      slideoverView: true,
      key: 'is_remote',
      label: 'Remoto',
      valueformat: (e) => {
        return <>
          {e ? (
            <CheckCircleIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
              aria-hidden="true"
            />
          ) : (
            ''
          )}
        </>
      },
    }, {
      slideoverView: true,
      key: 'allow_client_booking',
      label: 'Marcação web',
      valueformat: (e) => {
        return <>
          {e ? (
            <CheckCircleIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
              aria-hidden="true"
            />
          ) : (
            ''
          )}
        </>
      },
    },
  ],
  filter: {
    initFilter: {
      entries: 10,
      page: 1,
    },
    fields: [

    ],
  },
};
