import React from 'react';
import { callStatusLabel } from '../../call/getCallStatus';
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { UsersIcon, PencilSquareIcon, PlusIcon } from '@heroicons/react/20/solid';
import { afilliations } from '../../label/customer';
import { custCallStatus } from '../../label/call';
import CallActionMenu from '../../call/controls/CallActionMenu';
import CallReceptionNotes from '../../call/callnotes/CallReceptionNotes';
import SelectDateRangeFilter from '../../common/table/filters/SelectDateRangeFilter';
import SelectMultipleProvider from '../../common/combobox/SelectMultipleProvider';
import SelectMultipleCallStatus from '../../common/combobox/SelectMultipleCallStatus';
import InputBoxFiltering from '../table/filters/InputBoxFiltering';



import SelectCallState from '../../common/combobox/SelectCallState';
import BooleanFiltering from '../table/filters/BooleanFiltering';
import CallTagsList from '../../call/calltags/CallTagsList';
import CallStateLabel from '../../call/calltags/CallStateLabel';


export const tableconfig = {
  view: {
    title: 'Consulta',
    description: '',
    type: 'slideover',
    fullHeight: true,
    refreshOnClose: true,
    template: {
      nCols: 2,
      data: [
        {
          colSpan: 2,
          key: 'id',
          label: '',
          valueformat: (e, row) => {
            return (
              <>
                <CallReceptionNotes callId={row.id} />
              </>
            );
          },
        },
      ],
    },
  },
  columns: [
    {
      key: 'n_reception_notes',
      label: '',
      valueformat: (e, row) => {
        return (
          <div className="space-x-2 p-3">
            {e > 0 && <PencilSquareIcon className="w-6 h-6" />}
          </div>
        );
      },
    },
    {
      key: 'gid',
      label: 'ID',
      filter: {
        component: InputBoxFiltering,
        fieldSort: 'id',
        fieldOrig: 'value',
        fieldOrigLabel: 'label',
        fieldDest: 'id',
        fieldLabel: 'ID'
      },
      valueformat: (e, row) => {
        return (
          <span className="text-xs uppercase font-semibold">
            <Link to={`/call/${row.id}`}>{`${e}`}</Link>
          </span>
        );
      },
    },
    {
      key: 'datetm',
      label: 'Hora/Data',
      slideoverView: true,
      filter: {
        component: SelectDateRangeFilter,
        fieldSort: 'datetm',
        fieldOrig: 'value',
        fieldOrigLabel: 'label',
        fieldDest: 'datetm',
        fieldLabel: 'Data'
      },
      valueformat: (e, row) => {
        return (
          <div className="flex flex-row">
            <div>
              <p>
                <span className="text-sm font-semibold">
                  {moment(e).format('h:mm')}
                </span>
              </p>
              <p>
                <span className="text-xs">
                  {moment(e).format('DD/MMMM/YYYY')}
                </span>
              </p>
            </div>
          </div>
        );
      },
    },
    {
      key: 'state',
      label: 'Estado',
      slideoverView: true,
      filter: {
        component: SelectMultipleCallStatus,
        getLabelFromValue: callStatusLabel,
        fieldSort: 'state',
        fieldOrig: 'value',
        fieldOrigLabel: 'label',
        fieldDest: 'state',
        fieldLabel: 'Estado'
      },
      valueformat: (e, row) => {

        return (
          <CallStateLabel call={row}/>
        );
      },
    },
    {
      key: 'customers',
      label: 'Nome Paciente',
      filter: {
        component: InputBoxFiltering,
        fieldSort: 'customerName',
        fieldOrig: 'value',
        fieldOrigLabel: 'label',
        fieldDest: 'customerName',
        fieldLabel: 'Paciente'
      },
      valueformat: (e, row) => {
        return (
          <div className="text-sm font-light truncate">
            <p>
              <Link to={`/customer/${e.id}`}>{`${e.name} ${e.lastname}`}</Link>
            </p>
            {row.customer_managers != null && (
              <div className="rounded-md bg-blue-50 p-1 mt-1 w-fit">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <UsersIcon
                      className="h-3 w-3 mt-0.5 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-2">
                    <p className="text-xs text-blue-400 truncate">{`${row.customer_managers.name} ${row.customer_managers.lastname}`}</p>
                    <p className="text-xs text-blue-300 truncate">
                      {
                        afilliations.filter(
                          (a) =>
                            a.value == row.customer_managers.affiliation.value
                        )[0].desc
                      }
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      key: 'get_call_tags',
      label: 'Etiquetas',
      valueformat: (e, row) => {

        let st = {}
        if (e != null)
          st = custCallStatus.filter((s) => s.key == e)[0];

        return (
          <div className='flex'>
            <CallTagsList call={row} tags={e}/>
            
          </div>
        );
      },
    },
    {
      key: 'users',
      label: 'Médico',
      filter: {
        component: SelectMultipleProvider,
        fieldSort: 'providerName',
        fieldOrig: 'value',
        fieldOrigLabel: 'label',
        fieldDest: 'providerId',
        fieldLabel: 'Médico'
      },
      valueformat: (e, row) => {
        return (
          <span className="text-sm font-light truncate">
            <Link to={`/provider/${e && e.id}`}>{`${e && e.name} ${e && e.lastname}`}</Link>
          </span>
        );
      },
    },
    {
      key: 'servsubtype',
      label: 'Consulta',
      sortable: true,
      valueformat: (e, row) => {
        return (
          <div>
            <p className="text-xs font-semibold truncate">{`${e != null ? e.name : ''}`}</p>
            <p className="text-xs truncate">{`${e != null ? e.servtype.name : ''}`}</p>
          </div>
        );
      },
    },

    {
      key: 'id',
      label: '',
      valueformat: (e, row, refreshHandler) => {
        return (
          <>
            <CallActionMenu data={row} refreshHandler={refreshHandler} />
          </>
        );
      },
    },
  ],
  filter: {
    initFilter: {
      entries: 10,
      page: 1,
      //state: ['BOOKED','COMPLETED','ONGOING','WAITING']
    },

  },
};
