import React from 'react';
import { reqOk } from '../../common/fetchdata';
import MessageFlow from './MessageFlow';
import Select from 'react-select';

export default function ChatContainer({ globalcontext }) {
  const [chatSessionContext, setChatSessionContext] = React.useState(false);
  const [selectedOption, setselectedOption] = React.useState(null);
  const [groupedOptions, setgroupedOptions] = React.useState([]);
  const [messages, setMessages] = React.useState([]);

  const [optActiveCall, setoptActiveCall] = React.useState([]);
  const [optCustomerChat, setoptCustomerChat] = React.useState([]);
  const [optUserChat, setoptUserChat] = React.useState([]);

  // add the thread messages to the message stack
  React.useEffect(() => {
    if (reqOk(globalcontext.userData) && chatSessionContext) {
      const chatRef = chatSessionContext.ref.split('-');
      let _msgs = globalcontext.userData.items.messageThreads.messages.filter(
        (_m) => _m.conversation_type == chatRef[0]
      );
      let _msgStack = [];
      if (chatRef[0] == 'call') {
        _msgs = _msgs.filter((_m) => _m.call_req_id == chatRef[1]);
        if (_msgs.length > 0) _msgs = _msgs[0];
        else console.error('no thread available');
        _msgStack = _msgs.messages;
      }
      if (chatRef[0] == 'dm') {
        const _recp = chatSessionContext.recipient.public_id;
        _msgs.filter((_m) => {
          if (_m.destination.length == 1 && _m.destination[0].uid == _recp)
            _msgStack.messages;
          else console.error('messaging to group not implemented');
        });
      }
      console.log(_msgStack)
      setMessages(_msgStack);
    }
  }, [globalcontext.userData, chatSessionContext]);

  React.useEffect(() => {
    setgroupedOptions([
      {
        label: 'Consulta ativa',
        options: optActiveCall,
      },
      {
        label: 'Pacientes',
        options: optCustomerChat,
      },
      {
        label: 'Médicos',
        options: optUserChat,
      },
    ]);
  }, [optActiveCall, optCustomerChat, optUserChat]);

  const handleChangeRecipient = (_dest) => {
    console.log(_dest)
    setselectedOption(_dest);
    const msg = globalcontext.userData.items.messageThreads;
    const _d = _dest.value.split('-');
    let recipient = {};
    if (_d[0] == 'call') {
      const thiscallid = globalcontext.activeCall.items.current.id;
      if (msg.nThreads) {
        let callmsg = msg.messages.filter((m) => m.call_req_id == thiscallid);
        if (callmsg.length > 0) setMessages(callmsg[0].messages);
        recipient = activeCallRecipient();
      }
    }
    if (_d[0] == 'dm') {
      recipient = dmRecipient(_dest.data);
    }

    setChatSessionContext({
      sender: senderData(),
      recipient: recipient,
      ref: _dest.value,
    });
  };

  const activeCallRecipient = () => {
    return {
      conversation: 'active_call',
      name: `${globalcontext.activeCall.items.current.customers.customers_web.name} ${globalcontext.activeCall.items.current.customers.customers_web.lastname}`,
      id: globalcontext.activeCall.items.current.customers.customers_web.id,
      public_id:
        globalcontext.activeCall.items.current.customers.customers_web
          .public_id,
      avatar_url: globalcontext.activeCall.items.current.customers.avatar_url,
      initials: globalcontext.activeCall.items.current.customers.initials,
    };
  };

  const dmRecipient = (_dest) => {
    return {
      conversation: 'dm',
      name: `${_dest.name} ${_dest.lastname}`,
      id: _dest.id,
      public_id: _dest.uid,
      avatar_url: _dest.avatar_url,
      initials: _dest.initials,
    };
  };

  const senderData = () => {
    return {
      name: `${globalcontext.userData.items.user.name} ${globalcontext.userData.items.user.lastname}`,
      id: globalcontext.userData.items.user.id,
      public_id: globalcontext.userData.items.user.public_id,
      avatar_url: globalcontext.userData.items.user.avatar_url,
      initials: globalcontext.userData.items.user.initials,
    };
  };

  // Fill available options
  React.useEffect(() => {


    if (reqOk(globalcontext.userData) && reqOk(globalcontext.activeCall)) {
      const msg = globalcontext.userData.items.messageThreads.messages;
      let _custChat = [];
      let _usrChat = [];
      msg.filter((_m) => {
        if (
          globalcontext.activeCall.items.active == 1 &&
          _m.conversation_type == 'call' &&
          _m.call_req_id == globalcontext.activeCall.items.current.id
        ) {
          const actCallOpt = [
            {
              value: `call-${globalcontext.activeCall.items.current.id}`,
              label: `Consulta ${globalcontext.activeCall.items.current.servsubtype.servtype.name} - ${globalcontext.activeCall.items.current.customers.customers_web.name} ${globalcontext.activeCall.items.current.customers.customers_web.lastname}`,
            },
          ];
          setoptActiveCall(actCallOpt);
          handleChangeRecipient(actCallOpt[0]);
        }
        if (_m.conversation_type == 'dm') {
          if (_m.destination.length == 1) {
            if (_m.destination[0].type == 'customer') {
              _custChat.push({
                value: `dm-${_m.destination[0].uid}`,
                label: `${_m.destination[0].name} ${_m.destination[0].lastname}`,
                data: _m.destination[0],
              });
            }
            if (_m.destination[0].type == 'user') {
              _usrChat.push({
                value: `dm-${_m.destination[0].uid}`,
                label: `${_m.destination[0].name} ${_m.destination[0].lastname}`,
                data: _m.destination[0],
              });
            }
          }
        }
      });
      console.log(_custChat)
      console.log(_usrChat)

      setoptCustomerChat(_custChat);
      setoptUserChat(_usrChat);
    }
  }, [globalcontext.userData]);

  return (
    <>
      <div className="w-80">
        <div className="bg-gray-300 p-4 relative flex h-16 items-center justify-between">
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <Select
              /* isMulti */
              className="w-60 text-small"
              name="recipient"
              value={selectedOption}
              onChange={handleChangeRecipient}
              options={groupedOptions}
              classNamePrefix="select"
              noOptionsMessage={() => 'Sem opções disponíveis'}
            />
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <button
              onClick={() =>
                globalcontext.contextFcn.panel.chatbox.visible.toggle()
              }
              type="button"
              className=" rounded-full  bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            >
              <span className="sr-only">Fechar</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-x"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
        </div>
        <div className="mt-2 h-80">
          <MessageFlow
            messages={messages}
            chatSessionContext={chatSessionContext}
            globalcontext={globalcontext}
          />
        </div>
      </div>

      {/* <Tab.Group selectedIndex={actionsPopup} onChange={setActionsPopup}>
        <div className="bg-gray-300 p-4 relative flex h-16 items-center justify-between">
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
              {Object.keys(categories).map((category) => (
                <Tab
                  style={{ paddingLeft: '20px', paddingRight: '20px' }}
                  key={category}
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                      selected
                        ? 'bg-white shadow'
                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                    )
                  }
                >
                  {category}
                </Tab>
              ))}
            </Tab.List>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <button
              onClick={() => setActionsPopup(-1)}
              type="button"
              className=" rounded-full  bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            >
              <span className="sr-only">Fechar</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-x"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
        </div>
        <Tab.Panels className="mt-2">
          <Tab.Panel className="h-80">
            <MessageFlow
              messages={messages}
              chatSessionContext={chatSessionContext}
            />
          </Tab.Panel>
          <Tab.Panel
            className={classNames(
              'rounded-xl bg-white p-3',
              'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
            )}
          >
            <Filelist files={files} app={app}/>
            
          </Tab.Panel>


        </Tab.Panels>
      </Tab.Group> */}
    </>
  );
}
