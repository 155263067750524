import React, { useState } from 'react';
import FormSlideover from '../../common/slideover/FormSlideover';
import TableEngineTw from '../../common/table/TableEngineTw';
import SpeedDialAdd from '../../nav/speeddial/SpeedDialAdd';
import { reqOk, blankResult, getJson } from '../../common/fetchdata';
import { getRequests as gt } from '../../common/apiEndpoints';
import { tableconfig as tbcfg } from '../../common/configs/tableconfig_treatmenttype';
import { formfield_newtreatmenttype as fin } from "../../forms/formfield_treatmenttype";

const TreatmentTypePage = () => {

  const [edit_formData, edit_setFormData] = React.useState({});
  const [openAdd, setOpenAdd] = useState(false);
  const [formKey, setFormKey] = useState(0);

  const [getRes, setGetRes] = React.useState(blankResult);
  const fetchData = (args = {}) => {
    getJson(gt.treatmenttype, { ...getRes, params: args }, setGetRes);
  };

  const handleAddNewItem = (usr) => {
    setOpenAdd(true);
  };

  const edit_okHandler = () => {    
    setFormKey((k) => k+1)
    setOpenAdd(false)
    fetchData()
  };

  const edit_errHandler = () => {
    console.error('Error processing request')
    setFormKey((k) => k+1)
    setOpenAdd(false)
  };
  return (
    <>
      <FormSlideover
        fin={fin}
        key={formKey}
        open={openAdd}
        setOpen={setOpenAdd}
        formData={edit_formData}
        setFormData={edit_setFormData}
        okHandler={edit_okHandler}
        errHandler={edit_errHandler}
      />
      <SpeedDialAdd onClickAction={handleAddNewItem} />

      <TableEngineTw
        cfg={tbcfg}
        items={reqOk(getRes) && getRes.items}
        fetchData={fetchData}
        refreshHandler={fetchData}
      />
    </>
  );
};

export default TreatmentTypePage;
