import { postRequests as post } from "../common/apiEndpoints";


export const formfield_newunavailability = {
  title: 'Adicionar',
  description: 'Adicione dias de indisponibilidade',
  apiEndpoint: post.calendarunav,
  method: 'POST',
  preloadForm: false,
  name: '',
  id: '',
  submitLabel: 'Adicionar',
  rows: [

    {
      selector: 'daterangepicker',
      required: true,
      name: 'daterange',
      selectType: 'range',
      allowPast: false,
      title: 'Intervalo',
      placeholder: 'Intervalo de datas',
      id: 'newunavailability_daterange',
      map: true,
    },
   
    
  ],
};
