
// https://www.freepik.com/free-vector/young-creative-people-looking-new-ideas-projects-flat-illustration_19216997.htm#fromView=search&page=1&position=36&uuid=38aa116f-7e42-4319-b7d1-2d794be279df

import {  QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

export default function NotImplementedScreen() {
  return (
    <div className="text-center py-12">
      
      <QuestionMarkCircleIcon className="mx-auto h-16 w-16 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">Não implementado</h3>
      <p className="mt-1 text-sm text-gray-500">A funcionalidade ainda não está disponível.</p>
      <div className="mt-6">
       
      </div>
    </div>
  )
}
