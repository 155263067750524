export function validatePassword(password) {
    // Check if password length is between 8 and 24 characters
    const isValidLength = password.length >= 8 && password.length <= 24;

    // Check if password contains at least one uppercase letter
    const hasUppercase = /[A-Z]/.test(password);

    // Check if password contains at least one lowercase letter
    const hasLowercase = /[a-z]/.test(password);

    // Check if password contains at least one digit
    const hasDigit = /\d/.test(password);

    // Check if password contains at least one symbol
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return isValidLength && hasUppercase && hasLowercase && hasDigit && hasSymbol;
}