import { useEffect, useState } from 'react';
import { useNotificationActionsBelow } from './notifications/useNotificationActionsBelow';

export default function NotificationPanel({ triggerNotification, setTriggerNotification }) {


    // icon, title, body, actionFcn, actionLabel
    const [icon, setIcon] = useState(null)
    const [title, setTitle] = useState(null)
    const [body, setBody] = useState(null)
    const [actionFcn, setActionFcn] = useState(null)
    const [actionLabel, setActionLabel] = useState(null)

    const { NotificationComponentNab, showNab, setshowNab } = useNotificationActionsBelow(icon, title, body, actionFcn, actionLabel);




    useEffect(() => {
        if(Object.keys(triggerNotification).length > 0){
            if(triggerNotification.type == 'ACTIONS_BELOW'){
                setTitle(triggerNotification.title)
                setBody(triggerNotification.body)
                setActionFcn(triggerNotification.actionFcn)
                setActionLabel(triggerNotification.actionLabel)
                setshowNab(true)
            }
        }

    }, [triggerNotification])

    return (<>
        {showNab && <NotificationComponentNab />}


        {/* Rest of your component */}
    </>)
}