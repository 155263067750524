import React from 'react';
import { useEffect } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';

export default function DatePickerModule({
  field,
  formData,
  setFormData,
  formVal,
  setFormVal
}) {

  // initialize
  useEffect(() => {
    setFormVal((f) => ({
      ...f, [field.name]: {
        ...formVal[field.name],
        isTouched: false,
        isValid: isValidDate(formData[field.name])
      }
    }))
  }, [])


  // on update
  useEffect(() => {
    if (formData[field.name] !== '')
      setFormVal((f) => ({
        ...f, [field.name]: {
          ...formVal[field.name],
          isTouched: true,
          isValid: isValidDate(formData[field.name])
        }
      }))
  }, [formData[field.name]])

  function isValidDate(dt) {
    if(!field.required) return true
    if (dt == '' || dt == null ||
      (dt.startDate == null && dt.endDate == null)
    ) return false
    else return true
  }

  return (
    <>
      <Datepicker
        i18n={'pt'}
        asSingle={field.selectType == 'single' ? true : false}
        name={field.name}
        id={field.id}
        minDate={field.allowPast ? null : new Date()}
        placeholder={field.placeholder}
        inputClassName={`block w-full rounded-md border-0 p-1.5  shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white ${(formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400'} `}
        toggleClassName="absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed top-0"
        value={formData[field.name] != null ? formData[field.name] : ''}
        onChange={(e) => {
          setFormData({
            ...formData,
            [field.name]: e,
          });
        }}
        displayFormat={'DD/MM/YYYY'}
      />
      {
        (formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) &&
        <p className="mt-2 text-sm text-red-600" id="email-error">
          Data inválida
        </p>

      }
    </>
  );
}

//
