
// { localText(lang, 'REQ_SUC_ALERT_BODY') }

const ptLang = [
  {
    ref: 'REQ_SENT_TITLE',
    text: 'Pedido Enviado',
  },
  {
    ref: 'REQ_SENT_BODY',
    text: 'Obrigado pelo envio de pedido de marcação. Será contactado em breve para efeitos de confirmação da marcação.',
  },
  {
    ref: 'REQ_ERROR_ALERT_BODY',
    text: 'Erro ao obter dados de consulta. Atualize a página e tente novamente',
  },
  {
    ref: 'REQ_SUC_ALERT_BODY',
    text: 'Pedido enviado com sucesso',
  },
  {
    ref: 'GEN_ERROR_TITLE',
    text: 'Erro',
  },
  {
    ref: 'GEN_ERROR_TRYAGAIN',
    text: 'Tentar novamente',
  },
  {
    ref: 'FORM_FIX_FIELDS',
    text: 'Por favor corrija os campos com dados inválidos',
  },
  {
    ref: 'FORM_TITLE',
    text: 'Pedido de marcação de consulta',
  },
  {
    ref: 'FORM_SUBTITLE',
    text: 'Insira os seguintes dados.',
  },
  {
    ref: 'FORM_FNAME',
    text: 'Primeiro nome',
  },
  {
    ref: 'FORM_VALUE_NOT_VALID',
    text: 'Valor inválido',
  },
  {
    ref: 'FORM_LNAME',
    text: 'Último nome',
  },
  {
    ref: 'FORM_NIF',
    text: 'NIF',
  },
  {
    ref: 'FORM_ID_DOC',
    text: 'Documento Identificação',
  },
  {
    ref: 'FORM_NO_NIF',
    text: 'Não tenho NIF',
  },
  {
    ref: 'FORM_HAVE_NIF',
    text: 'Tenho NIF',
  },
  {
    ref: 'FORM_EMAIL',
    text: 'Email',
  },
  {
    ref: 'FORM_MOB_PHONE',
    text: 'Telemóvel',
  },
  {
    ref: 'FORM_BAD_MOB_PHONE',
    text: 'Número de telemóvel inválido',
  },
  {
    ref: 'FORM_SERV_TYPE',
    text: 'Tipo',
  },
  {
    ref: 'FORM_PICK',
    text: 'Escolha',
  },
  {
    ref: 'FORM_BAD_PICK',
    text: 'Escolha inválida',
  },
  {
    ref: 'FORM_SERV_SPECIALTY',
    text: 'Especialidade',
  },
  {
    ref: 'FORM_SERV_PROVIDER',
    text: 'Especialista',
  },
  {
    ref: 'FORM_ALL',
    text: 'Todos',
  },
  {
    ref: 'FORM_INSURANCE',
    text: 'Seguro Saúde',
  },
  {
    ref: 'FORM_PRIV_INSURANCE',
    text: 'Particular',
  },
  {
    ref: 'FORM_OTHER',
    text: 'Outro',
  },
  {
    ref: 'FORM_WHICH',
    text: 'Qual?',
  },
  {
    ref: 'FORM_OPTION',
    text: 'Opção',
  },
  {
    ref: 'FORM_ANY',
    text: 'Qualquer',
  },
  {
    ref: 'FORM_MORNING',
    text: 'Manhã',
  },
  {
    ref: 'FORM_AFTERNOON',
    text: 'Tarde',
  },
  {
    ref: 'FORM_COMMENTS',
    text: 'Comentários',
  },
  {
    ref: 'FORM_READAGREE',
    text: 'Li e concordo com a',
  },
  {
    ref: 'FORM_PRIV_POLICY',
    text: 'política de privacidade',
  },
  {
    ref: 'FORM_PRIV_POLICY_ERROR',
    text: 'Deverá concordar com os termos e condições',
  },
  {
    ref: 'FORM_CANCEL',
    text: 'Cancelar',
  },
  {
    ref: 'FORM_SUBMIT',
    text: 'Submeter',
  },
]

const esLang = [
  {
    ref: 'REQ_SENT_TITLE',
    text: 'Pedido Enviado',
  },
  {
    ref: 'REQ_SENT_BODY',
    text: 'Gracias por enviar su solicitud de cita. Será contactado pronto para confirmar su cita.',
  },
  {
    ref: 'REQ_ERROR_ALERT_BODY',
    text: 'Error al obtener datos de la consulta. Actualice la página e inténtelo de nuevo',
  },
  {
    ref: 'REQ_SUC_ALERT_BODY',
    text: 'Solicitud enviada con éxito',
  },
  {
    ref: 'GEN_ERROR_TITLE',
    text: 'Error',
  },
  {
    ref: 'GEN_ERROR_TRYAGAIN',
    text: 'Intentar de nuevo',
  },
  {
    ref: 'FORM_FIX_FIELDS',
    text: 'Por favor, corrija los campos con datos inválidos',
  },
  {
    ref: 'FORM_TITLE',
    text: 'Solicitud de cita',
  },
  {
    ref: 'FORM_SUBTITLE',
    text: 'Introduzca los siguientes datos.',
  },
  {
    ref: 'FORM_FNAME',
    text: 'Nombre',
  },
  {
    ref: 'FORM_VALUE_NOT_VALID',
    text: 'Valor inválido',
  },
  {
    ref: 'FORM_LNAME',
    text: 'Apellido',
  },
  {
    ref: 'FORM_NIF',
    text: 'NIF',
  },
  {
    ref: 'FORM_ID_DOC',
    text: 'Documento de Identificación',
  },
  {
    ref: 'FORM_NO_NIF',
    text: 'No tengo NIF',
  },
  {
    ref: 'FORM_HAVE_NIF',
    text: 'Tengo NIF',
  },
  {
    ref: 'FORM_EMAIL',
    text: 'Correo electrónico',
  },
  {
    ref: 'FORM_MOB_PHONE',
    text: 'Teléfono móvil',
  },
  {
    ref: 'FORM_BAD_MOB_PHONE',
    text: 'Número de teléfono móvil inválido',
  },
  {
    ref: 'FORM_SERV_TYPE',
    text: 'Tipo',
  },
  {
    ref: 'FORM_PICK',
    text: 'Elegir',
  },
  {
    ref: 'FORM_BAD_PICK',
    text: 'Elección inválida',
  },
  {
    ref: 'FORM_SERV_SPECIALTY',
    text: 'Especialidad',
  },
  {
    ref: 'FORM_SERV_PROVIDER',
    text: 'Especialista',
  },
  {
    ref: 'FORM_ALL',
    text: 'Todos',
  },
  {
    ref: 'FORM_INSURANCE',
    text: 'Seguro de Salud',
  },
  {
    ref: 'FORM_PRIV_INSURANCE',
    text: 'Privado',
  },
  {
    ref: 'FORM_OTHER',
    text: 'Otro',
  },
  {
    ref: 'FORM_WHICH',
    text: '¿Cuál?',
  },
  {
    ref: 'FORM_OPTION',
    text: 'Opción',
  },
  {
    ref: 'FORM_ANY',
    text: 'Cualquier',
  },
  {
    ref: 'FORM_MORNING',
    text: 'Mañana',
  },
  {
    ref: 'FORM_AFTERNOON',
    text: 'Tarde',
  },
  {
    ref: 'FORM_COMMENTS',
    text: 'Comentarios',
  },
  {
    ref: 'FORM_READAGREE',
    text: 'He leído y estoy de acuerdo con la',
  },
  {
    ref: 'FORM_PRIV_POLICY',
    text: 'política de privacidad',
  },
  {
    ref: 'FORM_PRIV_POLICY_ERROR',
    text: 'Debe estar de acuerdo con los términos y condiciones',
  },
  {
    ref: 'FORM_CANCEL',
    text: 'Cancelar',
  },
  {
    ref: 'FORM_SUBMIT',
    text: 'Enviar',
  },
]

const enLang = [
  {
    ref: 'REQ_SENT_TITLE',
    text: 'Request Sent',
  },
  {
    ref: 'REQ_SENT_BODY',
    text: 'Thank you for submitting your appointment request. You will be contacted shortly to confirm the appointment.',
  },
  {
    ref: 'REQ_ERROR_ALERT_BODY',
    text: 'Error retrieving appointment data. Please refresh the page and try again',
  },
  {
    ref: 'REQ_SUC_ALERT_BODY',
    text: 'Request sent successfully',
  },
  {
    ref: 'GEN_ERROR_TITLE',
    text: 'Error',
  },
  {
    ref: 'GEN_ERROR_TRYAGAIN',
    text: 'Try Again',
  },
  {
    ref: 'FORM_FIX_FIELDS',
    text: 'Please correct the fields with invalid data',
  },
  {
    ref: 'FORM_TITLE',
    text: 'Appointment Request',
  },
  {
    ref: 'FORM_SUBTITLE',
    text: 'Enter the following details.',
  },
  {
    ref: 'FORM_FNAME',
    text: 'First Name',
  },
  {
    ref: 'FORM_VALUE_NOT_VALID',
    text: 'Invalid Value',
  },
  {
    ref: 'FORM_LNAME',
    text: 'Last Name',
  },
  {
    ref: 'FORM_NIF',
    text: 'NIF',
  },
  {
    ref: 'FORM_ID_DOC',
    text: 'Identification Document',
  },
  {
    ref: 'FORM_NO_NIF',
    text: 'I do not have a NIF',
  },
  {
    ref: 'FORM_HAVE_NIF',
    text: 'I have a NIF',
  },
  {
    ref: 'FORM_EMAIL',
    text: 'Email',
  },
  {
    ref: 'FORM_MOB_PHONE',
    text: 'Mobile Phone',
  },
  {
    ref: 'FORM_BAD_MOB_PHONE',
    text: 'Invalid Mobile Phone Number',
  },
  {
    ref: 'FORM_SERV_TYPE',
    text: 'Type',
  },
  {
    ref: 'FORM_PICK',
    text: 'Choose',
  },
  {
    ref: 'FORM_BAD_PICK',
    text: 'Invalid Choice',
  },
  {
    ref: 'FORM_SERV_SPECIALTY',
    text: 'Specialty',
  },
  {
    ref: 'FORM_SERV_PROVIDER',
    text: 'Specialist',
  },
  {
    ref: 'FORM_ALL',
    text: 'All',
  },
  {
    ref: 'FORM_INSURANCE',
    text: 'Health Insurance',
  },
  {
    ref: 'FORM_PRIV_INSURANCE',
    text: 'Private',
  },
  {
    ref: 'FORM_OTHER',
    text: 'Other',
  },
  {
    ref: 'FORM_WHICH',
    text: 'Which?',
  },
  {
    ref: 'FORM_OPTION',
    text: 'Option',
  },
  {
    ref: 'FORM_ANY',
    text: 'Any',
  },
  {
    ref: 'FORM_MORNING',
    text: 'Morning',
  },
  {
    ref: 'FORM_AFTERNOON',
    text: 'Afternoon',
  },
  {
    ref: 'FORM_COMMENTS',
    text: 'Comments',
  },
  {
    ref: 'FORM_READAGREE',
    text: 'I have read and agree with the',
  },
  {
    ref: 'FORM_PRIV_POLICY',
    text: 'privacy policy',
  },
  {
    ref: 'FORM_PRIV_POLICY_ERROR',
    text: 'You must agree to the terms and conditions',
  },
  {
    ref: 'FORM_CANCEL',
    text: 'Cancel',
  },
  {
    ref: 'FORM_SUBMIT',
    text: 'Submit',
  },
]

const frLang = [
  {
    ref: 'REQ_SENT_TITLE',
    text: 'Commande Envoyée',
  },
  {
    ref: 'REQ_SENT_BODY',
    text: 'Merci pour votre demande de rendez-vous. Vous serez contacté bientôt pour confirmer le rendez-vous.',
  },
  {
    ref: 'REQ_ERROR_ALERT_BODY',
    text: 'Erreur lors de la récupération des données de consultation. Veuillez rafraîchir la page et réessayer',
  },
  {
    ref: 'REQ_SUC_ALERT_BODY',
    text: 'Demande envoyée avec succès',
  },
  {
    ref: 'GEN_ERROR_TITLE',
    text: 'Erreur',
  },
  {
    ref: 'GEN_ERROR_TRYAGAIN',
    text: 'Réessayer',
  },
  {
    ref: 'FORM_FIX_FIELDS',
    text: 'Veuillez corriger les champs avec des données invalides',
  },
  {
    ref: 'FORM_TITLE',
    text: 'Demande de Rendez-vous',
  },
  {
    ref: 'FORM_SUBTITLE',
    text: 'Entrez les détails suivants.',
  },
  {
    ref: 'FORM_FNAME',
    text: 'Prénom',
  },
  {
    ref: 'FORM_VALUE_NOT_VALID',
    text: 'Valeur invalide',
  },
  {
    ref: 'FORM_LNAME',
    text: 'Nom de Famille',
  },
  {
    ref: 'FORM_NIF',
    text: 'NIF',
  },
  {
    ref: 'FORM_ID_DOC',
    text: 'Document d’Identification',
  },
  {
    ref: 'FORM_NO_NIF',
    text: 'Je n’ai pas de NIF',
  },
  {
    ref: 'FORM_HAVE_NIF',
    text: 'J’ai un NIF',
  },
  {
    ref: 'FORM_EMAIL',
    text: 'E-mail',
  },
  {
    ref: 'FORM_MOB_PHONE',
    text: 'Téléphone Mobile',
  },
  {
    ref: 'FORM_BAD_MOB_PHONE',
    text: 'Numéro de téléphone mobile invalide',
  },
  {
    ref: 'FORM_SERV_TYPE',
    text: 'Type',
  },
  {
    ref: 'FORM_PICK',
    text: 'Choisir',
  },
  {
    ref: 'FORM_BAD_PICK',
    text: 'Choix invalide',
  },
  {
    ref: 'FORM_SERV_SPECIALTY',
    text: 'Spécialité',
  },
  {
    ref: 'FORM_SERV_PROVIDER',
    text: 'Spécialiste',
  },
  {
    ref: 'FORM_ALL',
    text: 'Tous',
  },
  {
    ref: 'FORM_INSURANCE',
    text: 'Assurance Santé',
  },
  {
    ref: 'FORM_PRIV_INSURANCE',
    text: 'Privé',
  },
  {
    ref: 'FORM_OTHER',
    text: 'Autre',
  },
  {
    ref: 'FORM_WHICH',
    text: 'Lequel ?',
  },
  {
    ref: 'FORM_OPTION',
    text: 'Option',
  },
  {
    ref: 'FORM_ANY',
    text: 'N’importe quel',
  },
  {
    ref: 'FORM_MORNING',
    text: 'Matin',
  },
  {
    ref: 'FORM_AFTERNOON',
    text: 'Après-midi',
  },
  {
    ref: 'FORM_COMMENTS',
    text: 'Commentaires',
  },
  {
    ref: 'FORM_READAGREE',
    text: 'J’ai lu et j’accepte la',
  },
  {
    ref: 'FORM_PRIV_POLICY',
    text: 'politique de confidentialité',
  },
  {
    ref: 'FORM_PRIV_POLICY_ERROR',
    text: 'Vous devez accepter les termes et conditions',
  },
  {
    ref: 'FORM_CANCEL',
    text: 'Annuler',
  },
  {
    ref: 'FORM_SUBMIT',
    text: 'Soumettre',
  },
]

const deLang = [
  {
    ref: 'REQ_SENT_TITLE',
    text: 'Bestellung Gesendet',
  },
  {
    ref: 'REQ_SENT_BODY',
    text: 'Danke für Ihre Terminanfrage. Sie werden bald kontaktiert, um den Termin zu bestätigen.',
  },
  {
    ref: 'REQ_ERROR_ALERT_BODY',
    text: 'Fehler beim Abrufen der Beratungsdaten. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut',
  },
  {
    ref: 'REQ_SUC_ALERT_BODY',
    text: 'Anfrage erfolgreich gesendet',
  },
  {
    ref: 'GEN_ERROR_TITLE',
    text: 'Fehler',
  },
  {
    ref: 'GEN_ERROR_TRYAGAIN',
    text: 'Erneut versuchen',
  },
  {
    ref: 'FORM_FIX_FIELDS',
    text: 'Bitte korrigieren Sie die Felder mit ungültigen Daten',
  },
  {
    ref: 'FORM_TITLE',
    text: 'Terminanfrage',
  },
  {
    ref: 'FORM_SUBTITLE',
    text: 'Geben Sie die folgenden Details ein.',
  },
  {
    ref: 'FORM_FNAME',
    text: 'Vorname',
  },
  {
    ref: 'FORM_VALUE_NOT_VALID',
    text: 'Ungültiger Wert',
  },
  {
    ref: 'FORM_LNAME',
    text: 'Nachname',
  },
  {
    ref: 'FORM_NIF',
    text: 'NIF',
  },
  {
    ref: 'FORM_ID_DOC',
    text: 'Ausweisdokument',
  },
  {
    ref: 'FORM_NO_NIF',
    text: 'Ich habe kein NIF',
  },
  {
    ref: 'FORM_HAVE_NIF',
    text: 'Ich habe ein NIF',
  },
  {
    ref: 'FORM_EMAIL',
    text: 'E-Mail',
  },
  {
    ref: 'FORM_MOB_PHONE',
    text: 'Handynummer',
  },
  {
    ref: 'FORM_BAD_MOB_PHONE',
    text: 'Ungültige Handynummer',
  },
  {
    ref: 'FORM_SERV_TYPE',
    text: 'Typ',
  },
  {
    ref: 'FORM_PICK',
    text: 'Wählen',
  },
  {
    ref: 'FORM_BAD_PICK',
    text: 'Ungültige Wahl',
  },
  {
    ref: 'FORM_SERV_SPECIALTY',
    text: 'Fachgebiet',
  },
  {
    ref: 'FORM_SERV_PROVIDER',
    text: 'Spezialist',
  },
  {
    ref: 'FORM_ALL',
    text: 'Alle',
  },
  {
    ref: 'FORM_INSURANCE',
    text: 'Krankenversicherung',
  },
  {
    ref: 'FORM_PRIV_INSURANCE',
    text: 'Privat',
  },
  {
    ref: 'FORM_OTHER',
    text: 'Andere',
  },
  {
    ref: 'FORM_WHICH',
    text: 'Welche?',
  },
  {
    ref: 'FORM_OPTION',
    text: 'Option',
  },
  {
    ref: 'FORM_ANY',
    text: 'Beliebig',
  },
  {
    ref: 'FORM_MORNING',
    text: 'Morgen',
  },
  {
    ref: 'FORM_AFTERNOON',
    text: 'Nachmittag',
  },
  {
    ref: 'FORM_COMMENTS',
    text: 'Kommentare',
  },
  {
    ref: 'FORM_READAGREE',
    text: 'Ich habe gelesen und stimme zu',
  },
  {
    ref: 'FORM_PRIV_POLICY',
    text: 'Datenschutzbestimmungen',
  },
  {
    ref: 'FORM_PRIV_POLICY_ERROR',
    text: 'Sie müssen den Allgemeinen Geschäftsbedingungen zustimmen',
  },
  {
    ref: 'FORM_CANCEL',
    text: 'Abbrechen',
  },
  {
    ref: 'FORM_SUBMIT',
    text: 'Absenden',
  },
]



const flagPT = () => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="502 261 56 40"
      >
        <rect x="502" y="261" fill="#FF0000" width="56" height="40" />
        <rect x="502" y="261" fill="#39953E" width="21" height="40" />
        <path
          fill="#FFD200"
          d="M523,290c-5,0-9-4-9-9s4-9,9-9c5,0,9,4,9,9S528,290,523,290z M523,275c-3.3,0-6,2.7-6,6s2.7,6,6,6
		s6-2.7,6-6S526.3,275,523,275z"
        />
      </svg>
    </>
  )
}
const flagUK = () => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="232 191 56 40"
      >
        <rect x="232" y="191" fill="#001468" width="56" height="40" />
        <path
          fill="#FFFFFF"
          d="M288,231h-6l-16-13v13h-12v-13l-16,13h-6v-4l12-10h-12v-12h12l-12-10v-4h6l16,13v-13h12v13l16-13h6v4
  l-12,10h12v12h-12l12,10V231z"
        />
        <path
          fill="#FF0000"
          d="M285,231l-17-14h5l15,13v1H285z M264,231h-8v-16h-24v-8h24v-16h8v16h24v8h-24V231z M268,205l17-14h3v1
  l-15,13H268z M232,192v-1h3l17,14h-5L232,192z M252,217l-17,14h-3v-1l15-13H252z"
        />
      </svg></>)
}
const flagES = () => {
  return (
    <><svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="592 401 56 40"
    >
      <rect x="592" y="401" fill="#FFD200" width="56" height="40" />
      <rect x="592" y="431" fill="#FF0000" width="56" height="10" />
      <rect x="592" y="401" fill="#FF0000" width="56" height="10" />
      <path
        fill="#FF9C00"
        d="M607.4,429c-3.2,0-6.4-0.9-6.4-0.9v-8.5c0-3.6,2.9-6.6,6.5-6.6s6.5,3,6.5,6.6v8.5
C614,428.1,610.7,429,607.4,429z"
      />
    </svg></>)
}
const flagDE = () => {
  return (
    <>      <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="502 401 56 40"
    >
      <rect x="502" y="401" fill="#FF0000" width="56" height="40" />
      <rect x="502" y="428" fill="#FFD200" width="56" height="13" />
      <rect x="502" y="401" fill="#3E3E3E" width="56" height="13" />
    </svg></>)
}
const flagFR = () => {
  return (
    <>      <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="412 401 56 40"
    >
      <rect x="412" y="401" fill="#FFFFFF" width="56" height="40" />
      <rect x="412" y="401" fill="#3B6AC3" width="18" height="40" />
      <rect x="450" y="401" fill="#FF0000" width="18" height="40" />
    </svg></>)
}


export function localFlag(lang) {
  if (lang == 'PT') {
    return flagPT()
  }
  else {
    if (lang == 'EN') {
      return flagUK()
    }
    else {
      if (lang == 'ES') {
        return flagES()
      }
      else {
        if (lang == 'FR') {
          return flagFR()
        }
        else {
          if (lang == 'DE') {
            return flagDE()
          }
          else {
            return <></>
          }
        }
      }
    }
  }
}

export function localText(lang, ref) {


  if (lang == 'PT') {
    return ptLang.filter(r => r.ref == ref)[0].text
  }
  else {
    if (lang == 'EN') {
      return enLang.filter(r => r.ref == ref)[0].text
    }
    else {
      if (lang == 'ES') {
        return esLang.filter(r => r.ref == ref)[0].text
      }
      else {
        if (lang == 'FR') {
          return frLang.filter(r => r.ref == ref)[0].text
        }
        else {
          if (lang == 'DE') {
            return deLang.filter(r => r.ref == ref)[0].text
          }
          else {
            return ''
          }
        }
      }
    }
  }
}