import React, { useEffect, useState, useRef } from 'react';

import { useErrorBoundary } from "react-error-boundary";
import { ErrorBoundary } from "react-error-boundary";
import {  localText } from './bookRequestLocaliz'
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import {
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import moment from 'moment-timezone'
import { tzbe, rgxtz } from '../state/globalui/timezoneConfig'
import 'moment/dist/locale/pt';
moment.locale('pt');

import GuestBookRequestForm from './GuestBookRequestForm';

function AlertError({ message, closeAlert }) {
  return (
    <div className="w-full mb-6">
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{message}</h3>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                onClick={closeAlert}
                type="button"
                className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-green-50"
              >
                <span className="sr-only">Fechar</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function AlertSuccess({ message, closeAlert }) {
  return (
    <div className="w-full mb-6">
      <div className="rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-green-800">{message}</p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                onClick={closeAlert}
                type="button"
                className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
              >
                <span className="sr-only">Fechar</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const logError = (error, info) => {

  console.error(info)
  console.error(error)
};

function Loading() {

  return (
    <>
      <div style={{ zIndex: 100 }} className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center w-screen h-screen">
      </div>
      <div style={{ zIndex: 110 }} className="fixed top-0 left-0 h-1 w-full indeterminate-progress-bar">
        <div className="indeterminate-progress-bar__progress bg-slate-700" />
      </div></>
  )
}

function SubmitSuccess() {
  return (
    <div className="text-center pt-12">
      <CheckCircleIcon className="mx-auto h-12 w-12 text-emerald-400"/>
      <h3 className="mt-2 text-sm font-semibold text-emerald-900">{localText(lang, 'REQ_SENT_TITLE')}</h3>
      <p className="mt-1 text-sm text-gray-500">{localText(lang, 'REQ_SENT_BODY')}</p>

    </div>
  )
}


export default function GuestBookRequest() {

  const recaptchaRef = useRef();

  const emptyState = {
    firstName: '',
    lastName: '',
    email: '',
    taxId: '',
    hasNif: true,
    notes: '',
    servId: 0,
    specId: 0,
    provId: 0,
    insTypeId: 0,
    insTypeOther: '',
    date_1: {
      startDate: null,
      endDate: null
    },
    date_2: {
      startDate: null,
      endDate: null
    },
    date_3: {
      startDate: null,
      endDate: null
    },
    period_1: 'morning',
    period_2: 'morning',
    period_3: 'morning',
    mobile_phone: {
      cc:
      {
        desc: "Portugal",
        value: "PRT",
        dial_code: "+351"
      },
      loc: ""
    },
    termAndConditions: false,
    isValid: false,
    errorFields: []
  }
  const [formInfo, setFormInfo] = useState({});
  const [formData, setFormData] = useState(emptyState);
  const [formAttr, setFormAttr] = useState({
    loading: false,
    disabled: false,
    submitSuccess: false
  });

  const [alert, setAlert] = useState({ type: null, message: null });
  const [lang, setLang] = useState('PT');

  function closeAlert() {
    setAlert({ type: null, message: null });
  }

  function throwFetchError() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    setAlert({ type: 'error', message: localText(lang, 'REQ_ERROR_ALERT_BODY') })
    setFormAttr({ ...formAttr, disabled: true, loading: false })
  }

  async function getServInfo() {
    try {
      setFormAttr((f) => ({ ...f, loading: true }))
      const req = await axios.get('../api/public/bookrequest/list');
      setFormAttr((f) => ({ ...f, loading: false }))
      if (req.status != 200)
        throwFetchError();
      else
        setFormInfo(req.data);
    } catch (error) {
      throwFetchError()
    }

  }

  async function getProvider(specId) {
    try {
      setFormAttr((f) => ({ ...f, loading: true }))
      const req = await axios.get('../api/public/bookrequest/provider', { params: { specId: specId } });
      setFormAttr((f) => ({ ...f, loading: false }))
      if (req.status != 200)
        throwFetchError();
      else
        return req.data.provider;
    } catch (error) {
      throwFetchError()
    }

  }

  async function handleSubmit(payload) {

    closeAlert()

    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ ...payload, token: token }),
      credentials: "omit",
      redirect: 'follow'
    };

    try {
      setFormAttr((f) => ({ ...f, loading: true }))
      fetch("../api/public/bookrequest", requestOptions)
        .then(response => {
          if (response.status == 200) {
            setAlert({ type: 'success', message: localText(lang, 'REQ_SUC_ALERT_BODY') })
            setFormAttr((f) => ({ ...f, loading: false, submitSuccess: true }))
          }
          else {
            throwFetchError()
          }
          setFormData(emptyState)
        })
        .then(result => console.log(result))
        .catch(error => throwFetchError());

    } catch (error) {
      throwFetchError()
    }
  }

  useEffect(() => {
    getServInfo();
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
      {formAttr.loading && <Loading />}
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
      />

      <div className="bg-white min-h-screen">
        {alert.type == 'error' && (
          <AlertError message={alert.message} closeAlert={closeAlert} />
        )}
        {alert.type == 'success' && (
          <AlertSuccess
            message={alert.message}
            closeAlert={closeAlert}
          />
        )}

        <main className="isolate">

          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl ">

              {formAttr.submitSuccess ?
                <SubmitSuccess /> :

                <GuestBookRequestForm
                  formData={formData}
                  setFormData={setFormData}
                  setAlert={setAlert}
                  handleSubmit={handleSubmit}
                  formInfo={formInfo}
                  getProvider={getProvider}
                  formAttr={formAttr}
                  lang={lang}
                  setLang={setLang}
                />}

            </div>
          </div>
        </main>
      </div>

    </ErrorBoundary>
  );
}



function ErrorFallback({ error }) {
  const { resetBoundary } = useErrorBoundary();

  return (
    <div role="alert">
      <p>{ localText(lang, 'GEN_ERROR_TITLE') }</p>
    {/*   <pre style={{ color: "red" }}>{error.message}</pre> */}
      <button onClick={resetBoundary}>{ localText(lang, 'GEN_ERROR_TRYAGAIN') }</button>
     </div>
  );
}
