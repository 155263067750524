import React from 'react';
import { useEffect, useState } from 'react';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline';
import { conditionallyRender, showConditionally } from './conditionallyRender';

export default function StackEditModule({
  field,
  formData,
  setFormData,
  formVal,
  setFormVal,
  context,
}) {
  const [propId, setPropId] = useState({});
  const [renderKey, setRenderKey] = useState(0);
  const [stackformVal, setStackFormVal] = React.useState({});



  // initialize
  useEffect(() => {
    setFormVal((f) => ({
      ...f, [field.name]: {
        ...formVal[field.name],
        isTouched: false,
        isValid: isValid()
      }
    }))
  }, [])


  // on update
  useEffect(() => {
    if (formData[field.name] !== '')
      setFormVal((f) => ({
        ...f, [field.name]: {
          ...formVal[field.name],
          isTouched: true,
          isValid: isValid()
        }
      }))
  }, [formData[field.name]])

  function isValid() {
    if (!field.required) return true
    else return !(formData[field.name].length == 0)
  }

  useEffect(() => {
    resetStackItem();
  }, []);

  const resetStackItem = () => {
    setRenderKey((e) => e + 1);
    let _stackObj = {};
    let fDataValidation = {};
    field.stack.fields.map(
      (rs) => (_stackObj = { ..._stackObj, [rs.name]: '' })
    );
    setPropId(_stackObj);
    setStackFormVal(fDataValidation)
  };

  const handleAddToStack = (_r) => {
    if (canSubmit()) {
      let forminput = {};
      field.stack.fields.map(
        (_f) => (forminput = { ...forminput, [_f.name]: propId[_f.name] })
      );

      let newobj = propId
      //if (
      //  typeof yourVariable === 'object' &&
      //  !Array.isArray(yourVariable) &&
      //  yourVariable !== null
      //) {
      //  newobj = {...propId}
      //}
      setFormData({ ...formData, [_r.name]: [...formData[_r.name], newobj] });
      resetStackItem();
    }
  };

  const handleRemoveItem = (e) => {
    setFormData({
      ...formData,
      [field.name]: formData[field.name].filter(
        (i) => JSON.stringify(i) != JSON.stringify(e)
      ),
    });
  };

  function canSubmit() {
    let _fields = []
    let _newFormVal = {}
    Object.keys(stackformVal).forEach(function (key, index) {
      _fields = [..._fields, stackformVal[key].isValid]
      _newFormVal = { ..._newFormVal, [key]: { ...stackformVal[key], isTouched: true } }
    });
    setStackFormVal(_newFormVal)
    const uniqueVal = [...new Set(_fields.map(item => item))]

    if (uniqueVal.length > 1) return false
    else {
      if (uniqueVal[0]) return true
      else return false
    }
  }

  return (
    <>


      <ul
        role="list"
        className={`divide-y rounded-md border border-dashed  my-3 ${(formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) ? 'border-red-500 divide-red-300' : 'border-gray-200 divide-gray-100'} `}
      >
        <li className="flex items-center justify-between py-3 pl-3 pr-5 text-sm leading-6">
          <div className="isolate -space-y-px rounded-md shadow-sm w-full">
            {field.stack.fields.map((f, k) => {
              if (showConditionally(propId, f)) {
                return (
                  <div
                    key={k}
                    className={`relative rounded-md px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600 ${k == 0 && field.stack.fields.length > 1
                      ? 'rounded-b-none'
                      : ''
                      } ${k == field.stack.fields.length - 1 &&
                        field.stack.fields.length > 1
                        ? 'rounded-t-none'
                        : ''
                      }
                ${k > 0 && k < field.stack.fields.length - 1
                        ? 'rounded-t-none rounded-b-none'
                        : ''
                      }`}
                  >
                    <label
                      htmlFor={f.id}
                      className="block text-xs font-medium text-gray-900"
                    >
                      {f.title}
                    </label>
                    <div className="pt-2">
                      {conditionallyRender(
                        f,
                        propId,
                        setPropId,
                        context,
                        setStackFormVal,
                        stackformVal,
                        formData[field.name]
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </div>

          <div className="ml-4 flex-shrink-0">
            <div
              className="cursor-pointer"
              onClick={() => handleAddToStack(field)}
            >
              <PlusIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400 hover:text-red-500"
                aria-hidden="true"
              />
            </div>
          </div>
        </li>
      </ul>
      {
        (formVal[field.name] && (!formVal[field.name].isValid && formVal[field.name].isTouched)) &&
        <p className="mt-2 text-sm text-red-600" id="email-error">
          Adicione pelo menos uma entrada
        </p>

      }
      <ul
        role="list"
        className="divide-y divide-gray-100 rounded-md border border-gray-200 mt-3"
      >
        {formData[field.name].length > 0 &&
          formData[field.name].map((l, k) => (
            <li
              key={k}
              className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
            >
              <div className="flex w-0 flex-1 items-center">
                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                  {field.stack.listItemView(l)}
                </div>
              </div>
              <div className="ml-4 flex-shrink-0">
                <div
                  className="cursor-pointer"
                  onClick={() => handleRemoveItem(l)}
                >
                  <TrashIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400 hover:text-red-500"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
}
