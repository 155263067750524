import React, { useEffect, useState } from "react";
import { Fragment } from 'react'
import { Dialog, Disclosure, Menu, Popover, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import AnalyticsDateRangeFilter from "./AnalyticsDateRangeFilter";
import moment from 'moment-timezone'
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import 'moment/dist/locale/pt';
moment.locale('pt');

const kpiOptions = [
    { name: 'Recebimentos', key: 'payments' },
    { name: 'Consultas', key: 'call_requests' },
]

const breakdownOptions = [
    { name: 'Total', key: 'total' },
    { name: 'Subsistema', key: 'insurance' },
    { name: 'Médico', key: 'provider' },
    { name: 'Especialidade', key: 'servtype' },
]

const granularityOptions = [
    { name: 'Anual', key: 'year' },
    { name: 'Trimestre', key: 'quarter' },
    { name: 'Mensal', key: 'month' },
    { name: 'Diária', key: 'day' },
]

const seriesOptions = [
    { name: 'Contínuo', key: 'standart' },
    { name: 'Comparativo', key: 'comparative' }
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function AnalyticsFilter({ filters, setFilters }) {

    const [dateRng, setDateRng] = useState({ startDate: null, endDate: null })

    useEffect(() => {
        setFilters({
            granularity: granularityOptions[2].key,
            metric: kpiOptions[0].key,
            breakdown: breakdownOptions[0].key,
            series: seriesOptions[0].key,
            rangeStart: moment().subtract(360, "days").format('YYYY-MM-DD'),
            rangeEnd: moment().format('YYYY-MM-DD')
        })

    }, [])

    function filterReady() {
        return filters && Object.keys(filters).length > 0;
    }

    return (
        <div className="bg-gray-50/50">

            <div className="mx-auto w-full px-4 text-center sm:px-6 lg:max-w-7xl lg:px-8">


                <section aria-labelledby="filter-heading" className="pb-3">
                    <h2 id="filter-heading" className="sr-only">
                        Filtros
                    </h2>

                    {filterReady() && <div className="grid grid-cols-1 sm:flex items-center justify-between">

                        <Popover.Group className="grid grid-cols-1 sm:flex sm:items-baseline sm:space-x-8">

                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">

                                        {kpiOptions.filter(k => k.key == filters.metric)[0].name}
                                        <ChevronDownIcon
                                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                        />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {kpiOptions.map((option) => (
                                                <Menu.Item key={option.key}>
                                                    {({ active }) => (
                                                        <a
                                                            href="#"
                                                            className={classNames(
                                                                active ? 'bg-gray-100' : '',
                                                                'block px-4 py-2 text-sm font-medium text-gray-900'
                                                            )}
                                                            onClick={() => setFilters({ ...filters, metric: option.key })}
                                                        >
                                                            {option.name}
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>

                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">

                                        {breakdownOptions.filter(k => k.key == filters.breakdown)[0].name}
                                        <ChevronDownIcon
                                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                        />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {breakdownOptions.map((option) => (
                                                <Menu.Item key={option.key}>
                                                    {({ active }) => (
                                                        <a
                                                            href="#"
                                                            className={classNames(
                                                                active ? 'bg-gray-100' : '',
                                                                'block px-4 py-2 text-sm font-medium text-gray-900'
                                                            )}
                                                            onClick={() => setFilters({ ...filters, breakdown: option.key })}
                                                        >
                                                            {option.name}
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>

                        </Popover.Group>
                        <Popover.Group className="grid grid-cols-1 sm:flex sm:items-baseline sm:space-x-8">

                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                        {granularityOptions.filter(k => k.key == filters.granularity)[0].name}
                                        <ChevronDownIcon
                                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                        />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {granularityOptions.map((option) => (
                                                <Menu.Item key={option.key}>
                                                    {({ active }) => (
                                                        <a
                                                            href="#"
                                                            className={classNames(
                                                                active ? 'bg-gray-100' : '',
                                                                'block px-4 py-2 text-sm font-medium text-gray-900'
                                                            )}
                                                            onClick={() => setFilters({ ...filters, granularity: option.key })}
                                                        >
                                                            {option.name}
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>


                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                        {seriesOptions.filter(k => k.key == filters.series)[0].name}
                                        <ChevronDownIcon
                                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                        />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {seriesOptions.map((option) => (
                                                <Menu.Item key={option.key}>
                                                    {({ active }) => (
                                                        <a
                                                            href="#"
                                                            className={classNames(
                                                                active ? 'bg-gray-100' : '',
                                                                'block px-4 py-2 text-sm font-medium text-gray-900'
                                                            )}
                                                            onClick={() => setFilters({ ...filters, series: option.key })}
                                                        >
                                                            {option.name}
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>

                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <div className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                        <AnalyticsDateRangeFilter filters={filters} setFilters={setFilters} />
                                    </div>
                                </div>
                            </Menu>

                        </Popover.Group>
                    </div>}
                </section>
            </div>
        </div>
    )
}
