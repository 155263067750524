import React from 'react';
import TabNavigation from '../nav/tabs/TabNavigation';
import { GlobalStateContext } from '../state/globalState';

import useToken from '../hooks/useToken';



const TabContent_Home = () => {
  return (
    <p className="text-sm text-gray-500 ">
      This is some placeholder content the{' '}
      <strong className="font-medium text-gray-800">
        Profile tab's associated content
      </strong>
      . Clicking another tab will toggle the visibility of this one for the
      next. The tab JavaScript swaps classes to control the content visibility
      and styling.
    </p>
  );
};

const TabContent_Dashboard = () => {
  return (
    <p className="text-sm text-gray-500 ">
      This is some TabContent_Dashboard content the{' '}
      <strong className="font-medium text-gray-800">
        Profile tab's associated content
      </strong>
      . Clicking another tab will toggle the visibility of this one for the
      next. The tab JavaScript swaps classes to control the content visibility
      and styling.
    </p>
  );
};

const TabContent_Settings = () => {
  return (
    <p className="text-sm text-gray-500 ">
      This is some placeholder content the{' '}
      <strong className="font-medium text-gray-800">
        Profile tab's associated content
      </strong>
      . Clicking another tab will toggle the visibility of this one for the
      next. The tab JavaScript swaps classes to control the content visibility
      and styling.
    </p>
  );
};

const tabs = [
  {
    title: 'Home',
    key: 'home',
    content: <TabContent_Home />,
    disabled: false,
  },
  {
    title: 'Dashboard',
    key: 'dashboard',
    content: <TabContent_Dashboard />,
    disabled: false,
  },
  {
    title: 'Help',
    key: 'help',
    content: <TabContent_Home />,
    disabled: false,
  },
  {
    title: 'Settings',
    key: 'settings',
    content: <TabContent_Settings />,
    disabled: false,
  },
];

const SupportHome = () => {
  const globalcontext = React.useContext(GlobalStateContext);
  const { token } = useToken();

  return (

        <div className="flex flex-col space-y-3">
    

          <div>
            <TabNavigation tabs={tabs} />
          </div>
        </div>
   
  );
};

export default SupportHome;
