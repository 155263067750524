import React from 'react';
import PickCustomer from '../../client/PickCustomer';
import PickProvider from '../../provider/PickProvider';
import NewBookingCalendar from '../../calendar/NewBookingCalendar';
import NewCallOptions from './NewCallOptions';
import NewCallConfirmation from '../NewCallConfirmation';
import useToken from '../../hooks/useToken';
//import { getCurrentStep } from './booking';

const NewBookingBody = ({ selection, setSelection }) => {
  const { token } = useToken();

  const handleConfirmCustomer = (customer, insuranceId) => {
    let cSel = selection.steps.filter((s) => s.key == 'customer')[0];
    cSel.insuranceId = insuranceId
    cSel.label = customer.name;
    cSel.value = customer.id;
    cSel.completed = true;
    let steps = [...selection.steps.filter((s) => s.key != 'customer'), cSel];

    console.log(selection)
    //const _step = getCurrentStep(steps);
    setSelection({ ...selection, steps: steps });
  };

  return (
    <>
      {selection.steps.filter((s) => s.id == selection.step)[0].key ==
        'customer' && (
        <PickCustomer token={token} handleConfirm={handleConfirmCustomer} />
      )}
      {selection.steps.filter((s) => s.id == selection.step)[0].key ==
        'provider' && (
        <PickProvider
          token={token}
          selection={selection}
          setSelection={setSelection}
        />
      )}
      {selection.steps.filter((s) => s.id == selection.step)[0].key ==
        'slot' && (
        <NewBookingCalendar
          token={token}
          selection={selection}
          setSelection={setSelection}
        />
      )}
      {selection.steps.filter((s) => s.id == selection.step)[0].key ==
        'option' && (
        <NewCallOptions
          token={token}
          selection={selection}
          setSelection={setSelection}
        />
      )}
      {selection.steps.filter((s) => s.id == selection.step)[0].key ==
        'confirm' && (
        <NewCallConfirmation
          token={token}
          selection={selection}
          setSelection={setSelection}
        />
      )}
    </>
  );
};

export default NewBookingBody;
