import React from 'react';
import { propertyExists, intToEur, intToEurSign } from '../../../common/comjs'
import { tzbe, rgxtz } from '../../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');

export default function CallBillingPreview({
    billing,
    call
}) {


    return (

        <>
            <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">

                <h2 className="text-base font-semibold leading-6 text-gray-900">Prestação de Serviço</h2>
                <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                    <div className="sm:pr-4">
                        <dt className="inline text-gray-500">Data</dt>{' '}
                        <dd className="inline text-gray-700">
                            <time dateTime={moment(billing.datetm).format()}>{moment(billing.bill_item.datetm).format('D MMM  YYYY, HH:mm')}</time>
                        </dd>
                        <br />
                        <dd className="inline text-gray-700">
                            <dt className="inline text-gray-500">{`Prestações: `}</dt>
                            {`${billing.bill_item.tot_installment}`}
                        </dd>
                    </div>
                    <div className="mt-2 sm:mt-0 sm:pl-4">
                        <dt className="font-semibold text-gray-900">Subsistema</dt>
                        <dd className="inline text-gray-700">
                            <time dateTime="2023-31-01">{`${billing.bill_item.insurances.name}`}</time>
                        </dd>
                    </div>
                    <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                        <dt className="font-semibold text-gray-900">Prestador</dt>
                        <dd className="mt-2 text-gray-500">
                            <span className="font-medium text-gray-900">{call.users.fullname}</span>
                            <br />

                        </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                        <dt className="font-semibold text-gray-900">Cliente</dt>
                        <dd className="mt-2 text-gray-500">
                            <span className="font-medium text-gray-900">{call.customers.fullname}</span>
                            <br />
                            {call.customers.address_contact != null &&
                                <span>
                                    {call.customers.address_contact.as_three_line[0]}
                                    <br />
                                    {call.customers.address_contact.as_three_line[1]}
                                    <br />
                                    {call.customers.address_contact.as_three_line[2]}
                                </span>}
                        </dd>
                    </div>
                </dl>
                <table className="mt-16 w-full whitespace-nowrap text-left text-sm leading-6">
                    <colgroup>
                        <col className="w-full" />
                        <col />
                        <col />
                        <col />
                    </colgroup>
                    <thead className="border-b border-gray-200 text-gray-900 text-xs">
                        <tr>
                            <th scope="col" className="px-0 py-3 font-semibold">
                                Projects
                            </th>
                            <th scope="col" className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                                Comparticipado
                            </th>
                            <th scope="col" className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                                Balcão
                            </th>
                            <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
                                Desconto
                            </th>
                            <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
                                IVA (%)
                            </th>
                            <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
                                IVA
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {billing.bill_items.map((item) => (
                            <tr key={item.id} className="border-b border-gray-100">
                                <td className="max-w-0 px-0 py-5 align-top">
                                    <div className="truncate font-medium text-gray-900">{propertyExists(item.treatments.servtype.name) && item.treatments.servtype.name}</div>
                                    <div className="whitespace-normal truncate text-gray-500">{item.description}</div>
                                </td>
                                <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                                    {intToEurSign(item.value_benef)}
                                </td>
                                <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                                    {intToEurSign(item.value_counter)}
                                </td>
                                <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                                    {intToEurSign(item.value_discount)}
                                </td>
                                <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                                    {`${item.vat_perc} %`}
                                </td>
                                <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                                    {intToEurSign(item.value_vat)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th scope="row" className="px-0 pb-0 pt-6 font-normal text-gray-700 sm:hidden">
                                Subtotal
                            </th>
                            <th
                                scope="row"
                                colSpan={5}
                                className="hidden px-0 pb-0 pt-6 text-right font-normal text-gray-700 sm:table-cell"
                            >
                                Comparticipado
                            </th>
                            <td className="pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-900">{intToEurSign(billing.bill_item.value_benef)}</td>
                        </tr>
                        <tr>
                            <th scope="row" className="pt-4 font-normal text-gray-700 sm:hidden">
                                Desconto
                            </th>
                            <th
                                scope="row"
                                colSpan={5}
                                className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
                            >
                                Desconto
                            </th>
                            <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">{intToEurSign(billing.bill_item.value_discount)}</td>
                        </tr>
                        <tr>
                            <th scope="row" className="pt-4 font-semibold text-gray-900 sm:hidden">
                                Total
                            </th>
                            <th
                                scope="row"
                                colSpan={5}
                                className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
                            >
                                Total
                            </th>
                            <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                                {intToEurSign(billing.bill_item.value_total)}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="pt-4 font-normal text-gray-700 sm:hidden">
                                Pago
                            </th>
                            <th
                                scope="row"
                                colSpan={5}
                                className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
                            >
                                Pago
                            </th>
                            <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">{intToEurSign(billing.bill_item.value_paid)}</td>
                        </tr>
                        <tr>
                            <th scope="row" className="pt-4 font-normal text-gray-700 sm:hidden">
                                Em dívida
                            </th>
                            <th
                                scope="row"
                                colSpan={5}
                                className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
                            >
                                Em dívida
                            </th>
                            <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">{intToEurSign(billing.bill_item.value_due)}</td>
                        </tr>

                    </tfoot>
                </table>
            </div>
        </>
    )
}