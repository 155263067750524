import React from 'react';
import {
    BanknotesIcon
} from '@heroicons/react/24/solid'
import { tzbe, rgxtz } from '../../state/globalui/timezoneConfig'
import moment from 'moment-timezone';
import 'moment/dist/locale/pt';
moment.locale('pt');
import CallPaymentCard from './cards/CallPaymentCard';
import CallBillingPreview from './invoice/CallBillingPreview';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CallBillingPage({ call }) {

    return (
        <>
            <main>
                <div className="mx-auto max-w-7xl px-4  sm:px-6 lg:px-8">

                    {call.get_billing_info != null ? call.get_billing_info.map((b, bk) =>
                        <div key={bk}>
                            <div className="relative py-4">
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div className="w-full border-t border-gray-300" />
                                </div>
                                <div className="relative flex justify-center">
                                    <span className="bg-white px-2 text-sm text-gray-500">{`Conta ${b.bill_item.id}`}</span>
                                </div>
                            </div>

                            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">

                                <CallPaymentCard billing={b} />
                                <CallBillingPreview billing={b} call={call} />


                            </div>

                        </div>)
                        :

                        <div className="text-center pt-8">
                            <BanknotesIcon className='mx-auto h-12 w-12 text-gray-400' />

                            <h3 className="mt-2 text-sm font-semibold text-gray-900">Não disponível</h3>
                            <p className="mt-1 text-sm text-gray-500">Não existe informação de faturação a apresentar.</p>

                        </div>

                    }
                </div>
            </main>
        </>
    )
}
