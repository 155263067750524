import { Fragment, useState } from 'react'
import { Disclosure, Menu, Switch, Transition, Tab } from '@headlessui/react'


import ClipboardHeartIcon from '../../common/icon/ClipboardHeartIcon'
import ClipboardPlusIcon from '../../common/icon/ClipboardPlusIcon'
import FilesIcon from '../../common/icon/FilesIcon'
import FolderIcon from '../../common/icon/FolderIcon'
import ListTreeIcon from '../../common/icon/ListTreeIcon'
import PuzzleIcon from '../../common/icon/PuzzleIcon'
import VideoIcon from '../../common/icon/VideoIcon'
import AnalysisIcon from '../../common/icon/AnalysisIcon'

import CallFileManager from '../filemanager/CallFileManager'
import CallAddonList from '../addons/CallAddonList'
import ExamsAnalysisContent from '../examsanalysis/ExamsAnalysisContent'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ActiveCallDashboard({ call }) {

  const [selectedIndex, setSelectedIndex] = useState(0)

  const subNavigation = [
    {
      name: 'Resumo', href: '#', icon: ClipboardPlusIcon,
      content: <>1</>
    },
    {
      name: 'Dados', href: '#', icon: ClipboardHeartIcon,
      content: <>2</>
    },
    {
      name: 'Registo SOAP', href: '#', icon: FilesIcon,
      content: <>3</>
    },
    {
      name: 'Análises', href: '#', icon: AnalysisIcon,
      content: <ExamsAnalysisContent context={call} />
    },
    {
      name: 'Ficheiros', href: '#', icon: FolderIcon,
      content: <CallFileManager />
    },
    {
      name: 'Apps', href: '#', icon: PuzzleIcon,
      content: <CallAddonList />
    },
    {
      name: 'Videoconsulta', href: '#', icon: VideoIcon,
      content: <>7</>
    },
    {
      name: 'Todas as consultas', href: '#', icon: ListTreeIcon,
      content: <>8</>
    },
  ]

  return (
    <div>
      <Disclosure as="div" className="relative overflow-hidden bg-sky-700 pb-32">
        {({ open }) => (
          <>

            <div
              aria-hidden="true"
              className={classNames(
                open ? 'bottom-0' : 'inset-y-0',
                'absolute inset-x-0 left-1/2 w-full -translate-x-1/2 transform overflow-hidden lg:inset-y-0'
              )}
            >
              <div className="absolute inset-0 flex">
                <div className="h-full w-1/2" style={{ backgroundColor: '#0a527b' }} />
                <div className="h-full w-1/2" style={{ backgroundColor: '#065d8c' }} />
              </div>
              <div className="relative flex justify-center">
                <svg className="flex-shrink-0" width={1750} height={308} viewBox="0 0 1750 308">
                  <path d="M284.161 308H1465.84L875.001 182.413 284.161 308z" fill="#0369a1" />
                  <path d="M1465.84 308L16.816 0H1750v308h-284.16z" fill="#065d8c" />
                  <path d="M1733.19 0L284.161 308H0V0h1733.19z" fill="#0a527b" />
                  <path d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z" fill="#0a4f76" />
                </svg>
              </div>
            </div>
            <header className="relative py-6">
              <div className="">

                <div className="mx-auto max-w-7xl px-4 pt-4 sm:px-6 lg:px-8">
                  <div className="flex">
                    <div className="w-full"><h1 className="text-3xl font-bold tracking-tight text-white">Nova Consulta</h1></div>

                  </div>
                </div>
              </div>
            </header>
          </>
        )}
      </Disclosure>

      <main className="relative -mt-32">
        <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-x lg:divide-y-0">

              <Tab.Group
                vertical
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex}
              >

                <aside className="py-6 lg:col-span-3">
                  <nav className="space-y-1">
                    <Tab.List className="">

                      {subNavigation.map((item) => (
                        <Tab
                          key={item.name}
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? 'border-teal-500 bg-teal-50 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                                : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                              'w-full group flex items-center border-l-4 px-3 py-2 text-sm font-medium'
                            )}
                          aria-current={({ selected }) => selected ? 'page' : undefined}
                        >
                          <item.icon
                            className={
                              classNames(
                                'text-gray-400 group-hover:text-gray-500',
                                '-ml-1 mr-3 h-6 w-6 flex-shrink-0'
                              )}
                            aria-hidden="true"
                          />
                          <span className="truncate">{item.name}</span>
                        </Tab>
                      ))}

                    </Tab.List>
                  </nav>
                </aside>

                <div className="divide-y divide-gray-200 lg:col-span-9">
                  {/* Profile section */}
                  <div className="px-4">
                    

                    <div className="mt-6 flex flex-col lg:flex-row w-full">
                      <Tab.Panels className="mt-2 w-full" unmount={false}>
                        {subNavigation.map((option, index) => (
                          <Tab.Panel
                            key={index}
                            className={classNames(
                              'rounded-xl bg-white p-3',
                              'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                            )}
                          >
                            <Transition appear show={selectedIndex == index}
                              enter="transition-opacity duration-500"
                              enterFrom="opacity-0"
                              enterTo="opacity-100"
                              leave="transition-opacity duration-500"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0">

                              {option.content}
                            </Transition>
                          </Tab.Panel>
                        ))}
                      </Tab.Panels>
                    </div>
                  </div>
                </div>
              </Tab.Group>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
