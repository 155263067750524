import { 
  getRequests as get,
  putRequests as put,
  postRequests as post,
 } from '../common/apiEndpoints';

 import { countryCodes } from '../label/countrycodes';
 import {
  phoneDesc
} from '../label/phoneDesc';

 
export const formfield_org_name = {
  title: 'Editar Nome',
  description: 'Edite o nome que designa o centro médico',
  apiEndpoint: get.orgdetails,
  method: 'PUT',
  reqArg: { type: 'org_name' },
  preloadForm: true,
  name: '',
  id: '',
  submitLabel: 'Editar',
  rows: [
    {
      selector: 'input',
      required: true,
      type: 'text',
      name: 'name',
      title: 'Nome',
      placeholder: 'Nome...',
      id: 'org__name',
      validation: (f) => f.length > 0,
      validationerr: 'Nome Inválido.',
    },
  ],
};

export const formfield_org_comercialname = {
  title: 'Editar Nome',
  description: 'Edite o nome comercial do centro médico',
  apiEndpoint: get.orgdetails,
  method: 'PUT',
  reqArg: { type: 'org_comercialname' },
  preloadForm: true,
  name: '',
  id: '',
  submitLabel: 'Editar',
  rows: [
    {
      selector: 'input',
      required: true,
      type: 'text',
      name: 'comercial_name',
      title: 'Nome comercial',
      placeholder: 'Nome...',
      id: 'org__comercialname',
      validation: (f) => f.length > 0,
      validationerr: 'Nome Inválido.',
    },
  ],
};


export const formfield_org_taxidref = {
  title: 'Número Fiscal',
  description: 'Edite o NIF do centro médico',
  apiEndpoint: get.orgdetails,
  method: 'PUT',
  reqArg: { type: 'org_tax_id_ref' },
  preloadForm: true,
  name: '',
  id: '',
  submitLabel: 'Editar',
  rows: [
    {
      selector: 'input',
      required: true,
      type: 'text',
      name: 'tax_id_ref',
      title: 'Identificação Fiscal',
      placeholder: 'NIF...',
      id: 'org__tax_id_ref',
      validation: (f) => f != null && f.length == 9,
      validationerr: 'NIF Inválido.',
    },
  ],
};



export const formfield_org_email = {
  title: 'Email',
  description: 'Indique o email principal',
  apiEndpoint: get.orgdetails,
  method: 'PUT',
  reqArg: { type: 'org_email' },
  preloadForm: true,
  name: '',
  id: '',
  submitLabel: 'Editar',
  rows: [
    {
      selector: 'input',
      required: true,
      type: 'text',
      name: 'email',
      title: 'Email',
      placeholder: 'centro@medico.pt',
      id: 'org__email',
      validation: (f) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(f).toLowerCase()
      ),
    validationerr: 'Email inválido.',
    },
  ],
};


export const formfield_org_logo = {
  title: 'Logotipo',
  description: 'Altere o logotipo que aparecerá nos diferentes documentos emitidos',
  apiEndpoint: post.configmedia,
  method: 'POST',
  payloadType: 'formdata',
  reqArg: { type: 'org_logo' },
  preloadForm: false,
  name: '',
  id: '',
  submitLabel: 'Editar',
  rows: [
    {
      selector: 'avatar',
      required: true,
      name: 'logo',
      title: 'Logo',
      id: 'org__logo',
      map: true,
    }
  ],
};


export const formfield_org_address = {
  title: 'Alterar morada',
  description: '',
  apiEndpoint: put.orgdetails,
  method: 'PUT',
  preloadForm: false,
  reqArg: { type: 'org_editaddressdata' },
  name: '',
  id: '',
  submitLabel: 'Editar',
  rows: [
    {
      selector: 'input',
      required: true,
      type: 'text',
      name: 'address_line1',
      title: 'Morada',
      placeholder: 'Nome da rua',
      id: 'address_contact_address_line1',
      map: true,
      validation: (f) => f.length > 0,
      validationerr: 'Morada Inválida.',
    },
    {
      selector: 'input',
      required: false,
      type: 'text',
      name: 'address_line2',
      title: 'Morada (detalhes)',
      placeholder: 'Número da porta/andar/ ...',
      id: 'address_contact_address_line2',
      map: true,
    },  
    {
      selector: 'input',
      required: true,
      type: 'text',
      name: 'address_postcode',
      title: 'Código Postal',
      placeholder: '1900-000',
      id: 'address_contact_address_postcode',
      map: true,
      validation: (f) => f.length > 3,
      validationerr: 'Morada Inválida.',
    },
    {
      selector: 'input',
      required: true,
      type: 'text',
      name: 'address_city',
      title: 'Cidade',
      placeholder: 'Lisboa',
      id: 'address_contact_address_city',
      map: true,
      validation: (f) => f.length > 2,
      validationerr: 'Cidade Inválida.',
    },
    {
      selector: 'input',
      required: true,
      type: 'text',
      name: 'address_region',
      title: 'Região',
      placeholder: 'Lisboa',
      id: 'address_contact_address_region',
      map: true,
      validation: (f) => f.length > 2,
      validationerr: 'Região Inválida.',
    },
    {
      selector: 'select',
      fieldTitle: 'desc',
      fieldSubTitle: 'value',
      noselectTxt: ' Escolha...',
      required: true,
      optionType: 'static',
      options: countryCodes,
      name: 'address_country',
      title: 'País',
      id: 'address_contact_address_country',
      map: true,
    },
  ],
}

export const formfield_org_newphone = {
  title: 'Adiciona telefone',
  description: 'Acrescente um novo telefone',
  apiEndpoint: put.orgdetails,
  method: 'PUT',
  preloadForm: false,
  reqArg: { type: 'org_newphone' },
  name: '',
  id: '',
  submitLabel: 'Editar',
  rows: [
    {
      selector: 'select',
      title: 'Descrição',
      name: 'mobile_phone_des',
      required: true,
      fieldTitle: 'label',
      fieldSubTitle: null,
      noselectTxt: ' Escolha...',
      id: 'org__mobile_phone_desc',
      optionType: 'static',
      options: phoneDesc,
      map: true,
    },
    {
      selector: 'phoneinput',
      required: true,
      countryList: countryCodes,
      defaultCountry: 'PRT',
      placeholder: 'Escolha...',
      placeholderNum: '910000000',
      type: 'text',
      name: 'mobile_phone',
      title: 'Telemóvel',
      id: 'org__mobile_phone',
      map: true,
    },
  ],
};